import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import settings from '../global/settings';

import MobileFixedContent from './MobileFixedContent';
import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore } = SharedStyles;
import CustomLink from './CustomLink';

const showItem = keyframes`
  0% {
    opacity: 0;
    transform: translate(-10px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const StFilter = styled.div``;

const StFilterNav = styled.div`
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
        min-width: 130px;
        whitespace: nowrap;
    }

    a {
        display: block;
        cursor: pointer;
    }
`;

const StFilterNavWrap = styled.div`
    & > ul {
        max-width: 270px;

        ${(props) =>
            props.ignoreMobileActive &&
            css`
                max-width: none;
                width: 100%;
                display: flex;
                & > li {
                    margin-bottom: 0px;
                    & > a {
                        font-size: 12px !important;
                        padding: 20px !important;
                        opacity: 0.5;

                        &:before {
                            border-bottom: 0px !important;
                        }

                        &.active,
                        &:hover {
                            opacity: 1;
                            &:before {
                                width: calc(100% - 20px);
                            }
                        }
                    }

                    &:not(:last-child) {
                        margin-bottom: 0 !important;
                    }
                    ul {
                        a {
                            font-size: 12px;
                            &:hover,
                            &.active {
                                color: #000;
                            }

                            &.active {
                                &:before {
                                    content: '';
                                    width: 10px;
                                    height: 6px;
                                    border-left: 2px solid rgba(0, 0, 0, 0.5);
                                    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
                                    position: absolute;
                                    right: 2px;
                                    top: 11px;
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }
            `};

        @media (min-width: ${settings.bp.medium.view}) {
            max-width: none;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & > li {
            position: relative;
            width: 100%;

            @media (min-width: 1300px) {
                width: 240px;
            }

            & > a {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.05em;
                color: ${settings.color.default};
                text-decoration: none;
                position: relative;
                padding: 5px 0;

                @media (min-width: ${settings.bp.medium.view}) {
                    font-size: 12px;
                    text-align: center;
                    padding: 20px 10px;

                    &:before {
                        content: '';
                        border-bottom: 2px solid ${settings.color.default};
                        left: 10px;
                        bottom: 0;
                        width: 0;
                        position: absolute;
                        transition: width 0.3s;
                    }

                    &.active,
                    &:hover {
                        &:before {
                            width: calc(100% - 20px);
                        }
                    }
                }
            }

            &:not(:last-child) {
                margin-bottom: 16px;

                @media (min-width: ${settings.bp.medium.view}) {
                    margin-bottom: 0;
                }
            }

            ul {
                @media (min-width: ${settings.bp.medium.view}) {
                    display: none;
                    position: absolute;
                    left: 10px;
                    right: 10px;
                    top: 100%;
                    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.03);
                    background-color: #fff;
                    padding: 25px;
                    z-index: 10;

                    li {
                        opacity: 0;
                        animation: ${showItem} 600ms ease-in-out forwards;
                    }
                }

                a {
                    color: ${settings.color.grayLight};
                    font-size: 14px;
                    font-weight: 500;
                    transition: color 0.3s;
                    padding: 5px 20px 5px 0;
                    position: relative;

                    @media (min-width: ${settings.bp.medium.view}) {
                        padding: 2px 0;
                    }

                    &:hover,
                    &.active {
                        color: #000;
                    }

                    &.active {
                        &:before {
                            content: '';
                            width: 10px;
                            height: 6px;
                            border-left: 2px solid rgba(0, 0, 0, 0.5);
                            border-bottom: 2px solid rgba(0, 0, 0, 0.5);
                            position: absolute;
                            right: 2px;
                            top: 11px;
                            transform: rotate(-45deg);

                            @media (min-width: ${settings.bp.medium.view}) {
                                display: none;
                            }
                        }
                    }
                }
            }

            &:hover {
                @media (min-width: ${settings.bp.medium.view}) {
                    & > ul {
                        display: block;
                    }

                    & > a:before {
                        width: calc(100% - 20px);
                    }
                }
            }
        }
    }
`;

const StFilterContent = styled.div``;

const StFixedMenuMobileBtn = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 49;
    background-color: #fff;
    padding: 20px;
    text-align: center;

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }

    a {
        min-width: 182px;
    }

    ${(props) =>
        props.ignoreMobileActive &&
        css`
            display: none;
        `};
`;

function FilterItem(props) {
    const { text, onClick, isActive, index, useDelay, items, ...restProps } =
        props;
    return (
        <li style={{ animationDelay: useDelay ? `${index * 50}ms` : `0ms` }}>
            <CustomLink
                className={isActive ? 'active' : undefined}
                onClick={onClick}
                {...restProps}
            >
                {text}
            </CustomLink>
            {items && <FilterItems items={items} useDelay />}
        </li>
    );
}

function FilterItems(props) {
    const { items, useDelay } = props;
    return (
        <ul>
            {items.map((item, index) => (
                <FilterItem
                    key={item.id || index}
                    {...item}
                    index={index}
                    useDelay={useDelay}
                />
            ))}
        </ul>
    );
}

export default function Filter(props) {
    const { items, mobileText, ignoreMobileActive } = props;
    const [isMobileActive, toggleMobileActive] = useState(false);

    useEffect(() => {
        if (window.innerWidth >= 1024) {
            document.body.style.paddingBottom = 0 + 'px';
        } else {
            document.body.style.paddingBottom = 90 + 'px';
        }
    }, []);

    return (
        <StFilter>
            {items && (
                <StFilterNav>
                    <MobileFixedContent
                        show={isMobileActive}
                        toggleShow={toggleMobileActive}
                        ignoreMobileActive={ignoreMobileActive}
                    >
                        <StFilterNavWrap
                            ignoreMobileActive={ignoreMobileActive}
                        >
                            <FilterItems items={items} />
                        </StFilterNavWrap>
                    </MobileFixedContent>
                    <StFixedMenuMobileBtn
                        ignoreMobileActive={ignoreMobileActive}
                    >
                        <StLinkMore>
                            <CustomLink
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleMobileActive(!isMobileActive);
                                }}
                            >
                                {mobileText}
                            </CustomLink>
                        </StLinkMore>
                    </StFixedMenuMobileBtn>
                </StFilterNav>
            )}
            <StFilterContent>{props.children}</StFilterContent>
        </StFilter>
    );
}
