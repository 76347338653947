import React from 'react';

import styled, { css } from 'styled-components';
import * as SharedStyles from '../global/SharedStyles';
import ScrollableBlock from './ScrollableBlock';

import settings from '../global/settings';
const { StLinkMore } = SharedStyles;
import CustomLink from './CustomLink';

const StListOfImg = styled.div`
    .StLinkMore {
        text-align: center;

        a {
            @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                padding: 15px 30px;
                min-width: 236px;
            }

            @media (min-width: ${settings.bp.small.view}) {
                min-width: 300px;
            }
        }
    }

    ${(props) =>
        props.mobileScrollable &&
        css`
            @media (min-width: ${settings.bp.medium.view}) {
                .StListOfWrap {
                    display: block;
                }

                .StScrollableBlockInner {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                .StScrollableBlockInner {
                    white-space: nowrap;
                }

                .StListOfImgItem {
                    width: 200px;
                    display: inline-block;
                    vertical-align: top;
                }

                .StListOfImgItemBg {
                    height: 260px;
                    padding-bottom: 0;
                }
            }
        `};
`;

const StListOfWrap = styled.div.attrs((props) => ({
    className: 'StListOfWrap',
}))`
    display: flex;
    flex-wrap: wrap;
`;

const StListOfImgItem = styled.div.attrs((props) => ({
    className: 'StListOfImgItem',
}))`
    white-space: normal;
    width: calc(33.333% - 20px);
    margin: 0 10px 30px;

    @media (min-width: ${settings.bp.small.view}) {
        width: calc(33.333% - 32px);
        margin: 0 16px 70px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: calc(25% - 32px);
        ${(props) =>
            props.setWidth &&
            css`
                width: calc(33.333% - 40px);
            `};
    }

    h3 {
        margin: 0;
        text-align: center;
        font-size: 15px;
        font-weight: normal;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 16px;
        }
    }

    ${(props) =>
        props.styleWidth50 &&
        css`
            width: 100%;

            .StListOfImgItemBg {
                padding-bottom: 50%;
            }

            h3 {
                font-weight: 500;
                text-transform: uppercase;
            }

            @media (min-width: ${settings.bp.small.view}) {
                width: 100%;
            }

            @media (min-width: ${settings.bp.medium.view}) {
                width: calc(50% - 32px);

                .StListOfImgItemBg {
                    padding-bottom: calc(60% - 19px);
                }

                h3 {
                    font-weight: normal;
                    text-transform: none;
                }
            }
        `};
`;

const StListOfImgItemBg = styled.div.attrs((props) => ({
    className: 'StListOfImgItemBg',
}))`
    padding-bottom: 140%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 20px;
`;

function ListOfImgItem(props) {
    const { img, title, styleWidth50, href, setWidth } = props;
    return (
        <StListOfImgItem styleWidth50={styleWidth50} setWidth={setWidth}>
            <CustomLink href={href}>
                {img && (
                    <StListOfImgItemBg
                        style={{ backgroundImage: `url(${img})` }}
                    />
                )}
                {title && <h3>{title}</h3>}
            </CustomLink>
        </StListOfImgItem>
    );
}

export default function ListOfImg(props) {
    const { items, mobileScrollable, btnMoreHref, btnTitle } = props;
    let setWidth = items.length <= 3 ? true : false;
    const inner = (
        <>
            {items.map((item, index) => (
                <ListOfImgItem key={item.id || index} {...item} setWidth={setWidth} />
            ))}
        </>
    );

    return (
        <StListOfImg mobileScrollable={mobileScrollable}>
            {mobileScrollable ? (
                <ScrollableBlock>{inner}</ScrollableBlock>
            ) : (
                <StListOfWrap>{inner}</StListOfWrap>
            )}
            {btnMoreHref && (
                <StLinkMore styleBig>
                    <CustomLink href={btnMoreHref}>{btnTitle}</CustomLink>
                </StLinkMore>
            )}
        </StListOfImg>
    );
}
