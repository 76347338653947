import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StLink, StHoverOverlay } = SharedStyles;
import CustomLink from './CustomLink';

const StProductStyle = styled.section`
    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const StProductStyleItem = styled.div`
    display: block;
    position: relative;
    overflow: hidden;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 20px 0;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 50%;
        padding: 0;
    }

    &:hover {
        .productStyleHidden {
            opacity: 1;
            margin-top: 0;
        }

        .productStyleVisible {
            opacity: 0;
            margin-top: 40px;
        }
    }
`;

const StProductStyleBg = styled.div`
    background-size: cover;
    background-position: center;
    padding-bottom: 100%;

    @media (min-width: ${settings.bp.small.view}) {
        width: 400px;
        height: 400px;
        margin: 0 auto;
        padding-bottom: 0;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }
`;

const StProductStyleHoverText = styled.div`
    padding: 20px 40px 60px;

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 13%;
    }

    h2 {
        font-size: 22px;
        margin: 0 0 12px;
        font-weight: 500;
        letter-spacing: 0.1em;
        line-height: 1.87;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 28px;
            margin: 0 0 16px;
        }
    }

    h3 {
        font-size: 16px;
        margin: 0 0 26px;
        font-weight: normal;
        line-height: 1.56;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 0.1em;
            line-height: 1.87;
        }
    }
`;

function ProductStyleItem(props) {
    const { bg, title, text, linkMoreText, linkMoreHref } = props;
    return (
        <StProductStyleItem>
            {bg && (
                <StProductStyleBg style={{ backgroundImage: `url(${bg})` }} />
            )}
            <StHoverOverlay>
                <StProductStyleHoverText>
                    {title && <h2>{title}</h2>}
                    {text && <h3>{text}</h3>}
                    {linkMoreText && linkMoreHref && (
                        <StLink>
                            <CustomLink href={linkMoreHref}>
                                {linkMoreText}
                            </CustomLink>
                        </StLink>
                    )}
                </StProductStyleHoverText>
            </StHoverOverlay>
        </StProductStyleItem>
    );
}

export default function ProductStyle(props) {
    const { items } = props;
    return (
        <StProductStyle>
            {items.map((item, index) => (
                <ProductStyleItem key={item.id || index} {...item} />
            ))}
        </StProductStyle>
    );
}
