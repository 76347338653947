import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../../global/settings';

import ColsArticle from './ColsArticle';

const StArticlesWrap = styled.div.attrs((props) => ({
    className: 'StArticlesWrap',
}))`
    ${(props) =>
        props.maxWidthMedium &&
        css`
            margin-left: auto;
            margin-right: auto;
            max-width: ${settings.bp.medium.view};
        `};
`;

const StArticlesRow = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (min-width: ${settings.bp.small.view}) {
        margin: 0 -20px 0;
    }

    .StArticlesItem {
        margin-bottom: 40px;

        @media (min-width: ${settings.bp.small.view}) {
            margin: 0 20px 40px;
            width: calc(50% - 40px);
        }
    }

    ${(props) =>
        props.styleSmall &&
        css`
            margin-left: -10px;
            margin-right: -10px;

            @media (min-width: ${settings.bp.small.view}) {
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;
            }

            .StArticlesItem {
                margin: 0 10px 20px;
                width: calc(50% - 20px);

                @media (min-width: ${settings.bp.small.view}) {
                    margin: 0 0 40px;
                    width: 25%;
                }
            }
        `};
`;

function ColsArticlesRow(props) {
    const { items, styleSmall, styleSizeBig, styleColorGray } = props;
    return (
        <StArticlesRow styleSmall={styleSmall}>
            {items.map((item, index) => (
                <ColsArticle
                    key={item.id || index}
                    {...item}
                    styleSmall={styleSmall}
                    styleSizeBig={styleSizeBig}
                    styleColorGray={styleColorGray}
                />
            ))}
        </StArticlesRow>
    );
}

export default function ColsArticles(props) {
    const { rows, maxWidthMedium } = props;
    return (
        <StArticlesWrap maxWidthMedium={maxWidthMedium}>
            {rows.map((item, index) => (
                <ColsArticlesRow key={item.id || index} {...item} />
            ))}
        </StArticlesWrap>
    );
}
