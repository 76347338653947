import React from 'react';

import styled from 'styled-components';

import settings from '../global/settings';

const StModal = styled.div`
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
`;

const StModalWrapper = styled.div`
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    @media (min-width: ${settings.bp.small.view}) {
        margin: 10% 60%;
    }
`;

const StModalContent = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
`;

const StCloseButton = styled.div`
    color: #aaa;
    float: right;
    margin-right: 14px;
    font-size: 28px;
    &:hover {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const StLogo = styled.div`
    width: 30%;
    margin: auto;
    padding-bottom: 20px;
`;

const StModalTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 600;
    letter-spacing: 0.07em;
`;

const StModalSubTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    opacity: 0.5;
`;

export default function QuestionModal(props) {
    const { onClick, text } = props;
    return (
        <StModal onClick={onClick}>
            <StModalWrapper>
                <StCloseButton onClick={onClick}>&times;</StCloseButton>
                <StModalContent>{text && <div>{text}</div>}</StModalContent>
            </StModalWrapper>
        </StModal>
    );
}
