import styled, { css } from 'styled-components';
import settings from '../../global/settings';

export const StSliderInfo = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        flex-direction: row-reverse;
        min-height: 668px;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export const StSliderInfoText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    color: #000;
    text-align: center;
    background-color: ${settings.color.yellow};
    width: 100%;
    padding: 26px 20px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 45%;
        padding: 6%;
        justify-content: center;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 39%;
    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.05em;
        margin: 0;
        text-transform: uppercase;
    }

    p,
    hr {
        display: none;

        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }
    }

    p {
        margin: 0;
    }

    hr {
        padding: 0;
        margin: 30px auto;
        border: none;
        border-bottom: 1px solid #000;
        width: 70px;
    }

    a {
        padding: 15px 30px;
        min-width: 150px;
        margin-top: 20px;

        @media (min-width: ${settings.bp.small.view}) {
            margin-top: 70px;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

export const StSliderInfoBg = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 70%;

    @media (min-width: ${settings.bp.small.view}) {
        width: 55%;
        padding-bottom: 0;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 61%;
    }
`;
