import React from 'react';
import styled, { css } from 'styled-components';
import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
const { StMenuCloseBtn } = SharedStyles;
import CustomLink from './CustomLink';
const { StLinkMore } = SharedStyles;

const StMobileFilterFixedContent = styled.div`
    @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
        transition: background-color 0.3s;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 101;
        background-color: transparent;
        transform: translate(0, 100%);
    }

    ${(props) =>
        props.show &&
        css`
            @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                background-color: rgba(0, 0, 0, 0.5);
                transform: translate(0, 0);
            }
        `};
`;

const StMobileFilterFixedContentInner = styled.div`
    @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
        max-height: calc(100% - 60px);
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #fff;
        padding: 30px;
        transition: transform 0.3s;
        transform: translate(0, 100%);

        ${(props) =>
            props.show &&
            css`
                @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                    transform: translate(0, 0);
                }
            `};
    }
`;

const StMobileFilterFixedContentBtn = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #fff;

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }
`;

const StButtonItem = styled.div.attrs((props) => ({
    className: 'StButtonItem',
}))`
    &:not(:last-child) {
        margin-right: 30px;
    }

    a {
        padding: 10px 30px;
    }
`;

export default function MobileFilterFixedContent(props) {
    const { btnItems, show, toggleShow, ignoreMobileActive } = props;
    return (
        <>
            {!ignoreMobileActive && (
                <StMobileFilterFixedContent
                    show={show}
                    onClick={() => toggleShow(!show)}
                >
                    <StMobileFilterFixedContentInner
                        show={show}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {props.children}
                    </StMobileFilterFixedContentInner>
                    <StMobileFilterFixedContentBtn>
                        {btnItems &&
                            btnItems.map((item, index) => {
                                return (
                                    <StButtonItem>
                                        <StLinkMore
                                            inversionColor={index === 0}
                                        >
                                            <CustomLink
                                                href="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    item.method();
                                                }}
                                            >
                                                {item.text}
                                            </CustomLink>
                                        </StLinkMore>
                                    </StButtonItem>
                                );
                            })}
                    </StMobileFilterFixedContentBtn>
                </StMobileFilterFixedContent>
            )}
            {ignoreMobileActive && <>{props.children}</>}
        </>
    );
}
