import React from 'react';

import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-react-intl';
// import settings from '../global/settings';
import Container from '../Container';
// import * as SharedStyles from '../global/SharedStyles';
import { StLinkMore, CssButton } from '../../global/SharedStyles'

const StImageWrapper = styled.div`
  h1 {
    text-align: center;
  }`;
const StTitleAndBtnWrapper = styled.div`
  max-width: 30%;
  margin-left: 12px;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;  
  justify-content: flex-start;
  & h2 {
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 500;
  }
  & span {
    display: flex;
    align-items: end;
    height: 50%;
  }
  & a {
    margin-top: 36px;
  }
`;

const StButton = styled.div`
${CssButton};
  padding: 8px 2px 8px 2px;
  margin-top: 24px;
`;
export default function LandingPageImgButton(props) {
    const { src, title, link } = props;
    return (
        <StImageWrapper>
            <Container>
              <img src={src} />
              
              <StTitleAndBtnWrapper>
                <span><h2>{title}</h2></span>
                <StButton>
                  <Link to={link}>
                  Join The Team
                  </Link>
                </StButton>
              </StTitleAndBtnWrapper>
                
            </Container>
        </StImageWrapper>
    );
}
