import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import Container from './Container';
import Slider from './Slider/Slider';
import * as SharedStyles from '../global/SharedStyles';
const { StLink } = SharedStyles;

const StArticles = styled.div`
    .container {
        @media (max-width: calc(${settings.bp.small.view} - 1px)) {
            margin: 0;
            width: 100%;
        }
    }

    @media (min-width: ${settings.bp.small.view}) {
        .StSlider {
            display: flex;
            flex-wrap: wrap;
        }
    }
`;

const StArticleItem = styled.div`
    width: 346px !important;
    padding: 0 0 0 20px;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 0;
        display: flex;
        width: 100% !important;
        margin-bottom: 8px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 50% !important;
        margin-bottom: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const StArticleBg = styled.div`
    height: 360px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: ${settings.bp.small.view}) {
        width: 45%;
        height: auto;
    }
`;

const StArticleDesc = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 20px 30px 35px;
    background-color: #fafafa;
    text-transform: uppercase;

    @media (min-width: ${settings.bp.small.view}) {
        width: 55%;
        padding: 5%;
    }

    h2,
    h3 {
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: 0.1em;
    }

    h2 {
        font-size: 14px;
        margin: 16px 0 32px;

        @media (min-width: ${settings.bp.small.view}) {
            margin-bottom: 0;
        }

        &:first-child {
            margin: 0;
        }
    }

    h3 {
        margin: 0 0 4px;
        font-size: 14px;
    }

    h2 ~ h3,
    hr,
    p {
        display: none;

        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }
    }

    p {
        font-size: 9px;
        letter-spacing: 0.03em;
        margin: 0 0 26px;
    }

    hr {
        margin: 20px auto;
        border: none;
        border-bottom: 1px solid ${settings.color.gray3};
        width: 53px;
    }
`;

function ArticlesItem(props) {
    const { bg, type, typeColor, title, subtitle, date, linkText, linkHref } =
        props;
    return (
        <StArticleItem>
            {bg && <StArticleBg style={{ backgroundImage: `url(${bg})` }} />}
            <StArticleDesc>
                {type && <h3 style={{ color: typeColor }}>{type}</h3>}
                {title && <h2>{title}</h2>}
                <hr />
                {subtitle && <h3>{subtitle}</h3>}
                {date && <p>{date}</p>}
                {linkText && linkHref && (
                    <StLink>
                        <a href={linkHref}>{linkText}</a>
                    </StLink>
                )}
            </StArticleDesc>
        </StArticleItem>
    );
}

export default function Articles(props) {
    const { items } = props;
    return (
        <StArticles>
            <Container>
                <Slider onlyMobile>
                    {items.map((item, index) => (
                        <ArticlesItem key={item.id || index} {...item} />
                    ))}
                </Slider>
            </Container>
        </StArticles>
    );
}
