import React, { useState } from 'react';
import VideoLightBox from '../VideoLightBox';
import styled, { css } from 'styled-components';

import settings from '../../global/settings';
import CustomLink from '../CustomLink';

export const StEventDetail = styled.div`
    cursor: default;
    margin-bottom: 80px;
    display: flex;
    flex-wrap: wrap;
    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 120px;
        flex-wrap: nowrap;
    }

    h1,
    h2,
    h3,
    h4 {
        text-transform: uppercase;
    }

    h1 {
        height: 50px;
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.79;
        letter-spacing: 2px;
        text-align: left;
        padding-left: 0px;
        padding-top: 30px;
        margin-bottom: 40px;
        display: inline-block;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 24px;
            padding-left: 20px;
        }
    }

    h2 {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 2px;
        text-align: left;
        padding-left: 0px;
        margin-bottom: 9px;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 14px;
            padding-left: 22px;
            padding-right: 7px;
        }
    }

    h3,
    h4 {
        font-size: 13px;
        letter-spacing: 0.1em;
    }

    h3 {
        margin: 0 0 5px;
        font-weight: bold;
    }

    h4 {
        margin: 0;
        font-weight: normal;
    }

    p {
        text-indent: 30px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        @media (min-width: ${settings.bp.small.view}) {
            text-indent: 0px;
        }
    }
`;

export const StEventDetailAboutContainer = styled.div`
    flex-wrap: wrap;
    @media (min-width: ${settings.bp.small.view}) {
        flex-wrap: nowrap;
        h2 {
            text-align: center;
        }
    }
    display: flex;
    padding-bottom: 10px;
`;

export const StEventDetailWhenContainer = styled.div`
    background-color: #f5f5f5;
    margin-left: 20px;
    margin-right: 15px;
    h2 {
        text-transform: uppercase;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14pt;
        line-height: 1.11;
        letter-spacing: 1.46px;
    }
    h4 {
        text-transform: uppercase;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }
    @media (min-width: ${settings.bp.small.view}) {
        margin-left: 13px;
        margin-right: 0px;
    }
`;

export const StEventDetailInstructorContainer = styled.div`
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 24px;
    border: solid 1px #d5d5d5;
    padding-bottom: 16px;

    h2 {
        text-transform: uppercase;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 21px;
        font-size: 14pt;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 2px;
        text-align: center;
    }
    p {
        text-transform: uppercase;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (min-width: ${settings.bp.small.view}) {
        margin-left: 13px;
        margin-right: 0px;
    }
`;

export const StEventDetailIconTitle = styled.div`
    margin-left: 20px;
    padding-top: 31px;
    padding-bottom: 11px;
    overflow: auto;
    img {
        height: 14px;
        width: 14px;
        float: left;
    }
    h3 {
        text-transform: uppercase;
        float: left;
        padding-left: 10px;
    }

    @media (min-width: ${settings.bp.small.view}) {
        img {
            height: 20px;
            width: 20px;
        }
        h3 {
            padding-top: 3px;
        }
    }
`;

export const StEventDetailLine = styled.div`
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 31px;
    height: 1px;
    background-color: #cacaca;
`;

export const StEventDetailLeftCol = styled.div`
    width: 100%;
    @media (min-width: ${settings.bp.small.view}) {
        min-width: 64%;
    }
`;

export const StEventDetailRightCol = styled.div`
    width: 100%;
    @media (min-width: ${settings.bp.small.view}) {
        min-width: 36%;
    }
`;

export const StEventDetailAboutLeftCol = styled.div`
    flex-grow: 1;
    min-width: 25%;
    text-align: left;
`;

export const StEventDetailAboutRightCol = styled.div`
    flex-grow: 2;
    text-align: left;
    padding-right: 0px;
    @media (min-width: ${settings.bp.small.view}) {
        padding-right: 70px;
    }
`;

export const StEventDetailFAQLink = styled.div`
    p {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: 1.86px;
        color: #9b9b9b;
        padding-top: 13px;
        padding-bottom: 45px;
    }
`;

export const StEventDetailMainImg = styled.div`
    min-height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 380px;
    }

    .container {
        @media (min-width: ${settings.bp.small.view}) {
            padding: 5% 0;
        }
    }
`;

export const StEventDetailInstructorImg = styled.div`
    min-height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 14px;
    margin-bottom: 14px;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 152px;
    }

    .container {
        @media (min-width: ${settings.bp.small.view}) {
            padding: 5% 0;
        }
    }
`;

export const StDirectionButtonLink = styled.div`
    display: block;
    text-align: left;
    margin-left: 20px;
    margin-top: 11px;
    margin-bottom: 31px;
    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: -20px;
    }
    p {
        cursor: pointer;
        font-size: 10px;
        font-weight: 500;
        text-decoration-line: underline;

        &:before {
            display: none;
        }
    }
`;

export const StUpcomingTitle = styled.div`
    h5 {
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }
`;

export function EventsList(props) {
    const { items } = props;
    return (
        <>
            <StEventsList>
                {items.map((item, index) => (
                    <EventsItem key={item.id || index} {...item} />
                ))}
            </StEventsList>
        </>
    );
}

const StEventsListItemImg = styled.div`
    position: relative;
    width: 85px;
    min-width: 85px;
    height: 85px;

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 28px;
        width: 116px;
        min-width: 116px;
        height: 94px;
    }
`;

const StEventsListItemInfo = styled.div`
    width: 116px;
    min-width: 116px;
    margin-left: 20px;
    display: none;
    margin-left: auto;
    padding-left: 11px;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
    }
`;

const StEventsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @media (min-width: ${settings.bp.medium.view}) {
        margin: 0;
        margin-bottom: 100px;
    }
`;

const StEventListLine = styled.div`
    display: none;
    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
        width: calc(100% - 16px);
        height: 1px;
        background-color: #979797;
        opacity: 50%;
        align-self: flex-start;
        margin-bottom: 25px;
    }
`;

const StEventListItemContainer = styled.div`
    flex-direction: column;
    flex: 1;
`;

const StEventListDetailRow = styled.div`
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
`;

const StEventsListItem = styled.div`
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 10px 0;
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
    margin: 0 10px 20px;

    @media (min-width: ${settings.bp.medium.view}) {
        border-bottom: none;
        padding-left: 5px;
        padding-right: 5px;
        border-top: none;
        margin: 0;
    }

    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    h3,
    h4 {
        text-transform: uppercase;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 0.08em;
    }

    h3 {
        font-size: 12px;
        text-align: center;
        padding: 0 10px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 16px;
            text-align: left;
            padding: 0;
        }
    }

    h4 {
        font-size: 14px;
        margin-bottom: 6px;
    }

    h5 {
        font-size: 13px;
        letter-spacing: 0.05em;
        font-weight: normal;
    }

    h6 {
        font-size: 9px;
        letter-spacing: 0.03em;
        font-weight: normal;
    }
`;

function EventsItem(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [eventItem, setEventItem] = useState({});

    const {
        img,
        videoOptions,
        title,
        dayWeek,
        date,
        time,
        link,
        ...restProps
    } = props;

    let incomingEventItem = {
        link: link,
        title: title,
        img: img,
        dayWeek: dayWeek,
        date: date,
        time: time,
    };

    if (Object.keys(eventItem).length == 0) {
        setEventItem(incomingEventItem);
    } else {
        // check for any changes
        if (
            incomingEventItem.link !== eventItem.link ||
            incomingEventItem.title !== eventItem.title ||
            incomingEventItem.img !== eventItem.img ||
            incomingEventItem.dayWeek !== eventItem.dayWeek ||
            incomingEventItem.date !== eventItem.date ||
            incomingEventItem.time !== eventItem.time
        ) {
            console.log('CHANGED', incomingEventItem);
            setEventItem(incomingEventItem);
        }
    }
    return (
        <CustomLink style={{ width: '50%' }} href={link}>
            <StEventsListItem>
                <StEventListItemContainer>
                    <StEventListLine />
                    <StEventListDetailRow>
                        <StEventsListItemImg
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalOpen(!isModalOpen);
                            }}
                        >
                            <VideoLightBox
                                useImgOverlay={videoOptions}
                                btnSizeSmall
                                bgImg={img}
                                videoOptions={videoOptions}
                                isOpen={isModalOpen}
                                {...restProps}
                            />
                        </StEventsListItemImg>
                        {title && <h3>{title}</h3>}
                        <StEventsListItemInfo>
                            {dayWeek && <h4>{dayWeek}</h4>}
                            {date && <h5>{date}</h5>}
                            {time && <h6>{time}</h6>}
                        </StEventsListItemInfo>
                    </StEventListDetailRow>
                </StEventListItemContainer>
            </StEventsListItem>
        </CustomLink>
    );
}
