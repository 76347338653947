import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import FieldRadio from './FieldRadio';

const StFieldRadios = styled.div.attrs((props) => ({
    className: 'StFieldRadios',
}))`
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;

    .StFieldRadio {
        width: 100%;
    }

    ${(props) =>
        props.typeList &&
        css`
            .StFieldRadio {
                @media (min-width: ${settings.bp.small.view}) {
                    width: 174px;
                    padding-right: 20px;
                }
            }
        `};
`;

export default function FieldRadios(props) {
    const { items, typeList, radioStyle2 } = props;
    return (
        <StFieldRadios typeList={typeList}>
            {items.map((item, index) => (
                <FieldRadio
                    radioStyle2={radioStyle2}
                    key={index.id || index}
                    {...item}
                />
            ))}
        </StFieldRadios>
    );
}
