import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../../global/settings';

export const StProductDetail = styled.div`
    margin-bottom: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 0px;
    }

    h1,
    h2,
    h3,
    h4 {
        text-transform: uppercase;
    }

    h1 {
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        line-height: 1.08;
        letter-spacing: 0.05em;
        margin: 0 0 10px;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 24px;
        }
    }

    h2 {
        line-height: 1.5;
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: normal;
        margin: 0 0 34px;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 16px;
        }
    }

    h3,
    h4 {
        font-size: 13px;
        letter-spacing: 0.1em;
    }

    h3 {
        margin: 0 0 5px;
        font-weight: bold;
    }

    h4 {
        margin: 0;
        font-weight: normal;
    }
`;
export const StProductDetailWrap = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        align-items: flex-start;
    }
`;

export const StProductDetailCol = styled.div`
    position: relative;

    @media (min-width: ${settings.bp.small.view}) {
        width: 50%;
    }

    &:first-child {
        margin-bottom: 10px;

        @media (min-width: ${settings.bp.small.view}) {
            padding-right: 2%;
            margin-bottom: 0;
        }

        @media (min-width: ${settings.bp.medium.view}) {
            padding-right: 5%;
        }
    }

    &:last-child {
        @media (min-width: ${settings.bp.small.view}) {
            padding-left: 2%;
        }

        @media (min-width: ${settings.bp.medium.view}) {
            padding-left: 5%;
        }
    }
`;

export const StProductDetailType = styled.div`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 12px;
    letter-spacing: 0.08em;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 13px;
    }
`;

export const StProductDetailMainImg = styled.div`
    position: absolute;
    width: 200px;
    height: 300px;
    right: 0;
    top: calc(50% - 40px);
    transform: translate(0, -50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
    }

    @media (min-width: ${settings.bp.large.view}) {
        width: 260px;
        height: 400px;
    }
`;

export const StProductDetailShopBtns = styled.div.attrs((props) => ({
    className: 'StProductDetailShopBtns',
}))`
    display: flex;
    margin-bottom: 60px;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 60px;
    }

    .StLinkMore {
        margin-right: 10px;
        margin-bottom: 10px;
        min-width: 200px;
    }

    a {
        width: 100%;
    }

    .StDDButton:not(:last-child) {
        margin-right: 30px;
    }

    ${(props) =>
        props.stack &&
        css`
            display: block;

            .StDDButton {
                display: flex;
                justify-content: center;
                margin: auto;
            }
            .StLinkMore {
                margin-right: 0px;
            }
        `};
`;

export const StProductDetailLoginText = styled.div`
    font-size: 14px;
    color: #7d7d7d;
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
`;

export const StProductSignInButton = styled.div`
    width: 200px;
    margin-left: 30px;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    padding: 11px 0px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;

    &:hover {
        background-color: #000;
        color: #fff;
    }
`;
