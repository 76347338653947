import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore, StLink } = SharedStyles;
import CustomLink from './CustomLink';

const StImgTextGrid = styled.div`
    ${(props) =>
        props.styleSquare &&
        css`
            .StImgTextGridRow {
                margin-bottom: 0;
            }

            .StImgTextGridImg {
                padding: 0;
            }

            .StImgTextGridImg,
            .StImgTextGridText {
                @media (min-width: ${settings.bp.small.view}) {
                    width: 50%;
                }
            }

            .StImgTextGridText {
                @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                    min-height: 100vw;
                    padding: 30px;
                }

                @media (min-width: ${settings.bp.medium.view}) {
                    padding-left: 6%;
                    padding-right: 6%;
                }
            }

            .StLinkMore a {
                min-width: 180px;
                padding: 16px 30px;
            }
        `};

    ${(props) =>
        props.rowRevers &&
        css`
            .StImgTextGridRow {
                &:nth-child(even) {
                    @media (min-width: ${settings.bp.small.view}) {
                        flex-direction: row;
                    }
                }
                &:nth-child(odd) {
                    @media (min-width: ${settings.bp.small.view}) {
                        flex-direction: row-reverse;
                    }
                }
            }
        `};
`;

const StImgTextGridRow = styled.section.attrs((props) => ({
    className: 'StImgTextGridRow',
}))`
    ${(props) =>
        props.split4060 &&
        css`
            padding: 0px 60px;

            .StImgTextGridText {
                @media (min-width: ${settings.bp.small.view}) {
                    padding: 30px;
                    width: calc(60%);
                }

                @media (min-width: ${settings.bp.medium.view}) {
                    padding: 0 60px;
                    width: calc(60%);
                }
            }

            .StImgTextGridImg {
                @media (min-width: ${settings.bp.small.view}) {
                    padding: 30px;
                    width: calc(40%);
                }

                @media (min-width: ${settings.bp.medium.view}) {
                    padding: 0 60px;
                    width: calc(40%);
                }
            }
        `};
    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 70px;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }

    &:nth-child(even) {
        @media (min-width: ${settings.bp.small.view}) {
            flex-direction: row-reverse;

            .StImgTextGridImg {
                padding-left: 0;
            }
        }
    }

    &:nth-child(odd) {
        @media (min-width: ${settings.bp.small.view}) {
            .StImgTextGridImg {
                padding-right: 0;
            }
        }
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 28px;
            color: ${settings.color.gray};
            line-height: 1.79;
            margin: 0 0 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 20px;
            letter-spacing: 0.08em;
        }
    }

    .StLinkMore {
        margin: 26px 0 0;

        @media (min-width: ${settings.bp.medium.view}) {
            margin: 46px 0 0;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    ${(props) =>
        props.styleWhiteColor &&
        css`
            color: #fff;

            h2,
            p {
                color: #fff;
            }

            .StLinkMore a {
                color: #000000;
                background-color: #fff;
            }
        `};
`;

const StImgTextGridImg = styled.div.attrs((props) => ({
    className: 'StImgTextGridImg',
}))`
    padding: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 30px;
        width: calc(50% - 30px);
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 0 60px;
        width: calc(50% - 60px);
    }

    img {
        width: 100%;
    }
`;

const StImgTextGridText = styled.div.attrs((props) => ({
    className: 'StImgTextGridText',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 20px 70px;
    text-align: center;

    @media (min-width: ${settings.bp.small.view}) {
        width: calc(50% + 30px);
        padding: 30px 6%;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: calc(50% + 60px);
        padding: 30px 11%;
    }

    *:last-child {
        margin-bottom: 0;
    }

    ${(props) =>
        props.color &&
        css`
            h2 {
                color: ${props.color} !important;
            }
        `};
    ${(props) =>
        props.styleSmall &&
        css`
            h2 {
                font-size: 20px;
                font-weight: 500;
            }
        `};
`;

const StImgTextGridTag = styled.div`
    display: flex;
    margin-bottom: 50px;

    span {
        text-align: center;
        width: 115px;
        height: 39px;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        display: block;
        padding: 7px;
    }
`;

function ImageTextGridItemTag(props) {
    const { text, tagBg } = props;
    return <span style={{ backgroundColor: tagBg }}>{text}</span>;
}

function ImageTextGridItem(props) {
    const {
        rowBg,
        rowColorWhite,
        img,
        title,
        text,
        linkText,
        linkHref,
        linkStyleWhite,
        linkStyleUnderline,
        tags,
        tagImg,
        split4060,
        color,
        styleSmall,
    } = props;
    return (
        <StImgTextGridRow
            style={{ backgroundColor: rowBg || 'transparent' }}
            styleWhiteColor={rowColorWhite}
            split4060={split4060}
        >
            {img && (
                <StImgTextGridImg>
                    <img src={img} alt="" />
                </StImgTextGridImg>
            )}
            <StImgTextGridText color={color} styleSmall={styleSmall}>
                {title && <h2>{title}</h2>}
                {text && <p> {text} </p>}
                {linkText && linkHref && !linkStyleUnderline && (
                    <StLinkMore inversionColor={linkStyleWhite}>
                        <CustomLink href={linkHref}>{linkText}</CustomLink>
                    </StLinkMore>
                )}
                {linkText && linkHref && linkStyleUnderline && (
                    <StLink>
                        <CustomLink href={linkHref}>{linkText}</CustomLink>
                    </StLink>
                )}
                {tags && (
                    <StImgTextGridTag>
                        {tags.map((item, index) => (
                            <ImageTextGridItemTag
                                key={item.id || index}
                                {...item}
                            />
                        ))}
                    </StImgTextGridTag>
                )}
                {tagImg && (
                    <p>
                        <img src={tagImg} alt="" />
                    </p>
                )}
            </StImgTextGridText>
        </StImgTextGridRow>
    );
}

export default function ImageTextGrid(props) {
    const { bgImg, items, ...restProps } = props;
    return (
        <StImgTextGrid
            style={{ backgroundImage: bgImg ? `url(${bgImg})` : 'none' }}
            {...restProps}
        >
            {items.map((item, index) => (
                <ImageTextGridItem
                    key={item.id || index}
                    {...item}
                    {...restProps}
                />
            ))}
        </StImgTextGrid>
    );
}
