import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import ButtonPlay from './ButtonPlay';
import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StLink } = SharedStyles;

const StVideoBlockWrap = styled.div`
    text-align: center;

    .StLink {
        text-align: center;
        margin-top: 40px;
    }
`;

const StVideoBlock = styled.div`
    ${props =>
        props.maxWidthMedium &&
        css`
            margin-left: auto;
            margin-right: auto;
            max-width: 1022px;
        `};
`;

const StVideoBlockInner = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    background-color: #000;

    iframe,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const StVideoBlockBg = styled.div`
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    background-color: #000;
`;

export default function VideoBlock(props) {
    const [visible, setVisible] = useState(true);
    const {
        imgUrl,
        renderVideo,
        maxWidthMedium,
        linkMobileText,
        linkMobileMore,
        removePlayCircle,
    } = props;

    function autoPlayVideo(e) {
        if (!e || !e.target) return;
        const video = e.target.parentNode.querySelector('video');
        if (video) {
            video.play();
        }
    }

    return (
        <StVideoBlockWrap>
            <StVideoBlock maxWidthMedium={maxWidthMedium}>
                <ButtonPlay removeCircle={removePlayCircle} isHidden={!visible}>
                    <StVideoBlockInner>
                        {imgUrl && visible && (
                            <StVideoBlockBg
                                style={{ backgroundImage: `url(${imgUrl})` }}
                                onClick={e => {
                                    setVisible(!visible);
                                    autoPlayVideo(e);
                                }}
                            />
                        )}
                        {renderVideo
                            ? props.children
                            : !imgUrl || !visible
                            ? props.children
                            : ''}
                    </StVideoBlockInner>
                </ButtonPlay>
            </StVideoBlock>
            {linkMobileText && linkMobileMore && (
                <StLink mobileStyleLinkMore className="only-mobile">
                    <a href={linkMobileMore}>{linkMobileText}</a>
                </StLink>
            )}
        </StVideoBlockWrap>
    );
}
