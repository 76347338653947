const settings = {
    bp: {
        extraSmall: {
            view: '375px',
            width: 'calc(100% - 40px)',
        },
        midSmall: {
            view: '573px',
            width: 'calc(100% - 45px)',
        },
        small: {
            view: '768px',
            width: 'calc(100% - 50px)',
        },
        medium: {
            view: '1024px',
            width: 'calc(100% - 60px)',
        },
        large: {
            view: '1200px',
            width: 'calc(100% - 100px)',
        },
        large2: {
            view: '1400px',
            width: 'calc(100% - 120px)',
        },
        extraLarge: {
            view: '1600px',
            width: 'calc(100% - 130px)',
        },
    },
    maxBlockWidth: '1170px',
    bgOverlay: 'rgba(8, 8, 8, 0.64)',
    color: {
        default: '#000',
        dark: '#080808',
        gray: '#4a4a4a',
        gray1: '#a5a5a5',
        gray2: '#656565',
        gray3: '#979797',
        gray4: '#6b6b6b',
        grayLight: '#8b8b8b',
        grayLight2: '#9b9b9b',
        grayLight3: '#d8d8d8',
        grayLight4: '#cdcdcd',
        grayLight5: '#f9f9f9',
        grayLight6: '#fbfbfb',
        red: '#c74343',
        yellow: '#ffc22c',
        green: '#395a13',
    },
    font: {
        default: "'Roboto', 'Arial', sans-serif",
        roboto: "'Roboto', 'Arial', sans-serif",
    },
};

export default settings;
