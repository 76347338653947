import styled, { css } from 'styled-components';

import settings from '../../global/settings';

export const StSliderFavorite3 = styled.div`
    width: 320px !important;
    padding: 0 30px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 420px !important;
        padding: 0 60px;
    }

    .StProductListItemImg {
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        height: 200px;

        @media (min-width: ${settings.bp.small.view}) {
            width: 300px;
            height: 300px;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }
`;
