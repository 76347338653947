import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import Container from './Container';
import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore } = SharedStyles;

const StImageTextInfo = styled.div``;

const StImageTextInfoItem = styled.section`
    margin-bottom: 60px;

    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -60px;
    }

    @media (min-width: ${settings.bp.large.view}) {
        margin-bottom: -120px;
    }

    &:first-child .ImageTextInfoDesc {
        padding-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:nth-child(even) {
        @media (min-width: ${settings.bp.small.view}) {
            flex-direction: row-reverse;
        }

        .ImageTextInfoDesc {
            @media (min-width: ${settings.bp.small.view}) {
                margin-left: 0;
                padding-left: 30px;
                padding-right: 24px;
                margin-right: 60px;
            }

            @media (min-width: ${settings.bp.large.view}) {
                padding-left: 60px;
                padding-right: 48px;
                margin-right: 120px;
            }
        }
    }
`;

const StImageTextInfoImg = styled.div`
    margin-bottom: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 0;
        width: 50%;
        max-width: 500px;
    }

    img {
        width: 100%;
    }
`;

const StImageTextInfoDesc = styled.div.attrs((props) => ({
    className: 'ImageTextInfoDesc',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        width: 50%;
        margin-bottom: 0;
        max-width: 500px;
        padding: 80px 30px 80px 24px;
        margin-left: 60px;
    }

    @media (min-width: ${settings.bp.large.view}) {
        padding: 140px 60px 140px 48px;
        margin-left: 120px;
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.1em;
        margin: 0 0 12px;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 28px;
            color: ${settings.color.gray};
            line-height: 1.79;
            margin: 0 0 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 20px;
            letter-spacing: 0.08em;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

function ImageTextInfoItem(props) {
    const { img, title, text, linkText, linkHref, linkStyleWhite } = props;
    return (
        <StImageTextInfoItem>
            {img && (
                <StImageTextInfoImg>
                    <img src={img} alt="" />
                </StImageTextInfoImg>
            )}
            <StImageTextInfoDesc>
                {title && <h2>{title}</h2>}
                {text && <p>{text}</p>}
                {linkText && linkHref && (
                    <StLinkMore inversionColor={linkStyleWhite}>
                        <a href={linkHref}>{linkText}</a>
                    </StLinkMore>
                )}
            </StImageTextInfoDesc>
        </StImageTextInfoItem>
    );
}

export default function ImageTextInfo(props) {
    const { items } = props;
    return (
        <StImageTextInfo>
            <Container>
                {items.map((item, index) => (
                    <ImageTextInfoItem key={item.id || index} {...item} />
                ))}
            </Container>
        </StImageTextInfo>
    );
}
