import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import ScrollableBlock from './ScrollableBlock';
import Slider from './Slider/Slider';
import StSliderTools from './Slider/SliderTools';

import { StLink } from '../global/SharedStyles';

const StCompChart = styled.div``;

const StCompChartInner = styled.div`
    display: flex;
`;

// export const StCompChartItem = styled.div`
//   width: 200px !important;
//   height: auto !important;
//   padding: 0 0 0 20px;
//   text-align: center;
//   display: flex;
//   flex-direction: column;

//   @media (min-width: ${settings.bp.small.view}) {
//     width: 200px !important;
//     padding: 0 0 0 30px;
//     &: hover {
//       border: 2px solid #000;
//     }
//   }

//   img {
//     width: 100%;
//     height: auto;
//     margin-bottom: 26px;
//   }

//   h3 {
//     text-transform: uppercase;
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 1.43;
//     margin: 0 0 10px;
//     letter-spacing: 0.06em;

//     @media (min-width: ${settings.bp.small.view}) {
//       font-size: 16px;
//       line-height: 1.25;
//       margin: 0 0 30px;
//     }
//   }

//   .StLink {
//     margin-top: auto;
//   }
// `;

const StCompChartItem = styled.div`
  text-align: center;
  margin: 0 11px;
  width: 100%;
  min-width: 370px;
  max-width: 370px;

  @media (min-width: ${settings.bp.small.view}) {
    margin: 0 4px;
    padding: 28px;
    min-width: 192px;
    max-width: 230px;

    &: hover {
      border: 2px solid #000;
  }
`;

const StCompChartItemTextMobile = styled.div`
    @media (max-width: calc(${settings.bp.small.view} - 1px)) {
        position: relative;
        padding: 44px 25px;
        background-color: #fbfbfb;
        border: 3px solid #fbfbfb;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: -2px;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 12px;
            height: 12px;
            z-index: 1;
            background-color: #fbfbfb;
            border-top: 3px solid #fbfbfb;
            border-right: 3px solid #fbfbfb;
        }

        ${(props) =>
            props.colorDecor &&
            css`
                border-color: ${props.colorDecor};

                &:before {
                    border-top-color: ${props.colorDecor};
                    border-right-color: ${props.colorDecor};
                }
            `};
    }
`;

const StCompChartItemTitle = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: 0.09em;
    margin-bottom: 20px;
    display: none;

    @media (min-width: ${settings.bp.small.view}) {
        display: block;
        min-height: 80px;
    }
`;

const StCompChartItemTitleMobile = styled.div`
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: 0.08em;
    margin-bottom: 20px;

    br {
        display: none;
    }

    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }
`;

const StCompChartItemImg = styled.div`
    margin-bottom: 30px;
`;

const StCompChartItemText = styled.div`
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 14px;
        text-transform: none;
        letter-spacing: 0.02em;
        color: ${settings.color.gray};
    }

    strong {
        font-weight: bold;
        display: block;
    }

    p {
        margin: 0 0 20px;

        @media (min-width: ${settings.bp.small.view}) {
            margin: 0 0 30px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

function CompChartItem(props) {
    const { colorDecor, title, img, text, url } = props;
    return (
        <a href={url}>
            <StCompChartItem colorDecor={colorDecor}>
                {title && (
                    <StCompChartItemTitle
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                )}
                {img && (
                    <StCompChartItemImg>
                        <img src={img} alt={title} />
                    </StCompChartItemImg>
                )}
                <StCompChartItemTextMobile colorDecor={colorDecor}>
                    {title && (
                        <StCompChartItemTitleMobile
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    )}
                    {text && (
                        <StCompChartItemText
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    )}
                </StCompChartItemTextMobile>
            </StCompChartItem>
        </a>
    );
}

export default function CompChart(props) {
    const { items } = props;
    return (
        <>
            {/* <StCompChart>
        <ScrollableBlock>
          <StCompChartInner>
            {items.map((item, index) => (
              <CompChartItem key={item.id || index} {...item} />
            ))}
          </StCompChartInner>
        </ScrollableBlock>
      </StCompChart> */}
            <Slider>
                {items.map((item, index) => (
                    <CompChartItem key={item.id || index} {...item} />
                ))}
            </Slider>
        </>
    );
}
