import React from 'react';

import styled, { css } from 'styled-components';
import settings from '../global/settings';
import { Container, Spacing } from '../index';

const StColsTitleText = styled.div`
    hr {
        border: 1px solid #757575;
        margin: 30px 0 0;

        @media (min-width: ${settings.bp.small.view}) {
            margin-left: -30px;
            margin-right: -30px;
        }

        @media (min-width: ${settings.bp.medium.view}) {
            margin-top: 60px;
        }
    }
`;

const StColsTitleWrap = styled.div`
    ${(props) =>
        props.useCols &&
        css`
            @media (min-width: ${settings.bp.small.view}) {
                display: flex;

                .StColsTitle {
                    width: 36%;
                    padding-right: 4%;
                }

                .StColsText {
                    width: 64%;
                }
            }
        `};
`;

const StColsTitle = styled.div.attrs((props) => ({
    className: 'StColsTitle',
}))`
    h1,
    h2 {
        font-weight: 500;
        letter-spacing: 0.07em;
        margin: 0 0 10px;
        text-transform: uppercase;
        text-align: center;

        @media (min-width: ${settings.bp.small.view}) {
            text-align: left;
        }
    }

    h1 {
        font-size: 18px;
    }

    h2 {
        font-size: 16px;
    }
`;

const StColsText = styled.div.attrs((props) => ({
    className: 'StColsText',
}))`
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.02em;

    p {
        margin: 0 0 24px;
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

export default function ColsTitleText(props) {
    const { useCols, useSeparator, title, text, ...restProps } = props;
    return (
        <StColsTitleText>
            <Spacing {...restProps}>
                <Container useLargeWidth>
                    <StColsTitleWrap useCols={useCols}>
                        {title && (
                            <StColsTitle
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        )}
                        {text && (
                            <StColsText
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        )}
                    </StColsTitleWrap>
                    {useSeparator && <hr />}
                </Container>
            </Spacing>
        </StColsTitleText>
    );
}
