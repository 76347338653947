import React from 'react';
import styled, { css } from 'styled-components';

const StFieldRadio = styled.div.attrs((props) => ({
    className: 'StFieldRadio',
}))`
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    input {
        position: absolute;
        left: -9999px;
        opacity: 0;
        visibility: hidden;

        &:checked + label {
            &:before {
                background-color: #000;
            }
        }
    }

    label {
        position: relative;
        padding-left: 34px;
        line-height: 1.4;
        display: block;
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
        color: #000;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            background-color: #cfcfcf;
            border-radius: 50%;
            pointer-events: none;
        }

        span {
            font-weight: normal;
        }
    }

    ${(props) =>
        props.radioStyle2 &&
        css`
            &:not(:last-child) {
                margin-bottom: 15px;
            }

            label {
                text-transform: none;
                font-weight: normal;
                color: rgba(0, 0, 0, 0.5);

                &:before {
                    width: 22px;
                    height: 22px;
                    border: 1px solid #000;
                    background-color: transparent;
                    top: -2px;
                }
            }
        `};
`;

export default function FieldRadio(props) {
    const { radioStyle2, label, id, name, error, ...restProps } = props;
    return (
        <StFieldRadio error={error} radioStyle2={radioStyle2}>
            <input type="radio" name={name} id={id} {...restProps} />
            <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
        </StFieldRadio>
    );
}
