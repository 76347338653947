import Cookies from 'js-cookie';

// export const MetaPixel = isPro => {
//     console.log('loading pixel', isPro);
//     if (typeof isPro === 'undefined') {
//         return null;
//     } else if (isPro) {
//         console.log('pixel 923');
//         return [
//             <script key="function" id="facebook-pixel-script">
//                 {`!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
//                         n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
//                     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//                     n.queue=[];t=b.createElement(e);t.async=!0;
//                     t.src=v;s=b.getElementsByTagName(e)[0];
//            s.parentNode.insertBefore(t,s)}(window,document,'script',
//                     'https://connect.facebook.net/en_US/fbevents.js');
//                     fbq('init', '275833177681923');
//                     fbq('track', 'PageView'); `}
//             </script>,
//         ];
//     } else {
//         console.log('pixel 942');
//         return [
//             <script key="function" id="facebook-pixel-script">
//                 {`!function(f,b,e,v,n,t,s)
//     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//     n.queue=[];t=b.createElement(e);t.async=!0;
//     t.src=v;s=b.getElementsByTagName(e)[0];
//     s.parentNode.insertBefore(t,s)}(window,document,'script',
//     'https://connect.facebook.net/en_US/fbevents.js');
//     fbq('init', '715787671838942');
//     fbq('track', 'PageView');`}
//             </script>,
//         ];
//     }
// };

export const trackMetaBuyNowEvent = async isPro => {
    const eventId = `${new Date().getTime()}${Math.floor(
        100000 + Math.random() * 900000
    )}`;
    await capiMetaEvent('AddToCart', eventId, isPro);

    return typeof window !== 'undefined' && window?.fbq
        ? fbq('track', 'AddToCart', {}, { event_id: eventId })
        : console.log('no pixel');
};

export const trackMetaContentView = () => {
    return typeof window !== 'undefined' && window?.fbq
        ? fbq('track', 'ViewContent')
        : console.log('no pixel');
};

const capiMetaEvent = async (eventName, eventId, isPro) => {
    try {
        const fbc = Cookies.get('_fbc');
        const fbp = Cookies.get('_fbp');
        const userAgent = window?.navigator?.userAgent;
        const ipAddress = await getIPFromAmazon();
        const data = {
            eventName,
            eventId,
            isPro,
            fbc,
            fbp,
            userAgent,
            ipAddress: ipAddress.replace('\n', ''),
        };

        await fetch('/.netlify/functions/metaCAPI', {
            method: 'POST',
            body: JSON.stringify(data),
        });
    } catch (error) {
        console.log(error);
    }
};

const getIPFromAmazon = async () => {
    const res = await fetch('https://checkip.amazonaws.com/');
    return res.text();
};

// export default MetaPixel;
