import React, { useState } from 'react';

import styled, { css, keyframes } from 'styled-components';

import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
const { StLink } = SharedStyles;
import CustomLink from './CustomLink';

const showItem = keyframes`
  0% {
    opacity: 0;
    transform: translate(-10px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const StNav = styled.nav.attrs((props) => ({
    className: 'StNav',
}))``;

const StNavFirst = styled.div.attrs((props) => ({
    className: 'StNavFirst',
}))`
    text-transform: uppercase;
    letter-spacing: 0.065em;
    font: normal 22px/1.4 ${settings.font.roboto};
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transform: translateZ(0) scale(1, 1);
    padding: 20px 40px;

    & > .StNavItem > a,
    & > .StNavItem > .StNavElm > a {
        font-weight: 500;
    }

    .sub-nav-hidden {
        & > .StCollapsibleBtn:after,
        & > .StNavSubElmTitle > .StCollapsibleBtn:after {
            display: block;
        }

        & > .StNavSub,
        & > .StNavSubElmInner {
            display: none;
        }
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 0;
        display: flex;
        font-size: 12px;
        font-weight: 500;

        & > .StNavItem {
            padding: 0 10px;

            &:hover > .StNavSub {
                display: flex;
            }

            & > a {
                font-weight: 500;
                padding: 20px 0;
                height: 60px;

                &:before {
                    border-bottom-width: 3px !important;
                }
            }
        }

        .sub-nav-hidden > .StNavSubElmInner {
            display: block;
        }
    }
`;

const StNavSecond = styled.div.attrs((props) => ({
    className: 'StNavSecond',
}))`
    padding: 20px 40px;
    background-color: #f4f4f4;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.1em;

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }
`;

const StNavSecondItem = styled.div.attrs((props) => ({
    className: 'StNavSecondItem',
}))`
    text-transform: uppercase;

    &:not(:last-child) {
        margin-bottom: 5px;
    }

    a {
        display: inline-block;
        padding: 2px 0;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid #000;
            transition: width 0.3s;
            width: 0;
        }

        &:hover:before {
            width: 100%;
        }

        ${(props) =>
            props.isActive &&
            css`
                &:before {
                    width: 100%;
                }
            `};
    }
`;

const StNavItem = styled.div.attrs((props) => ({
    className: 'StNavItem',
}))`
    position: relative;

    @media (min-width: ${settings.bp.medium.view}) {
        position: static;

        .StNavItem {
            opacity: 0;
            animation: ${showItem} 600ms ease-in-out forwards;
        }
    }

    ${(props) =>
        props.isHovered &&
        css`
            & > .StNavSub {
                display: block;
            }
        `};

    &.active > a,
    a:hover {
        &:before {
            width: 100%;
        }
    }

    a {
        display: inline-block;
        color: #000;
        text-decoration: none;
        padding: 10px 0;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 8px;
            border-bottom: 1px solid #000;
            transition: width 0.3s;
            width: 0;
        }

        @media (min-width: ${settings.bp.medium.view}) {
            padding: 0;

            &:before {
                bottom: 0;
            }
        }

        &.with-btn {
            margin-right: 50px;

            @media (min-width: ${settings.bp.medium.view}) {
                margin-right: 0;
            }
        }
    }
`;

const StNavSub = styled.div.attrs((props) => ({
    className: 'StNavSub',
}))`
    margin: 0 -40px;
    padding: 10px 40px;
    background-color: #f4f4f4;
    font-size: 18px;

    &.sub-nav-extended {
        .StNavSubElm {
            text-transform: none;
        }

        .StNavSubElmInner {
            padding: 0 23px;
            font-size: 16px;
        }
    }

    @media (min-width: ${settings.bp.medium.view}) {
        box-shadow: 0px 5px 8px -2px rgba(0, 0, 0, 0.3);
        font-size: inherit;
        position: absolute;
        border-top: 1px solid #000;
        top: 100%;
        background-color: #fff;
        margin: 0;
        display: none;
        padding: 20px;

        &.sub-nav-extended {
            margin-left: -49px;
            transform: translate(-50%, 0);
            left: 50%;

            .StNavSubElm {
                width: 120px;
                margin-right: 20px;
                font-weight: normal;
            }

            .StNavSubElmInner {
                padding: 0;
                font-size: inherit;
            }

            .StNavItem:not(:last-child) {
                margin-bottom: 6px;
            }
        }

        &.sub-nav-default {
            width: 190px;
            margin-left: -20px;

            .StNavItem:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    @media (min-width: ${settings.bp.large.view}) {
        &.sub-nav-extended {
            padding: 30px 45px;

            .StNavSubElm {
                width: 148px;
            }
        }

        &.sub-nav-default {
            padding: 30px 34px;
            margin-left: -34px;

            .StNavItem:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    @media (min-width: ${settings.bp.large.view}) {
        &.sub-nav-extended {
            padding: 30px 95px;
        }
    }
`;

const StNavElm = styled.div.attrs((props) => ({
    className: 'StNavElm',
}))``;

const StNavSubElm = styled.div.attrs((props) => ({
    className: 'StNavSubElm',
}))`
    position: relative;
`;

const StNavSubElmInner = styled.div.attrs((props) => ({
    className: 'StNavSubElmInner',
}))``;

const StNavSubElmTitle = styled.div.attrs((props) => ({
    className: 'StNavSubElmTitle',
}))`
    cursor: pointer;
    text-transform: uppercase;
    font-weight: normal;
    padding: 10px 50px 10px 0;

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 10px;
        font-weight: 500;
        padding: 0;
        cursor: default;
    }
`;

const StCollapsibleBtn = styled.div.attrs((props) => ({
    className: 'StCollapsibleBtn',
}))`
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 45px;
    cursor: pointer;

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        border-bottom: 2px solid #000;
    }

    &:after {
        display: none;
        transform: translate(-50%, -50%) rotate(90deg);
    }

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }
`;

const StNavPromo = styled.div.attrs((props) => ({
    className: 'StNavPromo',
}))`
    display: none;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
        width: 150px;
    }

    @media (min-width: ${settings.bp.large.view}) {
        width: 217px;
    }
`;

const StNavPromoImg = styled.div`
    margin-bottom: 12px;

    img {
        width: 100%;
    }
`;
const StNavPromoTitle = styled.div``;

const StNavPromoText = styled.div`
    margin-bottom: 14px;
    text-transform: none;
    font-weight: normal;
    font-size: 13px;
`;

const StNavCallback = styled.div`
    a.left {
        position: relative;
        font-weight: 500;
        cursor: pointer;
    }

    a.left:before {
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: #000;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }
    a.left:hover:before {
        visibility: visible;
        width: 100%;
    }
`;

const StNavPromoLink = styled.div`
    a {
        font-size: 10px;
        font-weight: 500;

        &:before {
            display: none;
        }
    }
`;

function NavListItemSub(props) {
    let [isOpen, toggleOpen] = useState(false);
    const { item, menuCallback } = props;
    const { itemsTitle, items } = item;
    return (
        <StNavSubElm
            className={!isOpen && itemsTitle ? 'sub-nav-hidden' : undefined}
        >
            {itemsTitle && (
                <StNavSubElmTitle onClick={() => toggleOpen(!isOpen)}>
                    {itemsTitle}
                    <StCollapsibleBtn isOpen={isOpen} />
                </StNavSubElmTitle>
            )}
            <StNavSubElmInner>
                {items.map((item, index) => (
                    <NavListItem
                        key={item.id || index}
                        item={item}
                        index={index}
                        menuCallback={menuCallback}
                    />
                ))}
            </StNavSubElmInner>
        </StNavSubElm>
    );
}

function NavPromo(props) {
    const { promo } = props;
    const { img, title, text, href, linkText } = promo;
    return (
        <StNavPromo>
            {img && (
                <StNavPromoImg>
                    <CustomLink href={href}>
                        <img src={img} alt="" />
                    </CustomLink>
                </StNavPromoImg>
            )}
            {title && (
                <StNavPromoTitle>
                    <StNavSubElmTitle>{title}</StNavSubElmTitle>
                </StNavPromoTitle>
            )}
            {text && <StNavPromoText>{text}</StNavPromoText>}
            {href && (
                <StNavPromoLink>
                    <StLink>
                        <CustomLink href={href}>
                            {linkText ? linkText : 'See More'}
                        </CustomLink>
                    </StLink>
                </StNavPromoLink>
            )}
        </StNavPromo>
    );
}

function NavListItem(props) {
    let [isOpen, toggleOpen] = useState(false);
    const { item, index, languageCallback, menuCallback } = props;
    const { title, href, isHovered, isActive, items, promo } = item;
    return (
        <StNavItem
            style={{ animationDelay: `${index * 50}ms` }}
            isHovered={isHovered}
            className={[
                !isOpen && items ? 'sub-nav-hidden' : undefined,
                isActive ? 'active' : undefined,
            ]}
        >
            {title &&
                href !== 'Language' &&
                href !== 'AccountSettings' &&
                href !== 'SignOut' &&
                href !== 'SignIn' &&
                href !== 'Pro' && (
                    <StNavElm onClick={() => toggleOpen(!isOpen)}>
                        {items && (
                            <CustomLink className={'with-btn'} href={href}>
                                {title}
                            </CustomLink>
                        )}
                        {!items && (
                            <CustomLink className={undefined} href={href}>
                                {title}
                            </CustomLink>
                        )}
                    </StNavElm>
                )}
            {title && href === 'Language' && (
                <StNavElm onClick={() => toggleOpen(!isOpen)}>
                    <StNavCallback onClick={languageCallback}>
                        <a className="left">{title}</a>
                    </StNavCallback>
                </StNavElm>
            )}
            {title &&
                (href === 'AccountSettings' ||
                    href === 'SignOut' ||
                    href === 'SignIn' ||
                    href === 'Pro') && (
                    <StNavElm onClick={() => toggleOpen(!isOpen)}>
                        <StNavCallback onClick={() => menuCallback(href)}>
                            <a className="left">{title}</a>
                        </StNavCallback>
                    </StNavElm>
                )}
            {items && (
                <>
                    <StCollapsibleBtn
                        onClick={() => toggleOpen(!isOpen)}
                        isOpen={isOpen}
                    />
                    <StNavSub
                        className={
                            items.length > 1
                                ? 'sub-nav-extended'
                                : 'sub-nav-default'
                        }
                    >
                        {items.map((item, index) => (
                            <NavListItemSub
                                key={item.id || index}
                                item={item}
                                menuCallback={menuCallback}
                            />
                        ))}
                        {promo && <NavPromo promo={promo} />}
                    </StNavSub>
                </>
            )}
        </StNavItem>
    );
}

function NavListSecondItem(props) {
    const { title, isActive, ...restProps } = props;
    return (
        <StNavSecondItem isActive={isActive}>
            <CustomLink {...restProps}>{title}</CustomLink>
        </StNavSecondItem>
    );
}

export default function Nav(props) {
    const { items, itemsSecond, languageCallback, menuCallback } = props;

    return (
        <StNav>
            <StNavFirst>
                {items.map((item, index) => (
                    <NavListItem
                        key={item.id || index}
                        item={item}
                        languageCallback={languageCallback}
                        menuCallback={menuCallback}
                    />
                ))}
            </StNavFirst>
            {itemsSecond && (
                <StNavSecond>
                    {itemsSecond.map((item, index) => (
                        <NavListSecondItem key={item.id || index} {...item} />
                    ))}
                </StNavSecond>
            )}
        </StNav>
    );
}
