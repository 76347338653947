import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import ProductItem from './ProductItem';

const StProductList = styled.div`
    ${(props) =>
        props.center &&
        css`
            align-items: center;
            justify-content: center;
        `};

    display: flex;
    flex-wrap: wrap;
`;

const StProductListItem = styled.div`
    margin-bottom: 60px;
    padding: 0 20px;
    width: 50%;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 100px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 33.333%;
    }

    @media (min-width: ${settings.bp.large.view}) {
        padding: 0 30px;
        margin-bottom: 140px;
    }

    .StProductListItemImg {
        padding-bottom: 140%;
        position: relative;

        img {
            position: absolute;
            left: 50%;
            top: 100%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -100%);
        }
    }
`;

export default function ProductList(props) {
    const { items, center, isLoggedIn, ...restProps } = props;

    return (
        <StProductList center={center}>
            {items.map((item, index) => (
                <StProductListItem key={item.id || index}>
                    <ProductItem {...item} isLoggedIn={isLoggedIn} {...restProps} />
                </StProductListItem>
            ))}
        </StProductList>
    );
}
