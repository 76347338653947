import React from 'react';
import styled from 'styled-components';
import Container from './Container';
import Line from '../assets/img/line.png';

const LatestArticlesSection = styled.div`
    text-align: center;

    font-size: 18px;
    line-height: 21px;
    margin-bottom: 24px;
`;

const ArticlesList = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const ArticlesElement = styled.div`
    @media (max-width: 768px) {
        &:first-child {
            margin-bottom: 14px;
        }
    }
`;

const MainImage = styled.img`
    width: 100%;
    margin-bottom: 37px;
    object-fit: cover;
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
    max-height: 424px;

    @media (min-width: 768px) {
        max-height: 424px;
    }
    @media (min-width: 2000px) {
        max-height: 614px;
    }
`;

const ArticleTitle = styled.h2`
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    margin-bottom: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    @media (min-width: 768px) {
        font-size: 20px;
        line-height: 23px;
    }
`;

const LineImg = styled.img`
    width: 237px;
    margin-bottom: 6px;
`;

const ByKenraText = styled.p`
    font-size: 14px;
    margin-bottom: 17px;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-transform: uppercase;
    @media (min-width: 768px) {
        margin-bottom: 24px;
        margin-top: 0;
    }
`;
const ReadArticle = styled.a`
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    color: #000000;
`;

const OurLatestArticles = (props) => {
    const {
        bg,
        type,
        typeColor,
        title,
        subtitle,
        date,
        linkText,
        linkHref,
        items,
        viewAll,
    } = props;
    return (
        <Container>
            <LatestArticlesSection>
                <ArticlesList>
                    {items.map((item, index) => {
                        return (
                            <ArticlesElement key={item.id || index}>
                                <MainImage
                                    src={item.bg}
                                    alt="curly-hair-woman"
                                />
                                <ArticleTitle>{item.title}</ArticleTitle>
                                <LineImg src={Line} alt="line" />
                                <ByKenraText>{item.subtitle}</ByKenraText>
                                <ReadArticle href={item.linkHref}>
                                    {item.linkText}
                                </ReadArticle>
                            </ArticlesElement>
                        );
                    })}
                </ArticlesList>
                {viewAll}
            </LatestArticlesSection>
        </Container>
    );
};

export default OurLatestArticles;
