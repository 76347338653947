import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore, StLink } = SharedStyles;
import CustomLink from './CustomLink';

const StTabHeader = styled.div``;

const StTabHeaderItem = styled.section.attrs((props) => ({
    className: 'StTabHeaderItem',
}))`
    margin: 0px 10px;
    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 40px;
        display: block;
        justify-content: center;
        align-items: center;
        margin: 0px 30px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 70px;
        margin: 0px auto;
        width: 50%;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.7;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 24px;
            color: ${settings.color.gray};
            line-height: 1.42;
            margin: 0 0 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.6;
        margin: 0 0 16px;
    }

    h4 {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.89;
        letter-spacing: 0.08em;
        margin: 20px 0px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 18px;
            color: ${settings.color.gray};
            line-height: 1.89;
            margin: 30px 0px;
        }
    }
`;

const StTabHeaderText = styled.div.attrs((props) => ({
    className: 'StTabHeaderText',
}))``;

const StTabHeaderButton = styled.div.attrs((props) => ({
    className: 'StTabHeaderButton',
}))`
    font-size: 16px;
    height: 34px;
    padding: 5px;
    border-radius: 17px;
    border: 1px solid #000;
    margin: 7px auto;

    ${(props) =>
        props.gradient &&
        props.isActive &&
        css`
            -webkit-background-clip: text;
            background: linear-gradient(${props.gradient});
            border: none;
            color: #fff;
        `};

    @media (min-width: ${settings.bp.small.view}) {
        margin: 0px;
        min-width: 118px;
        &:not(:first-child) {
            margin: 0px 0px 0px 30px;
        }
    }
    @media (max-width: ${settings.bp.small.view}) {
        width: 200px;
    }
`;

const StTabHeaderButtons = styled.div`
    display: block;
    justify-content: center;
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
    }
`;

function TabHeaderButton(props) {
    const { text, gradient, isActive, ...restProps } = props;

    return (
        <StTabHeaderButton
            gradient={gradient}
            isActive={isActive}
            {...restProps}
        >
            {text}
        </StTabHeaderButton>
    );
}

function TabHeaderItem(props) {
    const { text, buttons, ...restProps } = props;
    return (
        <StTabHeaderItem>
            <StTabHeaderText>{text && <p> {text} </p>}</StTabHeaderText>
            {buttons && (
                <StTabHeaderButtons>
                    {buttons.map((item, index) => (
                        <TabHeaderButton
                            key={item.id || index}
                            {...item}
                            {...restProps}
                        />
                    ))}
                </StTabHeaderButtons>
            )}
        </StTabHeaderItem>
    );
}

export default function TabHeader(props) {
    const { ...restProps } = props;
    return (
        <StTabHeader {...restProps}>
            <TabHeaderItem {...restProps} />
        </StTabHeader>
    );
}
