import styled, { css } from 'styled-components';
import settings from '../../global/settings';

export const StSliderLinkOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    a {
        display: block;
        width: 100%;
        height: 100%;
        text-indent: -9999px;
        font-size: 0;
    }
`;
