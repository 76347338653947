import styled, { css } from 'styled-components';

import settings from '../global/settings';

const TabContainer = styled.div.attrs((props) => ({
    className: 'TabContainer',
}))`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: ${settings.bp.extraSmall.width};

    @media (min-width: ${settings.bp.small.view}) {
        width: ${settings.bp.small.width};
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: ${settings.bp.medium.width};

        ${(props) =>
            props.useMediumWidth &&
            css`
                max-width: ${settings.bp.medium.width};
            `};

        ${(props) =>
            props.useLargeWidth &&
            css`
                max-width: 1070px;
            `};
    }

    @media (min-width: ${settings.bp.large.view}) {
        width: ${settings.bp.large.width};
    }

    ${(props) =>
        props.mobileWide &&
        css`
            @media (max-width: calc(${settings.bp.large.view} - 1px)) {
                width: 100%;
                max-width: 100%;
            }
        `};

    &:after {
        content: '';
        clear: both;
        display: table;
    }
`;

export default TabContainer;
