import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import PlayBtn from '../assets/img/play.svg';

const StButtonPlayContainer = styled.div.attrs((props) => ({
    className: 'StButtonPlayContainer',
}))`
    cursor: pointer;
    z-index: 1;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    ${(props) =>
        props.bgImg &&
        css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        `};

    ${(props) =>
        props.isHidden &&
        css`
            cursor: default;
        `};

    ${(props) =>
        props.useImgOverlay &&
        css`
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: ${settings.bgOverlay};
                z-index: 1;
                pointer-events: none;
                transition: opacity 0.3s;
            }

            &:hover:before {
                opacity: 0.8;
            }
        `};
`;

const StButtonPlay = styled.div.attrs((props) => ({
    className: 'StButtonPlay',
}))`
    pointer-events: none;
    z-index: 11;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 54px;
    height: 54px;
    border-radius: 50%;
    // box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.14);
    // border: 2px solid #fff;

    @media (min-width: ${settings.bp.small.view}) {
        width: 100px;
        height: 100px;
    }

    ${(props) =>
        props.btnSizeSmall &&
        css`
            @media (min-width: ${settings.bp.small.view}) {
                width: 34px;
                height: 34px;

                .StButtonPlayTriangle {
                    height: 12px;
                    left: calc(50% - 4px);

                    &:before,
                    &:after {
                        width: 10px;
                    }
                }
            }
        `};

    ${(props) =>
        props.btnSizeMedium &&
        css`
            @media (min-width: ${settings.bp.small.view}) {
                width: 54px;
                height: 54px;

                .StButtonPlayTriangle {
                    height: 20px;
                    left: calc(50% - 6px);

                    &:before,
                    &:after {
                        width: 18px;
                    }
                }
            }
        `};
`;

const StButtonPlayTriangle = styled.div.attrs((props) => ({
    className: 'StButtonPlayTriangle',
}))`
    width: 2px;
    background-color: #fff;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.14);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    height: 20px;
    left: calc(50% - 6px);

    @media (min-width: ${settings.bp.small.view}) {
        height: 36px;
        left: calc(50% - 12px);
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 2px;
        height: 2px;
        background-color: #fff;
        transform-origin: left;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.14);
        width: 18px;

        @media (min-width: ${settings.bp.small.view}) {
            width: 34px;
        }
    }

    &:before {
        top: 0;
        transform: rotate(30deg);
    }

    &:after {
        bottom: 0;
        transform: rotate(-30deg);
    }
`;

export default function NewButtonPlay(props) {
    const { useImgOverlay, bgImg, isHidden, btnSizeSmall, btnSizeMedium } =
        props;
    return (
        <StButtonPlayContainer
            bgImg={bgImg}
            useImgOverlay={useImgOverlay}
            style={{ backgroundImage: `url(${bgImg || ''})` }}
            isHidden={isHidden}
        >
            {!isHidden && (
                <StButtonPlay
                    btnSizeSmall={btnSizeSmall}
                    btnSizeMedium={btnSizeMedium}
                >
                    <img src={PlayBtn} alt="Play Video" />
                </StButtonPlay>
            )}
            {props.children}
        </StButtonPlayContainer>
    );
}
