import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import Container from './Container';
import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore } = SharedStyles;

const StImageWithDesc = styled.div.attrs((props) => ({
    className: 'StImageWithDesc',
}))`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #b5c1c1;
    padding: 60px 0;

    ${(props) =>
        !props.stylePage &&
        css`
            @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                background-image: none !important;
            }
        `};

    ${(props) =>
        props.stylePage &&
        css`
            @media (min-width: ${settings.bp.small.view}) {
                min-height: calc(100vh - 200px);
            }

            @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                padding: 50% 0 0;
                display: flex;
                justify-content: center;

                .container {
                    width: 100%;
                }

                .StImageWithDescText {
                    max-width: 100%;
                    width: 100%;
                    background-color: #fff;
                }
            }

            @media (min-width: ${settings.bp.large.view}) {
                .StImageWithDescText {
                    margin-left: 8%;
                }
            }
        `};

    ${(props) =>
        props.maxWidthMedium &&
        css`
            .container {
                max-width: ${settings.bp.medium.view};
            }
        `};

    ${(props) =>
        props.styleCols &&
        css`
            background: none !important;
            padding: 0;

            .container {
                display: flex;
                flex-direction: column-reverse;

                & > div {
                    max-width: 100%;
                }

                @media (min-width: ${settings.bp.small.view}) {
                    flex-direction: row;
                    min-height: 340px;

                    & > div {
                        width: 50%;
                    }
                }
            }

            .StImageWithDescText {
                text-align: left;
                background-color: #fbfbfb;
                color: ${settings.color.gray};
            }
        `};
`;

const StImageWithDescText = styled.div.attrs((props) => ({
    className: 'StImageWithDescText',
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    max-width: 489px;
    text-align: center;
    padding: 54px 42px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.1em;

    @media (min-width: ${settings.bp.medium.view}) {
        margin: 0;
        padding: 6%;
    }

    h2 {
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 0.07em;
        margin: 0 0 10px;
        color: #4a4a4a;
        text-transform: uppercase;
    }

    p {
        margin: 0 0 20px;
    }

    .StLinkMore a {
        min-width: 140px;

        @media (min-width: ${settings.bp.small.view}) {
            min-width: 184px;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    ${(props) =>
        props.styleSizeBig &&
        css`
            font-size: 16px;
            line-height: 1.63;
            letter-spacing: 0.05em;

            @media (min-width: ${settings.bp.medium.view}) {
                padding: 7%;
            }
        `};
`;

const StImageWithDescImg = styled.div.attrs((props) => ({
    className: 'StImageWithDescImg',
}))`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 50%;

    @media (min-width: ${settings.bp.small.view}) {
        padding-bottom: 0;
    }
`;

export default function ImageWithDesc(props) {
    const {
        stylePage,
        removeMargin,
        maxWidthMedium,
        styleCols,
        styleSizeBig,
        bg,
        title,
        text,
        btnText,
        href,
        onClick,
    } = props;
    return (
        <StImageWithDesc
            removeMargin={removeMargin}
            stylePage={stylePage}
            maxWidthMedium={maxWidthMedium}
            styleCols={styleCols}
            style={{ backgroundImage: `url(${bg})` }}
        >
            <Container>
                <StImageWithDescText styleSizeBig={styleSizeBig}>
                    {title && <h2>{title}</h2>}
                    {text && typeof text === 'string' && <p>{text}</p>}
                    {text && typeof text === 'object' && text}
                    {(href || onClick) && (
                        <StLinkMore>
                            <a href={href} onClick={onClick}>
                                {btnText}
                            </a>
                        </StLinkMore>
                    )}
                </StImageWithDescText>
                {styleCols && (
                    <StImageWithDescImg
                        style={{ backgroundImage: `url(${bg})` }}
                    />
                )}
            </Container>
        </StImageWithDesc>
    );
}
