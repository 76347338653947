import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import ButtonPlay from './ButtonPlay';
import settings from '../global/settings';

const StVideoLightBox = styled.div.attrs(props => ({
    className: 'StVideoLightBox',
}))`
    ${props =>
        props.bgImg &&
        css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            .StButtonPlayContainer {
                position: static;
            }
        `};
`;

const StVideoModal = styled.div`
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
`;

const StVideoModalOuter = styled.div`
    display: flex;
    min-height: 100%;
    align-items: center;
    padding: 0 40px;
`;

const StVideoModalInner = styled.div`
    width: 100%;
    max-width: 1970px;
    margin: 40px auto;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #000;

    iframe,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const StVideoModalCloseBtn = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        border-bottom: 2px solid #fff;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
`;

function GetTypeHtml(props) {
    const { videoOptions, ...restProps } = props;
    return (
        <video {...restProps}>
            {videoOptions.map((item, index) => (
                <source key={item.id || index} {...item} />
            ))}
            Video not supported.
        </video>
    );
}

function GetTypeIframe(props) {
    const { videoOptions, ...restProps } = props;
    if (restProps.autoPlay === true) {
        const isChrome =
            !!window.chrome &&
            (!!window.chrome.webstore || !!window.chrome.runtime);
        let srcString = videoOptions[0].src + '?autoplay=1';
        if (isChrome) {
            srcString += '&mute=1';
        }
        return (
            <iframe
                title={'videoframe'}
                allow="autoplay"
                frameBorder={0}
                src={srcString}
            />
        );
    }
    return (
        <iframe
            title={'videoframe'}
            frameBorder={0}
            {...videoOptions[0]}
            {...restProps}
        />
    );
}

function GetTypeVideo(props) {
    const { videoType, ...restProps } = props;
    return videoType === 'html' ? (
        <GetTypeHtml {...restProps} />
    ) : (
        <GetTypeIframe {...restProps} />
    );
}

export default function VideoLightBox(props) {
    let [isModalOpen, setModalOpen] = useState(false);
    const {
        img,
        bgImg,
        useImgOverlay,
        btnSizeSmall,
        btnSizeMedium,
        videoOptions,
        isOpen,
        onHide,
        ...restProps
    } = props;
    isModalOpen = !!isOpen ? isOpen : isModalOpen;
    return (
        <StVideoLightBox
            bgImg={bgImg}
            onClick={() => {
                setModalOpen(!isModalOpen);
                onHide && onHide();
            }}
        >
            {videoOptions && isModalOpen && (
                <StVideoModal>
                    <StVideoModalCloseBtn />
                    <StVideoModalOuter>
                        <StVideoModalInner onClick={e => e.stopPropagation()}>
                            <GetTypeVideo
                                videoOptions={videoOptions}
                                {...restProps}
                            />
                        </StVideoModalInner>
                    </StVideoModalOuter>
                </StVideoModal>
            )}
            <ButtonPlay
                bgImg={bgImg}
                useImgOverlay={useImgOverlay}
                btnSizeMedium={btnSizeMedium}
                btnSizeSmall={btnSizeSmall}
                isHidden={!videoOptions}
            >
                {img && <img src={img} alt="" />}
            </ButtonPlay>
        </StVideoLightBox>
    );
}
