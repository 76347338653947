import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

const StFormSearchStore = styled.div`
    @media (max-width: calc(${settings.bp.small.view} - 1px)) {
        background-color: #f5f5f5;
        padding: 50px 20px;

        .StField {
            margin-bottom: 33px;
        }
    }

    h1 {
        margin-bottom: 30px !important;

        @media (min-width: ${settings.bp.medium.view}) {
            margin-bottom: 60px !important;
        }
    }
`;

export default function FormSearchStore(props) {
    return <StFormSearchStore {...props} />;
}
