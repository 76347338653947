import React from 'react'
import {
    FormattedMessage,
} from 'gatsby-plugin-react-intl'

import {
    DDButton,
} from '../../../kenra-storybook/index'
import { ga4Event } from '../../../helpers/ga4'
import { trackMetaBuyNowEvent } from '../../../helpers/metaPixel'
import { trackTTBuyNowEvent } from '../../../helpers/tiktokPixel'
import { getDropDownItems } from './GoodHairMagicLandingPage'

const ShopNowButtons = (
    { productId,
        isPro }
) => {
    const dropDownItems = getDropDownItems(productId, isPro)


    return (
        <div style={{ alignSelf: 'center', marginTop: "12px" }} key={productId}>
            <DDButton
                items={dropDownItems}
                defaultTitle={
                    <FormattedMessage id="product-default-shop-button" />
                }
                onChange={(val, item) => {
                    const { productName, title, href, variantSize } = item
                    ga4Event('shopNowLinkClick', {
                        product: productName,
                        shop: title,
                        url: href,
                        size: variantSize,
                    })
                    trackMetaBuyNowEvent(isPro)
                    // typeof window !== 'undefined' && window?.fbq
                    //     ? fbq('track', 'AddToCart')
                    //     : console.log('no pixel')
                    trackTTBuyNowEvent(isPro, productId)
                }}
                inversionButtonColor={true}
            />
        </div>
    )
}


export default ShopNowButtons