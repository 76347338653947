import React from 'react';

import styled from 'styled-components';

import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore } = SharedStyles;
import settings from '../global/settings';

const StModal = styled.div`
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
`;

const StModalWrapper = styled.div`
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 550px;
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
`;

const StModalContent = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
`;

const StModalButtons = styled.div`
    padding-bottom: 30px;
    margin-top: 40px;
    display: flex;
    justify-content: center;

    .StLinkMore {
        a {
            width: 120px;
            @media (min-width: ${settings.bp.medium.view}) {
                width: 200px;
            }
        }
    }

    .StLinkMore:not(:last-child) {
        margin-right: 15px;
    }
`;

const StCloseButton = styled.div`
    color: #aaa;
    float: right;
    margin-right: 14px;
    font-size: 28px;
    &:hover {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const StLogo = styled.div`
    width: 30%;
    margin: auto;
    padding-bottom: 20px;
`;

const StLogoWrapper = styled.div`
    display: flex;
`;

const StLogoDivider = styled.div`
    border-left: 1px solid black;
    height: 50px;
`;

const StModalTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 600;
    letter-spacing: 0.07em;
`;

const StModalSubTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    font-size: 15px;
    line-height: 1.4;
    color: #818181;
`;

export default function SalonoryErrorModal(props) {
    const { onClick, logos, title, subtitle, onConfirmClick, confirm, onCancelClick, cancel } = props;

    let logo1 = logos[0];
    let logo2 = null;
    if (logos.length > 1) {
        logo2 = logos[1];
    }
console.log("CANCEL", cancel);
    return (
        <StModal
            onClick={(event) => {
                if (event.target === event.currentTarget && onClick) {
                    onClick();
                }
            }}
        >
            <StModalWrapper>
                <StCloseButton onClick={onClick}>&times;</StCloseButton>
                <StModalContent>
                    <StLogoWrapper>
                        {logo1 && logo1.src && (
                            <StLogo>
                                <img src={logo1.src} alt={logo1.alt} />
                            </StLogo>
                        )}
                        {logo2 && logo2.src && (
                            <>
                                <StLogoDivider />
                                <StLogo>
                                    <img src={logo2.src} alt={logo2.alt} />
                                </StLogo>
                            </>
                        )}
                    </StLogoWrapper>
                    {title && <StModalTitle>{title}</StModalTitle>}
                    {subtitle && <StModalSubTitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
                    <StModalButtons>
                        <StLinkMore inversionColor>
                            <a onClick={onConfirmClick}>{confirm}</a>
                        </StLinkMore>
                        {cancel &&
                            <StLinkMore inversionColor>
                                <a onClick={onCancelClick}>{cancel}</a>
                            </StLinkMore>
                        }
                    </StModalButtons>
                </StModalContent>
            </StModalWrapper>
        </StModal>
    );
}
