import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import TabContainer from './TabContainer';
import Spacing from './Spacing';
import * as SharedStyles from '../global/SharedStyles';
const { StSectionInfo } = SharedStyles;

const StBlockOne = styled.div.attrs((props) => ({
    className: 'StBlockOne',
}))`
    ${(props) =>
        props.imageGradient &&
        css`
            background-image: linear-gradient(${props.imageGradient});
        `};

    width: 30%;
    display: flex;
    margin-left: 30px;
`;

const StBlockTwo = styled.div.attrs((props) => ({
    className: 'StBlockTwo',
}))`
    width: 70%;
    text-align: left;
    margin: 0 50px;

    hr {
        border-bottom: 1px solid #c2c2c2;
    }
`;

const StInnerBlockOne = styled.div.attrs((props) => ({
    className: 'StInnerBlockOne',
}))`
    margin: auto;
`;

const StMobileBlockOne = styled.div.attrs((props) => ({
    className: 'StMobileBlockOne',
}))`
    ${(props) =>
        props.imageGradient &&
        css`
            background-image: linear-gradient(${props.imageGradient});
        `};

    height: 150px;
    display: flex;
    margin: 0 20px;
`;
const StMobileBlockTwo = styled.div.attrs((props) => ({
    className: 'StMobileBlockTwo',
}))`
    height: 70%;
    width: 100%;
    text-align: left;
    padding: 0 20px;

    hr {
        display: none;
    }
`;

export default function TabOptionDataStyling(props) {
    const { isMobile, bkgndColor, item, ...restProps } = props;
    return (
        <TabContainer>
            <StSectionInfo
                style={{
                    backgroundColor: bkgndColor,
                }}
            >
                {!isMobile && (
                    <>
                        {item && (
                            <Spacing flexSpacing>
                                <StBlockOne imageGradient={item.imageGradient}>
                                    <StInnerBlockOne>
                                        <h2>{item.title}</h2>
                                    </StInnerBlockOne>
                                </StBlockOne>
                                <StBlockTwo>{item.text}</StBlockTwo>
                            </Spacing>
                        )}
                    </>
                )}
                {isMobile && (
                    <>
                        {item && (
                            <Spacing>
                                <StMobileBlockOne
                                    imageGradient={item.imageGradient}
                                >
                                    <StInnerBlockOne>
                                        <h2>{item.title}</h2>
                                    </StInnerBlockOne>
                                </StMobileBlockOne>
                                <StMobileBlockTwo>{item.text}</StMobileBlockTwo>
                            </Spacing>
                        )}
                    </>
                )}
            </StSectionInfo>
        </TabContainer>
    );
}
