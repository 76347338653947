import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../../global/settings';

import * as SharedStyles from '../../global/SharedStyles';
const { StLink } = SharedStyles;
import CustomLink from '../CustomLink';

const StArticlesItem = styled.div.attrs(props => ({
    className: 'StArticlesItem',
}))`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        background-color: #fff;
    }
    ${props =>
        props.noBackground &&
        css`
            @media (min-width: ${settings.bp.small.view}) {
                background-color: transparent;
            }
        `};

    ${props =>
        props.useHover &&
        css`
            &:hover .StArticlesItemImg {
                transform: translate(-50%, -50%) scale(1.4);
            }
        `};

    h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.2;
        margin: 0 0 10px;
    }

    h3 {
        margin: 0;
        font-size: 14px;
        line-height: 1.2;
        font-weight: normal;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    p {
        margin: 0 0 16px;
    }

    ${props =>
        props.styleSmall &&
        css`
            flex-direction: row;
            align-items: center;
            text-align: center;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            padding: 10px 0;

            @media (min-width: ${settings.bp.small.view}) {
                flex-direction: column;
                text-align: left;
                border-top: none;
                border-bottom: none;
                padding: 0;
            }

            h2 {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.25;
                letter-spacing: 0.02em;
                margin: 0;

                @media (min-width: ${settings.bp.medium.view}) {
                    font-size: 16px;
                }
            }

            .StArticlesItemDesc {
                @media (min-width: ${settings.bp.small.view}) {
                    box-shadow: 0 0 0 0.2px ${settings.color.gray3};
                }

                @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                }
            }

            .StArticlesItemAuthor {
                margin-bottom: 18px;
                display: none;

                @media (min-width: ${settings.bp.small.view}) {
                    display: flex;
                }

                img {
                    min-width: 42px;
                    width: 42px;
                    height: 42px;
                    margin-right: 10px;
                }
            }

            .StArticlesItemDate {
                text-transform: none;
                margin-top: 10px;
                font-size: 13px;
                letter-spacing: 0.04em;
                display: none;

                @media (min-width: ${settings.bp.small.view}) {
                    display: block;
                }
            }

            .StArticlesItemImgWrap {
                min-width: 85px;
                width: 85px;
                height: 85px;
                padding-bottom: 0;

                @media (min-width: ${settings.bp.small.view}) {
                    min-width: 100%;
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                }
            }
        `};

    ${props =>
        props.styleSizeBig &&
        css`
            h2 {
                letter-spacing: 0.06em;
                font-size: 16px;
            }

            .StArticlesItemDesc {
                padding: 20px 10px 0;
                font-size: 16px;
                line-height: 1.63;
            }

            @media (min-width: ${settings.bp.medium.view}) {
                h2 {
                    letter-spacing: 0.1em;
                    font-size: 20px;
                }

                .StArticlesItemDesc {
                    letter-spacing: 0.05em;
                    padding: 40px 54px;
                }
            }
        `};

    ${props =>
        props.styleColorGray &&
        css`
            h2,
            .StArticlesItemDesc {
                color: ${settings.color.gray};
            }
        `};
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        text-indent: -9999px;
        font-size: 0;
    }
`;

const StArticlesItemOverlayLink = styled.a`
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        text-indent: -9999px;
        font-size: 0;
    }
`;

const StArticlesItemImgWrap = styled.div.attrs(props => ({
    className: 'StArticlesItemImgWrap',
}))`
    overflow: hidden;
    position: relative;
    padding-bottom: 68%;
`;

const StArticlesItemImg = styled.div.attrs(props => ({
    className: 'StArticlesItemImg',
}))`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    transition: transform 16s;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

const StArticlesItemType = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
    padding: 10px;
    min-width: 116px;
    text-align: center;

    @media (min-width: ${settings.bp.medium.view}) {
        right: 12px;
        top: 12px;
        min-width: 151px;
    }

    ${props =>
        props.positionTypeLeft &&
        css`
            right: auto !important;
            left: 0;

            @media (min-width: ${settings.bp.medium.view}) {
                left: 12px;
            }
        `};
`;

const StArticlesItemDesc = styled.div.attrs(props => ({
    className: 'StArticlesItemDesc',
}))`
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: ${settings.color.default};
    padding-top: 12px;
    width: 100%;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 16px 20px 20px;
        height: 100%;
        font-size: 14px;
        line-height: 1.36;
        color: ${settings.color.grayLight};
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 16px 32px 30px;
    }

    ul {
        margin: 0 0 16px;
        padding: 0 0 0 20px;

        @media (min-width: ${settings.bp.small.view}) {
            padding: 0 0 0 30px;
        }
    }

    .StLink {
        margin-top: 20px;
    }

    *:last-child {
        margin-bottom: 0;
    }
    ${props =>
        props.newStyle &&
        css`
            align-content: end;
            padding-bottom: 0px !important;
            h2 {
                font-size: 16px;
                margin-bottom: 0;
            }
        `};
`;

const StArticlesItemAuthor = styled.div.attrs(props => ({
    className: 'StArticlesItemAuthor',
}))`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
        margin-right: 14px;
        border-radius: 50%;
        overflow: hidden;
        min-width: 44px;
        width: 44px;
        height: 44px;

        @media (min-width: ${settings.bp.small.view}) {
            min-width: 55px;
            width: 55px;
            height: 55px;
        }
    }
`;

const StArticlesItemDate = styled.div.attrs(props => ({
    className: 'StArticlesItemDate',
}))`
    margin-top: 16px;
    color: ${settings.color.default};
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 500;

    @media (min-width: ${settings.bp.small.view}) {
        margin-top: 24px;
        font-size: 14px;
    }
`;

export default function ColsArticle(props) {
    const {
        href,
        img,
        type,
        typeColor,
        authorImg,
        authorName,
        title,
        text,
        date,
        styleSmall,
        styleSizeBig,
        styleColorGray,
        positionTypeLeft,
        moreLink,
        moreText,
        noBackground,
        newStyle,
    } = props;
    return (
        <StArticlesItem
            noBackground={noBackground}
            useHover={href}
            styleSmall={styleSmall}
            styleSizeBig={styleSizeBig}
            styleColorGray={styleColorGray}
            newStyle={newStyle}
        >
            {href && <CustomLink href={href}>{}</CustomLink>}
            {img && (
                <StArticlesItemImgWrap>
                    <StArticlesItemImg
                        style={{ backgroundImage: `url(${img})` }}
                    />
                    {type && !styleSmall && (
                        <StArticlesItemType
                            positionTypeLeft={positionTypeLeft}
                            style={{ background: typeColor }}
                        >
                            {type}
                        </StArticlesItemType>
                    )}
                </StArticlesItemImgWrap>
            )}
            <StArticlesItemDesc newStyle={newStyle}>
                {authorImg || authorName ? (
                    <StArticlesItemAuthor>
                        {authorImg && <img src={authorImg} alt="" />}
                        {authorName && <h3>{authorName}</h3>}
                    </StArticlesItemAuthor>
                ) : (
                    ''
                )}
                {title && <h2>{title}</h2>}
                {!styleSmall && (
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                )}
                {date && <StArticlesItemDate>{date}</StArticlesItemDate>}
                {moreLink && (
                    <StLink>
                        <CustomLink href={moreLink}>{moreText}</CustomLink>
                    </StLink>
                )}
            </StArticlesItemDesc>
        </StArticlesItem>
    );
}
