import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore } = SharedStyles;
import CustomLink from './CustomLink';

const StEducation = styled.div`
    ${(props) =>
        props.colorWhite &&
        css`
            color: #fff;

            * {
                color: #fff;
            }
        `};

    .StLinkMore {
        margin-top: 0px;
    }
`;

const StEducationWrap = styled.div``;

const StEducationRow = styled.div`
    margin-bottom: 60px;

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 20px;
    }

    @media (max-width: calc(${settings.bp.small.view} - 1px)) {
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const StEducationInner = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
    }
`;

const StEducationRowTitle = styled.h3`
    margin: 0 0 20px;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.07em;
    text-transform: uppercase;
`;

const StEducationItem = styled.div`
    font-size: 16px;
    line-height: 1.63;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.small.view}) {
        margin-right: 40px;
        margin-bottom: 0;
        width: 450px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 70px;
    }

    h4 {
        margin: 0 0 6px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        width: 400px;
    }

    p {
        margin: 0 0 14px;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

const StEducationItemText = styled.div``;

function EducationRowItem(props) {
    const { title, text } = props;
    return (
        <StEducationItem>
            {title && <h4>{title}</h4>}
            <StEducationItemText dangerouslySetInnerHTML={{ __html: text }} />
        </StEducationItem>
    );
}

function EducationRow(props) {
    const { title, items } = props;
    return (
        <StEducationRow>
            {title && <StEducationRowTitle>{title}</StEducationRowTitle>}
            <StEducationInner>
                {items.map((item, index) => (
                    <EducationRowItem key={item.id || index} {...item} />
                ))}
            </StEducationInner>
        </StEducationRow>
    );
}

export default function Education(props) {
    const { colorWhite, items, url, text } = props;
    return (
        <StEducation colorWhite={colorWhite}>
            <StEducationWrap>
                {items.map((item, index) => (
                    <EducationRow key={item.id || index} {...item} />
                ))}
            </StEducationWrap>
            <StLinkMore>
                <CustomLink href={url}>{text}</CustomLink>
            </StLinkMore>
        </StEducation>
    );
}
