import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import { useIntl } from 'gatsby-plugin-react-intl';
import CustomLink from './CustomLink';

const StContainer = styled.div.attrs((props) => ({
    className: 'StContainer',
}))`
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    margin-bottom: 50px;

    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
    }
`;

const StPanelImage = styled.div.attrs((props) => ({
    className: 'StPanelImage',
}))`
    width: 50%;
    margin: auto;

    img {
        height: 712px;
    }
`;

const StPanelText = styled.div.attrs((props) => ({
    className: 'StPanelText',
}))`
    width: 50%;
    margin: auto;
`;

const StPanelTextImage = styled.div.attrs((props) => ({
    className: 'StPanelTextImage',
}))`
    margin: 20px 0px;
    img {
        height: 80px;
    }
`;

const StPanelInnerText = styled.div.attrs((props) => ({
    className: 'StPanelInnerText',
}))`
    font-size: 22px;
    color: #fff;
    text-transform: uppercase;
    margin: 5px 0px 15px 0px;
`;

const StPanelInnerImage = styled.div.attrs((props) => ({
    className: 'StPanelInnerImage',
}))`
    img {
        height: 50px;
    }
`;

const StPanelButton = styled.div.attrs((props) => ({
    className: 'StPanelButton',
}))`
    background-color: #fff;
    width: 260px;
    height: 56px;
    padding: 21px;
    border-radius: 28px;
    margin: auto;
`;

const StPanelTitleImage = styled.div.attrs((props) => ({
    className: 'StPanelTitleImage',
}))`
    img {
        height: 100px;
    }
`;

const StGradientText = styled.div.attrs((props) => ({
    className: 'StGradientText',
}))`
    line-height: 1;

    ${(props) =>
        props.gradient &&
        css`
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            background: linear-gradient(${props.gradient});
        `};
`;

const StPanelTextButtonContainer = styled.div.attrs((props) => ({
    className: 'StPanelTextButtonContainer',
}))``;

function ThrowbackPanelItem(props) {
    const { text, image } = props;
    return (
        <>
            {image && (
                <StPanelInnerImage>
                    <img src={image} alt="" />
                </StPanelInnerImage>
            )}
            {text && <StPanelInnerText>{text}</StPanelInnerText>}
        </>
    );
}

export default function ThrowbackPanel(props) {
    const { items, image, petaImage, buttonUrl, titleImage, gradient } = props;
    let intl = useIntl();

    return (
        <>
            <StPanelTitleImage>
                <img src={titleImage} />
            </StPanelTitleImage>
            <StContainer>
                <StPanelTextButtonContainer>
                    <StPanelText>
                        {items.map((item, index) => (
                            <ThrowbackPanelItem
                                key={item.id || index}
                                {...item}
                            />
                        ))}
                        <StPanelTextImage>
                            <img src={petaImage} />
                        </StPanelTextImage>
                    </StPanelText>
                    <CustomLink href={buttonUrl}>
                        <StPanelButton>
                            <StGradientText
                                gradient={gradient}
                                style={{
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                }}
                            >
                                {intl.formatMessage({
                                    id: 'throwback-buy-now',
                                })}
                            </StGradientText>
                        </StPanelButton>
                    </CustomLink>
                </StPanelTextButtonContainer>
                <StPanelImage>
                    <img src={image} alt="" />
                </StPanelImage>
            </StContainer>
        </>
    );
}
