import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import VideoLightBox from './VideoLightBox';

import settings from '../global/settings';
import CustomLink from './CustomLink';

const StEventsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-bottom: 30px;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
        margin: 0;
        margin-bottom: 30px;
    }
    h2 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 1.3px;
    }
`;

const StEventsListItem = styled.div`
    padding: 10px 10px;
    display: flex;
    align-items: center;
    width: 100% @media (min-width: ${settings.bp.medium.view}) {
        padding: 10px 10px;
        margin: 0;
    }
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    h3,
    h4 {
        text-transform: uppercase;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 0.08em;
    }

    h3 {
        font-size: 12px;
        text-align: center;
        padding: 0 10px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 16px;
            text-align: left;
            padding: 0;
        }
    }

    h4 {
        font-size: 14px;
        margin-bottom: 6px;
    }

    h5 {
        font-size: 13px;
        letter-spacing: 0.05em;
        font-weight: normal;
    }

    h6 {
        font-size: 9px;
        letter-spacing: 0.03em;
        font-weight: normal;
    }
`;

const StEventsListItemImg = styled.div`
    position: relative;
    width: 85px;
    min-width: 85px;
    height: 85px;

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 28px;
        width: 116px;
        min-width: 116px;
        height: 94px;
    }
`;

const StEventsListItemInfo = styled.div`
    width: 116px;
    min-width: 116px;
    margin-left: 20px;
    margin-left: auto;
    padding-left: 11px;
    display: block;
    @media (min-width: ${settings.bp.medium.view}) {
        width: 116px;
        min-width: 116px;
        margin-left: 20px;
        margin-left: auto;
        padding-left: 11px;
        display: block;
    }
`;

const StEventListLine = styled.div`
    display: block;
    width: calc(100% - 16px);
    height: 1px;
    background-color: #000;
    opacity: 50%;
    align-self: flex-start;
    margin-bottom: 25px;
`;

const StEventListTitleDescriptionBlock = styled.div`
    p {
        display: none;
    }
    padding: 11px;
    @media (min-width: ${settings.bp.medium.view}) {
        p {
            display: block;
        }
    }
`;

export default function EventsList(props) {
    const { items, title } = props;
    return (
        <StEventsList>
            <h2>{title}</h2>
            <StEventListLine />
            {items.map((item, index) => (
                <EventsItem key={item.id || index} {...item} />
            ))}
        </StEventsList>
    );
}

function EventsItem(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [eventItem, setEventItem] = useState({});

    const {
        img,
        videoOptions,
        title,
        dayWeek,
        date,
        time,
        link,
        shortDescription,
        ...restProps
    } = props;

    let incomingEventItem = {
        link: link,
        title: title,
        img: img,
        shortDescription: shortDescription,
        dayWeek: dayWeek,
        date: date,
        time: time,
    };

    if (Object.keys(eventItem).length == 0) {
        setEventItem(incomingEventItem);
    } else {
        // check for any changes
        if (
            incomingEventItem.link !== eventItem.link ||
            incomingEventItem.title !== eventItem.title ||
            incomingEventItem.img !== eventItem.img ||
            incomingEventItem.shortDescription !== eventItem.shortDescription ||
            incomingEventItem.dayWeek !== eventItem.dayWeek ||
            incomingEventItem.date !== eventItem.date ||
            incomingEventItem.time !== eventItem.time
        ) {
            console.log('CHANGED', incomingEventItem);
            setEventItem(incomingEventItem);
        }
    }

    return (
        <CustomLink style={{ width: '100%' }} href={eventItem.link}>
            <StEventsListItem>
                <StEventsListItemImg
                    onClick={(e) => {
                        e.stopPropagation();
                        setModalOpen(!isModalOpen);
                    }}
                >
                    <VideoLightBox
                        useImgOverlay={videoOptions}
                        btnSizeSmall
                        bgImg={eventItem.img}
                        videoOptions={videoOptions}
                        isOpen={isModalOpen}
                        {...restProps}
                    />
                </StEventsListItemImg>
                <StEventListTitleDescriptionBlock>
                    {eventItem.title && <h3>{eventItem.title}</h3>}
                    {eventItem.shortDescription && (
                        <p>{eventItem.shortDescription}</p>
                    )}
                </StEventListTitleDescriptionBlock>
                <StEventsListItemInfo>
                    {eventItem.dayWeek && <h4>{eventItem.dayWeek}</h4>}
                    {date && <h5>{eventItem.date}</h5>}
                    {eventItem.time && <h6>{eventItem.time}</h6>}
                </StEventsListItemInfo>
            </StEventsListItem>
        </CustomLink>
    );
}
