import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import VideoLightBox from './VideoLightBox';

import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore } = SharedStyles;

const StVideoWrapList = styled.div`
    max-width: ${settings.maxBlockWidth};
    margin: 0 -20px;
    padding-bottom: 60px;
    background-color: #f4f4f4;

    @media (min-width: ${settings.bp.small.view}) {
        margin: 0 auto;
        padding-bottom: 0;
        background-color: #fff;
    }

    .StLinkMore {
        text-align: center;
        margin-top: 60px;

        @media (min-width: ${settings.bp.small.view}) {
            display: none;
        }

        a {
            padding: 15px 30px;
            min-width: 236px;
        }
    }

    ${props =>
        props.showBtnMoreDesktop &&
        css`
            .StLinkMore {
                @media (min-width: ${settings.bp.small.view}) {
                    display: block;
                }
            }
        `};
`;

const StVideoWrapListWrap = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        height: 480px;
        display: flex;
    }
`;

const StVideoBgView = styled.div`
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (min-width: ${settings.bp.small.view}) {
        padding-bottom: 0;
        width: 45%;
        height: 100%;
    }
`;

const StVideoList = styled.div`
    margin: -40px 0 0;
    padding: 0 32px;

    @media (min-width: ${settings.bp.small.view}) {
        margin: 0;
        border: 1px solid #000;
        width: 55%;
        overflow: hidden;
        overflow-y: auto;
        padding: 30px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 58px 40px 58px 60px;
    }

    @media (min-width: ${settings.bp.large.view}) {
        padding: 58px 40px 58px 80px;
    }
`;

const StVideoItem = styled.div`
    display: flex;
    line-height: 1.25;
    cursor: pointer;
    background-color: #fff;
    min-height: 100px;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 77px;
    }

    &:not(:last-child) {
        margin-bottom: 10px;

        @media (min-width: ${settings.bp.small.view}) {
            margin-bottom: 20px;
        }
    }

    h3 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.07em;
        margin: 0 0 5px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 16px;
        }
    }

    h4 {
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 0.05em;
        margin: 0;
    }
`;

const StVideoImg = styled.div`
    position: relative;
    width: 52%;

    @media (min-width: ${settings.bp.small.view}) {
        width: 30%;
    }
`;

const StVideoText = styled.div`
    width: 48%;
    padding: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 70%;
        padding: 24px;
    }
`;

function VideoWrapListItem(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const { img, title, subtitle, videoOptions, ...restProps } = props;

    return (
        <StVideoItem onClick={() => setModalOpen(!isModalOpen)}>
            <StVideoImg>
                <VideoLightBox
                    useImgOverlay={videoOptions}
                    btnSizeSmall
                    bgImg={img}
                    videoOptions={videoOptions}
                    isOpen={isModalOpen}
                    {...restProps}
                />
            </StVideoImg>
            <StVideoText>
                <h3>{title}</h3>
                <h4>{subtitle}</h4>
            </StVideoText>
        </StVideoItem>
    );
}

export default function VideoWrapList(props) {
    const {
        items,
        bgUrl,
        linkMobileText,
        linkMobileMore,
        showBtnMoreDesktop,
        invert,
    } = props;

    return (
        <StVideoWrapList showBtnMoreDesktop={showBtnMoreDesktop}>
            <StVideoWrapListWrap>
                {invert && (
                    <StVideoList>
                        {items.map((item, index) => (
                            <VideoWrapListItem
                                key={item.id || index}
                                {...item}
                            />
                        ))}
                    </StVideoList>
                )}
                <StVideoBgView style={{ backgroundImage: `url(${bgUrl})` }} />
                {!invert && (
                    <StVideoList>
                        {items.map((item, index) => (
                            <VideoWrapListItem
                                key={item.id || index}
                                {...item}
                            />
                        ))}
                    </StVideoList>
                )}
            </StVideoWrapListWrap>
            {linkMobileText && linkMobileMore && (
                <StLinkMore>
                    <a href={linkMobileMore}>{linkMobileText}</a>
                </StLinkMore>
            )}
        </StVideoWrapList>
    );
}
