import React from 'react';
import styled, { css } from 'styled-components';

import CustomLink from './CustomLink';

const StFieldLink = styled.div.attrs((props) => ({
    className: 'StFieldLink',
}))`
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #8b8b8b;
    margin: 0px 20px;
`;

export default function FieldLink(props) {
    const { url, text, ...restProps } = props;
    return (
        <StFieldLink>
            <CustomLink
                style={{ textDecoration: 'underline' }}
                href={url}
                target="_blank"
            >
                {text}
            </CustomLink>
        </StFieldLink>
    );
}
