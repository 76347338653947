import React from 'react';

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps';

function Map(props) {
    const { markers, ...restProps } = props;
    return (
        <GoogleMap {...restProps}>
            {markers &&
                markers.map((item, index) => (
                    <Marker
                        key={item.id || index}
                        {...item}
                        icon={{
                            scaledSize: { width: 32, height: 32 },
                            url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAwMNJStYg5z+AAADEklEQVRo3uWZS0hUURzGfzNjpj3U0iApibBoKqmF0UJ6kJSbCIqgokAoN0VY0SaIsFW0CUs3LYqKFhYIIhRFi8oExUUggr2wNCoyxqaJFF/NzG3hAybu/9x77j2jC7+zu/d8/993DjP3nHsuzHUFPHjClFNGmFUsIUScGH28pY3n9KU7bg7n6cISWwcnyU4XPJsaYgr4VPvBWTLM48v55AI+1booNQkPcJm4Bt7CYpTTpvAhbmvCp1odQf/4IA894i0s6v0HqPOBt7C44A9/yCfeIsFu7/hCfvsOYPGVxV4DNBjAW1hc84bfTNKh8CDdtPKGYce/5AovAR4oiz5iD5mTPbPZR4uy93V9fD5jYrkoFTaOwwwpHPN1A5wSi0VYK3hK+SO69usGaBYKxdmucB0QA9zUwwf4JRS66+B8Ivje6QVYKY4k7ODcKc6c1q+gXCjz3tEZIip4N9gb7NerfKF8l2OABN3CHaGmfYBFQpGoi9kbEK7n6AQY1iuSojzh+pBOgJ9CkWIXAaQ+Ay680yoRf8sFDs6w4EyKM2OrDPGxetHBWS/4PujgAV4JhWIUKlzrGBV89ySLtG1sF67n0USWcC+XZvFx06o7A2WKxbWd5TaOIl6Ljr8s0w0Q5JsiQoRzLEgZ+yXl9u2ZDJJfTuupVkYc4QU9RClgIzumNyf2quKO7gzAJsWI9FqMhfp4gDZDATxsyCZ0zAg+6biEi8rki4EATV7xAGcMBNjiJ0AW333iHzshQsq7cbDdgruVxVH6/cwAZPHZx/gb/MEnVOkZP84aEwGCdHoMUGsCD7CVhAd8P7mmAsAtDwGOmMPDUiKa+BZPZ7AKHdfCj7HeLB4CvNQIcMU0HqCEcZf43pTNioNCrntGgF0u+iU5SE86ZgAy6HAx/hvpgU+omEEH/EfxvdKQqpX4BNvSi4cATxUBrqYbD1AkHkF06p+FedNe2yPMIfMPH1m1NgFOzBwe5tH+H75xJvEAq1M+YPWaXHrdqmL6O9KIv52vd9VMBqiaHTwEaMTi/mzhAXJoSN+X0rmif+S00edRAdbNAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAzLTAzVDEzOjM3OjQzKzAwOjAwaFuwDgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMy0wM1QxMzozNzo0MyswMDowMBkGCLIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC',
                        }}
                    />
                ))}
        </GoogleMap>
    );
}

export default withScriptjs(withGoogleMap(Map));
