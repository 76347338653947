import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import settings from '../global/settings';
import get from 'lodash.get';

import { SharedStyles, Slider, Container } from '../index';
import * as SliderHero from '../components/Slider/SliderHero';
import CustomLink from './CustomLink';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { StProductDetailShopBtns } from './ProductDetail/ProductDetail';
import DDButton from './DDButton';

const {
    StSliderHeroItem,
    StSliderHeroImgMobile,
    StSliderHeroImgWeb,
    StSliderHeroItemDesc,
    StSliderHeroItemDescFirst,
    StSliderHeroItemDescWeb,
} = SliderHero;
const { StLinkMore } = SharedStyles;

const StProductGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
`;

const StProductGridItem = styled.div`
    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding: 20px;
        width: 50vw;
        height: 50vw;
        text-align: center;

        @media (min-width: ${settings.bp.medium.view}) {
            width: 20vw;
            height: 20vw;
        }

        * {
            color: #fff;
            text-transform: uppercase;

            &:last-child {
                margin-bottom: 0;
            }
        }

        dark {
            color: #000;
        }

        h2,
        h3 {
            font-weight: 500;
            line-height: 1.25;
            letter-spacing: 0.05em;
            font-size: 12px;

            @media (min-width: ${settings.bp.small.view}) {
                font-size: 16px;
            }
        }

        h2 {
            margin-bottom: 16px;

            @media (min-width: ${settings.bp.small.view}) {
                margin-bottom: 24px;
            }
        }

        h3 {
            @media (min-width: ${settings.bp.small.view}) {
                display: none;
            }
        }
    }

    .StDDButton {
        margin-top: 15px;
    }
    
    .StLinkMore {
        a {
            height: 47px;
        }
    }
`;

const StProductGridType = styled.div`
    font-size: 10px;
    line-height: 1.15;
    letter-spacing: 0.08em;
    margin-bottom: 16px;
    font-weight: normal;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 13px;
    }
`;

const StProductGridImg = styled.div`
    display: none;

    @media (min-width: ${settings.bp.small.view}) {
        display: block;
    }
`;

const StCategoryImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    padding: 20px;
    width: 100vw;
    height: 100vw;
    text-align: center;

    @media (min-width: ${settings.bp.medium.view}) {
        height: 40vw;
        min-width: 40vw;
        max-width: 40vw;
    }

    * {
        color: #fff;

        &:last-child {
            margin-bottom: 0;
        }
    }

    dark {
        color: #000;
    }

    h2 {
        text-transform: uppercase;
    }
    h3 {
        font-weight: 300;
        line-height: 1.25;
        letter-spacing: 0.05em;
        font-size: 12px;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 16px;
        }
    }

    h2 {
        margin-bottom: 16px;

        @media (min-width: ${settings.bp.small.view}) {
            margin-bottom: 24px;
        }
    }
`;

const StCategory = styled.div`
    display: block;
`;

const StCategoryGrid = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 100vw;
    @media (min-width: ${settings.bp.medium.view}) {
        flex-direction: row;
        padding-top: 0px;
    }
`;

const StProductGridBg = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`;

function ProductGridItem(props) {
    const { href, type, title, bg, img, subTitle, isLight, sku, isLoggedIn, addToCart } = props;

    let buttonTitle = (
        <FormattedMessage id="product-shop-addtocart-button" />
    );
    let data = {
        quantity: 1,
        code: sku,
    };
    return (
        <StProductGridItem>
            <CustomLink href={href}>
                <LazyLoad offset={300}>
                    {bg && (
                        <StProductGridBg
                            style={{ backgroundImage: `url(${bg})` }}
                        />
                    )}
                    {type && (
                        <StProductGridType
                            style={isLight || !bg ? { color: '#000' } : {}}
                        >
                            {type}
                        </StProductGridType>
                    )}
                    {title && (
                        <h2 style={isLight || !bg ? { color: '#000' } : {}}>
                            {title}
                        </h2>
                    )}
                    {img && (
                        <StProductGridImg>
                            <img src={img} alt={title} />
                        </StProductGridImg>
                    )}
                    {subTitle && <h3>{subTitle}</h3>}
                    {isLoggedIn && (
                        <StProductDetailShopBtns stack>
                            <DDButton
                                onClick={() => addToCart(data)}
                                defaultTitle={buttonTitle}
                            />
                        </StProductDetailShopBtns>
                    )}

                </LazyLoad>
            </CustomLink>
        </StProductGridItem>
    );
}

function CategoryImage(props) {
    const { categoryImage } = props;
    if (!categoryImage) {
        return null;
    }
    const { linkUrl, title, bg, subTitle, isLight, buttonText } = categoryImage;
    return (
        <StCategoryImage>
            {bg && (
                <StProductGridBg style={{ backgroundImage: `url(${bg})` }} />
            )}
            {title && (
                <h2 style={isLight || !bg ? { color: '#000' } : {}}>{title}</h2>
            )}
            {subTitle && (
                <h3 style={isLight || !bg ? { color: '#000' } : {}}>
                    {subTitle}
                </h3>
            )}
            {linkUrl && (
                <StLinkMore white={!isLight}>
                    <CustomLink href={linkUrl}>
                        {buttonText || 'Shop Now'}
                    </CustomLink>
                </StLinkMore>
            )}
        </StCategoryImage>
    );
}

function CategoryGrid(props) {
    const { items, categoryImage, ...restProps } = props;
    return (
        <StCategory>
            <StCategoryGrid>
                <CategoryImage categoryImage={categoryImage} />
                <StProductGrid>
                    {items.map((item, index) => {
                        if (index <= 5) {
                            return (
                                <ProductGridItem
                                    key={item.id || index}
                                    {...item}
                                    {...restProps}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </StProductGrid>
            </StCategoryGrid>
            {items && items.length > 6 && (
                <StProductGrid>
                    {items.map((item, index) => {
                        if (index >= 6) {
                            return (
                                <ProductGridItem
                                    key={item.id || index}
                                    {...item}
                                    {...restProps}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </StProductGrid>
            )}
        </StCategory>
    );
}

function CategorySlides(props) {
    const { slides, showButton } = props;
    if (!slides) {
        return null;
    } else {
        return (
            <Slider autoPlay hideProgress>
                {slides.map((slide, index) => {
                    return (
                        <StSliderHeroItem key={`slide-${index}`}>
                            <StSliderHeroImgWeb>
                                <CustomLink href={get(slide, 'slideLink')}>
                                    <img
                                        alt=""
                                        src={get(
                                            slide,
                                            'image.localFile.big.fluid.src'
                                        )}
                                    />
                                </CustomLink>
                                <StSliderHeroItemDescWeb>
                                    {get(slide, 'slidePreText') && (
                                        <h1>{get(slide, 'slidePreText')}</h1>
                                    )}
                                    {get(slide, 'slideText') && (
                                        <h2>{get(slide, 'slideText')}</h2>
                                    )}
                                    {get(slide, 'slideLink') && showButton && (
                                        <StLinkMore invertColor>
                                            <a href={get(slide, 'slideLink')}>
                                                {get(slide, 'slideLinkText') ||
                                                    'SHOP NOW'}
                                            </a>
                                        </StLinkMore>
                                    )}
                                </StSliderHeroItemDescWeb>
                            </StSliderHeroImgWeb>
                            <StSliderHeroImgMobile>
                                <CustomLink href={get(slide, 'slideLink')}>
                                    <img
                                        alt=""
                                        src={get(
                                            slide,
                                            'mobileImage.localFile.big.fluid.src'
                                        )}
                                        srcSet={get(
                                            slide,
                                            'mobileImage.localFile.big.fluid.src'
                                        )}
                                    />
                                </CustomLink>
                            </StSliderHeroImgMobile>
                            <StSliderHeroItemDesc>
                                <Container>
                                    <StSliderHeroItemDescFirst>
                                        {get(slide, 'slideText') && (
                                            <h2>{get(slide, 'slideText')}</h2>
                                        )}
                                        {get(slide, 'slideLink') && showButton && (
                                            <StLinkMore>
                                                <CustomLink
                                                    href={get(
                                                        slide,
                                                        'slideLink'
                                                    )}
                                                >
                                                    {get(
                                                        slide,
                                                        'slideLinkText'
                                                    ) || 'SHOP NOW'}
                                                </CustomLink>
                                            </StLinkMore>
                                        )}
                                    </StSliderHeroItemDescFirst>
                                </Container>
                            </StSliderHeroItemDesc>
                        </StSliderHeroItem>
                    );
                })}
            </Slider>
        );
    }
}

export default function ProductGrid(props) {
    const { items, categoryImage, showSlides, showButton, ...restProps } = props;

    let showLink = true;
    if (typeof showButton !== 'undefined' && showButton !== null) {
        showLink = showButton;
    }

    if (!categoryImage) {
        return (
            <StProductGrid>
                {items.map((item, index) => (
                    <ProductGridItem key={item.id || index} {...item} {...restProps}/>
                ))}
            </StProductGrid>
        );
    } else {
        return (
            <>
                {showSlides && (
                    <CategorySlides
                        slides={categoryImage.slides}
                        showButton={showLink}
                    />
                )}
                <CategoryGrid
                    key={categoryImage.title}
                    items={items}
                    categoryImage={categoryImage}
                    {...restProps}
                />
            </>
        );
    }
}
