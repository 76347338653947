import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import VideoLightBox from './VideoLightBox';
import ScrollableBlock from './ScrollableBlock';
import ButtonPlay from './ButtonPlay';

import settings from '../global/settings';
import CustomLink from './CustomLink';

const StListOfVideo = styled.div`
    ${props =>
        props.mobileScrollable &&
        css`
            @media (min-width: ${settings.bp.medium.view}) {
                .StListOfWrap {
                    display: block;
                }

                .StScrollableBlockInner {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                .StScrollableBlockInner {
                    white-space: nowrap;
                }

                .StListOfVideoItem {
                    width: 300px;
                    display: inline-block;
                    vertical-align: top;
                }
            }

            @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                .StListOfVideoItem {
                    width: 200px;
                }
            }
        `};
    ${props =>
        props.extraMargin &&
        css`
            text-align: center;
            margin-left: 15px;
            margin-right: 15px;
            @media (min-width: ${settings.bp.medium.view}) {
                margin-left: 40px;
                margin-right: 40px;
            }
        `};
    }
`;

const StListOfWrap = styled.div.attrs(props => ({
    className: 'StListOfWrap',
}))`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    ${props =>
        props.bitBigger &&
        css`
            margin-left: -8px;
            width: calc(100% + 16px);
        `};
    .StListOfVideoItem {
        &:first-child {
            margin-left: 0px;
        }
        &:last-child {
            margin-right: 0px;
        }
        ${props =>
            props.numberInRow &&
            css`
                width: calc(${100 / props.numberInRow}% - 15px) !important;
            `};
    }
`;

const StListOfVideoItem = styled.div.attrs(props => ({
    className: 'StListOfVideoItem',
}))`
    position: relative;
    white-space: normal;
    width: calc(50% - 16px);
    margin: 0 8px 16px;

    @media (min-width: ${settings.bp.small.view}) {
        width: calc(50% - 20px);
        margin: 0 10px 54px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: calc(33.333% - 20px);
    }

    ${props =>
        props.numberInRow &&
        css`
            @media (min-width: ${settings.bp.medium.view}) {
                width: calc(${100 / props.numberInRow}% - 20px);
            }
        `};

    h3 {
        margin: 0 0 10px;
        font-size: 13px;
        letter-spacing: 0.06em;
        font-weight: normal;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 16px;
            font-weight: 500;
        }
    }

    h4 {
        margin: 0;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        font-size: 12px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 13px;
        }
    }
`;

const StListOfVideoItemLink = styled.a.attrs(props => ({
    className: 'StListOfVideoItemLink',
}))`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    &:hover + .StButtonPlayContainer:before {
        opacity: 0;
    }
`;

const StListOfVideoItemBg = styled.div.attrs(props => ({
    className: 'StListOfVideoItemBg',
}))`
    padding-bottom: 56.25%;
    margin-bottom: 10px;
    position: relative;

    .StButtonPlayContainer {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
`;

function ListOfVideoItem(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const {
        href,
        img,
        videoOptions,
        title,
        subtitle,
        numberInRow,
        removeOverlay,
        ...restProps
    } = props;
    return (
        <StListOfVideoItem
            numberInRow={numberInRow}
            onClick={() => setModalOpen(!isModalOpen)}
        >
            <StListOfVideoItemBg>
                {href && <StListOfVideoItemLink href={href} />}
                {!href ? (
                    <VideoLightBox
                        showBtn
                        useImgOverlay={removeOverlay ? false : true}
                        bgImg={img}
                        videoOptions={videoOptions}
                        isOpen={isModalOpen}
                        {...restProps}
                    />
                ) : (
                    <ButtonPlay
                        btnSizeSmall
                        useImgOverlay={removeOverlay ? false : true}
                        bgImg={img}
                        isHidden={removeOverlay}
                    />
                )}
            </StListOfVideoItemBg>
            {title && (
                <h3>
                    {href ? (
                        <CustomLink href={href}>{title}</CustomLink>
                    ) : (
                        title
                    )}
                </h3>
            )}
            {subtitle && <h4>{subtitle}</h4>}
        </StListOfVideoItem>
    );
}

export default function ListOfVideo(props) {
    const {
        items,
        mobileScrollable,
        numberInRow,
        removeOverlay,
        extraMargin,
        bitBigger,
    } = props;
    const inner = (
        <>
            {items.map((item, index) => (
                <ListOfVideoItem
                    removeOverlay={removeOverlay}
                    numberInRow={numberInRow}
                    key={item.id || index}
                    {...item}
                />
            ))}
        </>
    );
    return (
        <StListOfVideo
            extraMargin={extraMargin}
            mobileScrollable={mobileScrollable}
        >
            {mobileScrollable ? (
                <ScrollableBlock>{inner}</ScrollableBlock>
            ) : (
                <StListOfWrap bitBigger={bitBigger}>{inner}</StListOfWrap>
            )}
        </StListOfVideo>
    );
}
