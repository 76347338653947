import React from 'react'
import styled, { css } from 'styled-components'
import settings from '../../../kenra-storybook/global/settings'
import {
    SliderBestSellerItem,
    SharedStyles
} from '../../../kenra-storybook/index'
import { useIntl } from 'gatsby-plugin-react-intl'
import { getProductUrl } from 'helpers/url'
import get from 'lodash.get'
import HorizontalScrollContainer from './HorizontalScrollContainer'
const { StPageTitle } = SharedStyles

const HorizontalSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 80vh;
`

const CardsContainer = styled.div`
  position: relative;
  height: 65%;
  padding: 0 0 0 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: ${settings.bp.small.view}) {
    padding: 0 0 0 150px;
    height: 80%;
    margin-top: -90px;
  }
  @media (min-width: 1650px) {
    padding: 0 0 0 0;
    margin-left: auto;
    margin-right: auto;
    height: 80%;
    margin-top: -90px;
    justify-content: center;
  }
`

const Products = ({
    products,
    shopifyProducts,
    allGoogleSheetProductsRow,
    magicMousse,
    isPro,
}) => {
    return products.map((product, index) => {
        let handle = product?.shopifyProduct?.handle
        let shopifyProduct = shopifyProducts?.find(
            p => p.handle === handle
        )
        if (!product || !shopifyProduct) return null

        let title = product.title
        let description = product.description
        let url = getProductUrl(handle)
        let homepageImage = get(
            product,
            'homepageImage.localFile.publicURL'
        )
        let homepageHoverImage = get(
            product,
            'homepageHoverImage.localFile.publicURL'
        )
        let gifOverlayImage = get(
            product,
            'gifOverlayImage.localFile.publicURL'
        )

        let productImage = get(
            product,
            'productItem.images[0].localFile.publicURL'
        )

        let storedRegion = ''
        let skus = []
        let productItem = get(product, 'productItem')

        if (typeof window !== 'undefined') {
            storedRegion = localStorage.getItem('preferred-region')
        }



        for (
            let shopifyLoop = 0;
            shopifyLoop < shopifyProduct.variants.length;
            shopifyLoop++
        ) {
            if (
                typeof productItem.variants[shopifyLoop] ===
                'undefined'
            ) {
                console.log(
                    'Product Item does not have matching variant for: ',
                    shopifyProduct.variants[shopifyLoop]
                )
                skus.push(shopifyProduct.variants[shopifyLoop].sku)
                continue
            }

            // Does the product exist for the caAssortment?
            if (storedRegion !== 'CA') {
                skus.push(shopifyProduct.variants[shopifyLoop].sku)
            } else {
                allGoogleSheetProductsRow.nodes.forEach(
                    productInfo => {
                        let shopifySku = parseInt(
                            shopifyProduct.variants[shopifyLoop].sku
                        )
                        if (
                            shopifySku === productInfo.sku &&
                            productInfo.caAssortment === 'Y'
                        ) {
                            let productItemIDH = productInfo.canIdh
                            if (
                                productItemIDH !== null &&
                                typeof productItemIDH !==
                                'undefined' &&
                                !skus.includes(
                                    productItemIDH.toString()
                                )
                            ) {
                                skus.push(
                                    productItemIDH.toString()
                                )
                            } else if (
                                !skus.includes(
                                    shopifyProduct.variants[
                                        shopifyLoop
                                    ].sku
                                )
                            ) {
                                skus.push(
                                    shopifyProduct.variants[
                                        shopifyLoop
                                    ].sku
                                )
                            }
                        }
                    }
                )
            }
        }
        return (
            <RowItemWrapper
                key={`${product.id}_${index}`}
            >
                <SliderBestSellerItem
                    title={title}
                    description={magicMousse ? null : description}
                    url={url}
                    productImage={productImage}
                    gifOverlayImage={homepageHoverImage}
                    homepageImage={homepageImage}
                    productId={skus[0]}
                    shopButtonStyle={magicMousse ? '3' : null}
                    starsImage={product.reviewStarsImage && StarsImage(product.reviewStarsImage)}
                    removeWidth={true}
                    externalShopNow={true}
                    isPro={isPro}
                />
            </RowItemWrapper>
        )
    })
}

export const RowItemWrapper = styled.div`
    width: auto;
    max-width: 318px;
    margin: 4px;
    padding: 0px;
    @media (max-width: ${settings.bp.medium.view}) {
        flex-shrink: 1 !important;
        margin: 0px;
        min-width: 30%;
    }
`

const StStarsContainer = styled.div`
    flex: 1; 
    display: flex;
    text-align: center;
    margin-bottom: -16px;
    max-width: 100px;
    align-self: center;
    @media (min-width: ${settings.bp.medium.view}) {
        padding-top: 10px;
        max-width: 150px;
    }
`

const StarsImage = (url) => {
    return <StStarsContainer>
        <img style={{ maxWidth: '100%' }} src={url} />
    </StStarsContainer>
}


const ScrollingProductsRow = ({
    title,
    subtitle,
    products,
    shopifyProducts,
    allGoogleSheetProductsRow,
    magicMousse,
    hideDots,
    removeWidth,
    alignCenter,
    isPro,
    mobileHorizontal,
}) => {
    let intl = useIntl()
    return <HorizontalSection>
        <HorizontalScrollContainer>
            <CardsContainer>
                <Products
                    products={products}
                    shopifyProducts={shopifyProducts}
                    allGoogleSheetProductsRow={allGoogleSheetProductsRow}
                    magicMousse={magicMousse}
                    isPro={isPro}
                />
            </CardsContainer>
        </HorizontalScrollContainer>
    </HorizontalSection>
}

export default ScrollingProductsRow
