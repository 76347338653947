import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';
import ImageLink from './ImageLink';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

const StAccordion = styled.div`
    ${props =>
        props.thin &&
        css`
            max-width: 100%;
            min-width: 100%;
        `};
`;

const StAccordionItem = styled.div`
    border-top: 2px solid #000;
    ${props =>
        props.thin &&
        css`
            border-top: 1.5px solid #000;
        `};
`;

const StAccordionData = styled.div`
    display: none;
    ${props =>
        props.isOpen &&
        css`
            display: block;
        `};
`;

const StAccordionTitle = styled.div.attrs(props => ({
    className: 'StAccordionTitle',
}))`
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
    opacity: 0.8;
    padding: 10px 0;
    letter-spacing: 0.07em;
    cursor: pointer;
    position: relative;
    padding-right: 18px;
    ${props =>
        props.thin &&
        css`
            padding-left: 5px;
            padding-right: 24px;
            padding-top: 28px;
            padding-bottom: 28px;
            font-size: 22px;
        `};

    &:after,
    &:before {
        content: '';
        position: absolute;
        right: 6px;
        top: 19px;
        width: 12px;
        border-bottom: 2px solid #000;
        ${props =>
            props.thin &&
            css`
                border-bottom: 1px solid #000;
                margin-top: 24px;
            `};
    }

    &:after {
        transform: rotate(90deg);
    }

    ${props =>
        props.isOpen &&
        css`
            &:after {
                display: none;
            }
        `};
`;

const StAccordionText = styled.div.attrs(props => ({
    className: 'StAccordionText',
}))`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.33;
    font-stretch: normal;
    padding-bottom: 30px;

    ${props =>
        props.thin &&
        css`
            font-weight: 400;
            line-height: 1.2;
            padding-left: 5px;
            padding-right: 24px;
            font-size: 18px;
        `};

    @media (min-width: ${settings.bp.small.view}) {
        padding-bottom: 60px;
        font-size: 15px;
        ${props =>
            props.thin &&
            css`
                padding-bottom: 28px;
                font-size: 18px;
            `};
    }

    h4 {
        text-transform: uppercase;
        font-size: 16px !important;
        line-height: 1.4;
        font-weight: 500;
        opacity: 0.8;
        padding: 10px 0;
        letter-spacing: 0.07em;
        position: relative;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    p {
        margin: 0 0 22px;
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

const StAccordionLabelText = styled.div.attrs(props => ({
    className: 'StAccordionLabelText',
}))`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.33;
    font-stretch: normal;
    padding-bottom: 30px;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 15px;
    }
`;

const StAccordionAdditionalLabelText = styled.div.attrs(props => ({
    className: 'StAccordionAdditionalLabelText',
}))`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.33;
    font-stretch: normal;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 15px;
    }
`;

const StAccordionTextData = styled.div.attrs(props => ({
    className: 'StAccordionTextData',
}))`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.33;
    font-stretch: normal;
    display: flex;
    flex-wrap: wrap;
    white-space: break-spaces;
    padding-bottom: 30px;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 15px;
    }

    h4 {
        text-transform: uppercase;
        font-size: 16px !important;
        line-height: 1.4;
        font-weight: 500;
        opacity: 0.8;
        padding: 10px 0;
        letter-spacing: 0.07em;
        position: relative;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    p {
        margin: 0 0 22px;
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

const StAccordionTextDataItem = styled.div.attrs(props => ({
    className: 'StAccordionTextDataItem',
}))`
    cursor: pointer;
    display: contents;
`;

const StAccordionTextDataItemName = styled.div.attrs(props => ({
    className: 'StAccordionTextDataItemName',
}))`
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
        text-decoration: underline;
    }
`;

const StAccordionLink = styled.div.attrs(props => ({
    className: 'StAccordionLink',
}))``;

const StAccordionProductAttribute = styled.div.attrs(props => ({
    className: 'StAccordionProductAttribute',
}))`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.33;
    font-stretch: normal;
    padding-bottom: 30px;

    @media (min-width: ${settings.bp.small.view}) {
        padding-bottom: 30px;
        font-size: 15px;
    }
`;

const StAccordionProductDataItem = styled.div.attrs(props => ({
    className: 'StAccordionProductDataItem',
}))`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.33;
    font-stretch: normal;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 15px;
    }
`;

const StAccordionProductSubtitle = styled.div.attrs(props => ({
    className: 'StAccordionProductSubtitle',
}))`
    font-size: 16px;
    font-weight: 600;
    line-height: 1.33;
    font-stretch: normal;
    text-transform: capitalize;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 15px;
    }
`;

function AccordionDataItem(props) {
    const { index, name, itemObject, onClick } = props;
    return (
        <StAccordionTextDataItem onClick={() => onClick(itemObject)}>
            {index > 0 && ', '}
            <StAccordionTextDataItemName>{name}</StAccordionTextDataItemName>
        </StAccordionTextDataItem>
    );
}

function AccordionProductDataItem(props) {
    const { productIngredients, onClick } = props;
    const { component, ingredientGroups } = productIngredients;

    return (
        <StAccordionProductDataItem>
            {component && (
                <StAccordionProductSubtitle>
                    {component}
                </StAccordionProductSubtitle>
            )}
            {ingredientGroups[0] &&
                ingredientGroups[0].map((group, index) => (
                    <div>
                        <StAccordionProductSubtitle>
                            {group.ingredientGroupName}:
                        </StAccordionProductSubtitle>
                        <StAccordionTextData>
                            {group.ingredients &&
                                group.ingredients.map((item, index) => {
                                    let ingredients = {
                                        name:
                                            item.ingredientName +
                                            item.specialCharacter,
                                        itemObject: item,
                                    };
                                    return (
                                        <AccordionDataItem
                                            key={ingredients.id || index}
                                            index={index}
                                            {...ingredients}
                                            onClick={onClick}
                                        />
                                    );
                                })}
                        </StAccordionTextData>
                    </div>
                ))}
        </StAccordionProductDataItem>
    );
}

function AccordionProductAttributes(props) {
    const { attributes } = props;

    return (
        <StAccordionProductAttribute>
            <StAccordionProductSubtitle>
                <FormattedMessage id="product-attributes" />
            </StAccordionProductSubtitle>
            <ul>
                {attributes.map((item, index) => (
                    <li>{item.attribute}</li>
                ))}
            </ul>
        </StAccordionProductAttribute>
    );
}

function AccordionItem(props) {
    const {
        title,
        subtitle,
        opened,
        text,
        label,
        data,
        downloadSDS,
        onClick,
        thin,
        onToggle,
    } = props;
    const [isOpen, toggleOpen] = useState(opened || false);
    var downloadSDSData = null;
    if (downloadSDS) {
        downloadSDSData = downloadSDS.downloadSDS;
    }

    let productDetails = {};
    let error = null;
    if (typeof data !== 'undefined') {
        if (data.error !== '') {
            error = data.error;
        } else {
            productDetails = data.productDetails;
        }
    }

    // Extract the data for the Product Details
    if (error) {
        return (
            <StAccordionItem>
                <StAccordionTitle
                    isOpen={isOpen}
                    onClick={() => {
                        toggleOpen(!isOpen)
                        onToggle && onToggle(!isOpen)
                    }}
                >
                    {title}
                </StAccordionTitle>
                <StAccordionData isOpen={isOpen}>
                    <StAccordionText
                        dangerouslySetInnerHTML={{ __html: error }}
                    />
                    {downloadSDSData && (
                        <StAccordionLink>
                            <ImageLink
                                image={downloadSDSData.image}
                                text={downloadSDSData.text}
                                link={downloadSDSData.link}
                            />
                        </StAccordionLink>
                    )}
                </StAccordionData>
            </StAccordionItem>
        );
    }

    return (
        <StAccordionItem thin={thin}>
            <StAccordionTitle
                isOpen={isOpen}
                onClick={() => {
                    toggleOpen(!isOpen)
                    onToggle && onToggle(!isOpen)
                }}
                thin={thin}
            >
                {title}
            </StAccordionTitle>
            <StAccordionData isOpen={isOpen}>
                {subtitle && (
                    <StAccordionText
                        thin={thin}
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                    />
                )}
                {text && (
                    <StAccordionText
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                )}
                {label && (
                    <StAccordionLabelText
                        dangerouslySetInnerHTML={{ __html: label }}
                    />
                )}
                {productDetails.productIngredients &&
                    productDetails.productIngredients.map((item, index) => (
                        <AccordionProductDataItem
                            key={item.id || index}
                            productIngredients={item}
                            onClick={onClick}
                        />
                    ))}
                {productDetails.additionalDetails && (
                    <StAccordionAdditionalLabelText
                        dangerouslySetInnerHTML={{
                            __html: productDetails.additionalDetails,
                        }}
                    />
                )}
                {productDetails.attributes && (
                    <AccordionProductAttributes
                        attributes={productDetails.attributes}
                    />
                )}
                {downloadSDSData && (
                    <StAccordionLink>
                        <ImageLink
                            image={downloadSDSData.image}
                            text={downloadSDSData.text}
                            link={downloadSDSData.link}
                        />
                    </StAccordionLink>
                )}
            </StAccordionData>
        </StAccordionItem>
    );
}

export default function Accordion(props) {
    const { items, onClick, thin } = props;
    return (
        <StAccordion thin={thin}>
            {items.map((item, index) => (
                <AccordionItem
                    key={item.id || index}
                    {...item}
                    onClick={onClick}
                    onToggle={item.onToggle}
                    thin={thin}
                />
            ))}
        </StAccordion>
    );
}
