import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore, StLink } = SharedStyles;
import VideoLightBox from './VideoLightBox';

const StImgVideoTextGrid = styled.div`
    ${(props) =>
        props.styleSquare &&
        css`
            .StImgVideoTextGridRow {
                margin-bottom: 0;
            }

            .StImgVideoTextGridImg {
                padding: 0;
            }

            .StImgVideoTextGridImg,
            .StImgVideoTextGridText {
                @media (min-width: ${settings.bp.small.view}) {
                    width: 50%;
                }
            }

            .StImgVideoTextGridText {
                @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                    min-height: 100vw;
                    padding: 30px;
                }

                @media (min-width: ${settings.bp.medium.view}) {
                    padding-left: 6%;
                    padding-right: 6%;
                }
            }

            .StLinkMore a {
                min-width: 180px;
                padding: 16px 30px;
            }
        `};

    ${(props) =>
        props.rowRevers &&
        css`
            .StImgVideoTextGridRow {
                &:nth-child(even) {
                    @media (min-width: ${settings.bp.small.view}) {
                        flex-direction: row;
                    }
                }
                &:nth-child(odd) {
                    @media (min-width: ${settings.bp.small.view}) {
                        flex-direction: row-reverse;
                    }
                }
            }
        `};
`;

const StImgVideoTextGridRow = styled.section.attrs((props) => ({
    className: 'StImgVideoTextGridRow',
}))`
    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 70px;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }

    &:nth-child(even) {
        @media (min-width: ${settings.bp.small.view}) {
            flex-direction: row-reverse;

            .StImgVideoTextGridImg {
                padding-left: 0;
            }
        }
    }

    &:nth-child(odd) {
        @media (min-width: ${settings.bp.small.view}) {
            .StImgVideoTextGridImg {
                padding-right: 0;
            }
        }
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 28px;
            color: ${settings.color.gray};
            line-height: 1.79;
            margin: 0 0 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 20px;
            letter-spacing: 0.08em;
        }
    }

    .StLinkMore {
        margin: 26px 0 0;

        @media (min-width: ${settings.bp.medium.view}) {
            margin: 46px 0 0;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    ${(props) =>
        props.styleWhiteColor &&
        css`
            color: #fff;

            h2,
            p {
                color: #fff;
            }

            .StLinkMore a {
                color: #000000;
                background-color: #fff;
            }
        `};
`;

const StImgVideoTextGridImg = styled.div.attrs((props) => ({
    className: 'StImgVideoTextGridImg',
}))`
    padding: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 30px;
        width: calc(50% - 30px);
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 0 60px;
        width: calc(50% - 60px);
    }

    img {
        width: 100%;
    }
`;

const StImgVideoTextGridVideo = styled.div.attrs((props) => ({
    className: 'StImgVideoTextGridVideo',
}))`
    padding: 20px;
    position: relative;
    height: 550px;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 30px;
        width: 50%;
        height: 450px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 0 60px;
        width: 50%;
        height: 550px;
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const StImgVideoTextGridText = styled.div.attrs((props) => ({
    className: 'StImgVideoTextGridText',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 20px 70px;
    text-align: center;

    @media (min-width: ${settings.bp.small.view}) {
        width: calc(50% + 30px);
        padding: 30px 6%;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: calc(50% + 60px);
        padding: 30px 11%;
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

const StImgVideoTextGridTag = styled.div`
    display: flex;
    margin-bottom: 50px;

    span {
        text-align: center;
        width: 115px;
        height: 39px;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        display: block;
        padding: 7px;
    }
`;

function ImageVideoTextGridItemTag(props) {
    const { text, tagBg } = props;
    return <span style={{ backgroundColor: tagBg }}>{text}</span>;
}

function ImageVideoTextGridItem(props) {
    const {
        rowBg,
        rowColorWhite,
        img,
        title,
        text,
        linkText,
        linkHref,
        linkStyleWhite,
        linkStyleUnderline,
        tags,
        tagImg,
        videoUrl,
        videoType,
        ...restProps
    } = props;
    return (
        <StImgVideoTextGridRow
            style={{ backgroundColor: rowBg || 'transparent' }}
            styleWhiteColor={rowColorWhite}
        >
            {img && (
                <StImgVideoTextGridImg>
                    <img src={img} alt="" />
                </StImgVideoTextGridImg>
            )}
            {!img && videoUrl && (
                <StImgVideoTextGridVideo>
                    <video controls autoPlay muted>
                        <source src={videoUrl} type={videoType} />
                        Video not supported.
                    </video>
                </StImgVideoTextGridVideo>
            )}
            <StImgVideoTextGridText>
                {title && <h2>{title}</h2>}
                {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
                {linkText && linkHref && !linkStyleUnderline && (
                    <StLinkMore inversionColor={linkStyleWhite}>
                        <a href={linkHref}>{linkText}</a>
                    </StLinkMore>
                )}
                {linkText && linkHref && linkStyleUnderline && (
                    <StLink>
                        <a href={linkHref}>{linkText}</a>
                    </StLink>
                )}
                {tags && (
                    <StImgVideoTextGridTag>
                        {tags.map((item, index) => (
                            <ImageVideoTextGridItemTag
                                key={item.id || index}
                                {...item}
                            />
                        ))}
                    </StImgVideoTextGridTag>
                )}
                {tagImg && (
                    <p>
                        <img src={tagImg} alt="" />
                    </p>
                )}
            </StImgVideoTextGridText>
        </StImgVideoTextGridRow>
    );
}

export default function ImageVideoTextGrid(props) {
    const { items, ...restProps } = props;
    return (
        <StImgVideoTextGrid {...restProps}>
            {items.map((item, index) => (
                <ImageVideoTextGridItem key={item.id || index} {...item} />
            ))}
        </StImgVideoTextGrid>
    );
}
