import React from 'react';
import styled, { css } from 'styled-components';
import Container from './Container';

const MdBreakPoint = '768px';
const MdBreakPointDown = '767.02px';

export const Title = styled.h2`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;

    @media (min-width: ${MdBreakPoint}) {
        font-size: 26px;
        line-height: 30px;
    }
`;

export const Col = styled.div`
    width: 100%;

    overflow: hidden;
    @media (min-width: ${MdBreakPoint}) {
        width: 50%;
        &.content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }

    &.image-side img {
        width: calc(100% + 40px);
        max-width: unset;
        margin-left: -20px;
        margin-right: -20px;
        @media (min-width: ${MdBreakPoint}) {
            width: 100%;
            max-width: 100%;
            margin-left: 0px;
            margin-right: 0px;
        }
        transition: all ease 1.5s;
        &:hover {
            transform: scale(1.1);
        }
    }
`;

export const Wrapper = styled.div`
    margin-top: 24px;
    & ${Col} {
        // order: unset !important;
        text-align: center;
    }

    @media (min-width: ${MdBreakPoint}) {
        margin-top: 0px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        &:nth-child(even) {
            ${Col} {
                &:nth-child(2) {
                    order: 1;
                }
                &:nth-child(1) {
                    order: 2;
                }
            }
        }
    }
`;

export const MainImage = styled.img`
    width: calc(100% + 40px);
    max-width: unset;
    margin-left: -20px;
    margin-right: -20px;
    @media (min-width: ${MdBreakPoint}) {
        width: 100%;
        max-width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }
    transition: all ease 1.5s;
    &:hover {
        transform: scale(1.1);
    }
`;

export const ReviewsWrapper = styled.div`
    margin-top: 24px;
    @media (min-width: ${MdBreakPoint}) {
        margin-top: 40px;
    }
`;

export const ContentTitle = styled.h4`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #000000;
    margin-top: 16px;
    margin-bottom: 16px;
    @media (min-width: ${MdBreakPoint}) {
        font-size: 22px;
        line-height: 26px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
`;
export const Content = styled.p`
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;

    span {
        margin-top: 30px;
        display: block;
        font-style: normal;
        letter-spacing: 0.1em;
    }

    @media (min-width: ${MdBreakPoint}) {
        width: 333px;
        font-size: 18px;
        line-height: 30px;
        margin-top: 0;
        margin-bottom: 0;
    }
`;

export const StarsImg = styled.img`
    margin-top: 24px;
    width: 114.12px;
    @media (min-width: ${MdBreakPoint}) {
        width: 166px;
        margin-top: 0;
    }
`;
