import styled from 'styled-components';
import Spacing from './Spacing';

export const GirlImage = styled.img`
    position: absolute;
    right: 0;
    width: 634px;
    height: 727px;
    top: 91px;
    right: -50px;
    object-fit: cover;

    @media screen and (max-width: 1000px) {
        right: -200px;
    }
    @media screen and (max-width: 850px) {
        display: none;
    }
`;

export const LoyaltyWrapper = styled(Spacing)`
    height: 818px;
    padding: 0px;
    overflow: hidden;
    @media screen and (max-width: 850px) {
        height: auto;
        padding-bottom: 40px;
        span {
            font-size: 16px;
        }
    }
`;

export const Logo = styled.img`
    display: block;
    position: relative;
    margin: 0 auto;
    width: 282px;
    height: 88px;
    left: 0;
    right: 0;
    top: 48px;
`;

export const LoyaltyContent = styled.div`
    max-width: 578px;
    color: white;
    margin-top: 149px;
    @media screen and (max-width: 850px) {
        margin-top: 80px;
    }
`;

export const IntroducingtheKenr = styled.span`
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: 1.78px;
    color: white;
    text-transform: uppercase;
`;

export const AtKenraProfessiona = styled.span`
    display: block;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.8px;
    color: white;
    max-width: 467px;
    margin-top: 10px;
`;

export const DiamondWrapper = styled.div`
    max-width: 470px;
`;

export const TheDiamondRewards = styled.span`
    display: block;
    margin-top: 25px;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.3px;
    color: white;
    text-transform: uppercase;
`;

export const ProvidesOurMostLo = styled.span`
    margin-top: 10px;
    display: block;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.8px;
    color: white;
`;

export const DiamondSection = styled.div`
    display: flex;
    margin-top: 20px;
`;

export const Icon = styled.div`
    width: ${(props) => (props.size == 'big' ? '100px' : '80px')};
    height: ${(props) => (props.size == 'big' ? '100px' : '80px')};
    background-image: url(${(props) =>
        props.color == 'white'
            ? 'https://images.ctfassets.net/6ntd78431879/2EAr57wUV3WchwtTuIaNTQ/e6a797209838e4732e23f51cb360ef74/white_diamond.png'
            : 'https://images.ctfassets.net/6ntd78431879/61YDHtd9uSBpKwLv2CPQ4x/50ff49e524f9ed6751e24eae45c9d01e/dark_diamond.png'});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 0px;
`;

export const DiamondContent = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Roboto', 'Arial', sans-serif;
    padding-left: 15px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.8px;
    color: var(--white);

    span {
        font-weight: bold;
    }
`;

export const HowItWorks = styled(Spacing)`
    max-width: 1440px;
    margin: 0 auto;
    left: 0;
    right: 0;
`;

export const HowColWrapper = styled.div`
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 70px;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: 20px;
    }
`;

export const HowCol = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    width: 33.3%;
    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
    }
    h2 {
        font-family: 'Roboto', 'Arial', sans-serif;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.27;
        letter-spacing: 1.57px;
        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }
`;

export const HowContent = styled.p`
    white-space: pre-wrap;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.8px;
    color: black;
    .text-style-1 {
        font-weight: bold;
    }
    ${DiamondContent} {
        max-width: 160px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 0.8px;
        span {
            font-weight: bold;
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;

export const HowTableWrapper = styled.div`
    display: flex;
    margin-top: 120px;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: 30px;
    }
    h2 {
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.27;
        letter-spacing: 1.57px;
        text-align: center;
        color: #4a4a4a;
        text-transform: uppercase;
    }
    table {
        width: 100%;
    }

    th {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0.8px;
        color: black;
        text-align: left;
        padding-bottom: 15px;
    }
    tr:nth-child(1) {
        border-bottom: solid 1px #979797;
    }
    tr {
        border-bottom: solid 1px #d0d0d0;
    }
    tr:nth-child(2) td {
        padding-top: 20px;
    }
    td {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.8px;
        color: black;
        padding-top: 15px;
        padding-bottom: 5px;
    }
`;
export const HowTable1 = styled.div`
    width: 60%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
export const HowTable2 = styled.div`
    width: 40%;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 20px;
    }
`;
export const TableWrapper = styled.div`
    background-color: rgba(216, 216, 216, 0.2);
    padding: 60px;
    border-radius: 16px;
    margin-left: 12px;
    margin-right: 12px;
    @media screen and (max-width: 768px) {
        padding: 30px;
    }
`;
export const RewardsAtGlanceWrapper = styled.div`
    background-color: rgba(236, 229, 224, 0.3);
    padding-bottom: 115px;
    @media screen and (max-width: 768px) {
        padding-bottom: 20px;
    }
`;

export const RewardsRow = styled.div`
    display: flex;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        font-size: 16px;
    }
`;

export const ImageCol = styled.div`
    width: 50%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
export const ContentCol = styled.div`
    width: 50%;
    padding-left: 51px;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
    }
    h2 {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.5;
        letter-spacing: 1.43px;
        color: #4a4a4a;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }
    li {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0.8px;
        color: var(--black);
    }
    ul {
        list-style-type: none;
        padding-left: 0;
        margin-top: 0px;
    }
    ${DiamondSection} {
        margin-top: 0;
    }
`;

export const FormSubtitle = styled.p`
    position: relative;
    max-width: 641px;
    display: block;
    margin: 0 auto !important;
`;

export const LoyaltyTable1 = styled.table`
    tbody tr:nth-child(1) td:first-child {
        color: black;
    }
    tbody tr:nth-child(2) td:first-child {
        color: rgb(169, 144, 92);
    }
    tbody tr:nth-child(3) td:first-child {
        color: rgb(123, 123, 123);
    }
    tbody tr:nth-child(4) td:first-child {
        color: rgb(186, 155, 132);
    }
`;
