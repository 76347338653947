import React from 'react';
import styled, { css } from 'styled-components';

import StContainer from './Container';

import settings from '../global/settings';

const StHero = styled.div`
    min-height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 380px;
    }

    .container {
        @media (min-width: ${settings.bp.small.view}) {
            padding: 5% 0;
        }
    }

    h1 {
        color: #fff;
        font-size: 28px;
        font-weight: 500;
        margin: 0;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        opacity: 0;
        position: absolute;

        @media (min-width: ${settings.bp.small.view}) {
            opacity: 1;
            position: static;
        }
        ${props =>
            props.invert &&
            css`
                color: #000;
                background-color: #d3d3d380;
                max-width: 40%;
                margin: auto;
                padding: 12px;
            `};
    }
`;

export default function HeroSection(props) {
    const { title, bgImg, invert, children } = props;
    return (
        <StHero invert={invert} style={{ backgroundImage: `url(${bgImg})` }}>
            <StContainer>
                <h1>{title}</h1>
                {children}
            </StContainer>
        </StHero>
    );
}
