module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://kenraprofessional.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kenra Professional","short_name":"kenrapro","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c02bd5fb75c92ddcff25f9a157aed0ce"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WQM6XMVV","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NM6QB9EVTS"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../plugins/kenra-search-plugin/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"type":["ShopifyProduct","ContentfulBlogPost"],"fields":[{"name":"handle","indexed":true,"resolver":"handle","attributes":{"encode":"icase","tokenize":"forward","threshold":1,"resolution":9,"depth":4},"store":true},{"name":"title","indexed":true,"resolver":"title","attributes":{"encode":"icase","tokenize":"forward","threshold":1,"resolution":9,"depth":4},"store":true},{"name":"tags","indexed":true,"resolver":"tags","attributes":{"encode":"icase","tokenize":"forward","threshold":1,"resolution":9,"depth":4},"store":true},{"name":"images","indexed":false,"resolver":"images","store":true},{"name":"url","indexed":true,"resolver":"url","attributes":{"encode":"icase","tokenize":"forward","threshold":1,"resolution":9,"depth":4},"store":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","fr"],"defaultLanguage":"en","redirect":false,"redirectDefaultLanguageToRoot":true,"ignoredPaths":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
