import React, { useState } from 'react';

import styled, { css } from 'styled-components';

const StQuantity = styled.div.attrs((props) => ({
    className: 'StQuantity',
}))`
    text-align: center;
    margin-right: 20px;
    display: flex;
    height: 48px;
    ${(props) =>
        props.reduceSize &&
        css`
            height: 30px;
        `};
    ${(props) =>
        props.center &&
        css`
            justify-content: center;
            margin: 0px;
        `};
    `;

const StQuantityButton = styled.div.attrs((props) => ({
    className: 'StQuantityButton',
}))`
    min-width: 47px !important;
    width: 47px !important;
    text-align: center !important;
    font-size: 16px;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    padding: 13px 7px;

    &:hover {
        background-color: #000;
        color: #fff;
    }

    ${(props) =>
        props.reduceSize &&
        css`
            min-width: 29px !important;
            width: 29px !important;
            border: 0px;
            font-size: 14px;
            padding: 5px 7px;
        `};
`;

const StQuantityInput = styled.div.attrs((props) => ({
    className: 'StQuantityInput',
}))`
    min-width: 47px !important;
    width: 47px !important;
    text-align: center !important;
    font-size: 16px;
    background-color: #fff;
    padding: 13px 7px;
    border: 1px solid #000;

    input {
        font-size: 16px;
        text-align: center;
        width: 30px;
        border-width: 0px;
    }
    ${(props) =>
        props.reduceSize &&
        css`
            min-width: 29px !important;
            width: 29px !important;
            font-size: 14px;
            padding: 5px 1px;
            input {
                font-size: 14px;
                width: 20px;
            }
        `};
`;

export default function QuantityButtons(props) {
    const {
        handleQuantityMinus,
        handleQuantityPlus,
        handleChange,
        quantity,
        reduceSize,
        center
    } = props;
    const [currentQuantity, setCurrentQuantity] = useState(quantity);

    const updateQuantity = (qty) => {
        setCurrentQuantity(qty);
    };

    return (
        <StQuantity reduceSize={reduceSize} center={center}>
            <StQuantityButton
                onClick={() => handleQuantityMinus(updateQuantity)}
                reduceSize={reduceSize}
            >
                -
            </StQuantityButton>
            <StQuantityInput reduceSize={reduceSize}>
                <input
                    onChange={(event) => handleChange(event, updateQuantity)}
                    value={currentQuantity}
                />
            </StQuantityInput>
            <StQuantityButton
                onClick={() => handleQuantityPlus(updateQuantity)}
                reduceSize={reduceSize}
            >
                +
            </StQuantityButton>
        </StQuantity>
    );
}
