import React from 'react';
import styled, { css } from 'styled-components';

import ZoomImg from './ZoomImg';

const StProductPicker = styled.div``;

const StProductPickerMain = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100%;
    cursor: pointer;
    position: relative;

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
`;

const StProductPickerList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
`;

const StProductPickerListImg = styled.div`
    width: 50px;
    height: 50px;
    margin: 0 15px 15px 0;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.9;
    }

    ${(props) =>
        props.checked &&
        css`
            opacity: 0.5 !important;
        `};
`;

function ProductPickerItem(props) {
    const { id, thmb, checked, onClickThmb } = props;
    return (
        <StProductPickerListImg
            checked={checked}
            onClick={() => onClickThmb(id)}
            style={{ backgroundImage: `url(${thmb})` }}
        />
    );
}

function ProductPickerMain(props) {
    const { items, onClickMainImg } = props;
    const current = items.find((currentValue) => currentValue.checked) || {};
    const { img, bgColor } = current;
    return (
        <StProductPickerMain
            onClick={onClickMainImg}
            style={{ backgroundColor: bgColor }}
        >
            <img src={img} alt="" />
        </StProductPickerMain>
    );
}

export default function ProductPicker(props) {
    const { items, onClickThmb, onClickMainImg } = props;
    return (
        <StProductPicker>
            <ProductPickerMain items={items} onClickMainImg={onClickMainImg} />
            <StProductPickerList>
                {items.map((item, index) => (
                    <ProductPickerItem
                        onClickThmb={onClickThmb}
                        key={item.id || index}
                        {...item}
                    />
                ))}
            </StProductPickerList>
        </StProductPicker>
    );
}
