import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
import Slider from './Slider/Slider';

const StImgTextTile = styled.div`
    display: flex;
    ${(props) =>
        props.rowRevers &&
        css`
            .StImgTextTileRow {
                &:nth-child(even) {
                    flex-direction: column;
                }
                &:nth-child(odd) {
                    flex-direction: column-reverse;
                }
            }
        `};
`;

const StImgTextTileRow = styled.div.attrs((props) => ({
    className: 'StImgTextTileRow',
}))`
    max-width: 47%;
    display: flex;
    margin: 0 1.5%;
    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 40px;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 70px;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }

    &:nth-child(even) {
        @media (min-width: ${settings.bp.small.view}) {
            flex-direction: column-reverse;

            .StImgTextTileImg {
                padding-left: 0;
            }
        }
    }

    &:nth-child(odd) {
        @media (min-width: ${settings.bp.small.view}) {
            .StImgTextTileImg {
                padding-right: 0;
            }
        }
    }

    h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;
        color: #003367;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 28px;
            color: ${settings.color.gray};
            line-height: 1.79;
            margin: 0 0 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 20px;
            letter-spacing: 0.08em;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

const StImgTextTileImg = styled.div.attrs((props) => ({
    className: 'StImgTextTileImg',
}))`
  @media (min-width: ${settings.bp.small.view}) {
    width: calc(75% - 30px);
  }

  @media (min-width: ${settings.bp.medium.view}) {
    width: calc(75% - 60px);
  }
  
  @media (max-width: calc(${settings.bp.small.view} - 1px)) {
    height: unset;
  }

  .StSlider {
    .swiper-container {
      .swiper-wrapper {
        img {
          height: min-intrinsic !important;
        }
      }
    }
  }
}

`;

const StImage = styled.img.attrs((props) => ({
    className: 'StImage',
}))`
  width 100%;
`;

const StImgTextTileText = styled.div.attrs((props) => ({
    className: 'StImgTextTileText',
}))`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 21%;

    *:last-child {
        margin-bottom: 0;
    }
`;

function ImageTextTileItem(props) {
    const { images, title, text } = props;
    return (
        <StImgTextTileRow>
            {images && (
                <StImgTextTileImg>
                    <Slider>
                        {images.map((image, index) => (
                            <StImage key={index} src={image} alt="" />
                        ))}
                    </Slider>
                </StImgTextTileImg>
            )}
            <StImgTextTileText>
                {title && <h2>{title}</h2>}
                {text}
            </StImgTextTileText>
        </StImgTextTileRow>
    );
}

export default function ImageTextTile(props) {
    const { bgImg, items, ...restProps } = props;
    return (
        <StImgTextTile {...restProps}>
            {items.map((item, index) => (
                <ImageTextTileItem key={item.id || index} {...item} />
            ))}
        </StImgTextTile>
    );
}
