import React from 'react';
import * as SharedStyles from '../global/SharedStyles';
import styled from 'styled-components';

const { StLink } = SharedStyles;

const StImageLink = styled.a`
    display: flex;
`;

const StImage = styled.img`
    float: left;
    width: 9px !important;
    height: 13px !important;
    align-self: center;
    margin-right: 10px;
    margin-bottom: 0px !important;
`;

export default function ImageLink(props) {
    const { image, text, link } = props;
    return (
        <StImageLink target="_blank" rel="noopener noreferrer" href={link}>
            {image && <StImage alt="" src={image} />}
            {text}
        </StImageLink>
    );
}
