import React from 'react';

import styled, { css } from 'styled-components';
import ImageWithDescHiddenOnMobile from './ImageWithDescHiddenOnMobile';

import settings from '../global/settings';

const StQuizWrap = styled.div``;

const StQuiz = styled.div`
    text-align: center;
    padding: 50px 0;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: calc(100vh - 340px);
        width: calc(100% - 50px) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: calc(100% - 60px) !important;
    }

    h1 {
        font-size: 28px;
        font-weight: 500;
        margin: 0 auto 40px;
        line-height: 1.61;
        letter-spacing: 0.1em;
        opacity: 0.8;
        color: #4a4a4a;
        max-width: 680px;
    }
`;

export default function QuizList(props) {
    const { items } = props;
    return (
        <StQuizWrap>
            <StQuiz>
                {items.map((item, index) => (
                    <QuizItem key={item.id || index} {...item} />
                ))}
            </StQuiz>
        </StQuizWrap>
    );
}

function QuizItem(props) {
    const { stylePage, removeMargin, title, text, btnText, onClick, href } =
        props;
    return (
        <ImageWithDescHiddenOnMobile
            stylePage={stylePage}
            removeMargin={removeMargin}
            title={title}
            text={text}
            btnText={btnText}
            onClick={onClick}
            href={href}
        />
    );
}
