import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import styled, { css } from 'styled-components';

import settings from '../../global/settings';

const StSlider = styled.div.attrs(props => ({
    className: 'StSlider',
}))`
    ${props =>
        props.hideArrows &&
        css`
            .swiper-button-next,
            .swiper-button-prev {
                display: none !important;
            }
        `};

    ${props =>
        props.hideProgress &&
        css`
            .swiper-pagination-progressbar {
                display: none !important;
            }
        `};

    ${props =>
        props.hideDesktopProgress &&
        css`
            @media (min-width: ${settings.bp.small.view}) {
                .swiper-pagination-progressbar {
                    display: none !important;
                }
            }
        `};

    ${props =>
        props.removeProgressTopSpacing &&
        css`
            .swiper-pagination-progressbar {
                margin-top: 0 !important;
            }
        `};

    .swiper-container {
        padding-bottom: 40px;
        .swiper-wrapper {
            @media (min-width: ${settings.bp.small.view}) {
                justify-content: center;
            }
        }
        .swiper-slide {
            height: auto;
        }
        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 35px;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;
            z-index: 2;
            margin-top: 0;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: calc(50% - 30px);
                left: 0;

                ${props =>
                    props.hideProgress &&
                    css`
                        top: 50%;
                    `};
            }

            &:before {
                transform: translate(0, -50%) rotate(45deg);
                width: 8px;
                height: 8px;
                border-left: 2px solid #000;
                border-bottom: 2px solid #000;
                z-index: 2;
                left: 14px;
            }

            &:after {
                transform: translate(0, -50%);
                width: 35px;
                height: 35px;
                border: 1px solid #000;
                background-image: none;
                background-color: #fff;
                z-index: 1;
            }

            &.swiper-button-disabled {
                opacity: 0 !important;
                visibility: hidden !important;
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-pagination-bullet-active {
            background: black;
        }

        .swiper-button-next {
            right: 0;

            &:after {
                right: 0;
                left: auto;
            }

            &:before {
                transform: translate(0, -50%) rotate(-135deg);
                right: 14px;
                left: auto;
            }
        }

        &.swiper-container-horizontal > .swiper-pagination-progressbar {
            position: relative;
            height: 2px;
            background-color: rgba(0, 0, 0, 0.4);
            overflow: hidden;
            max-width: 636px;
            width: calc(100% - 40px);
            margin: 60px auto 0;

            .swiper-pagination-progressbar-fill {
                background-color: #000;
            }
        }

        &:hover {
            .swiper-button-next,
            .swiper-button-prev {
                opacity: 1;
                visibility: visible;
            }
        }
    }
`;

let config = {
    speed: 600,
    slidesPerView: 'auto',
    pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
    },
    watchOverflow: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};

export default function CenterSlider(props) {
    const [isSwiperEnabled, setSwiper] = useState(true);

    let {
        removeProgressTopSpacing,
        spacingSmall,
        hideProgress,
        hideDesktopProgress,
        hideArrows,
        onlyMobile,
        settings,
    } = props;
    config = { ...config, ...settings };
    let timer;

    useEffect(() => {
        if (!onlyMobile) return;
        checkWidth();
        window.addEventListener('resize', handlerResize);
        return () => {
            window.removeEventListener('resize', handlerResize);
        };
    }, [handlerResize, onlyMobile]);

    function handlerResize() {
        clearTimeout(timer);
        timer = setTimeout(() => {
            checkWidth();
        }, 100);
    }

    function checkWidth() {
        if (window.innerWidth < 768) {
            setSwiper(true);
        } else {
            setSwiper(false);
        }
    }

    return (
        <StSlider
            hideArrows={hideArrows}
            hideProgress={hideProgress}
            hideDesktopProgress={hideDesktopProgress}
            removeProgressTopSpacing={removeProgressTopSpacing}
            spacingSmall={spacingSmall}
        >
            {isSwiperEnabled && props.children.length > 1 ? (
                <Swiper {...config}>{props.children}</Swiper>
            ) : (
                props.children
            )}
        </StSlider>
    );
}
