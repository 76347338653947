import React from 'react';
import styled, { css } from 'styled-components';
import settings from '../../global/settings';
const MdBreakPoint = '768px';

const ViewAllBtn = styled.a`
    width: 140px;
    height: 50px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    border: 0;
    text-transform: uppercase;
    color: white;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    font-style: normal;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: 24px;

    &:hover {
        cursor: pointer;
    }
    @media (min-width: ${MdBreakPoint}) {
        // margin-top: 48px;
    }

    ${(props) =>
        props.mobileHide &&
        css`
            @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                display: none !important;
            }
        `};

    ${(props) =>
        props.invertFontColor &&
        css`
        background: #fff;
        color: #000;
        border-radius: 100px;
        margin-top: 0px;
        `};
`;

const AllButton = ({ children, href, invertFontColor }) => {
    return <ViewAllBtn invertFontColor={invertFontColor} href={href}>{children}</ViewAllBtn>;
};

export default AllButton;
