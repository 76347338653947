import React from 'react';

import styled, { css } from 'styled-components';
import * as SharedStyles from '../global/SharedStyles';

import settings from '../global/settings';
const { StLinkMore } = SharedStyles;

const StLinkImage = styled.div`
    margin: 0 auto;

    @media (min-width: ${settings.bp.large.view}) {
        max-width: 1050px;
    }
`;

const StLinkImageItem = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        padding: 60px 0 0;

        &:last-child {
            padding-bottom: 60px;
        }
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 30px 0 0;

        &:last-child {
            padding-bottom: 30px;
        }
    }

    h3 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.07em;
        margin: 0 0 10px;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 18px;
        }
    }

    p {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.56;
        margin: 0 0 34px;
        letter-spacing: normal;
    }

    .StLinkMore a {
        min-width: 180px;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const StLinkImageItemDesc = styled.div`
    padding: 20px 0 54px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 50%;
        padding: 0 5% 0 0;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 45%;
    }
`;

const StLinkImageItemImg = styled.div`
    width: 100vw;
    transform: translate(-50%, 0);
    position: relative;
    left: 50%;

    @media (min-width: ${settings.bp.small.view}) {
        width: 50%;
        position: static;
        transform: translate(0, 0);
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 55%;
    }

    img {
        width: 100%;
    }
`;

function LinkImageItem(props) {
    const { title, text, btnText, btnHref, img } = props;
    return (
        <StLinkImageItem>
            <StLinkImageItemImg>
                <img src={img} alt="" />
            </StLinkImageItemImg>
            <StLinkImageItemDesc>
                {title && <h3>{title}</h3>}
                {text && typeof text === 'string' && <p>{text}</p>}
                {text && typeof text === 'object' && text}
                {btnText && btnHref && (
                    <StLinkMore inversionColor>
                        <a href={btnHref}>{btnText}</a>
                    </StLinkMore>
                )}
            </StLinkImageItemDesc>
        </StLinkImageItem>
    );
}

export default function LinkImage(props) {
    const { items } = props;
    return (
        <StLinkImage>
            {items.map((item, index) => (
                <LinkImageItem key={item.id || index} {...item} />
            ))}
        </StLinkImage>
    );
}
