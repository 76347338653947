import React, { useState } from 'react';
import { navigate } from 'gatsby-plugin-react-intl';

const defaultState = {
    isProfessional: 0,
    showProfessionalModal: false,
    showLoginWizard: false,
    isLoggedIn: false,
    wizardProgress: 0,
    shoppingCartQuantity: 0,
    showLoading: false,
    showUnavailable: false,
    userAccessToken: '',
    loadingMessageId: '',
    userAccessToken: null,
    outOfStock: false,
    showError: false,
    showInfo: false,
    startCheckout: false,
    setProfessional: () => {},
    toggleShowProfessional: () => {},
    toggleShowLoginWizard: () => {},
    toggleLoggedIn: () => {},
    toggleShowLoading: () => {},
    toggleShowUnavailable: () => {},
    toggleShowError: () => {},
    toggleShowInfo: () => {},
    toggleCheckout: () => {},
    stopLoading: () => {},
};

function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
}

async function sendRequest(endpoint, body) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body: null,
        };

        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }

        const response = await fetch(
            `/.netlify/functions/${endpoint}`,
            requestOptions
        );

        if (response.ok) {
            const responseBody = await response.json();
            return responseBody;
        } else {
            const responseBody = await response.json();
            return responseBody;
        }
    } catch (error) {
        return {
            statusCode: 500,
            result: false,
        };
    }
}

const AuthContext = React.createContext(defaultState);
class AuthProvider extends React.Component {
    /*    constructor(props) {
        super(props);
        this.state = {
            isProfessional: 0,
            showProfessionalModal: false,
            showLoginWizard: true,
            isLoggedIn: false,
            serverError: '',
            wizardProgress: 0,
            shoppingCartQuantity: 0,
            showLoading: false,
            showUnavailable: false,
            performCheckout: false,
            loadingMessageId: '',
            userAccessToken: null,
            outOfStock: false,
            showError: false,
            showInfo: false,
            startCheckout: false,
        };
    }*/
    state = {
        isProfessional: 0,
        showProfessionalModal: false,
        showLoginWizard: false,
        isLoggedIn: false,
        serverError: '',
        wizardProgress: 0,
        shoppingCartQuantity: 0,
        showLoading: false,
        showUnavailable: false,
        performCheckout: false,
        loadingMessageId: '',
        userAccessToken: null,
        outOfStock: false,
        showError: false,
        showInfo: false,
        startCheckout: false,
    };

    setProfessional = isProfessional => {
        let isProfessionalSet = isProfessional;
        localStorage.setItem(
            'isProfessional',
            JSON.stringify(isProfessionalSet)
        );

        let showLoginWizard = this.state.showLoginWizard;
        if (isProfessional > 1) {
            // Need to stop the login wizard showing at this time
            showLoginWizard = false;
            localStorage.setItem(
                'showLoginWizard',
                JSON.stringify(showLoginWizard)
            );
        } else {
            // Try to refresh the page
            window.location = window.location.href;
        }
        this.setState({
            showLoginWizard: showLoginWizard,
            isProfessional: isProfessional,
        });
    };
    toggleShowProfessional = () => {
        let showProfessionalModal = !this.state.showProfessionalModal;
        localStorage.setItem(
            'showProfessionalModal',
            JSON.stringify(showProfessionalModal)
        );
        this.setState({ showProfessionalModal: showProfessionalModal });
    };

    toggleShowLoginWizard = () => {
        let showLoginWizard = !this.state.showLoginWizard;
        let progress = this.state.wizardProgress;
        localStorage.setItem(
            'showLoginWizard',
            JSON.stringify(showLoginWizard)
        );

        let isLoggedIn = this.state.isLoggedIn;
        if (showLoginWizard) {
            // also reset ther isLoggedIn flag
            isLoggedIn = false;
            localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
            progress = 1;
            localStorage.setItem('wizardProgressTag', JSON.stringify(progress));
        }
        this.setState({
            showLoginWizard: showLoginWizard,
            isLoggedIn: isLoggedIn,
            wizardProgress: progress,
        });
    };
    toggleLoggedIn = () => {
        let isLoggedIn = !this.state.isLoggedIn;
        let progress = this.state.wizardProgress;
        let shoppingCartQuantity = this.state.shoppingCartQuantity;
        localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
        if (!isLoggedIn) {
            // also reset ther wizardProgress flag
            progress = 0;
            localStorage.setItem('wizardProgressTag', JSON.stringify(progress));
            shoppingCartQuantity = 0;
            localStorage.setItem(
                'shoppingCartQuantity',
                JSON.stringify(shoppingCartQuantity)
            );
        }
        this.setState({
            isLoggedIn: isLoggedIn,
            wizardProgress: progress,
            shoppingCartQuantity: shoppingCartQuantity,
        });
    };

    updateWizardProgress = progress => {
        localStorage.setItem('wizardProgressTag', JSON.stringify(progress));
        if (progress > 10) {
            // Don't show the wizard
            localStorage.setItem('showLoginWizard', JSON.stringify(false));
            this.setState({ wizardProgress: progress, showLoginWizard: false });
        } else {
            this.setState({ wizardProgress: progress });
        }
    };

    handleError = response => {
        this.setState({ serverError: response });
    };

    userLogin = async bodyData => {
        this.toggleShowLoading('loading-message-sign-in');

        let body = await sendRequest('clientToken', undefined);
        if (body.result) {
            let signIn = await sendRequest('userSignIn', bodyData);
            if (signIn.result) {
                // Get the current cart for the user
                let userCartBody = await sendRequest('getCart', {
                    isAnonymous: false,
                });
                // Does it exist?
                if (!userCartBody.result) {
                    // Need to create the cart
                    let createUserCartBody = await sendRequest('createCart', {
                        isAnonymous: false,
                    });
                    if (!createUserCartBody.result) {
                        this.updateWizardProgress(4);
                        this.stopLoading('loading-message-default');
                        return;
                    }
                }

                // Get the current cart for the user
                let anonCartBody = await sendRequest('getCart', {
                    isAnonymous: true,
                });
                // Does it exist?
                if (!anonCartBody.result) {
                    // Need to create the cart
                    let createAnonCartBody = await sendRequest('createCart', {
                        isAnonymous: true,
                    });
                    if (!createAnonCartBody.result) {
                        this.updateWizardProgress(4);
                        this.stopLoading('loading-message-default');
                        return;
                    }
                } else {
                    this.updateShoppingCartQuantity(
                        anonCartBody.data.products.length
                    );
                }

                this.updateWizardProgress(3);
                this.toggleLoggedIn();
                this.setState({ userAccessToken: signIn.token });
            } else {
                this.updateWizardProgress(4);
            }
        } else {
            this.updateWizardProgress(4);
        }

        this.stopLoading('loading-message-default');
    };

    createUser = async bodyData => {
        this.toggleShowLoading('loading-message-create-user');

        let body = await sendRequest('clientToken', undefined);
        if (body.result) {
            let createUser = await sendRequest('createUser', bodyData);
            if (createUser.result) {
                let userSignIn = await sendRequest('userSignIn', bodyData);
                if (userSignIn.result) {
                    // Get the current cart for the user
                    let userCartBody = await sendRequest('getCart', {
                        isAnonymous: false,
                    });
                    // Does it exist?
                    if (!userCartBody.result) {
                        // Need to create the cart
                        let createUserCartBody = await sendRequest(
                            'createCart',
                            { isAnonymous: false }
                        );
                        if (!createUserCartBody.result) {
                            this.updateWizardProgress(4);
                            this.stopLoading('loading-message-default');
                            return;
                        }
                    }

                    // Get the current cart for the user
                    let anonCartBody = await sendRequest('getCart', {
                        isAnonymous: true,
                    });
                    // Does it exist?
                    if (!anonCartBody.result) {
                        // Need to create the cart
                        let createAnonCartBody = await sendRequest(
                            'createCart',
                            { isAnonymous: true }
                        );
                        if (!createAnonCartBody.result) {
                            this.updateWizardProgress(4);
                            this.stopLoading('loading-message-default');
                            return;
                        }
                    } else {
                        this.updateShoppingCartQuantity(
                            anonCartBody.data.products.length
                        );
                    }

                    this.updateWizardProgress(3);
                    this.toggleLoggedIn();
                    this.setState({ userAccessToken: userSignIn.token });
                } else {
                    // NEED TO HANDLE REFRESH
                    this.updateWizardProgress(4);
                }
            } else {
                this.updateWizardProgress(4);
            }
        } else {
            this.updateWizardProgress(4);
        }

        this.stopLoading('loading-message-default');
    };

    addToCart = async bodyData => {
        this.toggleShowLoading('loading-message-add-cart');

        let errorFound = false;
        let body = await sendRequest('createCart', { isAnonymous: true });
        if (!body.result) {
            if (body.statusCode === 401) {
                // need to refresh
                let refreshBody = await sendRequest('refreshToken', null);
                if (refreshBody.result) {
                    body = await sendRequest('createCart', {
                        isAnonymous: true,
                    });
                    if (!body.result) {
                        errorFound = true;
                        if (body.error !== null) {
                            if (body.error === 'NO TOKENS') {
                                this.toggleShowLoginWizard();
                            } else {
                                this.toggleShowError();
                            }
                        }
                    }
                }
            } else if (body.error !== null) {
                errorFound = true;
                if (body.error === 'NO TOKENS') {
                    this.toggleShowLoginWizard();
                }
            }
        }

        if (!errorFound) {
            let addCartBody = await sendRequest('addToCart', bodyData);
            if (!addCartBody.result) {
                if (addCartBody.statusCode === 401) {
                    // need to refresh
                    let refreshBody = await sendRequest('refreshToken', null);
                    if (refreshBody.result) {
                        addCartBody = await sendRequest('addToCart', bodyData);
                        if (!addCartBody.result) {
                            if (addCartBody.error !== null) {
                                if (addCartBody.error === 'NO TOKENS') {
                                    this.toggleShowLoginWizard();
                                } else {
                                    this.toggleShowError();
                                }
                            }
                        }
                    }
                } else if (addCartBody.error !== null) {
                    errorFound = true;
                    if (addCartBody.error === 'NO TOKENS') {
                        this.toggleShowLoginWizard();
                    } else {
                        this.toggleShowError();
                    }
                }
            } else {
                // check the data
                let data = addCartBody.data;
                if (data) {
                    if (
                        data.quantityAdded === 0 &&
                        data.statusCode === 'unavailable'
                    ) {
                        // Show unavailable
                        this.toggleShowUnavailable(true);
                    } else {
                        this.updateShoppingCartQuantity(
                            this.state.shoppingCartQuantity + 1
                        );
                    }
                }
            }
        }
        this.stopLoading('loading-message-default');
    };

    getCart = async () => {
        this.toggleShowLoading('loading-message-get-cart');

        let body = await sendRequest('getCart', { isAnonymous: true });
        if (!body.result) {
            if (body.statusCode === 401) {
                // need to refresh
                let refreshBody = await sendRequest('refreshToken', null);
                if (refreshBody.result) {
                    body = await sendRequest('getCart', { isAnonymous: true });
                    if (!body.result) {
                        if (body.error !== null) {
                            if (body.error === 'NO TOKENS') {
                                this.toggleShowLoginWizard();
                            } else {
                                this.toggleShowError();
                            }
                        }
                    }
                }
            } else if (body.error !== null) {
                if (body.error === 'NO TOKENS') {
                    this.toggleShowLoginWizard();
                } else {
                    this.toggleShowError();
                }
            }
        } else {
            this.updateShoppingCartQuantity(body.data.products.length);
        }

        this.stopLoading('loading-message-default');
        return body.data;
    };

    logout = async () => {
        // Loop through this 3 times - changing the number each time so we clear all the different
        // cookies. We can only clear one at a time
        for (var i = 0; i < 3; i++) {
            let body = await sendRequest('logout', { item: i });
        }

        if (this.state.isLoggedIn) {
            this.toggleLoggedIn();
        }
        navigate('/');
    };

    updateShoppingCartQuantity = quantity => {
        localStorage.setItem('shoppingCartQuantity', JSON.stringify(quantity));
        this.setState({ shoppingCartQuantity: quantity });
    };

    deleteFromCart = async bodyData => {
        this.toggleShowLoading('loading-message-delete-from-cart');
        let body = await sendRequest('deleteFromCart', bodyData);
        if (!body.result) {
            if (body.statusCode === 401) {
                // need to refresh
                let refreshBody = await sendRequest('refreshToken', null);
                if (refreshBody.result) {
                    body = await sendRequest('deleteFromCart', bodyData);
                    if (!body.result) {
                        if (body.error !== null) {
                            if (body.error === 'NO TOKENS') {
                                this.toggleShowLoginWizard();
                            } else {
                                this.toggleShowError();
                            }
                        }
                    }
                }
            } else if (body.error !== null) {
                if (body.error === 'NO TOKENS') {
                    this.toggleShowLoginWizard();
                } else {
                    this.toggleShowError();
                }
            }

            this.stopLoading('loading-message-default');
            return null;
        } else {
            let data = await this.getCart();
            this.stopLoading('loading-message-default');
            return data;
        }
    };

    updateCart = async bodyData => {
        this.toggleShowLoading('loading-message-update-cart');
        let errorFound = false;
        let body = await sendRequest('updateCart', bodyData);
        if (!body.result) {
            if (body.statusCode === 401) {
                // need to refresh
                let refreshBody = await sendRequest('refreshToken', null);
                if (refreshBody.result) {
                    body = await sendRequest('updateCart', bodyData);
                    if (!body.result) {
                        if (body.error !== null) {
                            if (body.error === 'NO TOKENS') {
                                this.toggleShowLoginWizard();
                            } else {
                                this.toggleShowError();
                            }
                        }
                    }
                } else {
                    // Refresh has failed - log user out to start again
                    this.toggleShowLoginWizard();
                }
            } else if (body.error !== null) {
                if (body.error === 'NO TOKENS') {
                    this.toggleShowLoginWizard();
                } else {
                    this.toggleShowError();
                }
            }
            this.stopLoading('loading-message-default');
            return false;
        } else {
            this.stopLoading('loading-message-default');
            return true;
        }
    };

    subscribe = async bodyData => {
        let payload = {
            profiles: [
                {
                    'Full Name': `${bodyData.firstName} ${bodyData.lastName}`,
                    email: bodyData.email,
                    State: bodyData.addressLine3,
                    CustomerType: 'Professional',
                    CustomerLocation: bodyData.addressCountry,
                },
            ],
        };
        await sendRequest('subscribe', payload);
    };

    checkout = async () => {
        this.toggleShowLoading('loading-message-checkout');

        let body = await sendRequest('mergeCart', undefined);
        if (body.result) {
            let createCart = await sendRequest('createCart', {
                isAnonymous: true,
            });
            if (!createCart.result) {
                // Need to show an error if something fails
                this.toggleShowError();
                this.stopLoading('loading-message-default');
                return false;
            }
        } else {
            // Need to show an error if something fails
            this.toggleShowError();
            this.stopLoading('loading-message-default');
            return false;
        }

        this.updateShoppingCartQuantity(0);
        this.stopLoading('loading-message-default');
        return true;
    };

    toggleShowLoading = messageId => {
        let showLoading = !this.state.showLoading;
        localStorage.setItem('showLoading', JSON.stringify(showLoading));
        this.setState({
            showLoading: showLoading,
            loadingMessageId: messageId,
        });
    };

    toggleShowUnavailable = isOutOfStock => {
        let showUnavailable = !this.state.showUnavailable;
        localStorage.setItem(
            'showUnavailable',
            JSON.stringify(showUnavailable)
        );
        this.setState({
            showUnavailable: showUnavailable,
            outOfStock: isOutOfStock,
        });
    };

    toggleShowError = () => {
        let showError = !this.state.showError;
        this.setState({
            showError: showError,
        });
    };

    toggleShowInfo = () => {
        let showInfo = !this.state.showInfo;
        this.setState({
            showInfo: showInfo,
        });
    };

    toggleCheckout = () => {
        let startCheckout = !this.state.startCheckout;
        this.setState({
            startCheckout: startCheckout,
        });
    };

    getProduct = async productCodes => {
        let variantData = [];
        let productCodesObject = productCodes.productCodes;
        for (let i = 0; i < productCodesObject.length; i++) {
            let productCode = {
                productCode: productCodesObject[i],
            };
            let body = await sendRequest('getProduct', productCode);
            if (!body.result) {
                if (body.statusCode === 401) {
                    // need to refresh
                    let refreshBody = await sendRequest('refreshToken', null);
                    if (refreshBody.result) {
                        body = await sendRequest('getProduct', productCode);
                        if (!body.result) {
                            if (body.error !== null) {
                                if (body.error === 'NO TOKENS') {
                                    this.toggleShowLoginWizard();
                                } else {
                                    this.toggleShowError();
                                }
                            }
                        } else {
                            variantData.push(body.data);
                        }
                    } else {
                        // Refresh has failed - log user out to start again
                        this.toggleShowLoginWizard();
                    }
                } else if (body.error !== null) {
                    if (body.error === 'NO TOKENS') {
                        this.toggleShowLoginWizard();
                    } else {
                        this.toggleShowError();
                    }
                }
            } else {
                variantData.push(body.data);
            }
        }

        return variantData;
    };

    getAccessToken = async () => {
        let body = await sendRequest('getAccessToken', null);

        if (!body.result) {
            return null;
        }

        return body.data;
    };

    stopLoading = messageId => {
        localStorage.setItem('showLoading', JSON.stringify(false));
        this.setState({
            showLoading: false,
            loadingMessageId: messageId,
        });
    };

    async componentDidMount() {
        let storedRegion = '';
        if (typeof window !== 'undefined') {
            storedRegion = localStorage.getItem('preferred-region');
        }

        if (storedRegion === 'CA') {
            this.setState({
                isProfessional: 0,
                showProfessionalModal: false,
                showLoginWizard: false,
                isLoggedIn: false,
                userAccessToken: null,
            });
        } else {
            const isProfessional = JSON.parse(
                localStorage.getItem('isProfessional')
            );
            let stateSetting = {};
            if (
                isProfessional !== null &&
                isProfessional !== this.state.isProfessional
            ) {
                stateSetting['isProfessional'] = isProfessional;
            }
            const showProfessionalModal = JSON.parse(
                localStorage.getItem('showProfessionalModal')
            );
            if (showProfessionalModal !== null) {
                if (
                    showProfessionalModal !== this.state.showProfessionalModal
                ) {
                    stateSetting[
                        'showProfessionalModal'
                    ] = showProfessionalModal;
                }
            } else {
                stateSetting['showProfessionalModal'] = true;
            }

            const showLoginWizard = JSON.parse(
                localStorage.getItem('showLoginWizard')
            );
            if (
                showLoginWizard !== null &&
                showLoginWizard !== this.state.showLoginWizard
            ) {
                stateSetting['showLoginWizard'] = showLoginWizard;
            }
            const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
            if (isLoggedIn !== null && isLoggedIn !== this.state.isLoggedIn) {
                stateSetting['isLoggedIn'] = isLoggedIn;
            }
            const wizardProgress = JSON.parse(
                localStorage.getItem('wizardProgressTag')
            );
            if (
                wizardProgress !== null &&
                wizardProgress !== this.state.wizardProgress
            ) {
                stateSetting['wizardProgress'] = wizardProgress;
            }

            const shoppingCartQty = JSON.parse(
                localStorage.getItem('shoppingCartQuantity')
            );
            if (
                shoppingCartQty !== null &&
                shoppingCartQty !== this.state.shoppingCartQuantity
            ) {
                stateSetting['shoppingCartQuantity'] = shoppingCartQty;
            }

            const userAccessToken = await this.getAccessToken();
            if (
                userAccessToken !== null &&
                userAccessToken !== this.state.userAccessToken
            ) {
                stateSetting['userAccessToken'] = userAccessToken;
            }

            this.setState(stateSetting);
        }
    }

    render() {
        const { children } = this.props;
        const {
            isProfessional,
            showProfessionalModal,
            showLoginWizard,
            isLoggedIn,
            wizardProgress,
            shoppingCartQuantity,
            showLoading,
            showUnavailable,
            loadingMessageId,
            userAccessToken,
            outOfStock,
            showError,
            showInfo,
            startCheckout,
        } = this.state;

        return (
            <AuthContext.Provider
                value={{
                    isProfessional,
                    showProfessionalModal,
                    showLoginWizard,
                    isLoggedIn,
                    wizardProgress,
                    shoppingCartQuantity,
                    showLoading,
                    showUnavailable,
                    loadingMessageId,
                    userAccessToken,
                    outOfStock,
                    showError,
                    showInfo,
                    startCheckout,
                    setProfessional: this.setProfessional,
                    toggleShowProfessional: this.toggleShowProfessional,
                    toggleShowLoginWizard: this.toggleShowLoginWizard,
                    toggleLoggedIn: this.toggleLoggedIn,
                    userLogin: this.userLogin,
                    updateWizardProgress: this.updateWizardProgress,
                    createUser: this.createUser,
                    addToCart: this.addToCart,
                    getCart: this.getCart,
                    logout: this.logout,
                    checkout: this.checkout,
                    deleteFromCart: this.deleteFromCart,
                    updateCart: this.updateCart,
                    toggleShowUnavailable: this.toggleShowUnavailable,
                    updateShoppingCartQuantity: this.updateShoppingCartQuantity,
                    getProduct: this.getProduct,
                    toggleShowError: this.toggleShowError,
                    toggleShowInfo: this.toggleShowInfo,
                    toggleCheckout: this.toggleCheckout,
                    subscribe: this.subscribe,
                }}
            >
                {children}
            </AuthContext.Provider>
        );
    }
}
export default AuthContext;
export { AuthProvider };
