import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import { useIntl } from 'gatsby-plugin-react-intl';

import styled, { css } from 'styled-components';

import settings from '../../global/settings';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';

const StEventsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-bottom: 30px;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
        margin: 0;
        margin-bottom: 30px;
    }
    h2 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 1.3px;
        color: black;
        background-color: #d3d3d380;
    }
`;

function onSelectSlot(slot) {}

export default function EventsCalendar(props) {
    let intl = useIntl();
    const [calendarViewState, setCalendarViewState] = useState('month');
    const [calendarDateState, setCalendarDateState] = useState(new Date());
    const { items, title, onSelect } = props;

    let localeString = 'en-US';
    if (intl.locale === 'fr') {
        require('moment/locale/fr.js');
        localeString = 'fr-CA';
    } else {
        require('moment/locale/en-ca.js');
    }

    const localizer = momentLocalizer(moment);
    let calendarEvents = items.map(event => {
        // Update the title to remove the date and add the hour
        let titleParts = event.title.split('-');
        let time = event.actualDate.toLocaleString(localeString, {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        let title = `${time} - ${titleParts[0]}`;
        return {
            start: event.actualDate,
            end: event.endDate,
            title: title,
            link: event.link,
            onSelect: onSelect,
        };
    });

    const calendarMessages = {
        allDay: intl.formatMessage({ id: 'calendar-allday' }),
        previous: intl.formatMessage({ id: 'calendar-previous' }),
        next: intl.formatMessage({ id: 'calendar-next' }),
        today: intl.formatMessage({ id: 'calendar-today' }),
        month: intl.formatMessage({ id: 'calendar-month' }),
        week: intl.formatMessage({ id: 'calendar-week' }),
        day: intl.formatMessage({ id: 'calendar-day' }),
        agenda: intl.formatMessage({ id: 'calendar-agenda' }),
        date: intl.formatMessage({ id: 'calendar-date' }),
        time: intl.formatMessage({ id: 'calendar-time' }),
        event: intl.formatMessage({ id: 'calendar-event' }),
        showMore: total =>
            `+ ${total}${intl.formatMessage({ id: 'calendar-showmore' })}`,
    };

    return (
        <StEventsList>
            {title && <h2>{title}</h2>}
            <Calendar
                selectable
                localizer={localizer}
                defaultDate={calendarDateState}
                events={calendarEvents}
                style={{ height: '100vh' }}
                onSelectEvent={event => event.onSelect(event.link)}
                onView={setCalendarViewState}
                view={calendarViewState}
                messages={calendarMessages}
                culture={intl.locale}
                onSelectSlot={onSelectSlot}
            />
        </StEventsList>
    );
}
