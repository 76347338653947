import React from 'react';

import styled, { css } from 'styled-components';
import FieldInput from './FieldInput';
import FieldSubmit from './FieldSubmit';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StBtnBack, StLink } = SharedStyles;

const StQuiz = styled.div`
    text-align: center;
    padding: 50px 0;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: calc(100vh - 340px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    h1 {
        font-size: 28px;
        font-weight: 500;
        margin: 0 auto 40px;
        line-height: 1.61;
        letter-spacing: 0.1em;
        opacity: 0.8;
        color: #4a4a4a;
        max-width: 680px;
    }
`;

const StQuizWrap = styled.div``;

const StQuizStep = styled.div`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;
    margin: 0 0 10px;
`;

const StQuizTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.08em;
    margin: 0 0 2px;
    text-transform: uppercase;
`;

const StQuizSubTitle = styled.div`
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.09em;
    margin: 0 0 10px;
    text-transform: uppercase;
    color: #9b9b9b;

    ${(props) =>
        props.clickable &&
        css`
            text-decoration: underline;
            &:hover {
                color: black;
                text-decoration: none;
                cursor: pointer;
            }
            &:focus {
                color: black;
                text-decoration: none;
                cursor: pointer;
            }
        `};
`;

const StQuizInputs = styled.div`
    max-width: 500px;
    margin: 0 auto 34px;
`;

const StQuizCheckboxes = styled.div`
    margin: 30px auto 40px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 230px;
    }

    ${(props) =>
        props.twoCols &&
        css`
            @media (min-width: ${settings.bp.small.view}) {
                width: 520px;
                display: flex;
                flex-wrap: wrap;

                & > div {
                    width: calc(50% - 26px);
                    margin: 0 13px 15px;
                }
            }
        `};
`;

const StQuizImageCheckboxes = styled.div`
    margin: 30px auto 40px;
`;

const StQuizInput = styled.div`
    .StField {
        margin-bottom: 18px;
    }

    input {
        border-color: #979797;
        text-align: center;

        &:focus {
            border-color: #707070;
        }

        @media (min-width: ${settings.bp.small.view}) {
            padding: 16px;
            height: 51px;
        }
    }
`;

const StQuizCheckbox = styled.div`
    width: 100%;
    max-width: 230px;
    margin: 0 auto 15px;

    input[type='checkbox'] {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: -9999px;

        &:checked + label {
            border-color: #000;
            position: relative;

            &:before {
                content: '';
                width: 13px;
                height: 13px;
                background-color: #000;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
            }

            &:after {
                content: '';
                width: 8px;
                height: 5px;
                border-bottom: 2px solid #fff;
                border-left: 2px solid #fff;
                position: absolute;
                top: -4px;
                right: -4px;
                transform: rotate(-45deg);
            }
        }
    }

    label {
        display: block;
        width: 100%;
        border: 2px solid #9b9b9b;
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 0.08em;
        color: #000;
        margin: 0;
        padding: 11px;
        cursor: pointer;
        text-transform: uppercase;
        transition: border-color 0.3s;

        &:hover {
            border-color: #5f5f5f;
        }
    }
`;

const StQuizImageCheckbox = styled.div`
    max-width: 230px;
    margin: 0 auto 15px;
    display: inline;

    input[type='checkbox'] {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: -9999px;

        &:checked + label {
            border-color: #000;
            position: relative;

            &:before {
                content: '';
                width: 13px;
                height: 13px;
                background-color: #000;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
            }

            &:after {
                content: '';
                width: 8px;
                height: 5px;
                border-bottom: 2px solid #fff;
                border-left: 2px solid #fff;
                position: absolute;
                top: -4px;
                right: -4px;
                transform: rotate(-45deg);
            }
        }
    }

    label {
        display: inline-block;
        border: 2px solid #ffffff;
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 0.08em;
        color: #000;
        margin: 0;
        padding: 11px;
        cursor: pointer;
        text-transform: uppercase;
        transition: border-color 0.3s;

        &:hover {
            border-color: #5f5f5f;
        }
    }

    img {
        padding: 0px 15px 15px 15px;
        width: 100px;
    }
`;

const CssButton = css`
    ${(props) =>
        !props.show &&
        css`
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        `};
`;

const StQuizNextBtn = styled.div`
    margin-bottom: 22px;
    ${CssButton};
`;

const StQuizPrevBtn = styled.div`
    ${CssButton};
`;

function QuizInput(props) {
    return (
        <StQuizInput>
            <FieldInput {...props} />
        </StQuizInput>
    );
}

function QuizCheckbox(props) {
    const { label, id, ...restProps } = props;
    return (
        <StQuizCheckbox>
            <input type="checkbox" {...restProps} id={id} />
            <label htmlFor={id}>{label}</label>
        </StQuizCheckbox>
    );
}

function QuizImageCheckbox(props) {
    const { label, id, image, ...restProps } = props;
    return (
        <StQuizImageCheckbox>
            <input type="checkbox" {...restProps} id={id} />
            <label htmlFor={id}>
                <div>{image && <img src={image} alt="" />}</div>
                {label}
            </label>
        </StQuizImageCheckbox>
    );
}

function QuizClickableSubtitle(props) {
    const { questionSubtitle, onClickSubtitle } = props;
    return (
        <StQuizSubTitle clickable={true} onClick={onClickSubtitle}>
            {questionSubtitle.subtitle}
        </StQuizSubTitle>
    );
}

export default function Quiz(props) {
    const {
        h1,
        step,
        title,
        subtitle,
        inputs,
        checkboxes,
        showNextBtn,
        onClickNextBtn,
        nextBtnText,
        showBackBtn,
        onClickBackBtn,
        backBtnText,
        twoCols,
        hasImages,
        questionSubtitle,
        onClickSubtitle,
    } = props;

    let clickableProps = {
        questionSubtitle,
        onClickSubtitle,
    };

    return (
        <StQuiz>
            <StQuizWrap>
                {h1 && <h1>{h1}</h1>}
                {step && <StQuizStep>{step}</StQuizStep>}
                {title && <StQuizTitle>{title}</StQuizTitle>}
                {subtitle && <StQuizSubTitle>{subtitle}</StQuizSubTitle>}
                {questionSubtitle && !questionSubtitle.clickable && (
                    <StQuizSubTitle>{questionSubtitle.subtitle}</StQuizSubTitle>
                )}
                {questionSubtitle && questionSubtitle.clickable && (
                    <QuizClickableSubtitle {...clickableProps} />
                )}
                {inputs && (
                    <StQuizInputs>
                        {inputs.map((item, index) => (
                            <QuizInput key={item.id || index} {...item} />
                        ))}
                    </StQuizInputs>
                )}
                {checkboxes && !hasImages && (
                    <StQuizCheckboxes twoCols={twoCols}>
                        {checkboxes.map((item, index) => (
                            <QuizCheckbox key={item.id || index} {...item} />
                        ))}
                    </StQuizCheckboxes>
                )}
                {checkboxes && hasImages && (
                    <StQuizImageCheckboxes>
                        {checkboxes.map((item, index) => (
                            <QuizImageCheckbox
                                key={item.id || index}
                                {...item}
                            />
                        ))}
                    </StQuizImageCheckboxes>
                )}
                <StQuizNextBtn show={showNextBtn}>
                    <FieldSubmit>
                        <button onClick={onClickNextBtn}>{nextBtnText}</button>
                    </FieldSubmit>
                </StQuizNextBtn>
                <StQuizPrevBtn show={showBackBtn}>
                    <StBtnBack>
                        <button onClick={onClickBackBtn}>{backBtnText}</button>
                    </StBtnBack>
                </StQuizPrevBtn>
            </StQuizWrap>
        </StQuiz>
    );
}
