import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import StContainer from './Container.js';
import DDButton from './DDButton';

import settings from '../global/settings';

const StProductSticky = styled.div`
    background-color: #f8f8f8;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    transition: transform 0.3s;
    transform: translate(0, 100%);

    ${(props) =>
        props.show &&
        css`
            transform: translate(0, 0);
        `};

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: calc(${settings.bp.small.view} - 1px)) {
            width: 100%;
        }

        @media (min-width: ${settings.bp.small.view}) {
            padding: 14px 0;
        }

        &:after {
            display: none;
        }
    }
`;

const StProductStickyLeft = styled.div`
    display: flex;
    align-items: center;
`;

const StProductStickyRight = styled.div`
    display: flex;
    padding: 14px 14px 14px 0;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 0;
    }

    & > div {
        margin-left: 10px;

        @media (min-width: ${settings.bp.small.view}) {
            margin-left: 20px;
        }

        @media (min-width: ${settings.bp.medium.view}) {
            margin-left: 42px;
        }
    }
`;

const StProductStickyFilter = styled.div`
    display: none;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
    }
`;

const StProductStickyImg = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    margin-right: 14px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 70px;
        height: 70px;
        margin-right: 20px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 35px;
    }

    img {
        width: 100%;
    }
`;

const StProductStickyDesc = styled.div`
    text-transform: uppercase;

    h3 {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.01em;
        line-height: 1.4;
        color: #000;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 18px;
        }
    }

    h4 {
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.1em;
        color: #000;
        display: none;

        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }
    }
`;

export default function ProductSticky(props) {
    const [isShowed, toggleShow] = useState(false);
    const {
        img,
        title,
        subtitle,
        filterItems,
        filterTitle,
        onChangeFilter,
        shopAtItems,
        shopBtnHref,
    } = props;

    useEffect(() => {
        document.body.style.paddingBottom = 100 + 'px';
        window.addEventListener('scroll', handlerResize);
        return () => {
            window.removeEventListener('scroll', handlerResize);
        };
    }, []);

    function handlerResize() {
        toggleShow(window.scrollY >= 1000);
    }

    return (
        <StProductSticky show={isShowed}>
            <StContainer>
                <StProductStickyLeft>
                    {img && (
                        <StProductStickyImg
                            style={{ backgroundImage: `url(${img})` }}
                        />
                    )}
                    <StProductStickyDesc>
                        {title && <h3>{title}</h3>}
                        {subtitle && <h4>{subtitle}</h4>}
                    </StProductStickyDesc>
                </StProductStickyLeft>
                <StProductStickyRight>
                    <StProductStickyFilter>
                        <DDButton
                            items={filterItems}
                            onChange={onChangeFilter}
                            title={filterTitle}
                            styleArrow
                            inversionColor
                            useSelectedTitle
                        />
                    </StProductStickyFilter>
                    <DDButton
                        items={shopAtItems}
                        shopBtnHref={shopBtnHref}
                        mobileHideDD
                    />
                </StProductStickyRight>
            </StContainer>
        </StProductSticky>
    );
}
