import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
import CustomLink from './CustomLink';

const StImgTextGrid = styled.div``;

const StImgTextGridRow = styled.section.attrs((props) => ({
    className: 'StImgTextGridRow',
}))`
    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 70px;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 28px;
            color: ${settings.color.gray};
            line-height: 1.79;
            margin: 0 0 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 20px;
            letter-spacing: 0.08em;
        }
    }

    .StLinkMore {
        margin: 26px 0 0;

        @media (min-width: ${settings.bp.medium.view}) {
            margin: 46px 0 0;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    ${(props) =>
        props.styleWhiteColor &&
        css`
            color: #fff;

            h2,
            p {
                color: #fff;
            }

            .StLinkMore a {
                color: #000000;
                background-color: #fff;
            }
        `};
`;

const StImgTextGridImg = styled.div.attrs((props) => ({
    className: 'StImgTextGridImg',
}))`
  padding: 20px;
  margin: auto;

  ${(props) =>
      props.imgMaxWidth &&
      css`
          max-width: ${props.imgMaxWidth};
      `};

  @media (min-width: ${settings.bp.small.view}) {
    padding: 0 15px 0 30px;
    width: 60%;
  }

  @media (min-width: ${settings.bp.medium.view}) {
    padding: 0 30px;
    width: 60%;
  
  img {
    width: 100%;
  }
`;

const StImgTextGridText = styled.div.attrs((props) => ({
    className: 'StImgTextGridText',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 20px 70px;
    text-align: center;

    @media (min-width: ${settings.bp.small.view}) {
        width: 40%;
        padding: 0 15px 0 30px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 40%;
        padding: 0 30px;
    }

    *:last-child {
        margin-bottom: 0;
    }

    img {
        height: 35px;
    }
    ${(props) =>
        props.color &&
        css`
            h2 {
                color: ${props.color} !important;
            }
        `};

    i {
        font-size: 16px;
    }

    a {
        text-decoration: underline;
    }
`;

function ImageTextGridItem(props) {
    const {
        rowBg,
        rowColorWhite,
        title,
        text,
        image,
        textImage,
        link,
        url,
        imgMaxWidth,
        color,
    } = props;
    return (
        <StImgTextGridRow
            style={{ backgroundColor: rowBg || 'transparent' }}
            styleWhiteColor={rowColorWhite}
        >
            {image && (
                <StImgTextGridImg imgMaxWidth={imgMaxWidth}>
                    <img src={image} alt="" />
                </StImgTextGridImg>
            )}
            <StImgTextGridText color={color}>
                {title && <h2>{title}</h2>}
                {textImage && (
                    <p>
                        <img src={textImage} alt="" />
                    </p>
                )}
                {text && <p> {text} </p>}
                {link && <CustomLink href={url}>{link}</CustomLink>}
            </StImgTextGridText>
        </StImgTextGridRow>
    );
}

export default function ImageTextGridSingle(props) {
    const { bgImg, items, ...restProps } = props;
    return (
        <StImgTextGrid
            style={{ backgroundImage: bgImg ? `url(${bgImg})` : 'none' }}
            {...restProps}
        >
            {items.map((item, index) => (
                <ImageTextGridItem
                    key={item.id || index}
                    {...item}
                    {...restProps}
                />
            ))}
        </StImgTextGrid>
    );
}
