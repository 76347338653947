import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
const { StTitle } = SharedStyles;

const StBenefitRoot = styled.div.attrs((props) => ({
    className: 'StBenefitRoot',
}))`
    h2 {
        padding-top: 20px;
    }
    p {
        margin-left: 24px;
    }
`;

const StBenefitList = styled.div.attrs((props) => ({
    className: 'StBenefitList',
}))`
    align-items: center;
    justify-content: center;

    display: flex;
    flex-wrap: wrap;
`;

const StBenefitListItem = styled.div.attrs((props) => ({
    className: 'StBenefitListItem',
}))`
    padding: 0 10px 10px;
    width: 50%;
    align-self: baseline;

    @media (min-width: ${settings.bp.medium.view}) {
        width: 33.333%;
        padding: 0 20px 20px;
        ${(props) =>
            props.col4 &&
            css`
            width: 25%;
            `};
    
    }

    @media (min-width: ${settings.bp.large.view}) {
        padding: 0 30px 30px;
    }

    ${(props) =>
        props.smallImg &&
        css`
            .StBenefitItemImg {
                img {
                    max-height: 100px;
                }
            }
        `};

    .StBenefitItemImg {
        img {
            max-height: 300px;
        }
    }
`;

const StBenefitItem = styled.div.attrs((props) => ({
    className: 'StBenefitItem',
}))`
    text-align: center;
    color: ${settings.color.gray};
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.05em;

    h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000;
        margin: 0 0 5px;
    }

    p {
        margin: 0;
    }
`;

const StBenefitItemImg = styled.div.attrs((props) => ({
    className: 'StBenefitItemImg',
}))`
    position: relative;
    h2 {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000;
        margin: 0px 12px 8px 12px;
    }
`;

function BenefitItem(props) {
    const {
        image,
        title,
        text
    } = props;
    return (
        <StBenefitItem>
            {image && (
                <StBenefitItemImg>
                    <img src={image} alt={title} />
                </StBenefitItemImg>
            )}
            {title && (
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
            )}
            <p dangerouslySetInnerHTML={{ __html: text }} />
        </StBenefitItem>
    );
}

export default function BenefitList(props) {
    const { items, title, bgImg, smallImg, col4, embedTitleAndImage,disclaimer } = props;
    
    return (
        <StBenefitRoot style={{ backgroundImage: bgImg ? `url(${bgImg})` : 'none' }}>
            {title && (
                <StTitle>
                    <h2 dangerouslySetInnerHTML={{__html: title}} />
                </StTitle>
            )}
            <StBenefitList>
                {items.map((item, index) => (
                    <StBenefitListItem key={item.id || index} col4={col4} smallImg={smallImg}>
                        <BenefitItem {...item} />
                    </StBenefitListItem>
                ))}
            </StBenefitList>
            {disclaimer && (
                <p>{disclaimer}</p>
            )}
        </StBenefitRoot>
    );
}
