import React from 'react';
import { injectIntl } from 'gatsby-plugin-react-intl';
import styled, { css } from 'styled-components';
import settings from '../global/settings';
import Button from '../../kenra-storybook/components/atoms/AllButtonAlt';

const StyledButton = styled(Button)`
    margin-top: 24px;
    border-colour: red;
`;

const StImgGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const StImgGridItem = styled.div`
    display: flex;
    flexdirection: column;
`;

const StImgGridRow = styled.section.attrs(props => ({
    className: 'StImgGridRow',
}))`
    display: flex;
    justify-content: center;
    width: 45vw;
    padding-bottom: 40px;

    img {
        width: 80%;
    }

    ${props =>
        props.smallImages &&
        css`
            width: calc(75% - 12px);
            margin-bottom: 12px;
            img {
                width: 100%;
                max-width: 200px;
            }
            @media (min-width: ${settings.bp.small.view}) {
                width: calc(33.33% - 12px);
                margin-bottom: 0px;
                img {
                    width: 100%;
                    max-width: 200px;
                }
            }
        `};

    ${props =>
        props.outlined &&
        css`
            border-color: #00000066;
            border-width: 1px;
            border-style: solid;
            padding: 18px;
            margin-left: 6px;
            margin-right: 6px;
        `}
`;

const StImgGridRowTitle = styled.div`
    padding-top: 12px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1;
    @media (min-width: ${settings.bp.small.view}) {
        font-size: 24px;
        line-height: 24px;
    }
`;

const StImgGridRowSubtitle = styled.div`
    padding-top: 12px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    opacity: 80%;
    @media (min-width: ${settings.bp.small.view}) {
        font-size: 18px;
        line-height: 18px;
    }
`;

function ImageGridItem(props) {
    const {
        img,
        title,
        smallImages,
        href,
        description,
        outlined,
        learnMore,
        intl,
    } = props;

    return (
        <StImgGridRow outlined={outlined} smallImages={smallImages}>
            <StImgGridItem>
                <a
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                    href={href}
                >
                    <div style={{ paddingBottom: '12px', textAlign: 'center' }}>
                        {img && <img src={img} alt="" />}
                        {title && (
                            <StImgGridRowTitle>{title}</StImgGridRowTitle>
                        )}
                        {description && (
                            <StImgGridRowSubtitle>
                                {description}
                            </StImgGridRowSubtitle>
                        )}
                    </div>
                    {learnMore && (
                        <StyledButton variant={4} href={href}>
                            {intl.formatMessage({ id: 'learn-more-text' })}
                        </StyledButton>
                    )}
                </a>
            </StImgGridItem>
        </StImgGridRow>
    );
}

const ImageGrid = props => {
    const { items, outlined, learnMore, intl, ...restProps } = props;
    return (
        <StImgGrid>
            {items.map((item, index) => (
                <ImageGridItem
                    intl={intl}
                    outlined={outlined}
                    learnMore={learnMore}
                    key={item.id || index}
                    {...item}
                    {...restProps}
                />
            ))}
        </StImgGrid>
    );
};

export default injectIntl(ImageGrid);
