import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../../global/settings';

const StProductCurrentDesc = styled.div`
    display: none;
    align-items: center;
    margin-bottom: 30px;

    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
    }
`;

const StProductCurrentDescImg = styled.div`
    margin-right: 20px;
    width: 100px;
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
`;

const StProductCurrentDescText = styled.div``;

export default function ProductColorInfo(props) {
    const { img, title, subTitle } = props;
    return (
        <StProductCurrentDesc>
            <StProductCurrentDescImg
                style={{ backgroundImage: `url(${img})` }}
            />
            <StProductCurrentDescText>
                <h3>{title}</h3>
                <h4>{subTitle}</h4>
            </StProductCurrentDescText>
        </StProductCurrentDesc>
    );
}
