import React from 'react';
import styled, { css } from 'styled-components';

const StFieldCheckbox = styled.div.attrs((props) => ({
    className: 'StFieldCheckbox',
}))`
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    input {
        position: absolute;
        left: -9999px;
        opacity: 0;
        visibility: hidden;

        &:checked + label {
            &:before {
                background-color: #000;
            }
        }
    }

    label {
        position: relative;
        padding-left: 25px;
        line-height: 1.4;
        display: block;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: #8b8b8b;
        letter-spacing: normal;
        transition: color 0.3s;

        &:hover {
            color: #000;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 3px;
            width: 13px;
            height: 13px;
            background-color: transparent;
            pointer-events: none;
            border: 2px solid rgba(0, 0, 0, 0.8);
            border-radius: 2px;
        }

        span {
            font-weight: normal;
        }
    }

    ${(props) =>
        props.styleCircle &&
        css`
            label {
                padding-left: 30px;

                &:before {
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    border-width: 1px;
                    top: 1px;
                }
            }
        `};

    ${(props) =>
        props.styleBold &&
        css`
            label {
                font-size: 17px;
                font-weight: normal;
                color: #000;
                letter-spacing: 0.04em;
            }
        `};
`;

export default function FieldCheckbox(props) {
    const { styleCircle, styleBold, label, id, name, error, ...restProps } =
        props;
    return (
        <StFieldCheckbox
            styleCircle={styleCircle}
            styleBold={styleBold}
            error={error}
        >
            <input type="checkbox" name={name} id={id} {...restProps} />
            <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
        </StFieldCheckbox>
    );
}
