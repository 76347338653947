import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../../global/settings';

import FieldRadio from '../FieldRadio';

const StProductTypes = styled.div`
    display: block;
    margin-bottom: 24px;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
    }
`;

const StProductTypesTitle = styled.h3`
    margin: 0 0 15px !important;
`;

export default function ProductTypes(props) {
    const { title, items, onChange } = props;
    return (
        <StProductTypes>
            <StProductTypesTitle>{title}</StProductTypesTitle>
            {items.map((item, index) => (
                <FieldRadio
                    key={item.id || index}
                    {...item}
                    onChange={onChange}
                />
            ))}
        </StProductTypes>
    );
}
