import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';
import Spacing from './Spacing';
import Container from './Container';
import * as SharedStyles from '../global/SharedStyles';
const { StSectionInfo, StTitle } = SharedStyles;

const StContactInfo = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        background-color: #fbfbfb;
    }
`;

const StContactInfoWrap = styled.div`
    background-color: #f5f5f5;
    padding: 26px;

    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        flex-wrap: wrap;
        max-width: 1070px;
        margin: 0 auto;
        background-color: transparent;
        padding: 0;
    }
`;

const StStContactInfoItem = styled.div`
    word-break: break-word;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #cacaca;
    font-size: 14px;

    &:nth-child(1) {
        border-top: none;
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    @media (min-width: ${settings.bp.small.view}) {
        border-top-color: #000;
        padding-top: 30px;
        padding-bottom: 0;
        margin: 0 33px 40px;
        width: calc(50% - 66px);

        &:nth-child(2) {
            border-top: none;
            padding-top: 0;
        }
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin: 0 33px 80px;
        width: calc(33.333% - 66px);

        &:nth-child(3) {
            border-top: none;
            padding-top: 0;
        }
    }

    h3 {
        font-size: 15px;
        margin: 0 0 6px;
        font-weight: 500;
        letter-spacing: normal;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 22px;
            text-transform: none;
        }
    }

    a {
        color: ${settings.color.gray};
        text-decoration: none;
        line-height: 1.57;
        letter-spacing: 0.02em;
    }
`;

function ContactInfoItem(props) {
    const { title, email, tel } = props;
    return (
        <StStContactInfoItem>
            {title && <h3>{title}</h3>}
            {email && (
                <div>
                    <a href={`mailto:${email}`}>{email}</a>
                </div>
            )}
            {tel && (
                <div>
                    <a href={`tel:${tel}`}>{tel}</a>
                </div>
            )}
        </StStContactInfoItem>
    );
}

export default function ContactInfo(props) {
    const { title, items } = props;
    return (
        <StContactInfo>
            {title && (
                <Spacing removeSpaceBottom>
                    <StSectionInfo>
                        <Container>
                            <StTitle colorBlack styleMedium>
                                <h1>{title}</h1>
                            </StTitle>
                        </Container>
                    </StSectionInfo>
                </Spacing>
            )}
            <Spacing>
                <Container>
                    <StContactInfoWrap>
                        {items.map((item, index) => (
                            <ContactInfoItem key={item.id || index} {...item} />
                        ))}
                    </StContactInfoWrap>
                </Container>
            </Spacing>
        </StContactInfo>
    );
}
