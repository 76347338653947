import styled, { css } from 'styled-components';

import settings from '../../global/settings';

export const StSliderCompChart = styled.div`
    min-width: 370px;
    max-width: 370px;
    padding: 0 30px;
    height: 100%;
    flex: 1;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 0 60px;
        min-width: 200px;
        max-width: 250px;
    }

    :last-of-type {
        margin-right: 20px;
        @media (min-width: ${settings.bp.small.view}) {
            margin-right: 80px;
        }
    }

    .StCompChartItemImg {
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        height: 200px;

        @media (min-width: ${settings.bp.small.view}) {
            width: 300px;
            height: 300px;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }
`;
