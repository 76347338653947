import React from 'react';
import styled, { css } from 'styled-components';

import Field from './Field';

const StFieldTextarea = styled.div.attrs((props) => ({
    className: 'StFieldTextarea',
}))`
    max-width: 100%;
    width: 100%;

    textarea {
        resize: none;
        height: auto !important;
    }
`;

export default function FieldTextarea(props) {
    const { label, id, name, error, ...restProps } = props;
    return (
        <StFieldTextarea>
            <Field error={error}>
                <textarea name={name} id={id} {...restProps} />
                {label && <label htmlFor={id}>{label}</label>}
            </Field>
        </StFieldTextarea>
    );
}
