import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

export const StBackArrow = styled.div`
    display: flex;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    margin: 20px 0px;

    &:hover {
        text-decoration: underline;
    }
`;

export const StBackArrowText = styled.div`
    align-self: center;
`;

export const StTitleText = styled.div`
    font-size: 22px;
`;

export const StProductImage = styled.div`
    width: 50px;
    height: 50px;
    background-size: cover;
    margin-right: 15px;
`;

export const StCheckoutProduct = styled.div`
    display: flex;
    margin: 10px;
    align-items: center;
`;

export const StCheckoutProductText = styled.div`
    width: 30%;
`;

export const StCheckoutProductTextTitle = styled.div`
    font-size: 14px;
`;

export const StCheckoutProductTextSubtitle = styled.div`
    font-size: 12px;
    color: #909090;
`;

export const StShoppingList = styled.div`
    width: 100%;
    margin-bottom: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 60%;
        margin-bottom: 0px;
    }
`;

export const StSummary = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(246, 246, 246, 0.62);
    padding: 15px 33px 30px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 40%;
    }

    .StLinkMore a {
        width: 80%;
        margin: 20px 10%;
    }
`;

export const StSummaryTitle = styled.div`
    font-size: 22px;
    margin-bottom: 20px;
`;

export const StSummaryItemsText = styled.div`
    font-size: 14px;
    line-height: 2;
    color: #9d9d9d;
    float: left;
`;

export const StSummaryItemsPrice = styled.div`
    font-size: 14px;
    line-height: 2;
    color: #9d9d9d;
    float: right;
`;

export const StSummaryTotalText = styled.div`
    font-size: 18px;
    line-height: 2;
    float: left;
`;

export const StSummaryTotalPrice = styled.div`
    font-size: 18px;
    line-height: 2;
    float: right;
`;

export const StSummarySeperator = styled.div`
    width: 100%;
    border-bottom: 1px solid #c2c2c2;
    margin-bottom: 20px;
`;

export const StCheckout = styled.div`
    display: block;
    align-items: flex-start;

    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
    }
`;

export const StPrice = styled.div`
    width: 10%;
`;

export const StProductSeperator = styled.div`
    width: 80%;
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
`;

export const StDeleteButton = styled.div`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

export const StTaxText = styled.div`
    font-size: 12px;
    color: #7d7d7d;
    width: 80%;
`;

export const StCheckoutSpacer = styled.div`
    display: flow-root;
`;
