import styled, { css } from 'styled-components';

import settings from './settings';

export const StPage = styled.div`
    overflow: hidden;
`;

export const StBtnBack = styled.div`
    & > * {
        font-size: 13px;
        letter-spacing: 0.08em;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        color: #000;
        text-decoration: none;
        background: none;
        border: none;
        padding: 0 0 0 24px;
        margin: 0;
        cursor: pointer;

        &:before {
            content: '';
            width: 10px;
            height: 10px;
            border-top: 2px solid #000;
            border-left: 2px solid #000;
            position: absolute;
            left: 0;
            top: 1px;
            transform: rotate(-45deg);
        }
    }
`;

export const CssButton = css`
    letter-spacing: 1px;
    display: inline-block !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    text-transform: uppercase;
    text-decoration: none !important;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    padding: 13px 30px;
    text-align: center;
    transition: opacity 0.3s;
    border-radius: 0;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &[type='submit'] {
        cursor: pointer;
    }
`;

export const CssLinkMore = css`
    a {
        ${CssButton}
    }
`;

export const StLink = styled.div.attrs(props => ({
    className: 'StLink',
}))`
    a {
        display: inline !important;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.69;
        text-transform: uppercase;
        text-decoration: none !important;
        border-bottom: 1px solid #000;
        padding-bottom: 4px;
        color: #000;
    }

    ${props =>
        props.invertColor &&
        css`
            a {
                color: #fff;
                border-bottom: 1px solid #fff;
            }
        `};

    ${props =>
        props.mobileStyleLinkMore &&
        css`
            @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                ${CssLinkMore};
                text-align: center;

                a {
                    padding: 15px 30px;
                    min-width: 236px;
                }
            }
        `};

    ${props =>
        props.mobileHide &&
        css`
            @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                display: none !important;
            }
        `};
`;

export const StLinkMore = styled.div.attrs(props => ({
    className: 'StLinkMore',
}))`
    ${CssLinkMore}

    ${props =>
        props.styleBig &&
        css`
            a {
                @media (min-width: ${settings.bp.small.view}) {
                    padding: 17px 30px;
                    min-width: 250px;
                }
            }
        `};

    ${props =>
        props.styleMedium &&
        css`
            a {
                @media (min-width: ${settings.bp.small.view}) {
                    padding: 15px 30px;
                }
            }
        `};

    ${props =>
        props.styleSmall &&
        css`
            a {
                @media (min-width: ${settings.bp.small.view}) {
                    padding: 6px 20px;
                }
            }
        `};

    ${props =>
        props.inversionColorNotHover &&
        css`
            a {
                border: 1px solid #000 !important;
                background-color: transparent;
                color: #000;
            }
        `};

    ${props =>
        props.inversionColor &&
        css`
            a {
                transition: background-color 0.3s, color 0.3s;
                border: 1px solid #000 !important;
                background-color: transparent;
                color: #000;

                &:hover {
                    opacity: 1;
                    color: #fff;
                    background-color: #000;
                }
            }
        `};

    ${props =>
        props.white &&
        css`
            a {
                border: 1px solid #fff !important;
                background-color: transparent;
                color: #fff;

                &:hover {
                    opacity: 1;
                    color: #fff;
                    background-color: #000;
                    border: 1px solid #000 !important;
                }
            }
        `};

    ${props =>
        props.color &&
        css`
            a {
                border: 1px solid ${props.color} !important;
                color: #fff;
                background-color: ${props.color};

                &:hover {
                    opacity: 1;
                    border: 1px solid ${props.color} !important;
                    color: #fff;
                    background-color: ${props.color};
                }
            }
            ${props =>
                props.invertTextColor &&
                css`
                    a {
                        color: #121212;
                    }
                `};
        `};

    ${props =>
        props.hoverColor &&
        css`
            a {
                &:hover {
                    border: 1px solid ${props.hoverColor} !important;
                    color: #000;
                    background-color: ${props.hoverColor};
                }
            }
        `};

    ${props =>
        props.onetwoColor &&
        css`
            a {
                background-color: #121212;
                &:hover {
                    color: #121212;
                }
            }
        `};

    ${props =>
        props.rounded &&
        css`
            a {
                border-radius: 50px;
            }
        `};

    ${props =>
        props.responsiveFont &&
        css`
            a {
                font-size: 13px !important;
                @media (min-width: ${settings.bp.small.view}) {
                    font-size: 20px !important;
                }
            }
        `};

    ${props =>
        props.morePadding &&
        css`
            a {
                padding: 13px 65px;
            }
        `};

    ${props =>
        props.center &&
        css`
            text-align: center;
        `};

    ${props =>
        props.topMargin &&
        css`
            margin-top: 16px;

            @media (min-width: ${settings.bp.medium.view}) {
                margin-top: 24px;
            }
        `};

    ${props =>
        props.bottomMargin &&
        css`
            margin-bottom: 24px;

            @media (min-width: ${settings.bp.medium.view}) {
                margin-bottom: 39px;
            }
        `};
    ${props =>
        props.largeText &&
        css`
            a {
                font-size: 30px;
                font-weight: 200;
                padding: 8px 30px;
            }
        `};
`;

export const StPageTitle = styled.div`
    margin-bottom: 30px;
    text-align: center;
    font-size: 15px;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 60px;
    }

    span {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.1em;
        margin-bottom: 8px;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: top;
    }

    h1 {
        color: ${settings.color.default} !important;
        line-height: 1.4 !important;

        br {
            display: none;

            @media (min-width: ${settings.bp.small.view}) {
                display: block;
            }
        }
    }

    p {
        margin: 0 0 16px;
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

export const StPageTitleBar = styled.div`
    background-color: #f7f7f7;
    text-align: center;
    font-size: 15px;
    font-weight: normal;
    padding: 30px 0;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 42px 0;
    }

    h1 {
        text-transform: uppercase;
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        color: ${settings.color.default};
        line-height: 1.25;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 24px;
            margin: 0 0 10px;
            line-height: 1.08;
        }
    }

    p {
        display: none;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }

        br {
            display: none;

            @media (min-width: ${settings.bp.small.view}) {
                display: block;
            }
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

export const StPageImageTitleBar = styled.div`
    background-image: url(${props => props.url});
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f7f7f7;
    text-align: center;
    font-size: 15px;
    font-weight: normal;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 42px 0;
        height: 250px;
    }

    h1 {
        text-transform: uppercase;
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        color: ${settings.color.default};
        line-height: 1.25;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 24px;
            margin: 0 0 10px;
            line-height: 1.08;
        }
    }

    p {
        display: none;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }

        br {
            display: none;

            @media (min-width: ${settings.bp.small.view}) {
                display: block;
            }
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

const CssTitle = css`
    h1,
    h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.67;
        letter-spacing: 0.1em;
        color: ${settings.color.default};
        text-transform: uppercase;
        margin: 0 0 30px;
        text-align: center;

        @media (min-width: ${settings.bp.small.view}) {
            color: ${settings.color.gray};
        }
    }
`;

export const StTitle = styled.div`
    ${props =>
        props.color &&
        css`
            h2 {
                color: ${props.color} !important;
            }
        `};

    ${CssTitle};

    &:last-child {
        h1,
        h2 {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ${props =>
        props.mobileAlignLeft &&
        css`
            h1,
            h2 {
                @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                    text-align: left;
                }
            }
        `};

    ${props =>
        props.alignLeft &&
        css`
            h1,
            h2 {
                text-align: left;
            }
        `};

    ${props =>
        props.mobileSizeSmall &&
        css`
            h1,
            h2 {
                @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                    font-size: 18px;
                    letter-spacing: 0.08em;
                    margin-bottom: 10px;
                }
            }
        `};

    ${props =>
        props.styleMedium &&
        css`
            h1,
            h2 {
                letter-spacing: 0.05em;
                font-size: 18px;

                @media (min-width: ${settings.bp.small.view}) {
                    font-size: 28px;
                }
            }
        `};

    ${props =>
        props.styleBig &&
        css`
            h1 {
                letter-spacing: normal;
                font-weight: 500;
                text-transform: none;
                margin: 0;
                font-size: 26px;

                @media (min-width: ${settings.bp.small.view}) {
                    font-size: 40px;
                }
            }
        `};

    ${props =>
        props.colorBlack &&
        css`
            h1,
            h2 {
                color: #000;
            }
        `};
`;

export const StSubTitle = styled.div`
    margin: auto;
    width: 350px;
    font-size: 16px;

    img {
        width: 337px;
    }

    p {
        text-align: center;
    }
`;

export const StSectionTitle = styled.div.attrs(props => ({
    className: 'StSectionTitle',
}))`
    text-align: center;
    margin-bottom: 30px;

    .container {
        position: static;
        max-width: ${settings.maxBlockWidth};
    }

    @media (min-width: ${settings.bp.small.view}) {
        position: relative;
        margin-bottom: 60px;

        .container {
            padding: 0 200px;
            position: relative;
        }
    }

    ${CssTitle};
    ${props =>
        props.bitBigger &&
        css`
            margin: 0 auto 60px;
            font-size: 15px;
        `}
    ${props =>
        props.noBottomMargin &&
        css`
            margin-bottom: 0px !important;
            @media (min-width: ${settings.bp.small.view}) {
                margin-bottom: 0px !important;
            }
        `}
    ${props =>
        props.smallBottomMargin &&
        css`
            margin-bottom: 30px;
            @media (min-width: ${settings.bp.small.view}) {
                margin-bottom: 30px;
            }
        `}
    ${props =>
        props.removePadding &&
        css`
            @media (min-width: ${settings.bp.small.view}) {
                .container {
                    padding: 0 6px;
                    margin: 0;
                }
            }
        `}
    h1,
    h2 {
        margin-bottom: 0;
    }

    h2 {
        ${props =>
            props.styleBig &&
            css`
                @media (min-width: ${settings.bp.medium.view}) {
                    font-size: 28px;
                }
            `};

        & + .StLink {
            @media (min-width: ${settings.bp.small.view}) {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.67;
        letter-spacing: 0.1em;
        color: ${settings.color.gray};
        text-transform: uppercase;
        margin: 0;
    }

    ${props =>
        props.mobileAlignLeft &&
        css`
            text-align: left;

            @media (min-width: ${settings.bp.small.view}) {
                text-align: center;
            }

            h2 {
                text-align: left;

                @media (min-width: ${settings.bp.small.view}) {
                    text-align: center;
                }
            }
        `};


    ${props =>
        props.black &&
        css`
            h1 {
                color: ${settings.color.default};

                line-height: 1.1;
                font-size: 22px;
                @media (min-width: ${settings.bp.small.view}) {
                    font-size: 30px;
                }
            }
            h2 {
                color: ${settings.color.default};
                font-size: 28px;
            }
            h3 {
                color: ${settings.color.default};

                line-height: 1.1;
                font-size: 20px;
                @media (min-width: ${settings.bp.small.view}) {
                    font-size: 22px;
                }
            }
        `};
        ${props =>
            props.blackHeader &&
            css`
                color: #878787;

                p {
                    margin-top: 0px;
                    font-size: 16px;
                    letter-spacing: 2px;
                }
                h2 {
                    color: ${settings.color.default};
                    font-size: 18px;
                    @media (min-width: ${settings.bp.small.view}) {
                        font-size: 28px;
                        letter-spacing: 0.1em;
                        line-height: 1.67;
                    }
                }
            `};
        ${props =>
            props.grey &&
            css`
                margin-bottom: 0px;
                h1 {
                    color: #878787;
                    font-weight: 400;
                    line-height: 1.33;
                    font-size: 26px;
                    @media (min-width: ${settings.bp.small.view}) {
                        font-size: 30px;
                    }
                    letter-spacing: 2px;
                }
                h2 {
                    color: #878787;
                }
                h3 {
                    color: #878787;
                }
            `};

            ${props =>
                props.alignLeft &&
                css`
                    text-align: left !important;
                    h2 {
                        text-align: left !important;
                    }
                    h1 {
                        text-align: left !important;
                    }
                `};
    
`;

export const StLargeSectionTitle = styled.div.attrs(props => ({
    className: 'StLargeSectionTitle',
}))`
    text-align: center;
    font-size: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #fff;
    color: #000;

    ${props =>
        props.backgroundColor &&
        css`
            background-color: ${props.backgroundColor};
        `};
    ${props =>
        props.color &&
        css`
            color: ${props.color};
        `};

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 60px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        font-size: 70px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
`;

export const StSectionInfo = styled.section`
    text-align: center;

    ${props =>
        props.useMobileBg &&
        css`
            @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                background-color: #f6f6f6;
                padding: 60px 0;
            }
        `};

    .container {
        max-width: 670px;

        @media (min-width: ${settings.bp.medium.view}) {
            max-width: 920px;
        }
    }

    h2 {
        margin-bottom: 10px;

        @media (min-width: ${settings.bp.medium.view}) {
            margin-bottom: 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.56;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: 0.06em;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    .StLinkMore {
        @media (min-width: ${settings.bp.medium.view}) {
            margin-top: 70px;
        }
    }

    br {
        display: none;

        @media (min-width: ${settings.bp.medium.view}) {
            display: block;
        }
    }

    ${props =>
        props.styleSizeSmall &&
        css`
            p {
                @media (min-width: ${settings.bp.medium.view}) {
                    font-size: 16px;
                    letter-spacing: normal;
                }
            }

            .StLinkMore {
                @media (min-width: ${settings.bp.medium.view}) {
                    margin-top: 30px;
                }
            }
        `};
`;

export const StSectionBody = styled.section`
    text-align: center;
    text-transform: uppercase;

    h2 {
        font-size: 22px;
        color: #4a4a4a;
        font-weight: 500;
        letter-spacing: 1.57px;
    }
`;

export const StSectionMobileViewMore = styled.section`
    @media (max-width: calc(${settings.bp.small.view} - 1px)) {
        padding-bottom: 105px;
        position: relative;

        .StSectionTitle .StLink {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .StSectionTitle .StLink {
            bottom: 40px;
        }
    }
`;

export const StSectionLink = styled.section`
    h2 {
        margin-bottom: 10px;

        @media (min-width: ${settings.bp.medium.view}) {
            margin-bottom: 20px;
        }
    }

    p {
        font-size: 14px;
        line-height: 1.56;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 16px;
            line-height: 1.67;
            letter-spacing: 0.06em;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
    }
`;

export const StColImgText = styled.div`
    font-size: 16px;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    margin-bottom: 60px;

    @media (min-width: ${settings.bp.small.view}) {
        letter-spacing: 0.07em;
        font-size: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div {
        @media (min-width: ${settings.bp.small.view}) {
            max-width: 438px;
            width: 50%;
        }

        &:first-child {
            margin-bottom: 30px;

            @media (min-width: ${settings.bp.small.view}) {
                margin-right: 52px;
                margin-bottom: 0;
            }
        }

        img {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            max-width: calc(100% - 40px);

            @media (min-width: ${settings.bp.small.view}) {
                max-width: 100%;
            }
        }
    }

    p {
        margin: 0 0 16px;
        text-indent: 26px;

        @media (min-width: ${settings.bp.small.view}) {
            margin: 0;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        & + p {
            text-indent: 0;
        }
    }
`;

export const StImgDesc = styled.figure`
    margin: 0 0 20px;
    text-align: center;

    @media (min-width: ${settings.bp.small.view}) {
        margin: 0 0 60px;
    }

    & > div {
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }

    img {
        margin-left: -20px;
        margin-right: -20px;
        width: 100vw;
        max-width: 100vw;

        @media (min-width: ${settings.bp.small.view}) {
            margin-left: 0;
            margin-right: 0;
            width: auto;
            max-width: 100%;
        }
    }

    figcaption {
        font-size: 9px;
        font-weight: normal;
        text-transform: uppercase;
        margin-top: 4px;
        letter-spacing: 0.07em;
    }
`;

export const StInnerText = styled.div`
    margin: 0 auto 60px;
    max-width: 750px;
    font-size: 17px;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;

    @media (min-width: ${settings.bp.small.view}) {
        letter-spacing: 0.07em;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        & + p {
            text-indent: 0;
        }
    }

    p {
        margin: 0 0 24px;
        text-indent: 26px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const StInnerTextNoIndent = styled.div`
    margin: 0 auto 60px;
    max-width: 750px;
    font-size: 17px;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;

    @media (min-width: ${settings.bp.small.view}) {
        letter-spacing: 0.07em;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        & + p {
            text-indent: 0;
        }
    }

    p {
        margin: 0 0 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const StColImgImg = styled.div`
    margin-bottom: 60px;

    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div {
        @media (min-width: ${settings.bp.small.view}) {
            max-width: 640px;
            width: 50%;
        }

        &:first-child {
            margin-bottom: 20px;

            @media (min-width: ${settings.bp.small.view}) {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }
    }
`;

export const StHoverOverlay = styled.div`
    @media (min-width: ${settings.bp.medium.view}) {
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: ${settings.bgOverlay};
        text-align: center;
        opacity: 0;
        transform: scale(1.1);
        transition: opacity 0.5s, transform 0.5s;

        &:hover {
            opacity: 1;
            transform: scale(1);
        }

        *,
        .StLink a {
            color: #fff;
            border-color: #fff;
        }
    }
`;

export const StMenuCloseBtn = styled.div.attrs(props => ({
    className: 'StMenuCloseBtn',
}))`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    padding: 20px;
    text-transform: uppercase;
    color: ${settings.color.default};
    letter-spacing: 0.05em;
    border-top: 1px solid ${settings.color.default};
    cursor: pointer;
    background-color: #fff;

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }
`;

export const StPageExtraPadding = styled.div`
    padding-top: 39px;
    @media (min-width: ${settings.bp.small.view}) {
        padding-top: 34px;
    }
`;

export const StBigTitle = styled.div`
    font-size: 60px;
    font-weight: bold;
    text-transform: uppercase;
`;

export const StBigTitleGradient = styled.div`
    font-size: 60px;
    font-weight: bold;
    text-transform: uppercase;

    ${props =>
        props.gradient &&
        css`
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            background: linear-gradient(${props.gradient});
        `};
`;

export const StMediumTitle = styled.div`
    font-size: 33px;
    font-weight: bold;
    text-transform: uppercase;
`;

export const StMediumTitleGradient = styled.div`
    font-size: 33px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;

    ${props =>
        props.gradient &&
        css`
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            background: linear-gradient(${props.gradient});
        `};
`;

export const VidTitle = styled.h2`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;

    @media (min-width: 768px) {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 40px;
    }
`;
