import React from 'react';
import jsonp from 'jsonp';
import PropTypes from 'prop-types';

class Mailchimp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(evt) {
        evt.preventDefault();
        const { fields, action, onSignUp } = this.props;
        const values = fields
            .map((field) => {
                return `${field.name}=${encodeURIComponent(
                    this.state[field.name]
                )}`;
            })
            .join('&');
        const path = `${action}&${values}`;
        const url = path.replace('/post?', '/post-json?');
        const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
        const email = this.state['EMAIL'];
        !regex.test(email)
            ? this.setState({ status: 'empty' })
            : this.sendData(url, onSignUp);
    }

    sendData(url, onSignUp) {
        this.setState({ status: 'sending' });
        jsonp(url, { param: 'c' }, (err, data) => {
            if (data.msg.includes('already subscribed')) {
                this.setState({ status: 'duplicate' });
                if (onSignUp) {
                    onSignUp();
                }
            } else if (err) {
                this.setState({ status: 'error' });
            } else if (data.result !== 'success') {
                this.setState({ status: 'error' });
            } else {
                this.setState({ status: 'success' });
                if (onSignUp) {
                    onSignUp();
                }
            }
        });
    }

    render() {
        const { fields, styles, className, buttonClassName } = this.props;
        const messages = {
            ...Mailchimp.defaultProps.messages,
            ...this.props.messages,
        };
        const { status } = this.state;
        return (
            <form onSubmit={this.handleSubmit.bind(this)} className={className}>
                <div className="mailchimp-wrapper">
                    {fields.map((input, index) => (
                        <div
                            key={input.id || index}
                            className={`field-${input.type}`}
                        >
                            {input.label &&
                                input.type !== 'radio' &&
                                input.type !== 'checkbox' && (
                                    <label htmlFor={input.id}>
                                        {input.label}
                                    </label>
                                )}
                            <input
                                {...input}
                                onChange={({ target }) => {
                                    if (
                                        input.type !== 'radio' &&
                                        input.type !== 'checkbox'
                                    ) {
                                        this.setState({
                                            [input.name]: target.value,
                                        });
                                    } else {
                                        this.setState({
                                            [input.name]: target.id,
                                        });
                                    }
                                }}
                                defaultValue={this.state[input.name]}
                            />
                            {input.label &&
                                (input.type === 'radio' ||
                                    input.type === 'checkbox') && (
                                    <label htmlFor={input.id}>
                                        {input.label}
                                    </label>
                                )}
                        </div>
                    ))}
                </div>
                <button
                    disabled={status === 'sending' || status === 'success'}
                    type="submit"
                    className={buttonClassName}
                >
                    {messages.button}
                </button>
                <div style={styles.sendingMsg} className="msg-alert">
                    {status === 'sending' && <p>{messages.sending}</p>}
                    {status === 'success' && <p>{messages.success}</p>}
                    {status === 'duplicate' && <p>{messages.duplicate}</p>}
                    {status === 'empty' && <p>{messages.empty}</p>}
                    {status === 'error' && <p>{messages.error}</p>}
                </div>
            </form>
        );
    }
}

Mailchimp.defaultProps = {
    messages: {
        sending: 'Sending...',
        success: 'Thank you for subscribing!',
        error: 'An unexpected internal error has occurred.',
        empty: 'Please enter an e-mail.',
        duplicate: 'Looks like you are already subscribed',
        button: 'Submit',
    },
    buttonClassName: '',
    styles: {
        sendingMsg: {
            backgroundColor: '#0652DD',
        },
        successMsg: {
            backgroundColor: '#009432',
        },
        duplicateMsg: {
            backgroundColor: '#EE5A24',
        },
        errorMsg: {
            backgroundColor: '#ED4C67',
        },
    },
};

Mailchimp.propTypes = {
    action: PropTypes.string,
    messages: PropTypes.object,
    fields: PropTypes.array,
    styles: PropTypes.object,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
};

export default Mailchimp;
