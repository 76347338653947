import React from 'react';
import styled, { css } from 'styled-components';
import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
const { StMenuCloseBtn } = SharedStyles;

const StMobileFixedContent = styled.div`
    @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
        transition: background-color 0.3s;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 101;
        background-color: transparent;
        transform: translate(0, 100%);
    }

    ${(props) =>
        props.show &&
        css`
            @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                background-color: rgba(0, 0, 0, 0.5);
                transform: translate(0, 0);
            }
        `};
`;

const StMobileFixedContentInner = styled.div`
    @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
        max-height: calc(100% - 60px);
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #fff;
        padding: 30px;
        transition: transform 0.3s;
        transform: translate(0, 100%);

        ${(props) =>
            props.show &&
            css`
                @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                    transform: translate(0, 0);
                }
            `};
    }
`;

const StMobileFixedContentBtn = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }
`;

export default function MobileFixedContent(props) {
    const { btnCloseName, show, toggleShow, ignoreMobileActive } = props;
    return (
        <>
            {!ignoreMobileActive && (
                <StMobileFixedContent
                    show={show}
                    onClick={() => toggleShow(!show)}
                >
                    <StMobileFixedContentInner
                        show={show}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {props.children}
                    </StMobileFixedContentInner>
                    <StMobileFixedContentBtn>
                        <StMenuCloseBtn>
                            {btnCloseName || 'Close'}
                        </StMenuCloseBtn>
                    </StMobileFixedContentBtn>
                </StMobileFixedContent>
            )}
            {ignoreMobileActive && <>{props.children}</>}
        </>
    );
}
