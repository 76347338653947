import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

const StLoyalty = styled.div`
    ${(props) =>
        props.colorWhite &&
        css`
            color: #fff;

            * {
                color: #fff;
            }
        `};
`;

const StLoyaltyLogo = styled.div`
    text-align: center;
    margin: 0 auto 80px;
`;

const StLoyaltyWrap = styled.div``;

const StLoyaltyRow = styled.div`
    margin-bottom: 60px;

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 120px;
    }

    @media (max-width: calc(${settings.bp.small.view} - 1px)) {
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const StLoyaltyInner = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
    }
`;

const StLoyaltyRowTitle = styled.h3`
    margin: 0 0 40px;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.07em;
    text-transform: uppercase;
`;

const StLoyaltyItem = styled.div`
    font-size: 16px;
    line-height: 1.63;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.small.view}) {
        margin-right: 40px;
        margin-bottom: 0;
        width: 308px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 70px;
    }

    h4 {
        margin: 0 0 6px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }

    p {
        margin: 0 0 14px;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

const StLoyaltyItemText = styled.div``;

function LoyaltyRowItem(props) {
    const { title, text } = props;
    return (
        <StLoyaltyItem>
            {title && <h4>{title}</h4>}
            <StLoyaltyItemText dangerouslySetInnerHTML={{ __html: text }} />
        </StLoyaltyItem>
    );
}

function LoyaltyRow(props) {
    const { title, items } = props;
    return (
        <StLoyaltyRow>
            {title && <StLoyaltyRowTitle>{title}</StLoyaltyRowTitle>}
            <StLoyaltyInner>
                {items.map((item, index) => (
                    <LoyaltyRowItem key={item.id || index} {...item} />
                ))}
            </StLoyaltyInner>
        </StLoyaltyRow>
    );
}

export default function Loyalty(props) {
    const { colorWhite, logo, items } = props;
    return (
        <StLoyalty colorWhite={colorWhite}>
            {logo && (
                <StLoyaltyLogo>
                    <img src={logo} alt="loyalty logo" />
                </StLoyaltyLogo>
            )}
            <StLoyaltyWrap>
                {items.map((item, index) => (
                    <LoyaltyRow key={item.id || index} {...item} />
                ))}
            </StLoyaltyWrap>
        </StLoyalty>
    );
}
