import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import IsMobile from './IsMobile';

const StScrollableBlock = styled.div.attrs(props => ({
    className: 'StScrollableBlock',
}))`
    overflow: hidden;

    ${props =>
        props.onlyMobile &&
        css`
            @media (min-width: ${settings.bp.medium.view}) {
                overflow: visible;
            }
        `};
`;

const StScrollableBlockWrap = styled.div.attrs(props => ({
    className: 'StScrollableBlockWrap',
}))`
    text-align: center;
    overflow-y: hidden;
    overflow-x: scroll;
    margin-bottom: 20px;
    // @media (min-width: ${settings.bp.medium.view}) {
    //     overflow-y: hidden;
    //     overflow-x: scroll;
    // }

    ${props =>
        !props.IsMobile &&
        css`
            padding-bottom: 20px;
            margin-bottom: -20px;
        `};

    ${props =>
        props.onlyMobile &&
        css`
            @media (min-width: ${settings.bp.medium.view}) {
                text-align: inherit;
                overflow-y: visible;
                overflow-x: visible;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        `};
`;

const StScrollableBlockInner = styled.div.attrs(props => ({
    className: 'StScrollableBlockInner',
}))`
    display: inline-block;
    text-align: left;

    ${props =>
        props.onlyMobile &&
        css`
            @media (min-width: ${settings.bp.medium.view}) {
                display: block;
                text-align: inherit;
            }
        `};
`;

export default function ScrollableBlock(props) {
    const { onlyMobile } = props;
    return (
        <StScrollableBlock onlyMobile={onlyMobile}>
            <StScrollableBlockWrap
                onlyMobile={onlyMobile}
                IsMobile={IsMobile()}
            >
                <StScrollableBlockInner onlyMobile={onlyMobile}>
                    {props.children}
                </StScrollableBlockInner>
            </StScrollableBlockWrap>
        </StScrollableBlock>
    );
}
