import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

const StFieldPlaceholder = css`
    font-weight: normal;
    text-transform: uppercase;
    color: rgba(8, 8, 8, 0.37);
    opacity: 1;
    letter-spacing: 0.08em;
`;

const StField = styled.div.attrs((props) => ({
    className: 'StField',
}))`
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 40px;
    }

    select,
    input,
    textarea {
        margin: 0;
        padding: 7px 10px;
        border-radius: 0;
        color: ${settings.color.default};
        font-size: 13px;
        line-height: 1.2;
        width: 100%;
        appearance: none;
        box-shadow: none;
        background: #fdfdfd;
        border: solid 1px #e4e4e4;
        transition: border-color 0.3s, color 0.3s;

        @media (min-width: ${settings.bp.small.view}) {
            border-width: 2px;
            padding: 11px 17px;
            height: 41px;
        }

        ::placeholder,
        ::-webkit-input-placeholder {
            ${StFieldPlaceholder}
        }
        :-ms-input-placeholder {
            ${StFieldPlaceholder}
        }

        &:focus {
            border-color: #ccc;
        }
    }

    label,
    div {
        color: rgba(0, 0, 0, 0.5);
        font-size: 11px;
        line-height: 1.2;
        letter-spacing: 0.05em;
        margin: 6px 0 0;
        display: block;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 13px;
        }
    }

    ${(props) =>
        props.error &&
        css`
            input {
                background-color: rgba(255, 0, 0, 0.1);
            }
        `};
`;

export default function Field(props) {
    return <StField {...props} />;
}
