import React from 'react';
import { GlobalStyle } from '../kenra-storybook/index';

class Layout extends React.Component {
    render() {
        return (
            <>
                <GlobalStyle />
                {this.props.children}
            </>
        );
    }
}

export default Layout;
