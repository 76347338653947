import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
import MobileFixedContent from './MobileFixedContent';
const { StLinkMore } = SharedStyles;

const StDDButton = styled.div.attrs(props => ({
    className: 'StDDButton',
}))`
    position: relative;
    width: 200px;

    &:hover .StDDButtonList {
        opacity: 1;
        visibility: visible;
    }

    .StLinkMore {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        a {
            width: 100%;
            padding: 15px 0px;
        }
    }

    ${props =>
        props.styleArrow || props.styleArrowType2
            ? css`
                  .StLinkMore a {
                      position: relative;

                      &:before {
                          content: '';
                          position: absolute;
                          right: 20px;
                      }
                  }
              `
            : ``};

    ${props =>
        props.styleArrow &&
        css`
            .StLinkMore a:before {
                top: 50%;
                transform: translate(0, -50%);
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 10px solid #000;
            }

            &:hover .StLinkMore a:before {
                transform: translate(0, -50%) rotate(180deg);
            }
        `};

    ${props =>
        props.styleArrowType2 &&
        css`
            .StLinkMore a:before {
                top: calc(50% - 2px);
                transform: translate(0, -50%) rotate(45deg);
                border-bottom: 2px solid #000;
                border-right: 2px solid #000;
                width: 8px;
                height: 8px;
            }

            &:hover .StLinkMore a:before {
                top: 50%;
                transform: translate(0, -50%) rotate(-135deg);
            }
        `};

    ${props =>
        props.mobileFixedMenu &&
        css`
            @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
                .StLinkMore {
                    position: fixed;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 49;
                    background-color: #fff;
                    padding: 20px;
                    text-align: center;

                    a {
                        display: inline-block;
                        cursor: pointer;
                        width: auto;
                        min-width: 200px;

                        &:before {
                            display: none !important;
                        }
                    }
                }
            }
        `};
`;

const StDDButtonList = styled.div.attrs(props => ({
    className: 'StDDButtonList',
}))`
    position: absolute;
    z-index: 40;
    left: 0;
    right: 0;
    top: 100%;
    background-color: #000;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 12px;

    ${props =>
        props.mobileHideDD &&
        css`
            display: none;

            @media (min-width: ${settings.bp.medium.view}) {
                display: block;
            }
        `};

    div {
        border-bottom: 1px solid #edede8;
    }

    &.off-screen {
        bottom: auto;
        top: 100%;

        div {
            ${props =>
                !props.inversionColor &&
                css`
                    border-bottom: none;
                    border-top: 1px solid #edede8;
                `};
        }
    }

    a {
        cursor: pointer;
        min-height: 48px;
        color: #fff;
        display: block;
        padding: 15px;
        text-align: center;
        position: relative;
        transition: background-color 0.3s;
        text-decoration: none;

        &:hover {
            background-color: #989898;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: calc(100% - 20px);
            max-height: calc(100% - 20px);
        }
    }

    ${props =>
        props.inversionColor &&
        css`
            border: 1px solid #000;
            border-bottom: none;
            background-color: #fff;

            &.off-screen {
                border-top: none;
                border-bottom: 1px solid #000;
            }

            a {
                color: #000;
            }

            div {
                border-bottom-color: #000;

                &:last-child {
                    border-bottom: none;
                }
            }
        `};
`;

const StDDButtonListMobile = styled.div`
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05em;

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }

    a {
        padding: 4px 0;
        margin-bottom: 14px;
        display: block;
    }
`;

const StDDButtonLink = styled.a.attrs(props => ({
    className: 'StDDButtonLink',
}))``;

function DDButtonItem(props) {
    const { val, title, img, href, newTab, onClick, changeTitle } = props;
    let target = '';
    if (newTab) {
        target = '_blank';
    }

    return (
        <div>
            <StDDButtonLink
                onClick={() => {
                    onClick(val, props);
                    changeTitle(title);
                }}
                href={href}
                target={target}
            >
                {img ? (
                    <img
                        style={{
                            padding: '5px 5px 5px 5px',
                            objectFit: 'contain',
                        }}
                        src={img}
                        alt={title || ''}
                    />
                ) : (
                    title
                )}
            </StDDButtonLink>
        </div>
    );
}

export default function DDButton(props) {
    const [isMobileActive, toggleMobileActive] = useState(false);
    const {
        title,
        shopBtnHref,
        items,
        styleArrow,
        styleArrowType2,
        inversionColor,
        inversionButtonColor,
        useSelectedTitle,
        mobileHideDD,
        mobileFixedMenu,
        onChange,
        defaultTitle,
        onClick,
    } = props;
    const elmDDBtn = useRef();
    const elmDDList = useRef();
    const selected = items && items.find(item => item.selected);
    const [btnTitle, changeTitle] = useState(
        useSelectedTitle && selected ? selected.title : title || defaultTitle
    );
    const inner = (
        <>
            {items &&
                items.map((item, index) => (
                    <DDButtonItem
                        key={item.id || index}
                        {...item}
                        onClick={onChange}
                        changeTitle={handlerChangeTitle}
                    />
                ))}
        </>
    );

    function handlerChangeTitle(title) {
        useSelectedTitle && changeTitle(title);
    }

    function checkOffset() {
        if (!elmDDBtn || !elmDDBtn.current || !elmDDList || !elmDDList.current)
            return;
        const btnRect = elmDDBtn.current.getBoundingClientRect();
        const ddRect = elmDDList.current.getBoundingClientRect();
        const valueForTopPos = btnRect.top - ddRect.height;

        if (valueForTopPos < 0 || valueForTopPos + window.scrollY - 60 < 0) {
            elmDDList.current.classList.add('off-screen');
        } else {
            elmDDList.current.classList.remove('off-screen');
        }
    }

    useEffect(() => {
        if (window.innerWidth >= 1024) {
            document.body.style.paddingBottom = 0 + 'px';
        } else {
            document.body.style.paddingBottom = 90 + 'px';
        }
    }, []);

    return (
        <StDDButton
            mobileFixedMenu={mobileFixedMenu}
            styleArrow={styleArrow}
            styleArrowType2={styleArrowType2}
            onClick={onClick}
        >
            <StLinkMore
                ref={elmDDBtn}
                styleMedium
                inversionColorNotHover={inversionColor || inversionButtonColor}
            >
                <a
                    // onMouseEnter={checkOffset}
                    onClick={e => {
                        if (shopBtnHref) return;
                        e.preventDefault();
                        toggleMobileActive(!isMobileActive);
                    }}
                    href={shopBtnHref}
                >
                    {btnTitle}
                </a>
            </StLinkMore>
            {items && (
                <>
                    <StDDButtonList
                        ref={elmDDList}
                        mobileHideDD={mobileHideDD || mobileFixedMenu}
                        inversionColor={inversionColor}
                    >
                        {inner}
                    </StDDButtonList>
                    {mobileFixedMenu && (
                        <StDDButtonListMobile>
                            <MobileFixedContent
                                show={isMobileActive}
                                toggleShow={toggleMobileActive}
                            >
                                {inner}
                            </MobileFixedContent>
                        </StDDButtonListMobile>
                    )}
                </>
            )}
        </StDDButton>
    );
}
