import styled, { css } from 'styled-components';

import settings from '../../global/settings';

export const StSliderIG = styled.div`
    width: 220px !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 330px !important;
        padding: 0 0 0 30px;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export const StSliderIGDesc = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 30px;
    }

    h3 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 14px;
        color: ${settings.color.default};
        letter-spacing: 0.06em;
    }

    p {
        margin: 0 0 20px;
    }

    .StLink {
        margin-top: auto;
    }

    a {
        font-size: 12px;
    }
`;
