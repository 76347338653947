import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../../global/settings';

const MdBreakPoint = '768px';

const ViewAllBtn = styled.a`
    width: 100%;
    max-width: 140px;
    ${props =>
        props.wide &&
        css`
            max-width: 350px;
        `};
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    border: 0;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    font-style: normal;
    margin: 0 auto;
    left: 0;
    right: 0;
    border: 1px solid black;

    &:hover {
        cursor: pointer;
    }

    ${props =>
        props.variant == 2
            ? css`
                  background: black;
                  color: white !important;
              `
            : null};
    ${props =>
        props.variant == 3
            ? css`
                  border: 0px;
                  text-decoration: underline;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 10.822px;
                  letter-spacing: 0.773px;
                  @media (min-width: ${settings.bp.small.view}) {
                      font-size: 16px;
                      line-height: 22px;
                      letter-spacing: 1.143px;
                  }
              `
            : null};
    ${props =>
        props.variant == 4
            ? css`
                  border-color: #00000066;
              `
            : null};
`;

const AllButtonAlt = ({ children, href, variant, wide }) => {
    return (
        <ViewAllBtn wide={wide} variant={variant} href={href}>
            {children}
        </ViewAllBtn>
    );
};

export default AllButtonAlt;
