import styled, { css } from 'styled-components';
import settings from '../../global/settings';

export const StSliderColorMelt = styled.div`
    width: 184px !important;
    height: auto !important;
    padding: 0 0 0 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    letter-spacing: 0.02em;

    @media (min-width: ${settings.bp.small.view}) {
        width: 490px !important;
        padding: 0 0 0 30px;
        text-align: center;
        font-size: 16px;
        letter-spacing: 0.04em;
    }

    img {
        width: 100%;
        height: 184px;
        margin-bottom: 26px;
        object-fit: cover;

        @media (min-width: ${settings.bp.small.view}) {
            height: 490px;
        }
    }

    h3 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        margin: 0 0 10px;
        letter-spacing: 0.06em;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 16px;
            line-height: 1.25;
        }
    }

    p {
        margin: 0;
    }
`;
