import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../../global/settings';

const StProductColorsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
    }
`;

const StProductColorsItem = styled.div`
    width: 26px;
    height: 26px;
    position: relative;
    box-sizing: content-box;
    cursor: pointer;
    transition: opacity 0.3s;
    padding: 5px;

    &:hover {
        opacity: 0.9;
    }

    ${(props) =>
        props.checked &&
        css`
            opacity: 1 !important;
            border: 2px solid #fff;

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                border: 1px solid #000;
                border-radius: 50%;
            }
        `};

    div {
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
    }
`;

function ProductColorsItem(props) {
    const {
        img,
        alt,
        id,
        onClickColor,
        onHoverColor,
        onLeaveColor,
        ...restProps
    } = props;
    return (
        <StProductColorsItem
            {...restProps}
            onMouseEnter={() => onHoverColor(id)}
            onMouseLeave={() => onLeaveColor(id)}
            onClick={() => onClickColor(id)}
        >
            <div style={{ backgroundImage: `url(${img})` }} />
        </StProductColorsItem>
    );
}

export default function ProductColors(props) {
    const { items, ...restProps } = props;
    return (
        <StProductColorsList>
            {items.map((item, index) => (
                <ProductColorsItem
                    {...item}
                    {...restProps}
                    key={item.id || index}
                />
            ))}
        </StProductColorsList>
    );
}
