import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';
import CustomLink from './CustomLink';

const StCompChartItem = styled.div`
  text-align: center;
  margin: 0 11px;
  width: 100%;
  // min-width: 370px;
  // max-width: 370px;
  height: 100%;

  @media (min-width: ${settings.bp.small.view}) {
    margin: 0 4px;
    padding: 28px;
    min-width: 192px;
    max-width: 230px;
    border: 2px solid transparent;

    &: hover {
      border: 2px solid #000;
  }
`;

const StCompChartItemTextMobile = styled.div`
    @media (max-width: calc(${settings.bp.small.view} - 1px)) {
        position: relative;
        padding: 44px 25px;
        background-color: #fbfbfb;
        border: 3px solid #fbfbfb;

        ${props =>
            props.colorDecor &&
            css`
                border-color: ${props.colorDecor};

                &:before {
                    border-top-color: ${props.colorDecor};
                    border-right-color: ${props.colorDecor};
                }
            `};
            ${props =>
                props.white &&
                css`
                    background-color: #ffffff;
                    border-color: #ffffff;

                    &:before {
                        border-top-color: #ffffff;
                        border-right-color: #ffffff;
                    }
                `};
    }
`;

const StCompChartItemTitle = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: 0.09em;
    margin-bottom: 20px;
    display: none;

    @media (min-width: ${settings.bp.small.view}) {
        display: block;
        min-height: 80px;
    }
`;

const StCompChartItemTitleMobile = styled.div`
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: 0.08em;
    margin-bottom: 20px;

    br {
        display: none;
    }

    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }
`;

const StCompChartItemImg = styled.div`
    margin-bottom: 30px;
    img {
        max-height: 140px;
    }
    @media (min-width: ${settings.bp.small.view}) {
        img {
            max-height: 210px;
        }
    }
`;

const StCompChartItemText = styled.div`
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 14px;
        text-transform: none;
        letter-spacing: 0.02em;
        color: ${settings.color.gray};
    }

    strong {
        font-weight: bold;
        display: block;
    }

    p {
        margin: 0 0 20px;

        @media (min-width: ${settings.bp.small.view}) {
            margin: 0 0 30px;
        }

        &:last-child {
            margin-bottom: 0;
        }

    }

    class-feature {
        display: none;
        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }
    }
`;

export default function CompChartItem(props) {
    const { colorDecor, title, img, text, url, white } = props;
    return (
        <CustomLink href={url}>
            <StCompChartItem colorDecor={colorDecor}>
                {title && (
                    <StCompChartItemTitle
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                )}
                {img && (
                    <StCompChartItemImg>
                        <img src={img} alt={title} />
                    </StCompChartItemImg>
                )}
                <StCompChartItemTextMobile
                    white={white}
                    colorDecor={colorDecor}
                >
                    {title && (
                        <StCompChartItemTitleMobile
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    )}
                    {text && (
                        <StCompChartItemText
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    )}
                </StCompChartItemTextMobile>
            </StCompChartItem>
        </CustomLink>
    );
}
