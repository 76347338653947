import React from 'react';
import jsonp from 'jsonp';
import PropTypes from 'prop-types';

class SignInUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async handleSubmit(evt) {
        evt.preventDefault();
        const { fields, action, onSignUp } = this.props;
        var jsonData = {};
        var jsonFields = {};
        fields.forEach((field) => {
            jsonFields[field.name] = this.state[field.name];
        });
        jsonData['data'] = jsonFields;

        var postBody = JSON.stringify(jsonData);
        const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
        /*    const email = this.state['email'];
    !regex.test(email)
      ? this.setState({ status: 'empty' })
      : await this.sendData('', postBody, onSignUp, action);*/
        await this.sendData('', postBody, onSignUp, action);
    }

    async sendData(url, body, onSignUp, action) {
        this.setState({ status: 'sending' });
        action(body);
        /*    await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: body,
    })
    .then(response => {
      if (!response.ok) {
        if (response.status === 406) {
          throw new Error(
            'Looks like you might be a robot, please try filling the form out manually'
          );
        }
        throw new Error(
          "Something went wrong with submitting this form. Please try again and if you still can't get in touch online please call us"
        );
      }
    })
    .then(() => {
      this.setState({ status: 'success' });
      if (onSignUp) {
        onSignUp();
      }        
    })
    .catch(error => {
      console.log("Subscribe Error", error);
      this.setState({ status: 'error' });
    });*/
    }

    render() {
        const {
            fields,
            email,
            styles,
            className,
            buttonClassName,
            action,
            confirmButtonText,
        } = this.props;
        const messages = {
            ...SignInUp.defaultProps.messages,
            ...this.props.messages,
        };
        const { status } = this.state;

        return (
            <form onSubmit={this.handleSubmit.bind(this)} className={className}>
                <p hidden>
                    <label>
                        Don’t fill this out:
                        <input
                            name="bot-field"
                            onChange={({ target }) => {
                                this.setState({
                                    [bot - field]: target.id,
                                });
                            }}
                        />
                    </label>
                </p>
                <div className="signinup-wrapper">
                    {fields.map((input, index) => (
                        <div
                            key={input.id || index}
                            className={`field-${input.type}`}
                        >
                            {input.label &&
                                input.type !== 'radio' &&
                                input.type !== 'checkbox' &&
                                input.type !== 'link' && (
                                    <label htmlFor={input.id}>
                                        {input.label}
                                    </label>
                                )}
                            {input.type === 'link' && (
                                <a onClick={input.onClick}>{input.label}</a>
                            )}
                            {input.type !== 'label' && input.type !== 'link' && (
                                <input
                                    {...input}
                                    onChange={({ target }) => {
                                        if (
                                            input.type !== 'radio' &&
                                            input.type !== 'checkbox'
                                        ) {
                                            this.setState({
                                                [input.name]: target.value,
                                            });
                                        } else {
                                            this.setState({
                                                [input.name]: target.id,
                                            });
                                        }
                                    }}
                                    defaultValue={this.state[input.name]}
                                />
                            )}
                            {input.label &&
                                (input.type === 'radio' ||
                                    input.type === 'checkbox') && (
                                    <label htmlFor={input.id}>
                                        {input.label}
                                    </label>
                                )}
                        </div>
                    ))}
                </div>
                <button type="submit" className={buttonClassName}>
                    {confirmButtonText}
                </button>
                <div style={styles.sendingMsg} className="msg-alert">
                    {status === 'sending' && <p>{messages.sending}</p>}
                    {status === 'success' && <p>{messages.success}</p>}
                    {status === 'duplicate' && <p>{messages.duplicate}</p>}
                    {status === 'empty' && <p>{messages.empty}</p>}
                    {status === 'error' && <p>{messages.error}</p>}
                </div>
            </form>
        );
    }
}

SignInUp.defaultProps = {
    messages: {
        sending: 'Sending...',
        success: 'Thank you for subscribing!',
        error: 'An unexpected internal error has occurred.',
        empty: 'Please enter an e-mail.',
        duplicate: 'Looks like you are already subscribed',
        button: 'Submit',
    },
    buttonClassName: '',
    styles: {
        sendingMsg: {
            backgroundColor: '#0652DD',
        },
        successMsg: {
            backgroundColor: '#009432',
        },
        duplicateMsg: {
            backgroundColor: '#EE5A24',
        },
        errorMsg: {
            backgroundColor: '#ED4C67',
        },
    },
};

SignInUp.propTypes = {
    action: PropTypes.string,
    messages: PropTypes.object,
    email: PropTypes.string,
    fields: PropTypes.array,
    styles: PropTypes.object,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
};

export default SignInUp;
