import React from 'react';
import styled from 'styled-components';

const MdBreakPoint = '768px';

const TitleWrapper = styled.h2`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    @media (min-width: ${MdBreakPoint}) {
        margin-top: 40px;
        font-style: italic;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
    }
`;

const Subtitle = ({ children }) => {
    return <TitleWrapper>{children}</TitleWrapper>;
};

export default Subtitle;
