import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore } = SharedStyles;
import CustomLink from './CustomLink';

const StProductGrid = styled.div`
    padding-top: 20px;
    ${props =>
        props.rowRevers &&
        css`
            .StProductGridRow {
                &:nth-child(even) {
                    @media (min-width: ${settings.bp.small.view}) {
                        flex-direction: row;
                    }
                }
                &:nth-child(odd) {
                    @media (min-width: ${settings.bp.small.view}) {
                        flex-direction: row-reverse;
                    }
                }
            }
        `};
`;

const StProductGridRow = styled.section.attrs(props => ({
    className: 'StProductGridRow',
}))`
    margin: auto;
    padding-bottom: 15px;
    @media (min-width: ${settings.bp.small.view}) {
        width: calc(80% - 50px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: calc(80% - 100px);
    }

    &:last-child {
        margin-bottom: 0 !important;
    }

    &:nth-child(even) {
        @media (min-width: ${settings.bp.small.view}) {
            flex-direction: row-reverse;

            .StProductGridImg {
                padding-left: 0;
            }
        }
    }

    &:nth-child(odd) {
        @media (min-width: ${settings.bp.small.view}) {
            .StProductGridImg {
                padding-right: 0;
            }
        }
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.medium.view}) {
            color: ${settings.color.gray};
            line-height: 1.79;
            margin: 0 0 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 18px;
            letter-spacing: 0.08em;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    ${props =>
        props.split5050 &&
        css`
            .StProductGridImg {
                width: 50%;
            }

            .StProductGridText {
                width: 50%;

                .StProductGridParagraphText {
                    font-size: 16px;
                }
            }
        `};
`;

const StProductGridImg = styled.div.attrs(props => ({
    className: 'StProductGridImg',
}))`
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;

    img {
        width: 60%;
    }

    ${props =>
        props.split5050 &&
        css`
            width: 50%;
        `};

    &:hover {
        .StProductGridInternalMainImg {
            display: none;
        }

        .StProductGridInternalOverlayImg {
            display: block;
        }
    }
`;

const StProductGridInternalImg = styled.img.attrs(props => ({
    className: 'StProductGridInternalImg',
}))`
    width: 60%;
`;

const StProductGridInternalMainImg = styled.img.attrs(props => ({
    className: 'StProductGridInternalMainImg',
}))`
    width: 60%;
`;

const StProductGridInternalOverlayImg = styled.img.attrs(props => ({
    className: 'StProductGridInternalOverlayImg',
}))`
    width: 60%;
    display: none;
`;

const StProductGridText = styled.div.attrs(props => ({
    className: 'StProductGridText',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 60%;

    *:last-child {
        margin-bottom: 0;
    }

    p {
        white-space: pre-line;

        ul {
            padding-inline-start: 20px;
        }

        li {
            text-align: left;
        }
    }

    @media (max-width: ${settings.bp.small.view}) {
        margin: auto;
        padding: 20px 20px 70px;
    }

    ${props =>
        props.color &&
        css`
            h2 {
                color: ${props.color} !important;
            }
        `};
    ${props =>
        props.itemColor &&
        css`
            h2 span {
                background-color: ${props.itemColor} !important;
            }
        `};
    ${props =>
        props.leftJustify &&
        css`
            justify-content: left;
            align-items: start;
            text-align: left;
        `};
`;

const StProductGridParagraphText = styled.div.attrs(props => ({
    className: 'StProductGridParagraphText',
}))`
    white-space: pre-line;
    line-height: 1.5;
    margin: 0 0 16px;

    ul {
        padding-inline-start: 20px;
    }

    li {
        text-align: left;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        font-size: 18px;
    }
`;

const StProductGridHighlightText = styled.div.attrs(props => ({
    className: 'StProductGridHighlightText',
}))`
    ${props =>
        props.fontFamily &&
        css`
            font-family: ${props.fontFamily};
        `};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 60%;
`;

function ProductGridItem(props) {
    const {
        title,
        text,
        image,
        overlay,
        color,
        split5050,
        leftJustify,
        link,
        linkText,
        itemColor,
        highlightText,
        inversionColor,
        buttonImage,
        fontFamily,
        invertTextColor,
    } = props;

    return (
        <StProductGridRow split5050={split5050}>
            {image && overlay && (
                <StProductGridImg>
                    <StProductGridInternalMainImg src={image} />
                    <StProductGridInternalOverlayImg src={overlay} />
                </StProductGridImg>
            )}
            {image && !overlay && (
                <StProductGridImg>
                    <StProductGridInternalImg src={image} />
                </StProductGridImg>
            )}

            <StProductGridText
                color={color}
                itemColor={itemColor}
                leftJustify={leftJustify}
            >
                {highlightText && (
                    <StProductGridHighlightText
                        fontFamily={fontFamily}
                        dangerouslySetInnerHTML={{ __html: highlightText }}
                    ></StProductGridHighlightText>
                )}
                {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
                {text && (
                    <StProductGridParagraphText
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                )}
                {link && (
                    <StLinkMore
                        color={itemColor}
                        invertTextColor={invertTextColor}
                        inversionColor={inversionColor}
                    >
                        <CustomLink
                            href={link}
                            style={{
                                backgroundImage: buttonImage
                                    ? `url(${buttonImage})`
                                    : 'none',
                            }}
                        >
                            {linkText}
                        </CustomLink>
                    </StLinkMore>
                )}
            </StProductGridText>
        </StProductGridRow>
    );
}

export default function GenericProductList(props) {
    const { items, ...restProps } = props;
    return (
        <StProductGrid {...restProps}>
            {items.map((item, index) => (
                <ProductGridItem
                    key={item.id || index}
                    {...item}
                    {...restProps}
                />
            ))}
        </StProductGrid>
    );
}
