import React from 'react'
import styled, { css } from 'styled-components'
import settings from '../../../kenra-storybook/global/settings'
import {
    SliderBestSellerItem,
} from '../../../kenra-storybook/index'
import { useIntl } from 'gatsby-plugin-react-intl'

import { getProductUrl } from 'helpers/url'
import get from 'lodash.get'

const StRow = styled.div`
    flex: 1; 
    display: flex;
    flex-direction: column;
    ${props =>
        props.mobileHorizontal &&
        css`
        flex-direction: row;
        `};
    text-align: center;
    align-self: center;
    @media (min-width: ${settings.bp.midSmall.view}) {
        padding-top: 10px;
        justify-content: center;
        flex-direction: row;
    }

`

export const RowItemWrapper = styled.div`
    width: auto;
    max-width: 318px;
    margin: 4px;
    @media (max-width: ${settings.bp.midSmall.view}) {
        flex-shrink: 1 !important;
        max-width: 100%;
        margin: 0px;
    }
`

const StStarsContainer = styled.div`
    flex: 1; 
    display: flex;
    text-align: center;
    margin-bottom: -16px;
    max-width: 100px;
    align-self: center;
    @media (min-width: ${settings.bp.midSmall.view}) {
        padding-top: 10px;
        max-width: 150px;
    }
`

const StarsImage = (url) => {
    return <StStarsContainer>
        <img style={{ maxWidth: '100%' }} src={url} />
    </StStarsContainer>
}


const ProductsRow = ({
    title,
    subtitle,
    products,
    shopifyProducts,
    allGoogleSheetProductsRow,
    magicMousse,
    hideDots,
    removeWidth,
    alignCenter,
    isPro,
    mobileHorizontal,
}) => {
    let intl = useIntl()
    return (
        <StRow mobileHorizontal={mobileHorizontal}>
            {products.map((product, index) => {
                let handle = product?.shopifyProduct?.handle
                let shopifyProduct = shopifyProducts?.find(
                    p => p.handle === handle
                )
                if (!product || !shopifyProduct) return null

                let title = product.title
                let description = product.description
                let url = getProductUrl(handle)
                let homepageImage = get(
                    product,
                    'homepageImage.localFile.publicURL'
                )
                let homepageHoverImage = get(
                    product,
                    'homepageHoverImage.localFile.publicURL'
                )
                let gifOverlayImage = get(
                    product,
                    'gifOverlayImage.localFile.publicURL'
                )

                let productImage = get(
                    product,
                    'productItem.images[0].localFile.publicURL'
                )

                let storedRegion = ''
                let skus = []
                let productItem = get(product, 'productItem')

                if (typeof window !== 'undefined') {
                    storedRegion = localStorage.getItem('preferred-region')
                }



                for (
                    let shopifyLoop = 0;
                    shopifyLoop < shopifyProduct.variants.length;
                    shopifyLoop++
                ) {
                    if (
                        typeof productItem.variants[shopifyLoop] ===
                        'undefined'
                    ) {
                        console.log(
                            'Product Item does not have matching variant for: ',
                            shopifyProduct.variants[shopifyLoop]
                        )
                        skus.push(shopifyProduct.variants[shopifyLoop].sku)
                        continue
                    }

                    // Does the product exist for the caAssortment?
                    if (storedRegion !== 'CA') {
                        skus.push(shopifyProduct.variants[shopifyLoop].sku)
                    } else {
                        allGoogleSheetProductsRow.nodes.forEach(
                            productInfo => {
                                let shopifySku = parseInt(
                                    shopifyProduct.variants[shopifyLoop].sku
                                )
                                if (
                                    shopifySku === productInfo.sku &&
                                    productInfo.caAssortment === 'Y'
                                ) {
                                    let productItemIDH = productInfo.canIdh
                                    if (
                                        productItemIDH !== null &&
                                        typeof productItemIDH !==
                                        'undefined' &&
                                        !skus.includes(
                                            productItemIDH.toString()
                                        )
                                    ) {
                                        skus.push(
                                            productItemIDH.toString()
                                        )
                                    } else if (
                                        !skus.includes(
                                            shopifyProduct.variants[
                                                shopifyLoop
                                            ].sku
                                        )
                                    ) {
                                        skus.push(
                                            shopifyProduct.variants[
                                                shopifyLoop
                                            ].sku
                                        )
                                    }
                                }
                            }
                        )
                    }
                }
                return (
                    <RowItemWrapper
                        key={`${product.id}_${index}`}
                    >
                        <SliderBestSellerItem
                            title={title}
                            description={magicMousse ? null : description}
                            url={url}
                            productImage={productImage}
                            gifOverlayImage={homepageHoverImage}
                            homepageImage={homepageImage}
                            productId={skus[0]}
                            shopButtonStyle={magicMousse ? '3' : null}
                            starsImage={product.reviewStarsImage && StarsImage(product.reviewStarsImage)}
                            removeWidth={true}
                            externalShopNow={true}
                            isPro={isPro}
                            autoRotate={true}
                        />
                    </RowItemWrapper>
                )
            })}
        </StRow>
    )
}

export default ProductsRow
