import React from 'react';
import styled from 'styled-components';
import Container from './Container';
import Title from './atoms/Title';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 20px;
        margin-top: 40px;
    }
`;

const Cell = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    @media (min-width: 768px) {
        &:last-child {
            grid-column: span 2;
        }
    }
`;

const Icon = styled.img`
    width: 180px;
    // height: 180px;
    border-radius: 100%;
    @media (min-width: 1500px) {
        width: 14vw;
        // height: 5vw;
    }
    transition: 0.5s all;
    &:hover {
        transform: scale(1.2);
    }
`;

const ColTitle = styled.h4`
    margin-top: 16px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    @media (min-width: 768px) {
        font-size: 22px;
        line-height: 26px;
    }
`;

const Contents = styled.p`
    max-width: 378px;
    text-align: center;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    @media (min-width: 768px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

const WhatWeStandFor = ({ icons, whatWeStandForTitle }) => {
    return (
        <Container>
            <Title>{whatWeStandForTitle}</Title>

            <Wrapper>
                {icons.fragments.map((step, index) => {
                    let returnData = {};
                    step.forEach((fragmentData) => {
                        switch (fragmentData.key) {
                            case 'Icon':
                                returnData['icon'] = fragmentData.value;
                                break;
                            case 'Title':
                                returnData['title'] = fragmentData.value;
                                break;
                            case 'Content':
                                returnData['content'] = fragmentData.value;
                                break;
                            case 'Url':
                                returnData['url'] = fragmentData.value;
                                break;
                            default:
                                break;
                        }
                    });

                    return (
                        <Cell key={`icon-` + index}>
                            {returnData['url'] && (
                                <a href={returnData['url']}>
                                    <Icon
                                        src={returnData['icon']}
                                        alt="What we stand for icon"
                                    />
                                </a>
                            )}
                            {!returnData['url'] && (
                                <Icon
                                    src={returnData['icon']}
                                    alt="What we stand for icon"
                                />
                            )}
                            <ColTitle>{returnData['title']}</ColTitle>
                            <Contents>{returnData['content']}</Contents>
                        </Cell>
                    );
                })}
            </Wrapper>
        </Container>
    );
};

export default WhatWeStandFor;
