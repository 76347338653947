import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';

const StFieldset = styled.fieldset`
    border: none;
    overflow: hidden;
    clear: both;
    margin: 0;
    padding: 0;
    width: 100%;
    padding-left: 6px;
    padding-right: 6px;

    .spacing,
    p {
        margin: 40px 0;
    }

    p {
        font-size: 17px;
        letter-spacing: 0.04em;
    }
`;

const StLegend = styled.div`
    display: flex;
    legend {
        display: block;
        margin: 0 0 4px;
        padding: 0;
        font-weight: 500;
        line-height: 1.23;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 16px;
        }
    }

    .spacing,
    p {
        margin: 40px 0;
    }

    p {
        font-size: 17px;
        letter-spacing: 0.04em;
    }
`;

const StSubLegend = styled.div`
    line-height: 1.23;
    font-size: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-left: 5px;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 16px;
    }
`;

export default function Fieldset(props) {
    const { legend, subLegend, children, divStyle, style } = props;
    return (
        <StFieldset style={style}>
            <StLegend>
                {legend && <legend>{legend}</legend>}
                {subLegend && <StSubLegend>{subLegend}</StSubLegend>}
            </StLegend>
            {children && <div style={divStyle}>{children}</div>}
        </StFieldset>
    );
}
