import React, { useState, useEffect, useRef } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import styled, { css } from 'styled-components';

import settings from '../../global/settings';

const StSlider = styled.div``;

const StSliderGallery = styled.div.attrs((props) => ({
    id: 'StSliderGallery',
}))`
    margin-bottom: 40px;

    img {
        width: 100%;
        height: auto;
    }
`;

const StSliderMainImgWrap = styled.div``;

const StSliderMainImgMobile = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }
`;

const StSliderMainImgDesktop = styled.div`
    display: none;

    @media (min-width: ${settings.bp.small.view}) {
        display: block;
    }
`;

const StSliderSubtitle = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: ${settings.color.default};
    white-space: nowrap;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
`;

const CssArrow = css`
    position: absolute;
    top: 50%;
    width: 34px;
    height: 34px;
    cursor: pointer;
    z-index: 2;
    display: none;

    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 14px;
        height: 14px;
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
    }
`;

const StSliderArrowPrev = styled.div`
    ${CssArrow};
    left: 15px;
    transform: translate(0, -50%);
`;

const StSliderArrowNext = styled.div`
    ${CssArrow};
    right: 15px;
    transform: translate(0, -50%) rotate(180deg);
`;

const StSliderThmb = styled.div.attrs((props) => ({
    id: 'StSliderThmb',
}))`
    max-width: 695px;
    margin: 0 auto;
    padding: 0 50px;
    position: relative;

    &:not(.swiper-container-custom-centered) {
        .SliderArrow-show {
            display: block;
        }
    }

    &.swiper-container-custom-centered {
        .swiper-wrapper {
            justify-content: center;
        }
    }

    .swiper-container {
        position: static;
    }

    .swiper-slide {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        // border: 1px solid transparent;
        padding: 3px;
        cursor: pointer;
    }

    .StSliderItemSelected {
        // border: 1px solid #000;

        img {
            box-shadow: 0 0 0 3px #fff;
        }
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
    }
`;

function SliderColor(props) {
    const [gallerySwiper, setGallerySwiper] = useState(null);
    const [thumbnailSwiper, setThumbnailSwiper] = useState(null);
    const elmPrevBtn = useRef();
    const elmNextBtn = useRef();
    const { items } = props;
    let { speed } = props;
    speed = speed || 600;

    const gallerySwiperParams = {
        speed,
        effect: 'fade',
        allowTouchMove: false,
    };

    const thumbnailSwiperParams = {
        speed,
        freeMode: true,
        spaceBetween: 6,
        slidesPerView: 'auto',
        allowTouchMove: false,
    };

    function handlerSetSlide(index) {
        if (!gallerySwiper || !thumbnailSwiper) return;
        index = !isNaN(index) ? index : thumbnailSwiper.clickedIndex;
        gallerySwiper.slideTo(index);

        if (index > thumbnailSwiper.snapGrid.length - 1) {
            thumbnailSwiper.slideTo(thumbnailSwiper.snapGrid.length - 1);
        } else {
            thumbnailSwiper.slideTo(index);
        }

        for (let i = 0, l = thumbnailSwiper.slides.length; i < l; i++) {
            if (index === i) {
                thumbnailSwiper.slides[i].classList.add('StSliderItemSelected');
            } else {
                thumbnailSwiper.slides[i].classList.remove(
                    'StSliderItemSelected'
                );
            }
        }

        checkPrevArrowVisible(index);
        checkNextArrowVisible(index);
    }

    function checkPrevArrowVisible(index) {
        if (!elmPrevBtn || !elmPrevBtn.current) return;
        if (index > 0) {
            elmPrevBtn.current.classList.add('SliderArrow-show');
        } else {
            elmPrevBtn.current.classList.remove('SliderArrow-show');
        }
    }

    function checkNextArrowVisible(index) {
        if (!elmNextBtn || !elmNextBtn.current) return;
        if (index < thumbnailSwiper.slides.length - 1) {
            elmNextBtn.current.classList.add('SliderArrow-show');
        } else {
            elmNextBtn.current.classList.remove('SliderArrow-show');
        }
    }

    function setCentered() {
        if (!gallerySwiper || !thumbnailSwiper) return;

        const wrapperWidth = thumbnailSwiper.width;
        const slidesWidth = thumbnailSwiper.slidesSizesGrid.reduce(
            (sum, current) =>
                sum + current + thumbnailSwiper.params.spaceBetween,
            0
        );

        if (slidesWidth < wrapperWidth) {
            thumbnailSwiper.$el[0].parentNode.classList.add(
                'swiper-container-custom-centered'
            );
        } else {
            thumbnailSwiper.$el[0].parentNode.classList.remove(
                'swiper-container-custom-centered'
            );
        }
    }

    function toNextSlide() {
        if (!gallerySwiper || !thumbnailSwiper) return;
        const newIndex = gallerySwiper.activeIndex + 1;
        const checkNewIndex =
            newIndex < gallerySwiper.slides.length - 1
                ? newIndex
                : gallerySwiper.slides.length - 1;
        handlerSetSlide(checkNewIndex);
    }

    function toPrevSlide() {
        if (!gallerySwiper || !thumbnailSwiper) return;
        const newIndex = gallerySwiper.activeIndex - 1;
        const checkNewIndex = newIndex > 0 ? newIndex : 0;
        handlerSetSlide(checkNewIndex);
    }

    useEffect(() => {
        const StSliderGallery = document.getElementById('StSliderGallery');
        const StSliderThmb = document.getElementById('StSliderThmb');
        setGallerySwiper(
            StSliderGallery.querySelector('.swiper-container').swiper
        );
        setThumbnailSwiper(
            StSliderThmb.querySelector('.swiper-container').swiper
        );
        setCentered();
        handlerSetSlide(0);
        window.addEventListener('resize', setCentered);
        return () => {
            window.removeEventListener('resize', setCentered);
        };
    }, [handlerSetSlide, setCentered]);

    return (
        <StSlider>
            <StSliderGallery>
                <Swiper {...gallerySwiperParams}>
                    {items.map((item, index) => (
                        <StSliderMainImgWrap key={item.id || index}>
                            <StSliderMainImgMobile>
                                <a href={item.href}>
                                    <img alt="" src={item.imgContent.mobile} />
                                </a>
                            </StSliderMainImgMobile>
                            <StSliderMainImgDesktop>
                                <a href={item.href}>
                                    <img alt="" src={item.imgContent.desktop} />
                                    <StSliderSubtitle>
                                        {item.subtitle}
                                    </StSliderSubtitle>
                                </a>
                            </StSliderMainImgDesktop>
                        </StSliderMainImgWrap>
                    ))}
                </Swiper>
            </StSliderGallery>
            <StSliderThmb>
                <StSliderArrowPrev onClick={toPrevSlide} ref={elmPrevBtn} />
                <Swiper {...thumbnailSwiperParams}>
                    {items.map((item, index) => (
                        <div
                            key={item.id || index}
                            onClick={(e) => handlerSetSlide()}
                        >
                            {/* <img src={item.imgThumbnail} alt="" /> */}
                        </div>
                    ))}
                </Swiper>
                <StSliderArrowNext onClick={toNextSlide} ref={elmNextBtn} />
            </StSliderThmb>
        </StSlider>
    );
}

export default SliderColor;
