import styled, { css } from 'styled-components';
import settings from '../../global/settings';

export const StSliderFavorite = styled.div`
    width: 346px !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 376px !important;
        padding: 0 0 0 30px;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export const StSliderFavoriteDesc = styled.div`
    display: flex;
    height: 100%;

    h3 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        margin: 0;
        letter-spacing: 0.06em;
    }

    p {
        margin: auto 0 0;
        font-size: 14px;
        line-height: 1.4;
        color: ${settings.color.gray4};
    }
`;

export const StSliderFavoriteImg = styled.div`
    position: relative;
    z-index: 1;
    width: 110px;
    min-width: 110px;

    img {
        width: auto;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        max-width: 80%;
    }
`;

export const StSliderFavoriteText = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    &:first-child:last-child {
        width: 100%;
        text-align: center;
    }
`;
