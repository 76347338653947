import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
import CustomLink from './CustomLink';

const StImgTextGrid = styled.div`
    ${(props) =>
        props.styleSquare &&
        css`
            .StImgTextGridRow {
                margin-bottom: 0;
            }

            .StImgTextGridImg {
                padding: 0;
            }

            .StImgTextGridImg,
            .StImgTextGridText {
                @media (min-width: ${settings.bp.small.view}) {
                    width: 50%;
                }
            }

            .StImgTextGridText {
                @media (max-width: calc(${settings.bp.small.view} - 1px)) {
                    min-height: 100vw;
                    padding: 30px;
                }

                @media (min-width: ${settings.bp.medium.view}) {
                    padding-left: 6%;
                    padding-right: 6%;
                }
            }

            .StLinkMore a {
                min-width: 180px;
                padding: 16px 30px;
            }
        `};

    ${(props) =>
        props.rowRevers &&
        css`
            .StImgTextGridRow {
                &:nth-child(even) {
                    @media (min-width: ${settings.bp.small.view}) {
                        flex-direction: row;
                    }
                }
                &:nth-child(odd) {
                    @media (min-width: ${settings.bp.small.view}) {
                        flex-direction: row-reverse;
                    }
                }
            }
        `};
`;

const StImgTextGridRow = styled.section.attrs((props) => ({
    className: 'StImgTextGridRow',
}))`
    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 70px;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }

    &:nth-child(even) {
        @media (min-width: ${settings.bp.small.view}) {
            flex-direction: row-reverse;

            .StImgTextGridImg {
                padding-left: 0;
            }
        }
    }

    &:nth-child(odd) {
        @media (min-width: ${settings.bp.small.view}) {
            .StImgTextGridImg {
                padding-right: 0;
            }
        }
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 28px;
            color: ${settings.color.gray};
            line-height: 1.79;
            margin: 0 0 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 20px;
            letter-spacing: 0.08em;
        }
    }

    .StLinkMore {
        margin: 26px 0 0;

        @media (min-width: ${settings.bp.medium.view}) {
            margin: 46px 0 0;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    ${(props) =>
        props.styleWhiteColor &&
        css`
            color: #fff;

            h2,
            p {
                color: #fff;
            }

            .StLinkMore a {
                color: #000000;
                background-color: #fff;
            }
        `};
`;

const StImgTextGridImg = styled.div.attrs((props) => ({
    className: 'StImgTextGridImg',
}))`
  padding: 20px;
  margin: auto;

  ${(props) =>
      props.imgMaxWidth &&
      css`
          max-width: ${props.imgMaxWidth};
      `};

  @media (min-width: ${settings.bp.small.view}) {
    padding: 30px;
    width: calc(50% - 30px);
    ${(props) =>
        props.singleRow &&
        css`
            width: 60%;
        `};
    }
  }

  @media (min-width: ${settings.bp.medium.view}) {
    padding: 0 60px;
    width: calc(50% - 60px);
    ${(props) =>
        props.singleRow &&
        css`
            width: 60%;
        `};
    }
  
  img {
    width: 100%;
  }
`;

const StImgTextGridText = styled.div.attrs((props) => ({
    className: 'StImgTextGridText',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 70px;
  text-align: center;

  @media (min-width: ${settings.bp.small.view}) {
    width: calc(50% + 30px);
    padding: 30px 6%;
    ${(props) =>
        props.singleRow &&
        css`
            width: 40%;
        `};
    }
  }

  @media (min-width: ${settings.bp.medium.view}) {
    width: calc(50% + 60px);
    padding: 30px 11%;
    ${(props) =>
        props.singleRow &&
        css`
            width: 40%;
            padding: 30px 60px;
        `};
    }
  }

  *:last-child {
    margin-bottom: 0;
  }

  img {
      height: 35px;
  }
  ${(props) =>
      props.color &&
      css`
          h2 {
              color: ${props.color} !important;
          }
      `};
`;

function ImageTextGridItem(props) {
    const {
        rowBg,
        rowColorWhite,
        title,
        text,
        image,
        textImage,
        link,
        url,
        imgMaxWidth,
        color,
        singleRow,
    } = props;
    return (
        <StImgTextGridRow
            style={{ backgroundColor: rowBg || 'transparent' }}
            styleWhiteColor={rowColorWhite}
        >
            {image && (
                <StImgTextGridImg
                    imgMaxWidth={imgMaxWidth}
                    singleRow={singleRow}
                >
                    <img src={image} alt="" />
                </StImgTextGridImg>
            )}
            <StImgTextGridText color={color}>
                {title && <h2>{title}</h2>}
                {textImage && (
                    <p>
                        <img src={textImage} alt="" />
                    </p>
                )}
                {text && <p> {text} </p>}
            </StImgTextGridText>
            {link && <CustomLink href={url}>{link}</CustomLink>}
        </StImgTextGridRow>
    );
}

export default function ImageTextGridImage(props) {
    const { bgImg, items, ...restProps } = props;
    return (
        <StImgTextGrid
            style={{ backgroundImage: bgImg ? `url(${bgImg})` : 'none' }}
            {...restProps}
        >
            {items.map((item, index) => (
                <ImageTextGridItem
                    key={item.id || index}
                    {...item}
                    {...restProps}
                />
            ))}
        </StImgTextGrid>
    );
}

/*
        {text && <p dangerouslySetInnerHTML={{ __html: text }} />}

*/
