import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';
import DDButton from './DDButton';

const StPageControl = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default function PageTopicsControl(props) {
    return (
        <StPageControl>
            <DDButton {...props} />
        </StPageControl>
    );
}
