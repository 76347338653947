import React from 'react';
import styled, { css } from 'styled-components';
import settings from '../global/settings';

const StEmbeddedForm = styled.section.attrs((props) => ({
    className: 'StEmbeddedForm',
}))`
    iframe {
        height: 1400px;
        width: 100%;

        @media (min-width: ${settings.bp.midSmall.view}) {
            height: 1900px;
        }
    }
`;

export default function EmbeddedForm(props) {
    const { src, ...restProps } = props;
    return (
        <StEmbeddedForm>
            <iframe src={src} frameborder="0" scrolling="no" />
        </StEmbeddedForm>
    );
}
