import styled, { css } from 'styled-components';
import settings from '../../global/settings';

export const StSliderTools = styled.div`
    width: 184px !important;
    height: auto !important;
    padding: 0 0 0 20px;
    text-align: center;
    display: flex;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        width: 376px !important;
        padding: 0 0 0 30px;
    }

    img {
        width: 100%;
        height: auto;
        margin-bottom: 26px;
    }

    h3 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        margin: 0 0 10px;
        letter-spacing: 0.06em;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 16px;
            line-height: 1.25;
            margin: 0 0 30px;
        }
    }

    .StLink {
        margin-top: auto;
    }
`;
