import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';
import CustomLink from './CustomLink';

const StCompChartItem = styled.div`
  text-align: center;
  margin: 0 11px;
  width: 100%;
  height: 100%;

  @media (min-width: ${settings.bp.small.view}) {
    margin: 0 4px;
    padding: 28px;
    min-width: 192px;
    max-width: 230px;
    border: 2px solid transparent;

    &: hover {
      border: 2px solid #000;
  }
`;

const StCompChartItemTitle = styled.div`
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 1.857px;
    margin-bottom: 20px;

    br {
        display: none;
    }

    @media (min-width: ${settings.bp.small.view}) {
        text-transform: uppercase;
        margin-bottom: 20px;
        min-height: 54px;
        height: 54px;
    }
`;

const StCompChartItemImg = styled.div`
    margin-bottom: 30px;
    img {
        max-height: 140px;
    }
    @media (min-width: ${settings.bp.small.view}) {
        img {
            max-height: 210px;
        }
    }
`;

const StCompChartItemText = styled.div`
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;

    strong {
        font-weight: bold;
        display: block;
        margin-bottom: 6px;
    }

    p {
        margin: 0 0 20px;

        @media (min-width: ${settings.bp.small.view}) {
            margin: 0 0 30px;
        }

        &:last-child {
            margin-bottom: 0;
        }

    }

    .class-feature {
        display: none;
        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }
    }
`;

export default function CompChartItem(props) {
    const { colorDecor, title, img, text, url, white } = props;
    return (
        <CustomLink href={url}>
            <StCompChartItem colorDecor={colorDecor}>
                {title && (
                    <StCompChartItemTitle
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                )}
                {img && (
                    <StCompChartItemImg>
                        <img src={img} alt={title} />
                    </StCompChartItemImg>
                )}
                {text && (
                    <StCompChartItemText
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                )}
            </StCompChartItem>
        </CustomLink>
    );
}
