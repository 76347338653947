import React from 'react';

import styled, { css } from 'styled-components';
import settings from '../global/settings';
import VideoLightBox from './VideoLightBox';

const StVideoRow = styled.div.attrs((props) => ({
    className: 'StVideoRow',
}))`
    max-width: ${settings.maxBlockWidth};
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
`;

const StVideoRowWrap = styled.div.attrs((props) => ({
    className: 'StVideoRowWrap',
}))`
    ${(props) =>
        props.center &&
        css`
            justify-content: center;
        `};
    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }
`;

const StVideoRowItem = styled.div.attrs((props) => ({
    className: 'StVideoRowItem',
}))`
    box-shadow: 2px -2px 35px 0 rgba(123, 123, 123, 0.05);
    background-color: #fff;
    margin: 0 0 24px;
    display: flex;
    text-align: left;
    min-height: 130px;

    @media (min-width: ${settings.bp.medium.view}) {
        width: calc(50% - 30px);
        margin: 0 15px 30px;

        ${(props) =>
            props.fullWidth &&
            css`
                width: 80%;
            `};
    }
`;

const StVideoRowImg = styled.div.attrs((props) => ({
    className: 'StVideoRowImg',
}))`
    width: 52%;
    padding-bottom: 35%;
    height: 0;
    position: relative;

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            padding-bottom: 60%;
        `};
`;

const StVideoRowDesc = styled.div`
    width: 48%;
    padding: 20px;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.04em;
    font-weight: normal;

    h3 {
        margin: 0 0 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.29;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }

    p {
        margin: 0 0 16px;
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

function VideoRowItem(props) {
    const { title, text, fullWidth, ...restProps } = props;
    let showText = text || title;

    return (
        <>
            {showText && (
                <StVideoRowItem>
                    <StVideoRowImg>
                        <VideoLightBox {...restProps} />
                    </StVideoRowImg>
                    <StVideoRowDesc>
                        {title && <h3>{title}</h3>}
                        {text && <p>{text}</p>}
                    </StVideoRowDesc>
                </StVideoRowItem>
            )}
            {!showText && (
                <StVideoRowItem fullWidth={fullWidth}>
                    <StVideoRowImg fullWidth={true}>
                        <VideoLightBox {...restProps} />
                    </StVideoRowImg>
                </StVideoRowItem>
            )}
        </>
    );
}

export default function VideoRow(props) {
    const { items, center, ...restProps } = props;
    return (
        <StVideoRow>
            <StVideoRowWrap center={center}>
                {items.map((item, index) => (
                    <VideoRowItem
                        key={item.id || index}
                        {...item}
                        {...restProps}
                    />
                ))}
            </StVideoRowWrap>
        </StVideoRow>
    );
}
