import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import Spacing from './Spacing';
import * as SharedStyles from '../global/SharedStyles';
const { StSectionInfo } = SharedStyles;

const StBlockOne = styled.div.attrs((props) => ({
    className: 'StBlockOne',
}))`
    display: block;
    width: 100%;
    @media (min-width: ${settings.bp.small.view}) {
        width: 50%;
        padding: 0px 30px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 0px 50px;
    }

    h2 {
        text-align: left;
    }
`;

const StBlockTwo = styled.div.attrs((props) => ({
    className: 'StBlockTwo',
}))`
    width: 100%;
    text-align: left;
    margin: auto;
    padding: 20px;
    @media (min-width: ${settings.bp.small.view}) {
        width: 50%;
    }

    StSpacing {
        padding-top: 10px;
    }
`;

const StInnerBlockOne = styled.div.attrs((props) => ({
    className: 'StInnerBlockOne',
}))`
    display: flex;
    justify-content: center;
    ${(props) =>
        props.highlightImage &&
        css`
            margin-left: 20px;
        `};
`;

const StInnerBlockTwo = styled.div.attrs((props) => ({
    className: 'StInnerBlockTwo',
}))`
    width: 100%;
    text-align: left;
    margin: auto;
    padding: 0px;

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 20px;
    }
`;
const StSeparator = styled.div.attrs((props) => ({
    className: 'StSeparator',
}))`
    width: 100%;
    border-bottom: 1px solid #c2c2c2;
    margin: 20px auto;

    @media (max-width: ${settings.bp.small.view}) {
        display: none;
    }
`;

const StBenefits = styled.div.attrs((props) => ({
    className: 'StBenefits',
}))`
    text-align: left;
    p {
        margin: 0px;
    }
`;

const StOptionData = styled.div.attrs((props) => ({
    className: 'StOptionData',
}))`
    display: block;

    img {
        width: 100%;
        max-width: 108px;
        ${(props) =>
            props.largeImage &&
            css`
                max-width: 326px;
            `};
        ${(props) =>
            props.highlightImage &&
            css`
                max-width: none;
                max-height: 270px;
            `};
        ${(props) =>
            props.isActive &&
            css`
                border: 1px solid #000;
            `};
        margin-bottom: 20px;
    }

    p {
        font-weight: bolder;
    }

    &:not(:first-child) {
        margin: 0px 0px 0px 30px;
        ${(props) =>
            props.highlightImage &&
            css`
                margin: 0px;
            `};
    }

    @media (max-width: ${settings.bp.small.view}) {
        &:not(:first-child) {
            margin: 0px 0px 0px 20px;
            ${(props) =>
                props.highlightImage &&
                css`
                    margin: 0px;
                `};
        }
    }
`;

const StTabOptionContainer = styled.div.attrs((props) => ({
    className: 'StTabOptionContainer',
}))`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 15px;
    width: ${settings.bp.extraSmall.width};

    @media (min-width: ${settings.bp.small.view}) {
        width: ${settings.bp.small.width};
        padding: 0px 10px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: ${settings.bp.medium.width};

        ${(props) =>
            props.useMediumWidth &&
            css`
                max-width: ${settings.bp.medium.width};
            `};

        ${(props) =>
            props.useLargeWidth &&
            css`
                max-width: 1070px;
            `};
    }

    @media (min-width: ${settings.bp.large.view}) {
        width: ${settings.bp.large.width};
    }

    ${(props) =>
        props.mobileWide &&
        css`
            @media (max-width: calc(${settings.bp.large.view} - 1px)) {
                width: 100%;
                max-width: 100%;
            }
        `};

    &:after {
        content: '';
        clear: both;
        display: table;
    }
`;

function TabOptionDataItem(props) {
    const { image, text, largeImage, highlightImage, isActive, ...restProps } =
        props;
    return (
        <StOptionData
            largeImage={largeImage}
            highlightImage={highlightImage}
            isActive={isActive}
        >
            {image && <img src={image} />}
            {text && <p>{text}</p>}
        </StOptionData>
    );
}

export default function TabOptionData(props) {
    const {
        isMobile,
        bkgndColor,
        benefitsTitle,
        item,
        highlightImage,
        activeImage,
        ...restProps
    } = props;
    return (
        <StTabOptionContainer>
            <StSectionInfo
                style={{
                    backgroundColor: bkgndColor,
                }}
            >
                {item && (
                    <Spacing flexSpacing>
                        <StBlockOne isMobile={isMobile}>
                            <StInnerBlockOne highlightImage={highlightImage}>
                                {item.imageData &&
                                    item.imageData.map((data, index) => (
                                        <TabOptionDataItem
                                            key={index}
                                            {...data}
                                            {...restProps}
                                            highlightImage={highlightImage}
                                            isActive={activeImage === index}
                                        />
                                    ))}
                            </StInnerBlockOne>
                            {!isMobile && item.benefits && (
                                <StInnerBlockTwo>
                                    <StSeparator />
                                    <h2>{benefitsTitle}</h2>
                                    <StBenefits>{item.benefits}</StBenefits>
                                </StInnerBlockTwo>
                            )}
                        </StBlockOne>
                        <StBlockTwo>
                            {item.text}
                            {isMobile && item.benefits && (
                                <>
                                    <Spacing
                                        removeSpaceTopMobile
                                        removeSpaceBottomMobile
                                    />
                                    <h2>{benefitsTitle}</h2>
                                    <StBenefits>{item.benefits}</StBenefits>
                                </>
                            )}
                        </StBlockTwo>
                    </Spacing>
                )}
            </StSectionInfo>
        </StTabOptionContainer>
    );
}
