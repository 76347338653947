import React from 'react';

import styled from 'styled-components';

import settings from '../global/settings';
import Container from './Container';
import VideoRow from './VideoRow';
import * as SharedStyles from '../global/SharedStyles';
const { StTitle, StLink } = SharedStyles;
import CustomLink from './CustomLink';

const StStyleBlock = styled.section`
    @media (min-width: ${settings.bp.medium.view}) {
        position: relative;
        z-index: 10;
        margin-top: -70px;
    }

    .container {
        max-width: 670px;
        padding: 20px 0;

        @media (min-width: ${settings.bp.medium.view}) {
            padding: 42px 92px 72px;
            max-width: 1070px;
        }

        @media (min-width: ${settings.bp.medium.view}) {
            border-bottom: 1px solid ${settings.color.gray1};
            background-color: #fff;
            box-shadow: 0 2px 51px 0 rgba(171, 171, 171, 0.06);
        }
    }

    h2 {
        line-height: 1.67;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0.07em;
        margin: 0 0 20px;
    }
`;

const StStyleBlockTop = styled.div`
    margin-bottom: 50px;
    text-align: center;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 70px;
    }

    h1 {
        margin-bottom: 0;
    }
`;

const StStyleBlockBottom = styled.div`
    .StLink {
        text-align: center;
        margin-top: 40px;
    }

    .StVideoRow {
        margin-bottom: 0;
    }
`;

export default function StyleBlock(props) {
    const {
        title,
        subTitle,
        linkText,
        linkHref,
        linkMobileText,
        linkMobileMore,
        items,
    } = props;
    return (
        <StStyleBlock>
            <Container>
                <StStyleBlockTop>
                    {title && (
                        <StTitle>
                            <h1>{title}</h1>
                        </StTitle>
                    )}
                    {subTitle && <h2>{title}</h2>}
                    {linkText && linkHref && (
                        <StLink>
                            <CustomLink href={linkHref}>{linkText}</CustomLink>
                        </StLink>
                    )}
                </StStyleBlockTop>
                <StStyleBlockBottom>
                    {items && <VideoRow items={items} />}
                    {linkMobileText && linkMobileMore && (
                        <StLink className="only-mobile">
                            <CustomLink href={linkMobileMore}>
                                {linkMobileText}
                            </CustomLink>
                        </StLink>
                    )}
                </StStyleBlockBottom>
            </Container>
        </StStyleBlock>
    );
}
