import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import settings from '../global/settings';

import Accordion from './Accordion';
import ScrollableBlock from './ScrollableBlock';
import ScrollTo from './ScrollTo';

const StTabsSide = styled.div``;

const StTabsWrap = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
    }
`;

const StTabsAside = styled.aside`
    white-space: nowrap;
    margin: 0 -20px 20px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 290px;
        min-width: 290px;
        padding-right: 5%;
        white-space: normal;
        margin: 0;
    }
`;

const StTabsAsideItem = styled.div`
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    padding: 10px;
    margin: 0 10px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    position: relative;

    &:before {
        content: '';
        border-bottom: 2px solid ${settings.color.default};
        left: 0;
        bottom: 0;
        width: 0;
        position: absolute;
        transition: width 0.3s;
    }

    &:hover:before {
        width: 100%;
    }

    ${(props) =>
        props.isActive &&
        css`
            &:before {
                width: 100%;
            }
        `};

    @media (min-width: ${settings.bp.small.view}) {
        display: block;
        font-size: 18px;
        letter-spacing: 0.1em;
        margin: 0 0 26px;
        padding: 0;

        &:before {
            display: none !important;
        }
    }
`;

const StTabsContent = styled.div`
    width: 100%;

    h2 {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin: 0 0 10px;
        display: none;

        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }
    }

    .StAccordionTitle {
        text-transform: none;
    }

    .StAccordionText {
        padding-bottom: 30px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
    }
`;

const StTabsAccordionItem = styled.div`
    margin-bottom: 50px;

    @media (max-width: calc(${settings.bp.small.view} - 1px)) {
        display: none;

        ${(props) =>
            props.isActive &&
            css`
                display: block;
            `};
    }
`;

function TabsAsideItem(props) {
    const { title, isActive, onClick, goToId } = props;
    return (
        title && (
            <StTabsAsideItem isActive={isActive} onClick={onClick}>
                <ScrollTo toElmPosition={goToId} correction={-15}>
                    {title}
                </ScrollTo>
            </StTabsAsideItem>
        )
    );
}

function TabsContentItem(props) {
    const { isActive, title, accordionItems, id } = props;
    return (
        <StTabsAccordionItem isActive={isActive} id={id}>
            {title && <h2>{title}</h2>}
            {accordionItems && <Accordion items={accordionItems} />}
        </StTabsAccordionItem>
    );
}

export default function TabsSide(props) {
    const { items } = props;
    const [activeTab, setActiveTab] = useState(
        items.findIndex((item) => item.isActive) || 0
    );
    return (
        <StTabsSide>
            <StTabsWrap>
                <StTabsAside>
                    <ScrollableBlock onlyMobile>
                        {items.map((item, index) => (
                            <TabsAsideItem
                                key={item.id || index}
                                {...item}
                                onClick={() => setActiveTab(index)}
                                isActive={activeTab === index}
                                goToId={`TabsContentItem-${index}`}
                            />
                        ))}
                    </ScrollableBlock>
                </StTabsAside>
                <StTabsContent>
                    {items.map((item, index) => (
                        <TabsContentItem
                            key={item.id || index}
                            {...item}
                            isActive={activeTab === index}
                            id={`TabsContentItem-${index}`}
                        />
                    ))}
                </StTabsContent>
            </StTabsWrap>
        </StTabsSide>
    );
}
