import React from 'react';
import settings from '../global/settings';
import styled from 'styled-components';
//import '@google/model-viewer/dist/model-viewer-umd';

/*if (typeof window !== `undefined`) {
    const module = require('@google/model-viewer');
//    const module = require('@google/model-viewer/dist/model-viewer-legacy');
}*/

const ModelStyles = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }

    // @supports not (-webkit-touch-callout: none) {
    //   display: none;
    // }

    display: flex;
    margin-top: 20px;

    button[slot='ar-button'] {
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.29;
        text-transform: uppercase;
        text-decoration: none !important;
        border: none;
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
        color: #000;
        background-color: #fff;
        text-align: center;
        transition: opacity 0.3s;
        cursor: pointer;
        background-image: url('/images/box.png');
        background-position: left top;
        background-repeat: no-repeat;
        padding-left: 25px;
    }

    /* This keeps child nodes hidden while the element loads */
    :not(:defined) > * {
        display: none;
    }

    model-viewer {
        width: 100%;
        height: 100px;
    }
`;

const ModelPrompt = styled.div`
    display: none;
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.29;
        text-transform: uppercase;
        text-decoration: none !important;
        border: none;
        padding: 20px;
        color: #000;
        background-color: #fff;
        text-align: center;
        transition: opacity 0.3s;
        cursor: default;
        background-image: url('/images/box.png');
        background-position: left center;
        background-repeat: no-repeat;
        padding-left: 25px;
        text-align: center;
    }
`;

const ModelViewer = (props) => {
    const { model, iosModel, btnTitle, prompt } = props;
    return (
        <></>
    );
/*    return (
        <>
            <ModelStyles className="model-box">
                <model-viewer
                    src={model || './assets/Astronaut.glb'}
                    ar
                    ar-modes="quick-look scene-viewer"
                    quick-look-browsers="safari chrome"
                    ar-scale="auto"
                    camera-controls
                    alt="A 3D model"
                    ios-src={iosModel}
                >
                    <button slot="ar-button">{btnTitle}</button>
                </model-viewer>
            </ModelStyles>
            <ModelPrompt>{prompt}</ModelPrompt>
        </>
    );*/
};

export default ModelViewer;
