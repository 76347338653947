import React from 'react';

import styled from 'styled-components';

import settings from '../global/settings';
import Spacing from './Spacing';

import * as SharedStyles from '../global/SharedStyles';

const StSingleImage = styled.div.attrs((props) => ({
    className: 'StSingleImage',
}))`
  display: block;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width: ${settings.bp.small.view}) {
    flex: 1;
    flex-direction row;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  img {
    width: 30%;
  }
`;

const StSingleImageTitle = styled.div.attrs((props) => ({
    className: 'StSingleImageTitle',
}))`
    font-size: 18px;
    text-transform: uppercase;
    width: 70%;
    margin: 0 auto;
    @media (min-width: ${settings.bp.medium.view}) {
        font-size: 22px;
        width: 60%;
    }
`;

const StSingleImageText = styled.div.attrs((props) => ({
    className: 'StSingleImageText',
}))`
    font-size: 15px;
    text-decoration: underline;
    width: 60%;
    margin: 0 auto;
    @media (min-width: ${settings.bp.medium.view}) {
        width: 50%;
    }
`;

export default function SingleImageBanner(props) {
    const { logo, title, text, backgroundColor } = props;

    return (
        <StSingleImage style={{ backgroundColor: backgroundColor }}>
            <Spacing>
                <img src={logo} />
            </Spacing>
            {title && (
                <Spacing removeSpaceTop>
                    <StSingleImageTitle>{title}</StSingleImageTitle>
                </Spacing>
            )}
            {text && (
                <Spacing removeSpaceTop>
                    <StSingleImageText>{text}</StSingleImageText>
                </Spacing>
            )}
        </StSingleImage>
    );
}
