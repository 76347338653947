import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import settings from '../../global/settings';
import FieldRadio from '../FieldRadio';
import * as SharedStyles from '../../global/SharedStyles';
import MobileFixedContent from '../MobileFixedContent';

const { StLinkMore } = SharedStyles;

const StProductDetailItemsMobile = styled.div`
    display: block;

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }
`;

const StProductDetailItemsTitle = styled.div`
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 20px;
`;

const StProductDetailItemsList = styled.div`
    padding-bottom: 10px;
`;

const StProductDetailItemsItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;

    .StFieldRadio label {
        font-size: 18px;
        padding-left: 50px;

        &:before {
            width: 25px;
            height: 25px;
        }
    }
`;

const StProductDetailItemsItemColor = styled.div`
    width: 128px;
    height: 40px;
    margin-left: 20px;
`;

function ProductDetailItemsItem(props) {
    const { color, idItem, onChange, ...restProps } = props;
    return (
        <StProductDetailItemsItem>
            <div>
                <FieldRadio id={idItem} onChange={onChange} {...restProps} />
            </div>
            {color && (
                <StProductDetailItemsItemColor
                    style={{ backgroundColor: color }}
                />
            )}
        </StProductDetailItemsItem>
    );
}

export default function ProductDetailMobileItems(props) {
    const [show, toggleShow] = useState(false);
    const { title, items, onChange } = props;
    return (
        <StProductDetailItemsMobile>
            <StLinkMore inversionColor styleMedium>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        toggleShow(!show);
                    }}
                    href="/"
                >
                    {title}
                </a>
            </StLinkMore>
            <MobileFixedContent show={show} toggleShow={toggleShow}>
                <StProductDetailItemsTitle>{title}</StProductDetailItemsTitle>
                <StProductDetailItemsList>
                    {items.map((item, index) => (
                        <ProductDetailItemsItem
                            {...item}
                            key={item.id || index}
                            onChange={onChange}
                        />
                    ))}
                </StProductDetailItemsList>
            </MobileFixedContent>
        </StProductDetailItemsMobile>
    );
}
