import React, { useState, useEffect, Fragment } from 'react'
import styled, { css } from 'styled-components'
import settings from '../../../kenra-storybook/global/settings'
import { useSwipeable } from 'react-swipeable'

const ReadMoreButton = styled.a`
    width: 185px;
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    border: 1px solid #000;
    text-transform: uppercase;
    color: #000;
    font-size: 16px;
    line-height: 40pxpx;
    font-weight: 700;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: 12px;
    letter-spacing: 2px;

    &:hover {
        cursor: pointer;
    }

`

const StMediaSnippetsContainer = styled.div`
    max-width: 100%;
    min-height: 260px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 260px;
        max-width: 90%;
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 12px;
        padding-bottom: 12px;
        ${(props) =>
        props.backgroundColor &&
        css`
            background-color: ${props.backgroundColor};
            `
    };
    }

    @media (min-width: ${settings.bp.medium.view}) {
        min-height: 260px;
    }
`

const StSnippetTitle = styled.div`
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 2px;
    @media (min-width: ${settings.bp.small.view}) {
        font-size: 38px;
    }
`

export const MediaSnippets = props => {
    const { snippets } = props
    const maxIndex = snippets.length - 1
    const [index, setIndex] = useState(0)
    const [autoRotate, setAutoRotate] = useState(true)
    const [mousePosition, setMousePosition] = useState(0)

    const mouseMoveHandler = event => {
        const { width } = event.target.getBoundingClientRect()
        const percentage =
            ((event.clientX - event.target.offsetLeft) / width) * 100
        setMousePosition(percentage)
    }

    useEffect(() => {
        window.addEventListener('mousemove', mouseMoveHandler)
        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler)
        }
    }, [])

    const config = {
        delta: 10,
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0, // set a rotation angle
        swipeDuration: 250, // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    }

    const swipeHandler = useSwipeable({
        onSwiped: eventData => {
            // if (autoRotate) {
            //     setAutoRotate(false)
            // }
            if (eventData.dir === 'Left' && eventData.deltaX < -50) {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            } else if (eventData.dir === 'Right' && eventData.deltaX > 50) {
                let newIndex = index - 1 < 0 ? maxIndex : index - 1
                setIndex(newIndex)
            }
        },
        ...config,
    })

    useEffect(() => {
        if (autoRotate) {
            const interval = setInterval(() => {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            }, 3000)
            return () => clearInterval(interval)
        }
    }, [index, autoRotate])

    if (!snippets) {
        return null
    }

    return (
        <StMediaSnippetsContainer
            onClick={() => {
                // if (autoRotate) {
                //     setAutoRotate(false)
                // }
                if (mousePosition >= 50) {
                    let newIndex = index + 1 > maxIndex ? 0 : index + 1
                    setIndex(newIndex)
                } else {
                    let newIndex = index - 1 < 0 ? maxIndex : index - 1
                    setIndex(newIndex)
                }
            }}
            {...swipeHandler}
        >
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
                key={`snippet_${index}`}
            >
                <div>
                    <StSnippetTitle>
                        {snippets[index]?.title}
                    </StSnippetTitle>
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <img
                            style={{ maxHeight: 87 }}
                            src={snippets[index]?.icon?.localFile?.publicURL}
                        />
                    </div>
                    <ReadMoreButton style={{
                        minHeight: '48px'
                    }}
                        target="_blank"
                        href={snippets[index]?.ctaUrl}>
                        {snippets[index]?.ctaText?.toUpperCase()}
                    </ReadMoreButton>
                </div>
            </div>
        </StMediaSnippetsContainer>
    )
}
