import React from 'react';
import styled from 'styled-components';

import settings from '../global/settings';

const StSocial = styled.div.attrs((props) => ({
    className: 'StSocial',
}))`
    display: flex;
`;

const StSocialIco = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 15px;
    transition: opacity 0.3s;

    @media (min-width: ${settings.bp.small.view}) {
        width: 16px;
        height: 16px;
        margin-left: 13px;
    }

    &:hover {
        opacity: 0.8;
    }

    &:first-child {
        margin-left: 0;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

function SocialItem(props) {
    const { img, href, ...restProps } = props;
    return (
        <StSocialIco>
            <a href={href}>{img && <img src={img} alt="" {...restProps} />}</a>
        </StSocialIco>
    );
}

export default function Social(props) {
    const { items } = props;
    return (
        <StSocial>
            {items.map((item, index) => (
                <SocialItem key={item.id || index} {...item} />
            ))}
        </StSocial>
    );
}
