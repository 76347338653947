import React from 'react';
import styled, { css } from 'styled-components';
import Container from './Container';
import Title from './atoms/Title';
import AllButton from './atoms/AllButton';
import settings from '../global/settings';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Side = styled.div`
    width: 100%;
    padding: 30px;

    background: url(${(props) => props.backgroundImage});
    &:nth-child(1) {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &:nth-child(2) {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    ${(props) =>
        !props.fullWidth &&
        css`
            @media (min-width: 768px) {
                width: 50%;
                padding: 50px;
            }
    `};

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            padding: 0px;
        `};
`;
const Overlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    ${(props) =>
        props.overlayBackground &&
        css`
            background: rgba(255, 255, 255, 0.7);
        `};

    min-height: 323px;
    padding: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 620px;
        padding: 0px;
    }

    ${(props) =>
        props.fullWidth &&
        css`
            min-height: 250px;
            width: 40%;
            margin-left: auto;
            padding: 0px;
            padding-top: 140px;
            @media (min-width: ${settings.bp.small.view}) {
                min-height: 350px;
                padding-top: 160px;
            }
            @media (min-width: ${settings.bp.medium.view}) {
                min-height: 500px;
                padding-top: 300px;
            }
            @media (min-width: ${settings.bp.large.view}) {
                min-height: 550px;
                padding-top: 350px;
            }
            @media (min-width: ${settings.bp.extraLarge.view}) {
                min-height: 700px;
                padding-top: 350px;
            }
        `};

`;
const Subtitle = styled.h4`
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-top: 0;
    margin-bottom: 0;
    ${(props) =>
        props.invertFontColor &&
        css`
        font-size: 30px;
        color: #fff;
        text-shadow: 4px 4px 7px #000;
        min-height: 64px;

    `}
`;
const Content = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    text-align: center;
    letter-spacing: 0.05em;
    max-width: 310px;
    margin-top: 24px;
    margin-bottom: 24px;
    @media (max-width: 767.08px) {
        a {
            margin-top: 0px;
        }
    }
    @media (min-width: 768px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    ${(props) =>
        props.invertFontColor &&
        css`
        color: #fff;
        text-shadow: 4px 4px 7px #000;
        margin-top: 0px;
        margin-bottom: 0px;
        max-width: 80%;
        @media (min-width: 768px) {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    `}
`;

export default function FindYourPerfectProduct({
    items,
    findYourPerfectProductTitle,
}) {
    return (
        <section>
            {findYourPerfectProductTitle && (<Container>
                <Title>{findYourPerfectProductTitle}</Title>
            </Container>)}

            <Wrapper>
                {items.map((item, index) => {
                    console.log('rendering', item, item.invertFontColor)
                    return (
                        <Side key={`side-quizz-` + index} 
                                backgroundImage={item.backgroundImage} 
                                fullWidth={item.fullWidth}>
                            <Overlay 
                                overlayBackground={item.overlayBackground}
                                fullWidth={item.fullWidth}>
                                <Subtitle  invertFontColor={item.invertFontColor}  >{item?.title}</Subtitle>
                                {!item.fullWidth && <Content  invertFontColor={item.invertFontColor}  >{item?.text}</Content>}
                                <AllButton  invertFontColor={item.invertFontColor}  href={item?.url}>
                                    {item?.btnText}
                                </AllButton>
                            </Overlay>
                        </Side>
                    );
                })}
            </Wrapper>
        </section>
    );
}
