import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import settings from '../../global/settings';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

const Container = styled.div`
    padding-left: 30px;
    padding-right: 30px;

    position: relative;

    @media (min-width: ${settings.bp.medium.view}) {
        padding-left: 100px;
        padding-right: 100px;
    }
    .swiper-pagination-bullet-active {
        background: black;
    }
    .swiper-wrapper {
        @media (min-width: ${settings.bp.medium.view}) {
            // justify-content: center;
        }
    }
`;

const NewArrow = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid #000;
    background: #fff;

    z-index: 999;

    width: 28px;
    height: 28px;

    cursor: pointer;
    display: none;
    @media (min-width: ${settings.bp.medium.view}) {
        width: 40px;
        height: 40px;
    }

    @media (max-width: ${settings.bp.medium.view}) {
        ${props =>
            props.numberOfItems &&
            props?.numberOfItems >= 1 &&
            css`
                display: block;
            `}
    }

    @media (min-width: ${settings.bp.medium.view}) {
        ${props =>
            props.numberOfItems &&
            props?.numberOfItems > 3 &&
            css`
                display: block;
            `}
    }

    @media (min-width: 1410) {
        ${props =>
            props.numberOfItems &&
            props?.numberOfItems <= 4 &&
            css`
                display: none;
            `}
    }
`;

const NewArrowRight = styled.div`
    ${NewArrow}
    right: 0px;
    background-image: url('/images/rightArrow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
`;

const NewArrowLeft = styled.div`
    ${NewArrow}
    right: calc(100% - 28px);
    background-image: url('/images/leftArrow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media (min-width: ${settings.bp.medium.view}) {
        right: calc(100% - 40px);
    }
`;

const Arrow = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);

    border: 2px solid black;
    z-index: 999;

    width: 10px;
    height: 10px;

    cursor: pointer;

    @media (min-width: ${settings.bp.medium.view}) {
        width: 24px;
        height: 24px;
    }
`;

const ArrowLeft = styled.div`
    ${Arrow}

    right: calc(100% - 25px);

    border-top: 0px;
    border-right: 0px;

    @media (min-width: ${settings.bp.medium.view}) {
        right: calc(100% - 70px);
    }
`;

const ArrowRight = styled.div`
    ${Arrow}

    // display: none;
    right: 15px;

    border-bottom: 0px;
    border-left: 0px;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
        left: calc(100% - 70px);
    }
`;

const Heading = styled.div`
    font-size: 18px;
    padding-top: 20px;

    text-transform: uppercase;

    text-align: center;
    @media (min-width: ${settings.bp.medium.view}) {
        padding-top: 50px;
        font-size: 26px;
    }
`;

const SliderBestSeller = props => {
    const [swiper, updateSwiper] = useState(null);

    let { swiperUseEffect, showDots, overrideConfig } = props;

    const autoPlayConfig = overrideConfig
        ? {
              slidesPerView: 3,
              spaceBetween: 20,
              navigation: {
                  nextEl: '.bs-button-next',
                  prevEl: '.bs-button-prev',
              },
              getSwiper: updateSwiper,
              breakpoints: {
                  // when window width is >= 480px
                  [settings.bp.medium.view.replace(/\D/g, '')]: {
                      slidesPerView: 3,
                      spaceBetween: 51,
                  },
              },
          }
        : {
              slidesPerView: 'auto',
              spaceBetween: 20,
              navigation: {
                  nextEl: '.bs-button-next',
                  prevEl: '.bs-button-prev',
              },
              getSwiper: updateSwiper,
              breakpoints: {
                  // when window width is >= 480px
                  [settings.bp.medium.view.replace(/\D/g, '')]: {
                      slidesPerView: 3,
                      spaceBetween: 51,
                  },
                  // when window width is >= 640px
                  1410: {
                      slidesPerView: 4,
                      spaceBetween: 51,
                  },
              },
          };

    const dots = {
        pagination: {
            el: '.swiper-pagination',
        },
    };

    useEffect(() => {
        if (swiper && swiperUseEffect) {
            swiperUseEffect(swiper);
        } else if (swiper) {
            const slides = swiper.slides;
            Object.values(slides).forEach(elem => {
                if (
                    elem.querySelector &&
                    elem.classList.contains('swiper-slide-duplicate')
                ) {
                    if (elem.querySelector('img')) {
                        elem.querySelector('img').style.opacity = 1;
                    }
                }
            });
        }
    }, [swiper, swiperUseEffect]);

    const config = overrideConfig
        ? overrideConfig
        : showDots
        ? { ...autoPlayConfig, ...dots }
        : { ...autoPlayConfig };

    return (
        <>
            {props.heading && <Heading>{props.heading}</Heading>}
            <Container>
                <Swiper {...config}>{props.children}</Swiper>
                {props.newArrowStyle ? (
                    <NewArrowLeft
                        numberOfItems={props.numberOfItems}
                        onClick={() => {
                            swiper.slidePrev();
                        }}
                    />
                ) : (
                    <ArrowLeft
                        onClick={() => {
                            swiper.slidePrev();
                        }}
                    />
                )}
                {props.newArrowStyle ? (
                    <NewArrowRight
                        numberOfItems={props.numberOfItems}
                        onClick={() => {
                            swiper.slideNext();
                        }}
                    />
                ) : (
                    <ArrowRight
                        onClick={() => {
                            swiper.slideNext();
                        }}
                    />
                )}
            </Container>
        </>
    );
};

export default SliderBestSeller;
