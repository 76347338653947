import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash.get';

import settings from '../global/settings';

import Logo from './Logo';
import Nav from './Nav';
import Container from './Container';
import AnnouncementBanner from './AnnouncementBanner';
import FieldInput from './FieldInput';
import * as SharedStyles from '../global/SharedStyles';
const { StMenuCloseBtn } = SharedStyles;
import CustomLink from './CustomLink';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
library.add(faShoppingBasket);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StPageHeader = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    background-color: #fff;
    border-bottom: 1px solid #000;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.18);

    @media (min-width: ${settings.bp.medium.view}) {
        box-shadow: none;
    }

    .container {
        @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
            width: 100%;
        }
    }
`;

const StPageHeaderInner = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
`;

const StPageHeaderMobileBtn = styled.div.attrs((props) => ({
    className: 'StPageHeaderMobileBtn',
}))`
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-right: 1px solid ${settings.color.grayLight3};
    position: relative;
    z-index: 100;

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-bottom: 2px solid #000;
        width: 29px;
        transition: transform 0.15s;
    }

    &:before {
        transform: translate(-50%, calc(-50% - 4px));
    }

    &:after {
        transform: translate(-50%, calc(-50% + 4px));
    }

    ${(props) =>
        props.mobileMenuOpen &&
        css`
            &:before {
                transform: translate(-50%, calc(-50%)) rotate(45deg);
            }

            &:after {
                transform: translate(-50%, calc(-50%)) rotate(-45deg);
            }
        `};
`;

const StPageHeaderNav = styled.div.attrs((props) => ({
    className: 'StPageHeaderNav',
}))`
    margin: 0 auto;
    position: fixed;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.3s, opacity 0.3s;
    overflow: hidden;

    & > div {
        transition: transform 0.3s;
        transform: translate(0, -20px);
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 110px;

        & > .StNav {
            height: calc(100vh - 170px);
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    ${(props) =>
        props.mobileMenuOpen &&
        css`
            opacity: 1;
            visibility: visible;

            & > div {
                transform: translate(0, 0);
            }
        `};

    @media (min-width: ${settings.bp.medium.view}) {
        transition: none;
        opacity: 1;
        visibility: visible;
        position: static;
        overflow: visible;

        & > div {
            transition: none;
            transform: none;
            background-color: transparent;
            position: static;
            padding-top: 0;

            & > .StNav {
                height: auto;
                overflow-y: visible;
            }
        }
    }
`;

const CssSearchBtn = css`
    cursor: pointer;
    width: 60px;
    height: 60px;
    position: relative;
    border: none;
    background: transparent;

    @media (min-width: ${settings.bp.medium.view}) {
        border-left: none;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:before {
        border: 2px solid #000;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }

    &:after {
        width: 5px;
        border-bottom: 2px solid #000;
        transform: rotate(-45deg);
        margin-left: -9px;
        margin-top: 9px;
    }
`;

const StPageSearchBtn = styled.div`
    ${CssSearchBtn};
    border-left: 1px solid ${settings.color.grayLight3};

    @media (min-width: ${settings.bp.medium.view}) {
        border-left: none;
    }

    ${(props) =>
        props.searchOpen &&
        css`
            &:before,
            &:after {
                width: 20px;
                border: none;
                border-bottom: 2px solid #000;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                margin: 0;
                border-radius: 0;
                height: 0;
            }

            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        `};
`;

const StPageSearchBtnWrap = styled.div`
    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
        justify-content: flex-end;
    }
`;

const CssBasketBtn = css`
    cursor: pointer;
    width: 60px;
    height: 60px;
    position: relative;
    border: none;
    background: transparent;

    @media (min-width: ${settings.bp.medium.view}) {
        border-left: none;
    }
`;

const StPageBasketBtn = styled.div`
    display: contents;
    background-image: url(${(props) => props.url});
    ${CssBasketBtn};
    border-left: 1px solid ${settings.color.grayLight3};

    @media (min-width: ${settings.bp.medium.view}) {
        border-left: none;
    }
`;

const StPageBasketBtnWrap = styled.div`
    cursor: pointer;

    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
        justify-content: flex-end;
    }
`;
const StMobileSearchBtn = styled.button`
    background-color: transparent;
    ${CssSearchBtn};
    position: absolute;
    left: 0;
    top: 0;

    &:before,
    &:after {
        opacity: 0.3;
    }
`;

const StMobileSearchInnerForm = styled.form`
    position: relative;
`;

const StMobileSearch = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding: 25px;
    z-index: 1;
    background-color: #fff;

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }

    .StField {
        margin-bottom: 0;
    }

    input {
        height: 60px !important;
        font-size: 16px !important;
        padding: 21px 20px 20px 60px !important;
        border: none !important;
        background-color: #f0f0f0 !important;
    }
`;

const StSearch = styled.div`
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    pointer-events: none;

    ${(props) =>
        props.isActive &&
        css`
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        `};

    ${(props) =>
        props.bannerIsPresent &&
        css`
            top: 115px;
            @media (min-width: ${settings.bp.medium.view}) {
                top: 100px;
            }
        `};
`;

const StFieldPlaceholder = css`
    font-weight: inherit;
    text-transform: inherit;
    color: inherit;
    opacity: 1;
    letter-spacing: inherit;
`;

const StSearchInnerForm = styled.form`
    padding: 5% 0;
    max-width: 270px;
    margin: 0 auto;

    @media (min-width: ${settings.bp.medium.view}) {
        max-width: 420px;
    }

    .StField {
        margin-bottom: 0;
    }

    input {
        background: transparent;
        border: none;
        box-shadow: none;
        font-size: 26px;
        font-weight: 300;
        letter-spacing: 0.05em;
        text-transform: none;
        height: auto;
        padding: 0;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 40px;
        }

        ::placeholder,
        ::-webkit-input-placeholder {
            ${StFieldPlaceholder}
        }
        :-ms-input-placeholder {
            ${StFieldPlaceholder}
        }
    }
`;

const StProductSearchResult = styled.div`
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 120px;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.1em;
    color: #000;
    background-color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    @media (min-width: ${settings.bp.small.view}) {
        margin-left: 140px;
        margin-right: 140px;
        font-size: 14px;
    }
    @media (min-width: ${settings.bp.medium.view}) {
        margin-left: 200px;
        margin-right: 200px;
    }
`;

const StProductSearchResultItem = styled.div`
    border: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 1px;
    margin-bottom: 5px;
    border-style: solid;
    @media (min-width: ${settings.bp.small.view}) {
        &:hover {
            background-color: rgba(8, 8, 8, 0.04);
        }
    }

    a {
        width: 100%;
    }
`;

const StPageSearchResult = styled.div`
    text-transform: uppercase;
    margin: 0;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.1em;
    color: #000;
    line-height: 80px;
`;

const StPageHeaderAnnouncement = styled.div`
    z-index: 100;
`;

const StPageHeaderLogo = styled.div`
    width: 116px;
    margin: 0 auto;
    z-index: 100;

    @media (min-width: ${settings.bp.medium.view}) {
        margin: 0;
    }
`;

const StProductSearchNoResult = styled.div`
    font-size: 30px;
    display: flex;
    justify-content: center;
`;

const StProductSearchItemRow = styled.div`
    padding: 5px;
    flex-direction: row;
    flex: 1;
    overflow: auto;

    img {
        height: 80px;
        width: 80px;
        object-fit: contain;
        padding-right: 12px;
        float: left;
    }
`;

const StProductSearchItemRowText = styled.div`
`;

const StProductSearchItemRowTextTitle = styled.div`
    float: left;
    padding-top: 12px;
`;

const StProductSearchItemRowTextLabel = styled.div`
    float: right;
    padding-top: 12px;
    font-weight: 600;
`;

function SearchResultItem(props) {
    const { index, href, imgSrc, title, type } = props;
    return (
        <StProductSearchResultItem
            key={index}
        >
            <CustomLink
                href={href}
            >
                <StProductSearchItemRow>
                    <img
                        alt=""
                        src={imgSrc}
                    />
                    <StProductSearchItemRowText>
                        <StProductSearchItemRowTextTitle
                        >
                            {title}
                        </StProductSearchItemRowTextTitle>
                        <StProductSearchItemRowTextLabel
                        >
                            {type}
                        </StProductSearchItemRowTextLabel>
                    </StProductSearchItemRowText>
                 </StProductSearchItemRow>
            </CustomLink>
        </StProductSearchResultItem>
    );
}

export default function PageHeader(props) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [searchOpen, toggleSearchOpen] = useState(false);

    const {
        logo,
        navItems,
        navItemsSecond,
        onSubmitSearchForm,
        onSubmitMobileSearchForm,
        handleChange,
        searchResults,
        searchTerm,
        bannerText,
        bannerButtonText,
        bannerButtonLink,
        bannerColor,
        bannerTextColor,
        showBasket,
        shoppingCartImage,
        shoppingCartQuantity,
        onClickShoppingCart,
        showLanguageSwitcher,
        menuCallback,
        intl
    } = props;
    const elmSearch = useRef();

    function handlerClickSearchBtn() {
        toggleSearchOpen(!searchOpen);

        if (!elmSearch || !elmSearch.current || searchOpen) return;

        const elmInput = elmSearch.current.querySelector('input');
        if (elmInput) {
            setTimeout(() => {
                elmInput.focus();
            }, 100);
        }
    }

    let noResultText = intl.formatMessage({
        id: 'header-search-no-results',
    }) + searchTerm;
    return (
        <StPageHeader>
            {bannerText && (
                <StPageHeaderAnnouncement>
                    <AnnouncementBanner
                        bannerText={bannerText}
                        bannerButtonText={bannerButtonText}
                        bannerButtonLink={bannerButtonLink}
                        bannerColor={bannerColor}
                        bannerTextColor={bannerTextColor}
                    />
                </StPageHeaderAnnouncement>
            )}
            <Container>
                <StPageHeaderInner>
                    <StPageHeaderMobileBtn
                        mobileMenuOpen={mobileMenuOpen}
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    />
                    <StPageHeaderLogo>
                        <Logo href={logo.href} alt={logo.alt} src={logo.src} />
                    </StPageHeaderLogo>
                    <StPageHeaderNav mobileMenuOpen={mobileMenuOpen}>
                        <div>
                            <Nav
                                items={navItems}
                                itemsSecond={navItemsSecond}
                                languageCallback={showLanguageSwitcher}
                                menuCallback={menuCallback}
                            />
                            <StMenuCloseBtn
                                onClick={() =>
                                    setMobileMenuOpen(!mobileMenuOpen)
                                }
                            >
                                Close
                            </StMenuCloseBtn>
                        </div>
                    </StPageHeaderNav>
                    {showBasket && (
                        <StPageBasketBtnWrap>
                            <span
                                className="fa-layers fa-2x fa-fw"
                                onClick={onClickShoppingCart}
                            >
                                <FontAwesomeIcon icon={shoppingCartImage} />
                                <span className="fa-layers-counter">
                                    {shoppingCartQuantity}
                                </span>
                            </span>
                        </StPageBasketBtnWrap>
                    )}
                    <StPageSearchBtnWrap>
                        <StPageSearchBtn
                            searchOpen={searchOpen}
                            onClick={() => handlerClickSearchBtn()}
                        />
                    </StPageSearchBtnWrap>
                    <StSearch
                        ref={elmSearch}
                        isActive={searchOpen}
                        bannerIsPresent={bannerText}
                    >
                        <Container>
                            <StSearchInnerForm onSubmit={onSubmitSearchForm}>
                                <FieldInput
                                    placeholder={intl.formatMessage({
                                        id: 'header-search-placeholder',
                                    })}
                                    value={searchTerm}
                                    onChange={handleChange}
                                />
                            </StSearchInnerForm>
                            {}
                            {searchResults && searchResults.length > 0 && (
                                <StProductSearchResult>
                                    {searchResults.map((result, index) => {
                                        let imgSrc = get(
                                            result,
                                            'images[0].localFile.thumbnail.resize.src'
                                        );

                                        let href = `/product/${result.handle}`;
                                        let type = intl.formatMessage({
                                            id: 'header-search-product-text',
                                        });
                                        if (result.type === 'ContentfulBlogPost') {
                                            href = `/blog/${result.url}`;
                                            type = intl.formatMessage({
                                                id: 'header-search-blog-text',
                                            });
                                        }

                                        return (
                                            <SearchResultItem 
                                                index={index}
                                                href={href}
                                                imgSrc={imgSrc}
                                                title={result.title}
                                                type={type}
                                            />
                                        );
                                    })}
                                </StProductSearchResult>
                            )}
                            {searchResults && searchResults.length === 0 && searchTerm && (
                                <Container>
                                    <StProductSearchNoResult>
                                        {noResultText}
                                    </StProductSearchNoResult>
                                </Container>
                            )}
                        </Container>
                    </StSearch>
                </StPageHeaderInner>
            </Container>
        </StPageHeader>
    );
}

/*
          {showBasket && (
            <StPageBasketBtnWrap>
              <StPageBasketBtn
                onClick={onClickShoppingCart}>
                <img src={shoppingCartImage}/>
              </StPageBasketBtn>
            </StPageBasketBtnWrap> 
          )}

*/
/*
                                        if (result.type === 'ShopifyProduct') {
                                            return (
                                                <StProductSearchResultItem
                                                    key={index}
                                                >
                                                    <CustomLink
                                                        href={`/product/${result.handle}`}
                                                    >
                                                        <StProductSearchItemRow>
                                                            <img
                                                                alt=""
                                                                src={imgSrc}
                                                            />
                                                            <StProductSearchItemRowText>
                                                                <StProductSearchItemRowTextTitle
                                                                >
                                                                    {result.title}
                                                                </StProductSearchItemRowTextTitle>
                                                                <StProductSearchItemRowTextLabel
                                                                >
                                                                    {intl.formatMessage({
                                                                        id: 'header-search-product-text',
                                                                    })}
                                                                </StProductSearchItemRowTextLabel>
                                                            </StProductSearchItemRowText>
                                                         </StProductSearchItemRow>
                                                    </CustomLink>
                                                </StProductSearchResultItem>
                                            );
                                        } else if (result.type === 'ContentfulBlogPost') {
                                            return (
                                                <StProductSearchResultItem
                                                    key={index}
                                                >
                                                    <CustomLink
                                                        href={`/blog/${result.url}`}
                                                    >
                                                        <div
                                                            style={{
                                                                padding: '5px',
                                                                flexDirection:
                                                                    'row',
                                                                flex: 1,
                                                                overflow: 'auto',
                                                            }}
                                                        >
                                                            <img
                                                                height="80px"
                                                                width="80px"
                                                                style={{
                                                                    objectFit:
                                                                        'contain',
                                                                    paddingRight:
                                                                        '12px',
                                                                    float: 'left',
                                                                }}
                                                                alt=""
                                                                src={imgSrc}
                                                            />
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        float: 'left',
                                                                        paddingTop: 12,
                                                                    }}
                                                                >
                                                                    {result.title}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        float: 'right',
                                                                        paddingTop: 12,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    {'BLOG'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                   </CustomLink>
                                                </StProductSearchResultItem>
                                            );
                                        }
*/
