import React from 'react';

import styled, { css } from 'styled-components';

// import settings from '../global/settings';
import Container from '../Container';
// import * as SharedStyles from '../global/SharedStyles';

const StItemsWrapper = styled.div`
  display:flex;
  flex: 1;
  flex-direction:row;
  margin-top: 24px;

`;

export default StItemsWrapper;
