import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import CustomLink from './CustomLink';
import { useIntl } from 'gatsby-plugin-react-intl';

const StContainer = styled.div.attrs((props) => ({
    className: 'StContainer',
}))``;

const StPanel = styled.div.attrs((props) => ({
    className: 'StPanel',
}))`
    margin: 20px 0px;
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        margin-top: 0px;
    }
`;

const StPanelImage = styled.div.attrs((props) => ({
    className: 'StPanelTextImage',
}))`
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
`;

const StPanelButton = styled.div.attrs((props) => ({
    className: 'StPanelButton',
}))`
    background-color: #6420c0;
    width: 220px;
    margin: auto;
    height: 53px;
    border-radius: 28px;
    border: 1px solid #fff;
    color: #fff;
`;

const StPanelButtonImage = styled.div.attrs((props) => ({
    className: 'StPanelButtonImage',
}))`
    img {
        width: 37px;
        height: 37px;
        margin: auto;
        margin-left: 15px;
    }

    display: flex;
`;

const StPanelButtonImageText = styled.div.attrs((props) => ({
    className: 'StPanelButtonImageText',
}))`
    margin-right: auto;
    padding: 15px;
`;
const StPanelTitle = styled.div.attrs((props) => ({
    className: 'StPanelTitle',
}))`
    text-transform: uppercase;
    height: 140px;
    padding: 30px;
    color: #fff;
    font-size: 30px;
`;

export default function SpotifyPanel(props) {
    const { title, image, buttonImage, buttonUrl } = props;
    let intl = useIntl();

    return (
        <StContainer>
            <StPanelTitle>{title}</StPanelTitle>
            <StPanel>
                <StPanelImage>
                    <img src={image} />
                </StPanelImage>
                <StPanelButton>
                    <CustomLink href={buttonUrl} target="_blank">
                        <StPanelButtonImage>
                            <img src={buttonImage} />
                            <StPanelButtonImageText>
                                {intl.formatMessage({
                                    id: 'throwback-join-playlist',
                                })}
                            </StPanelButtonImageText>
                        </StPanelButtonImage>
                    </CustomLink>
                </StPanelButton>
            </StPanel>
        </StContainer>
    );
}
