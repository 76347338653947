import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import settings from '../global/settings';

import ScrollableBlock from './ScrollableBlock';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StTabs = styled.div``;

const StTabsNav = styled.div`
    text-transform: uppercase;

    font-weight: 500;
    letter-spacing: 0.05em;
    color: ${settings.color.default};
    white-space: nowrap;

    font-size: 12px;
    line-height: 14px;
    @media (min-width: ${settings.bp.medium.view}) {
        font-size: 16px;
        line-height: 19px;
    }
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    li {
        width: 100%;

        @media (min-width: 1300px) {
            width: 240px;
        }
    }

    a {
        text-align: center;
        text-decoration: none;
        display: block;
        cursor: pointer;
        padding: 20px;
        position: relative;

        &:before {
            content: '';
            border-bottom: 2px solid ${settings.color.default};
            left: 10px;
            bottom: 0;
            width: 0;
            position: absolute;
            transition: width 0.3s;
        }
        &.active {
            font-weight: bold;
        }

        &.active:before {
            // width: calc(100% - 20px);
        }
    }
`;

const StTabsContent = styled.div``;

const StTabsContentItem = styled.div`
    animation: 600ms ${fadeIn} ease-out;
`;

function TabsItem(props) {
    const { text, isActive, onClick, ...restProps } = props;
    return (
        <li>
            <a
                className={isActive ? 'active' : undefined}
                onClick={onClick}
                {...restProps}
            >
                {text}
            </a>
        </li>
    );
}

export default function Tabs(props) {
    const { items } = props;
    const [activeTab, setActiveTab] = useState(
        items.findIndex((item) => item.isActive) || 0
    );
    return (
        <StTabs>
            {items && (
                <StTabsNav>
                    <ScrollableBlock>
                        <ul>
                            {items.map((item, index) => (
                                <TabsItem
                                    key={item.id || index}
                                    {...item}
                                    isActive={index === activeTab}
                                    onClick={() => setActiveTab(index)}
                                />
                            ))}
                        </ul>
                    </ScrollableBlock>
                </StTabsNav>
            )}
            <StTabsContent>
                <StTabsContentItem key={activeTab}>
                    {props.children[activeTab]}
                </StTabsContentItem>
            </StTabsContent>
        </StTabs>
    );
}
