import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDropzone } from 'react-dropzone';

import settings from '../global/settings';

const StFieldDropZone = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: solid 2px #e4e4e4;
    color: #b6b6b6;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-bottom: 20px;
    font-size: 12px !important;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 40px;
    }

    &:focus {
        border-color: #ccc;
    }

    p {
        font-size: 12px;
        letter-spacing: 0.04em;
    }
`;

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

export default function FieldDropZone(props) {
    const { type, label, desc, id, name, error, ...restProps } = props;
    const [files, setFiles] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: 'image/* ,.doc, .docx, .pdf',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} />
            </div>
        </div>
    ));

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <div className="container">
            <StFieldDropZone
                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
                <input {...getInputProps()} />
                {label && <p>{label}</p>}
            </StFieldDropZone>
            <aside style={thumbsContainer}>{thumbs}</aside>
        </div>
    );
}
