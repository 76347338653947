import styled, { css } from 'styled-components';

import settings from '../../global/settings';

export const StSliderQuiz = styled.div`
    // padding: 0 30px;

    @media (min-width: ${settings.bp.small.view}) {
        // padding: 0 60px;
    }

    @media (min-width: ${settings.bp.large.view}) {
        // padding: 0 30px;
    }

    &:not(.swiper-slide) {
        margin-left: auto;
        margin-right: auto;
    }

    .StProductListItemImg {
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        height: 200px;

        @media (min-width: ${settings.bp.small.view}) {
            width: 300px;
            height: 300px;
        }

        @media (min-width: ${settings.bp.large.view}) {
            width: calc(25vw - 60px);
            height: calc(25vw - 60px);
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }
`;
