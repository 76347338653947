import React from 'react';

import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
library.add(faSpinner);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StLoadingPage = styled.div`
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StData = styled.div`
    align-self: center;
`;

const StLogo = styled.div`
    width: 30%;
    margin: auto;
    padding-bottom: 20px;
`;

const StPageTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.07em;
`;

const StSpinner = styled.span`
    font-size: 32px;
`;

export default function LoadingPage(props) {
    const { title } = props;
    return (
        <StLoadingPage>
            <StData>
                <StLogo>
                    <StSpinner>
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className={'fa fa-spin'}
                        />
                    </StSpinner>
                </StLogo>
                {title && <StPageTitle>{title}</StPageTitle>}
            </StData>
        </StLoadingPage>
    );
}
