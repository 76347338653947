import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
const { CssButton } = SharedStyles;

const StFieldSubmit = styled.div`
    text-align: center;

    & > * {
        ${CssButton};
        min-width: 100%;
        max-width: 300px;
        padding: 16px 30px;

        @media (min-width: ${settings.bp.small.view}) {
            max-width: none;
            min-width: 365px;
            padding: 23px 34px;
        }
    }
`;

export default function FieldSubmit(props) {
    return <StFieldSubmit {...props} />;
}
