import styled, { css } from 'styled-components';
import settings from '../../global/settings';

export const StSliderHeroItem = styled.div`
    width: 100%;

    .desktop-video {
        display: none;
    }

    @media (min-width: ${settings.bp.small.view}) {
        .desktop-video {
            display: block;
        }
        & > div {
            height: 100%:
        }
    }

    .mobile-video {
        display: block;
    }
    @media (min-width: ${settings.bp.small.view}) {
        .mobile-video {
            display: none;
        }
    }

`;

export const StSliderHeroImg = styled.div`
    img {
        width: 100%;
        height: auto;
    }
`;
export const StSliderHeroImgMobile = styled.div`
    img {
        width: 100%;
        height: auto;
    }
    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }
`;
export const StSliderHeroImgWeb = styled.div`
    img {
        width: 100%;
        height: auto;
    }
    display: none;
    @media (min-width: ${settings.bp.small.view}) {
        display: block;
        height: 100%;
        & > div {
            height: 100%;
        }
    }
`;

export const StSliderHeroItemDesc = styled.div`
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 10px 0;

        @media (min-width: ${settings.bp.small.view}) {
            display: none;
        }
    }

    h2 {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.36;
        letter-spacing: 0.06em;
        margin: 0 0 20px;
    }
`;

export const StSliderHeroItemDescFirst = styled.div`
    text-align: center;

    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }

    &:not(:last-child) {
        width: 45%;
        text-align: left;

        h2 {
            font-size: 22px;
            letter-spacing: 0.1em;
            color: ${props => props.textColor};
        }
    }

    .StLink a {
        color: ${props => props.textColor};
    }
`;

export const StSliderHeroItemDescWeb = styled.div.attrs(props => ({
    className: 'StSliderHeroItemDescWeb',
}))`
    text-align: center;
    display: none;
    @media (min-width: ${settings.bp.small.view}) {
        display: block;
    }

    h2 {
        color: ${props => props.textColor};
        font-size: 20px;
        letter-spacing: 0.1em;
        font-weight: 400;
    }
    h1 {
        color: ${props => props.textColor};
        font-size: 26px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: 600;
    }
    position: absolute;
    right: 10%;
    top: 40%;

    .StLink a {
        color: ${props => props.textColor};
        border-color: ${props => props.textColor};
    }
`;

export const StSliderHeroItemDescSecond = styled.div`
    max-width: 55%;
    margin-left: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        max-width: 25%;
        margin-left: 0;
        position: absolute;
        right: 3%;
        bottom: -62px;
    }
`;

export const StSliderHeroImgTextContainer = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        margin-left: 0;
        position: absolute;
        right: 8%;
        top: 40%;
        max-width: 24%;
    }
`;

export const StSliderHeroSmallImgWeb = styled.div`
    img {
        width: 100%;
        height: auto;
    }
    display: none;
    @media (min-width: ${settings.bp.small.view}) {
        display: block;
        position: relative;
        right: 20%;
    }
`;

export const StSliderHeroItemSmallImgText = styled.div``;

export const StSliderHeroImgContainer = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        margin-top: 110px;
    }
    @media (min-width: ${settings.bp.medium.view}) {
        margin-top: 120px;
    }
    @media (min-width: ${settings.bp.large.view}) {
        margin-top: 135px;
    }
    @media (min-width: ${settings.bp.extraLarge.view}) {
        margin-top: 140px;
    }
`;

export const StSliderHeroImgText = styled.div`
    text-align: center;
    display: none;
    @media (min-width: ${settings.bp.small.view}) {
        display: block;
        margin-top: -350px;
    }
    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
        margin-top: -400px;
    }
    @media (min-width: ${settings.bp.large.view}) {
        display: block;
        margin-top: -480px;
    }
    @media (min-width: ${settings.bp.extraLarge.view}) {
        display: block;
        margin-top: -500px;
    }

    h2 {
        color: ${props => props.textColor};
        font-size: 20px;
        letter-spacing: 0.1em;
        font-weight: 400;
    }
    h1 {
        color: ${props => props.textColor};
        font-size: 26px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: 600;
    }
    position: relative;

    .StLink a {
        color: ${props => props.textColor};
        border-color: ${props => props.textColor};
    }
`;

export const SlideWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    @media (min-width: ${settings.bp.small.view}) {
        height: 100%;
        flex-wrap: no-wrap;
    }
`;

export const SliderCol = styled.div`

    width: 50%;
    @media (min-width: ${settings.bp.small.view}) {
        width: 33.3%;
    }
    &:nth-child(1) {
        background-color: #c4c4c4;
        img {
          min-height: 260px;
        }
    }
    &:nth-child(2) {
    }
    &:nth-child(3) {
        img {
          min-height: 260px;
        }
        
        width: 100%;
        @media (min-width: ${settings.bp.small.view}) {
            width: 33.3%;
        }
        background-color: #c8dae1;
        &.colour {
            background-color: ${props =>
                props.backgroundColor ? props.backgroundColor : ' #dcdcdc'};

            ${props =>
                props.backgroundColor == '#000000'
                    ? css`
                          h4,
                          p {
                              color: white !important;
                          }
                          a {
                              color: black;
                              background: white;
                          }
                      `
                    : ``};
        }
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        h4 {
            margin-top: 0;
            margin-bottom: 18px;

            text-align: center;
            color: #000000;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 36px;
            @media (min-width: ${settings.bp.small.view}) {
                font-size: 28px;
                line-height: 36px;
            }
        }
        span {
            font-style: normal;
            font-weight: normal;
            text-align: center;
            display: block;
            margin-top: 0;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 8px;

            @media (min-width: ${settings.bp.small.view}) {
              font-size: 18px;
              line-height: 21px;
              margin-bottom: 24px;
            }
        }
    }

        p {
            margin-top: 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            text-align: center;
            margin-bottom: 0px;
            @media (min-width: ${settings.bp.small.view}) {
              font-size: 14px;
              line-height: 22px;
            }
        }

        a {
            display: inline-block;
            width: 140px;
            height: 50px;
            background: #000000;
            color: white;
            text-decoration: none;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            border-radius: 0;
            border: 0px;
            line-height: 50px;
            margin-top: 24px;

            
            @media (min-width: ${settings.bp.small.view}) {
              margin-top: 40px;

            }
        } 

        .slide-content-wrapper {
          padding-top: 22px;
          padding-bottom: 22px;
          max-width: 343px;
          @media (min-width: ${settings.bp.small.view}) {
              max-width: 273px;
              padding-top: 0px;
              padding-bottom: 0px;
            }
        }
    }
`;

export const SliderImageIn = styled.div`
    height: 100%;
    .gatsby-image-wrapper {
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        max-width: unset;
    }
`;

export const SliderImageInColor = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    max-width: unset;
`;
