import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

const StyledInputGroup = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        justify-content: space-between;
        margin-left: -10px !important;
        margin-right: -10px !important;
    }

    & > * {
        max-width: 100%;
        width: 100%;

        @media (min-width: ${settings.bp.small.view}) {
            margin-left: 10px !important;
            margin-right: 10px !important;

            &:first-child:last-child {
                max-width: calc(50% - 20px);
            }
        }
    }

    ${(props) =>
        props.widthAuto &&
        css`
            @media (min-width: ${settings.bp.small.view}) {
                justify-content: flex-start;

                & > div {
                    width: auto;
                }
            }
        `};
`;

export default function FieldsGroup(props) {
    return <StyledInputGroup {...props} />;
}
