import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';

const StFlexImgText = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
    }
`;

const StFlexImgTextRow = styled.section.attrs((props) => ({
    className: 'StFlexImgTextRow',
}))`
    max-width: 100%;
    @media (min-width: ${settings.bp.small.view}) {
        max-width: 50%;
    }
`;

const StFlexImgTextImg = styled.div.attrs((props) => ({
    className: 'StFlexImgTextImg',
}))`
    max-width: 100px;
    margin: auto;
`;

const StFlexImgTextText = styled.div`
    padding: 0px 30px;
    ${(props) =>
        props.color &&
        css`
            h2 {
                color: ${props.color} !important;
            }
        `};
    ${(props) =>
        props.styleSmall &&
        css`
            h2 {
                font-size: 20px;
                font-weight: 500;
            }
        `};
`;

function FlexImageTextItem(props) {
    const { img, title, text, color, styleSmall } = props;
    return (
        <StFlexImgTextRow>
            {img && (
                <StFlexImgTextImg>
                    <img src={img} alt="" />
                </StFlexImgTextImg>
            )}
            <StFlexImgTextText color={color} styleSmall={styleSmall}>
                {title && <h2>{title}</h2>}
                {text && <p> {text} </p>}
            </StFlexImgTextText>
        </StFlexImgTextRow>
    );
}

export default function FlexImageText(props) {
    const { items, ...restProps } = props;
    return (
        <StFlexImgText {...restProps}>
            {items.map((item, index) => (
                <FlexImageTextItem
                    key={item.id || index}
                    {...item}
                    {...restProps}
                />
            ))}
        </StFlexImgText>
    );
}
