import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import Slider from './Slider';

import settings from '../../global/settings';

import ScrollableBlock from '../ScrollableBlock';

const StSlider = styled.div``;

const StSliderNav = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: ${settings.color.default};
    white-space: nowrap;
    padding-bottom: 15px;

    @media (min-width: ${settings.bp.medium.view}) {
        padding-bottom: 45px;
    }

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    li {
        width: 116px;

        @media (min-width: ${settings.bp.medium.view}) {
            width: 160px;
        }

        @media (min-width: 1300px) {
            width: 240px;
        }
    }

    a {
        text-align: center;
        text-decoration: none;
        display: block;
        cursor: pointer;
        padding: 20px;
        position: relative;

        &:before {
            content: '';
            border-bottom: 2px solid ${settings.color.default};
            left: 10px;
            bottom: 0;
            width: 0;
            position: absolute;
            transition: width 0.3s;
        }

        &.active:before {
            width: calc(100% - 20px);
        }
    }
`;

const StSliderContent = styled.div.attrs((props) => ({
    id: 'StSliderContent',
}))`
    position: relative;
`;

const StSliderContentItem = styled.div`
    width: 260px !important;
    padding: 0 0 0 20px;

    @media (min-width: ${settings.bp.medium.view}) {
        width: 540px !important;
        padding-left: 40px;
    }

    img {
        width: 100%;
    }
`;

const StSliderContentItemText = styled.div`
    padding: 14px 12px;
    color: ${settings.color.gray};
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.05em;

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 40px 50px;
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.08em;
        margin: 0 0 6px;

        @media (min-width: ${settings.bp.medium.view}) {
            margin-bottom: 18px;
        }
    }

    p {
        margin: 0 0 14px;
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

function SliderNavItem(props) {
    const { title, isActive, id, onClickNav } = props;
    return (
        <li data-index={id}>
            <a
                className={isActive ? 'active' : undefined}
                onClick={(e) => {
                    e.preventDefault();
                    onClickNav();
                }}
                href="#"
            >
                {title}
            </a>
        </li>
    );
}

function SliderStage(props) {
    const [activeSlide, setActiveSlide] = useState(0);
    const [swiper, setSwiper] = useState(null);
    const { itemsNav, itemsContent, onClick } = props;

    useEffect(() => {
        const StSlider = document.getElementById('StSliderContent');
        setSwiper(StSlider.querySelector('.swiper-container').swiper);

        if (swiper) {
            swiper.on('slideChange', onSlideChange);
        }
    }, [onSlideChange, swiper]);

    function onSlideChange() {
        const currentSlideIndex = parseInt(
            swiper.slides[swiper.activeIndex].dataset.navSlide
        );
        setActiveSlide(currentSlideIndex);
    }

    function goToSlide(index) {
        if (!swiper) return;

        const currentSlides = Array.prototype.filter.call(
            swiper.slides,
            (item) => parseInt(item.dataset.navSlide) === index
        );
        const currentSlideIndex = parseInt(currentSlides[0].dataset.indexSlide);

        if (currentSlideIndex > swiper.snapGrid.length - 1) {
            swiper.slideTo(swiper.snapGrid.length - 1);
        } else {
            swiper.slideTo(currentSlideIndex);
        }
    }

    return (
        <StSlider>
            <StSliderNav>
                <ScrollableBlock>
                    <ul>
                        {itemsNav.map((item, index) => (
                            <SliderNavItem
                                key={item.id || index}
                                {...item}
                                onClickNav={() => {
                                    setActiveSlide(item.id);
                                    goToSlide(item.id);
                                }}
                                isActive={activeSlide === item.id}
                            />
                        ))}
                    </ul>
                </ScrollableBlock>
            </StSliderNav>
            <StSliderContent>
                <Slider
                    hideProgress
                    settings={{
                        slidesPerView: 'auto',
                        allowTouchMove: false,
                    }}
                >
                    {itemsContent.map((item, index) => (
                        <StSliderContentItem
                            key={index}
                            data-index-slide={index}
                            data-nav-slide={item.id}
                        >
                            <div
                                onClick={() => {
                                    if (onClick) {
                                        onClick(index);
                                    }
                                }}
                            >
                                {item.img && <img src={item.img} alt="" />}
                                {item.text && (
                                    <StSliderContentItemText
                                        dangerouslySetInnerHTML={{
                                            __html: item.text,
                                        }}
                                    />
                                )}
                            </div>
                        </StSliderContentItem>
                    ))}
                </Slider>
            </StSliderContent>
        </StSlider>
    );
}

export default SliderStage;
