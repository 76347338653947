import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import Container from './Container';
import NewVideoLightBox from './NewVideoLightBox';
import * as SharedStyles from '../global/SharedStyles';
const { StLink } = SharedStyles;

export const StVideoWithDesc = styled.div`
    // .container {
    //     display: flex;
    //     flex-direction: column-reverse;

    //     @media (min-width: ${settings.bp.small.view}) {
    //         min-height: 343px;
    //         flex-direction: row;
    //         background-color: #fff;
    //         justify-content: space-between;
    //         &:after {
    //             display: none;
    //         }
    //     }
    // }
`;

const StVideoWithDescText = styled.div`
    padding: 30px;
    background-image: -webkit-repeating-radial-gradient(
        center center,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4) 0,
        transparent 1px,
        transparent 100%
    );
    background-image: -moz-repeating-radial-gradient(
        center center,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4) 0,
        transparent 1px,
        transparent 100%
    );
    background-image: -ms-repeating-radial-gradient(
        center center,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4) 0,
        transparent 1px,
        transparent 100%
    );
    background-image: repeating-radial-gradient(
        center center,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4) 0,
        transparent 1px,
        transparent 100%
    );
    background-size: 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: ${settings.bp.small.view}) {
        width: 50%;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 59px 80px;
    }

    h5 {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.1em;
        margin: 0 0 10px;

        @media (min-width: ${settings.bp.small.view}) {
            margin-bottom: 5px;
        }
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.1em;
        margin: 0 0 10px;
        color: #4a4a4a;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 30px;
            margin-bottom: 20px;
        }
    }

    h3 {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        letter-spacing: 0.1em;
        margin: 0 0 40px;
        display: none;

        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }
    }
`;

export const StVideoWithDescBgView = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    // height: 278px;
    border: 1px solid #dadada;
    & + & {
        margin-top: 24px;
    }

    @media (min-width: ${settings.bp.small.view}) {
        height: auto;
        width: calc(50% - 8px);
        display: flex;
        flex-direction: column;
        & + & {
            margin-top: auto;
        }
    }
`;

export const AmendedVideoLightBox = styled(NewVideoLightBox)`
    height: 194px;
    @media (min-width: ${settings.bp.small.view}) {
        min-height: 343px;
    }
    @media (min-width: 1500px) {
        min-height: calc(343px + 9vw);
    }
`;

export const VideoDesc = styled.div`
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    padding: 5px 10px;
    @media (min-width: ${settings.bp.small.view}) {
        min-height: 82px;
        font-size: 20px;
        line-height: 23px;
        padding-left: 15px;
        padding-right: 15px;
    }
`;

export const ViewAllBtn = styled.button.attrs((props) => ({
    className: 'ViewAllBtn',
}))`
    width: 140px;
    height: 50px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    border: 0;
    text-transform: uppercase;
    color: white;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: 24px;

    &:hover {
        cursor: pointer;
    }
    @media (min-width: ${settings.bp.small.view}) {
        margin-top: 48px;
    }
    ${(props) =>
        props.backgroundColor &&
        css`
            background: ${props.backgroundColor};
        `};
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    @media (min-width: ${settings.bp.small.view}) {
        flex-direction: row;
    }
`;

export default function NewVideoWithDesc(props) {
    const {
        text,
        text2,
        linkMoreText,
        linkMoreHref,
        bgImg,
        bgImg2,
        videoOptions,
        videoOptions2,
        btnBgColor
    } = props;

    let backgroundColor = '#000000';
    if (btnBgColor) {
        backgroundColor = btnBgColor;
    }

    return (
        <StVideoWithDesc>
            <Container>
                <Row>
                    <StVideoWithDescBgView>
                        <AmendedVideoLightBox
                            bgImg={bgImg}
                            videoOptions={videoOptions}
                            autoplay
                            videoType="youtube"
                        />
                        <VideoDesc>
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                        </VideoDesc>
                    </StVideoWithDescBgView>
                    { videoOptions2 && (
                    <StVideoWithDescBgView>
                        <AmendedVideoLightBox
                            bgImg={bgImg2}
                            videoOptions={videoOptions2}
                            autoplay
                            videoType="youtube"
                        />
                        <VideoDesc>
                            <div dangerouslySetInnerHTML={{ __html: text2 }} />
                        </VideoDesc>
                    </StVideoWithDescBgView>
                    )}
                </Row>
                {linkMoreText && linkMoreHref && (
                    <ViewAllBtn backgroundColor={backgroundColor}>
                        <a href={linkMoreHref}>{linkMoreText}</a>
                    </ViewAllBtn>
                )}
            </Container>
        </StVideoWithDesc>
    );
}
