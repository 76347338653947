import React from 'react';

import styled from 'styled-components';

const StScrollTo = styled.div`
    cursor: pointer;
    user-select: none;

    * {
        pointer-events: none;
    }
`;

export default function ScrollTo(props) {
    const { toPosition, toElmPosition, correction } = props;

    function handlerScrollTo(toPosition) {
        if (!isNaN(toPosition)) {
            window.scroll({
                top: toPosition + (correction || 0),
                behavior: 'smooth',
            });
        }
    }

    function getPosition(elm) {
        const isElm = elm && document.getElementById(elm);
        return isElm ? isElm.getBoundingClientRect().top + window.scrollY : 0;
    }

    return (
        <StScrollTo
            onClick={() => {
                handlerScrollTo(toPosition);
                toElmPosition &&
                    setTimeout(() => {
                        handlerScrollTo(getPosition(toElmPosition));
                    }, 200);
            }}
            {...props}
        />
    );
}
