import React from 'react';
import { Link } from 'gatsby-plugin-react-intl';

export default function CustomLink(props) {
    let { children, href, ...restProps } = props;

    let linkChildren = children;
    if (typeof children === 'undefined') {
        linkChildren = ' ';
    }

    if (
        href === null ||
        typeof href === 'undefined' ||
        (href && href.substring(0, 4) === 'http') ||
        (href && href.substring(0, 7) === '/static')
    ) {
        return (
            <a href={href} {...restProps}>
                {linkChildren}
            </a>
        );
    }

    return (
        <Link to={href} {...restProps}>
            {linkChildren}
        </Link>
    );
}
