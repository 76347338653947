import styled, { css } from 'styled-components';

import settings from '../global/settings';

const Container = styled.div.attrs(props => ({
    className: 'container',
}))`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    ${props =>
        props.useBackground &&
        css`
            @media (max-width: calc(${settings.bp.large2.view} - 1px)) {
                background-color: #ffffff80;
                padding: 24px;
            }
        `};

    ${props =>
        !props.fullWidth &&
        css`
            width: ${settings.bp.extraSmall.width};

            @media (min-width: ${settings.bp.small.view}) {
                width: ${settings.bp.small.width};
            }

            @media (min-width: ${settings.bp.medium.view}) {
                width: ${settings.bp.medium.width};

                ${props =>
                    props.useMediumWidth &&
                    css`
                        max-width: ${settings.bp.medium.width};
                    `};

                ${props =>
                    props.useLargeWidth &&
                    css`
                        max-width: 1070px;
                    `};
            }

            @media (min-width: ${settings.bp.large.view}) {
                width: ${settings.bp.large.width};
            }
        `};

    ${props =>
        props.almostFullWidth &&
        css`
            padding-left: 10px;
            padding-right: 10px;
        `};

    ${props =>
        props.unsetMaxWidth &&
        css`
            max-width: unset;
        `};

    ${props =>
        props.mobileWide &&
        css`
            @media (max-width: calc(${settings.bp.large.view} - 1px)) {
                width: 100%;
                max-width: 100%;
            }
        `};

    &:after {
        content: '';
        clear: both;
        display: table;
    }

    img {
        ${props =>
            props.fullWidth &&
            css`
                width: 100%;
            `};
    }
`;

export default Container;
