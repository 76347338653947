import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import VideoLightBox from './VideoLightBox';
import ColsArticle from './ColsArticles/ColsArticle';

import settings from '../global/settings';

const StEvents = styled.div`
    margin-bottom: 24px;
`;

const StEventsWrap = styled.div`
    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
    }
`;

const StEventsWrapCol = styled.div`
    @media (min-width: ${settings.bp.medium.view}) {
        width: 50%;
    }

    &:first-child {
        margin-bottom: 60px;

        @media (min-width: ${settings.bp.medium.view}) {
            margin-bottom: 0;
            padding-right: 40px;
        }
    }
    ${props =>
        props.newStyle &&
        css`
            display: flex;
        `};
`;

const StEventsControl = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.08em;
    font-weight: normal;
    border: solid 1px #979797;
    border-right: none;
    border-left: none;
    padding: 7px 30px 7px 18px;
    position: relative;
    display: none;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
    }

    ${props =>
        props.newStyle &&
        css`
            border-top: none;
        `};
`;

const StEventsControlDate = styled.div`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.06em;
    ${props =>
        props.newStyle &&
        css`
            font-weight: 300;
        `};
`;

const StEventsControlBtn = styled.div`
    position: absolute;
    width: 30px;
    height: 30px;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;

    &:before {
        content: '';
        left: 50%;
        top: 50%;
        width: 10px;
        height: 10px;
        position: absolute;
        transform: translate(-50%, -50%) rotate(45deg);
        border-bottom: 2px solid #979797;
        border-right: 2px solid #979797;
    }

    ${props =>
        props.sortedUp &&
        css`
            &:before {
                transform: translate(-50%, -50%) rotate(-135deg);
            }
        `};

    ${props =>
        props.newStyle &&
        css`
            right: 0;
            left: 340px;
            top: 40%;
            transform: translate(0, -55%);
        `};
`;

const StEventsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
        margin: 0;
    }
`;

const StEventsListItem = styled.div`
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    padding: 10px 0;
    display: flex;
    align-items: center;
    //width: calc(50% - 25px);
    margin: 0 10px 20px;

    @media (min-width: ${settings.bp.medium.view}) {
        border-top: none;
        width: 100%;
        padding: 20px 18px;
        margin: 0;
    }

    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    h3,
    h4 {
        text-transform: uppercase;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 0.08em;
    }

    h3 {
        font-size: 12px;
        text-align: center;
        padding: 0 10px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 16px;
            text-align: left;
            padding: 0;
        }
    }

    h4 {
        font-size: 14px;
        margin-bottom: 6px;
    }

    h5 {
        font-size: 13px;
        letter-spacing: 0.05em;
        font-weight: normal;
    }

    h6 {
        font-size: 9px;
        letter-spacing: 0.03em;
        font-weight: normal;
    }
`;

const StEventsListItemImg = styled.div`
    position: relative;
    width: 85px;
    min-width: 85px;
    height: 85px;

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 28px;
        width: 116px;
        min-width: 116px;
        height: 94px;
    }
`;

const StEventsListItemInfo = styled.div`
    width: 116px;
    min-width: 116px;
    display: none;
    margin-left: auto;
    padding-left: 11px;

    @media (min-width: ${settings.bp.medium.view}) {
        display: block;
    }
    ${props =>
        props.newStyle &&
        css`
            margin-left: 0px !important;
            padding-left: 0px !important;
        `};
`;

function EventsList(props) {
    const [sortedUp, toggleSortedUp] = useState(false);
    const { startOn, onSortChange, onClickEvent, items, newStyle } = props;
    return (
        <>
            {items &&
                (newStyle ? (
                    <StEventsControl newStyle={newStyle}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    lineHeight: 2,
                                    marginRight: '80px',
                                    fontWeight: '600',
                                }}
                            >
                                Start on
                            </div>
                            {startOn && (
                                <StEventsControlDate newStyle={newStyle}>
                                    {startOn}
                                    <StEventsControlBtn
                                        newStyle={newStyle}
                                        sortedUp={sortedUp}
                                        onClick={() => {
                                            toggleSortedUp(!sortedUp);
                                            onSortChange();
                                        }}
                                    />
                                </StEventsControlDate>
                            )}
                        </div>
                    </StEventsControl>
                ) : (
                    <StEventsControl>
                        Start on {newStyle ? '' : <br />}
                        {startOn && (
                            <StEventsControlDate>{startOn}</StEventsControlDate>
                        )}
                        <StEventsControlBtn
                            sortedUp={sortedUp}
                            onClick={() => {
                                toggleSortedUp(!sortedUp);
                                onSortChange();
                            }}
                        />
                    </StEventsControl>
                ))}
            <StEventsList newStyle={newStyle}>
                {items.map((item, index) =>
                    newStyle ? (
                        <NewEventsItem
                            newStyle
                            key={item.id || index}
                            {...item}
                            onClickEvent={onClickEvent}
                        />
                    ) : (
                        <EventsItem
                            key={item.id || index}
                            {...item}
                            onClickEvent={onClickEvent}
                        />
                    )
                )}
            </StEventsList>
        </>
    );
}

function EventsItem(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const {
        img,
        videoOptions,
        title,
        dayWeek,
        date,
        time,
        link,
        ...restProps
    } = props;
    return (
        <a href={link}>
            <StEventsListItem>
                <StEventsListItemImg
                    onClick={e => {
                        e.stopPropagation();
                        setModalOpen(!isModalOpen);
                    }}
                >
                    <VideoLightBox
                        useImgOverlay={videoOptions}
                        btnSizeSmall
                        bgImg={img}
                        videoOptions={videoOptions}
                        isOpen={isModalOpen}
                        {...restProps}
                    />
                </StEventsListItemImg>
                {title && <h3>{title}</h3>}
                <StEventsListItemInfo>
                    {dayWeek && <h4>{dayWeek}</h4>}
                    {date && <h5>{date}</h5>}
                    {time && <h6>{time}</h6>}
                </StEventsListItemInfo>
            </StEventsListItem>
        </a>
    );
}

function NewEventsItem(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const {
        img,
        videoOptions,
        title,
        dayWeek,
        date,
        time,
        link,
        newStyle,
        ...restProps
    } = props;
    return (
        <a href={link}>
            <StEventsListItem newStyle={newStyle}>
                <StEventsListItemImg
                    onClick={e => {
                        e.stopPropagation();
                        setModalOpen(!isModalOpen);
                    }}
                >
                    <VideoLightBox
                        useImgOverlay={videoOptions}
                        btnSizeSmall
                        bgImg={img}
                        videoOptions={videoOptions}
                        isOpen={isModalOpen}
                        {...restProps}
                    />
                </StEventsListItemImg>
                <div>
                    {title && <h3>{title}</h3>}
                    <StEventsListItemInfo newStyle={newStyle}>
                        {dayWeek && (
                            <h4
                                style={{
                                    marginBottom: '0px',
                                    marginTop: '4px',
                                }}
                            >
                                {dayWeek}
                            </h4>
                        )}
                        {date && <h5>{date}</h5>}
                        {time && <h5>{time}</h5>}
                    </StEventsListItemInfo>
                </div>
            </StEventsListItem>
        </a>
    );
}

export default function Events(props) {
    const { startOn, onSortChange, items, mainItem, newStyle } = props;
    return (
        <StEvents>
            <StEventsWrap>
                <StEventsWrapCol newStyle={newStyle}>
                    <ColsArticle
                        newStyle={newStyle}
                        noBackground
                        {...mainItem}
                        positionTypeLeft
                    />
                </StEventsWrapCol>
                <StEventsWrapCol>
                    <EventsList
                        newStyle={newStyle}
                        startOn={startOn}
                        onSortChange={onSortChange}
                        items={items}
                    />
                </StEventsWrapCol>
            </StEventsWrap>
        </StEvents>
    );
}
