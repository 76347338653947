import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import Field from './Field';

const StFieldSelect = styled.div.attrs((props) => ({
    className: 'StFieldSelect',
}))`
    max-width: 100%;
    width: 100%;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        right: 14px;
        top: 8px;
        width: 10px;
        height: 10px;
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(-45deg);
        pointer-events: none;

        @media (min-width: ${settings.bp.small.view}) {
            top: 13px;
            right: 16px;
        }
    }

    select {
        cursor: pointer;
        padding-right: 40px !important;

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
        }
    }
`;

function FieldSelectOption(props) {
    const { label, ...restProps } = props;
    return <option {...restProps}>{label}</option>;
}

export default function FieldSelect(props) {
    const { options, label, desc, id, name, error, ...restProps } = props;
    return (
        <StFieldSelect>
            <Field error={error}>
                <select name={name} id={id} {...restProps}>
                    {options &&
                        options.map((item, index) => (
                            <FieldSelectOption
                                key={item.id || index}
                                {...item}
                            />
                        ))}
                </select>
                {label && <label htmlFor={id}>{label}</label>}
                {desc && <div>{desc}</div>}
            </Field>
        </StFieldSelect>
    );
}
