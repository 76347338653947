import React from 'react';

const TikTokPixel = () => {
    const scripts = [];
    scripts.push(
        <script key="function" id="tiktok-pixel-script">
            {`!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for( var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script") ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)}; ttq.load('CB3NLKJC77U7JD3PC9TG'); ttq.page(); }(window, document, 'ttq'); `}
        </script>
    );

    return scripts;
};

export const trackTTBuyNowEvent = async (isPro, productId) => {
    const eventId = `${new Date().getTime()}${Math.floor(
        100000 + Math.random() * 900000
    )}`;
    await capiTTBuyNowEvent(eventId, isPro, productId);

    return typeof window !== 'undefined' && window?.ttq
        ? ttq.track('AddToCart', { event_id: eventId, content_id: productId })
        : console.log('no tt pixel');
};

const capiTTBuyNowEvent = async (eventId, isPro, productId) => {
    try {
        const data = {
            event: 'AddToCart',
            event_time: Math.floor(new Date().getTime() / 1000),
            event_id: eventId,
            content_id: productId,
        };
        await fetch('/.netlify/functions/tikTokCAPI', {
            method: 'POST',
            body: JSON.stringify(data),
        });
    } catch (error) {
        console.log(error);
    }
};

export default TikTokPixel;
