import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import ScrollableBlock from './ScrollableBlock';

const StStyleMemory = styled.div`
    white-space: nowrap;
    color: ${(props) => props.colorDecor};
`;

const StStyleMemoryItem = styled.div`
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
    width: 200px;

    @media (min-width: ${settings.bp.small.view}) {
        margin: 0 25px;
        width: 280px;
    }
`;

const StStyleMemoryImg = styled.div`
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 5px;
    border: 3px solid ${(props) => props.colorDecor};

    img {
        width: 100%;
    }
`;

const StStyleMemoryTitle = styled.div`
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    white-space: normal;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 24px;
    }
`;

function StyleMemoryItem(props) {
    const { img, title, colorDecor } = props;
    return (
        <StStyleMemoryItem>
            <StStyleMemoryImg colorDecor={colorDecor}>
                <img src={img} alt={title} />
            </StStyleMemoryImg>
            <StStyleMemoryTitle>{title}</StStyleMemoryTitle>
        </StStyleMemoryItem>
    );
}

export default function StyleMemory(props) {
    const { items, colorDecor } = props;
    return (
        <StStyleMemory colorDecor={colorDecor}>
            <ScrollableBlock>
                {items.map((item, index) => (
                    <StyleMemoryItem
                        key={item.id || index}
                        {...item}
                        colorDecor={colorDecor}
                    />
                ))}
            </ScrollableBlock>
        </StStyleMemory>
    );
}
