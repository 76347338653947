import styled from 'styled-components';

import settings from '../global/settings';

export const StPageFooter = styled.footer`
    background-color: #fff;
    position: relative;
    z-index: 9;
`;

export const StPageFooterTop = styled.div`
    border: 1px solid ${settings.color.grayLight2};
    border-left: none;
    border-right: none;
    padding: 24px 0 40px;
    text-align: center;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 0;
        text-align: left;
    }

    .StSocial {
        justify-content: center;

        @media (min-width: ${settings.bp.small.view}) {
            justify-content: flex-start;
        }
    }
`;

export const StPageFooterBottom = styled.div`
    text-align: center;
    font-size: 9px;
    line-height: 1.5;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 18px 0;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 13px;
        padding: 20px 0;
    }
`;

export const StPageFooterInner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding-top: 70px;

    @media (min-width: ${settings.bp.small.view}) {
        flex-direction: row;
        padding-top: 0;
    }
`;

export const StPageFooterCol = styled.div`
    width: 100%;

    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
        align-items: center;
    }

    &:first-child {
        @media (min-width: ${settings.bp.small.view}) {
            justify-content: center;
            padding: 18px 20px 15px 0;
        }

        @media (min-width: ${settings.bp.medium.view}) {
            padding: 15px 20px 15px 0;
        }

        @media (min-width: ${settings.bp.large.view}) {
            padding: 15px 30px;
        }
    }

    &:last-child {
        @media (min-width: ${settings.bp.small.view}) {
            border-left: 1px solid ${settings.color.grayLight2};
            padding: 15px 0 15px 20px;
        }

        @media (min-width: ${settings.bp.large.view}) {
            padding: 15px 30px;
        }
    }
`;

export const StPageFooterSocial = styled.div`
    margin-bottom: 30px;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 20px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 4%;
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.large.view}) {
        margin-right: 15%;
    }
`;

export const StPageFooterNav = styled.div`
    position: relative;
    top: 2px;
    letter-spacing: 0.065em;
    font: normal 16px/1.15 ${settings.font.roboto};

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 13px;
        font-weight: 500;
    }

    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 10px;

        @media (min-width: ${settings.bp.small.view}) {
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:not(:first-child) {
            margin-left: 14px;
        }
    }

    a {
        color: ${settings.color.dark};
        display: block;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const StPageFooterForm = styled.div`
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    form {
        width: 100%;
        display: flex;

        .msg-alert {
            position: absolute;
            left: 0;
            bottom: calc(100% + 5px);
            color: #fff;
            border-radius: 4px;
            font-weight: 500;

            p {
                padding: 5px 10px;
                margin: 0;
            }
        }

        .mailchimp-wrapper {
            width: 100%;
        }

        .subscribe-wrapper {
            width: 100%;
        }

        .signinup-wrapper {
            width: 100%;
        }

        .field-radio {
            margin: 10px 10px 0;
            display: inline-block;

            @media (min-width: ${settings.bp.small.view}) {
                margin: 10px 20px 0 0;
            }

            input {
                position: absolute;
                left: -9999px;
                visibility: hidden;
                opacity: 0;

                &:checked + label {
                    &:after {
                        width: 6px;
                        height: 6px;
                        left: 4px;
                        top: 5px;
                        background-color: #000;
                    }
                }
            }

            label {
                cursor: pointer;
                position: relative;
                padding-left: 20px;
                user-select: none;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                }

                &:before {
                    left: 0;
                    top: 1px;
                    width: 14px;
                    height: 14px;
                    border: 2px solid #000;
                }
            }
        }

        .field-label {
            margin: 10px 10px 0;

            @media (min-width: ${settings.bp.small.view}) {
                margin: 10px 20px 0 0;
            }
        }
    }

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 20px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 10%;
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.large.view}) {
        margin-right: 15%;
    }

    .StField {
        margin-bottom: 0;
    }

    input {
        max-width: 100%;
        width: 100%;
        padding: 13px 17px !important;
        height: auto !important;
        text-align: left;

        @media (min-width: ${settings.bp.small.view}) {
            max-width: 333px;
        }

        @media (min-width: ${settings.bp.medium.view}) {
            max-width: none;
            border: none !important;
        }
    }

    button {
        cursor: pointer;
        border: none;
        padding: 0;
        margin: 10px 0 0 16px;
        border-radius: 0;
        min-width: 21px;
        max-width: 21px;
        height: 20px;
        background-color: transparent;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjCggNJAlT7DmTAAACwklEQVRo3u2ZP2gTURzHP9dGYkVtRQlWHRTbKhFRUIgSFBwKFgt2UKpUpV0UHQzFQd061sFBcVFctBFEN+vin6EgLbjYgrTUxlhBpaUqtbnapPZPHGLkcu/d5e7y55b73ZL73e99P99L3r337gW88MILl0PRfK4iSC1VJSYmmWCYlD69g0f8Jl2mY5YoDVp8B6mywbNHkvYsvo3lsuPTpFnmNCgEiLHWpR6o0lDJdRpdwoOfpMIQe1wzAEMKCdYI6UnOMF5k1DaibBSyCQw6yA8OFxV/gEkDkmEfTXG2aPiTzBlyTB+TbioKhitcNX3M8zyrTwocmv305CHknIxKCgYIOMYHGJAojhob6KSDeaHBF/Y6wu/ik6C1wEU6zQxAmCmhWYJm2/hGpgWdnxyBfAZgOyNC00Uu28Kf54+gEWMnWDEA63gt+e3u4rMEr6Rb0voNG/5dt2AAfNyRiLygOi9+Nc8kLe+z4n+FJQMAERYFofdsNcVv5p1kPOnKqbFsAI4yI8h955AhPsSEUK9yXFdlwwDs5rMgmaJNij8hWdR9ZZ9QZ8sA1PJW+qUquroIS0LdIFskijYNgJ+opFs91gzSfh5IKp6ySqpn2wAodEkmlOwgvZ4+Cf6W4UTmwABAq2RKjROkng+SXnLORMmhAfkgPS0ZcKcIm+rkGLAz3/cTYliXq6FGlxkjTL91UXsLjnEO8ty04hUhYnYk7a54VFq4bXj1Hsf4ZU/Q/pJriQgXWJDkr0nzRTeQudNm3Z2qtHDDiZS1CVaMl+znCnUoQJqP3CTuTMipAYhzyXFbTRS+7PYMeAY8A56BAsOHqtmiaaKPmRITq2nSnCXc3qQarKDXRTz0KgQYs/DGV5pIZHaMT7m2VduaddJOsuz4udylez09zJYNrvKQugxY+4a3kiCbyvCHxTdGmHep13nhhRdi/AWISNayLjUu3gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0xMC0wOFQxMzozNjowOSswMDowMOPHZysAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMTAtMDhUMTM6MzY6MDkrMDA6MDCSmt+XAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==');
        font-size: 0;
    }
`;

export const StPageFooterFormBtn = styled.button`
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0 0 0 16px;
    border-radius: 0;
    min-width: 21px;
    max-width: 21px;
    height: 20px;
    background-color: transparent;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjCggNJAlT7DmTAAACwklEQVRo3u2ZP2gTURzHP9dGYkVtRQlWHRTbKhFRUIgSFBwKFgt2UKpUpV0UHQzFQd061sFBcVFctBFEN+vin6EgLbjYgrTUxlhBpaUqtbnapPZPHGLkcu/d5e7y55b73ZL73e99P99L3r337gW88MILl0PRfK4iSC1VJSYmmWCYlD69g0f8Jl2mY5YoDVp8B6mywbNHkvYsvo3lsuPTpFnmNCgEiLHWpR6o0lDJdRpdwoOfpMIQe1wzAEMKCdYI6UnOMF5k1DaibBSyCQw6yA8OFxV/gEkDkmEfTXG2aPiTzBlyTB+TbioKhitcNX3M8zyrTwocmv305CHknIxKCgYIOMYHGJAojhob6KSDeaHBF/Y6wu/ik6C1wEU6zQxAmCmhWYJm2/hGpgWdnxyBfAZgOyNC00Uu28Kf54+gEWMnWDEA63gt+e3u4rMEr6Rb0voNG/5dt2AAfNyRiLygOi9+Nc8kLe+z4n+FJQMAERYFofdsNcVv5p1kPOnKqbFsAI4yI8h955AhPsSEUK9yXFdlwwDs5rMgmaJNij8hWdR9ZZ9QZ8sA1PJW+qUquroIS0LdIFskijYNgJ+opFs91gzSfh5IKp6ySqpn2wAodEkmlOwgvZ4+Cf6W4UTmwABAq2RKjROkng+SXnLORMmhAfkgPS0ZcKcIm+rkGLAz3/cTYliXq6FGlxkjTL91UXsLjnEO8ty04hUhYnYk7a54VFq4bXj1Hsf4ZU/Q/pJriQgXWJDkr0nzRTeQudNm3Z2qtHDDiZS1CVaMl+znCnUoQJqP3CTuTMipAYhzyXFbTRS+7PYMeAY8A56BAsOHqtmiaaKPmRITq2nSnCXc3qQarKDXRTz0KgQYs/DGV5pIZHaMT7m2VduaddJOsuz4udylez09zJYNrvKQugxY+4a3kiCbyvCHxTdGmHep13nhhRdi/AWISNayLjUu3gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0xMC0wOFQxMzozNjowOSswMDowMOPHZysAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMTAtMDhUMTM6MzY6MDkrMDA6MDCSmt+XAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==');
`;

export const StPageFooterBtnToTop = styled.div`
    text-transform: uppercase;
    font-size: 9px;
    line-height: 1.35;
    letter-spacing: 0.08em;
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin-left: auto;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 13px;
        position: static;
        transform: none;
        white-space: nowrap;
        text-align: left;
    }
`;

export const StPageFooterBtnToTopIco = styled.div`
    border-radius: 50%;
    border: 2px solid #000;
    min-width: 33px;
    width: 33px;
    height: 33px;
    margin: 0 auto 10px;
    position: relative;

    @media (min-width: ${settings.bp.small.view}) {
        display: inline-block;
        vertical-align: middle;
        margin: -4px 15px 0 0;
        min-width: 22px;
        width: 22px;
        height: 22px;
    }

    &:before {
        position: absolute;
        left: 50%;
        top: calc(50% + 2px);
        content: '';
        transform: translate(-50%, -50%) rotate(135deg);
        width: 10px;
        height: 10px;
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;

        @media (min-width: ${settings.bp.small.view}) {
            width: 6px;
            height: 6px;
        }
    }
`;
