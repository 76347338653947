import React from 'react';

import styled from 'styled-components';

import settings from '../global/settings';

const StModal = styled.div`
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
`;

const StModalWrapper = styled.div`
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 450px;
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
`;

const StModalContent = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
`;

const StCloseButton = styled.div`
    color: #aaa;
    float: right;
    margin-right: 14px;
    font-size: 28px;
    &:hover {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const StLogo = styled.div`
    width: 30%;
    margin: auto;
    padding-bottom: 20px;
`;

const StModalTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 600;
    letter-spacing: 0.07em;
`;

const StModalSubTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    opacity: 0.5;
`;

const StModalText = styled.div`
    padding: 8px 14px;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    opacity: 0.5;
`;

export const StForm = styled.div`
    margin-bottom: 20px;
    padding-bottom: 50px;
    align-items: center;
    width: 100%;
    position: relative;
    display: flex;

    form {
        width: 100%;

        .msg-alert {
            position: absolute;
            left: 25%;
            top: calc(100% - 30px);
            color: #fff;
            border-radius: 0px;
            font-weight: 500;
            max-width: 50%;
            width: 50%;
            text-align: center;

            p {
                padding: 5px 10px;
                margin: 0;
            }
        }

        .mailchimp-wrapper {
            width: 100%;
        }

        .field-radio {
            margin: 10px 10px 0;
            display: inline-block;

            @media (min-width: ${settings.bp.small.view}) {
                margin: 20px 25px 0 45px;
            }

            input {
                position: absolute;
                left: -9999px;
                visibility: hidden;
                opacity: 0;

                &:checked + label {
                    &:after {
                        width: 10.5px;
                        height: 10.5px;
                        left: 1.5px;
                        top: 2.5px;
                        background-color: #000;
                    }
                }
            }

            label {
                cursor: pointer;
                position: relative;
                padding-left: 20px;
                user-select: none;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                }

                &:before {
                    left: 0;
                    top: 1px;
                    width: 14px;
                    height: 14px;
                    border: 2px solid #000;
                }
            }
        }
    }

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 20px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 10%;
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.large.view}) {
        margin-right: 15%;
    }

    .StField {
        margin-bottom: 0;
    }

    input {
        padding: 13px 17px !important;
        height: auto !important;
        text-align: left;
        border: 1px solid #00000020;
        margin: 12px 20px;
        letter-spacing: 0.01em;
        max-width: calc(100% - 40px);
        width: calc(100% - 40px);
    }

    button {
        cursor: pointer;
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 20px;
        height: 60px;
        padding: 0;
        border-radius: 0;
        background-color: #000;
        color: #fff;
        font-size: 10pt;
        text-transform: uppercase;
    }
`;

export default function LanguageSwitcherModal(props) {
    const {
        onClick,
        logo,
        languageTitle,
        regionTitle,
        languages,
        regions,
        onLanguageClick,
        onRegionClick,
        currentLocale,
        currentRegion,
    } = props;
    return (
        <StModal
            onClick={(event) => {
                if (event.target === event.currentTarget && onClick) {
                    onClick();
                }
            }}
        >
            <StModalWrapper>
                <StCloseButton onClick={onClick}>&times;</StCloseButton>
                <StModalContent>
                    {logo && logo.src && (
                        <StLogo>
                            <img src={logo.src} alt={logo.alt} />
                        </StLogo>
                    )}
                    {languageTitle && (
                        <StModalTitle>{languageTitle}</StModalTitle>
                    )}
                    {languages &&
                        languages.map((language, index) => (
                            <StModalText
                                key={index}
                                onClick={() => onLanguageClick(language.code)}
                                style={{
                                    color:
                                        currentLocale === language.code
                                            ? `red`
                                            : `black`,
                                }}
                            >
                                {language.text}
                            </StModalText>
                        ))}
                    {regionTitle && <StModalTitle>{regionTitle}</StModalTitle>}
                    {regions &&
                        regions.map((region, index) => (
                            <StModalText
                                key={index}
                                onClick={() => onRegionClick(region.code)}
                                style={{
                                    color:
                                        currentRegion === region.code
                                            ? `red`
                                            : `black`,
                                }}
                            >
                                {region.text}
                            </StModalText>
                        ))}
                </StModalContent>
            </StModalWrapper>
        </StModal>
    );
}
