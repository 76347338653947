import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

const StEducatorApplicationRoot = styled.div`
    display: flex;
    flex-direction: row;
`;

const StEducatorApplication = styled.div`
    ${(props) =>
        props.colorWhite &&
        css`
            color: #fff;

            * {
                color: #fff;
            }
        `};
`;

const StEducatorApplicationLogo = styled.div`
    text-align: center;
    margin: 0 auto 80px;
`;

const StEducatorApplicationWrap = styled.div``;

const StEducatorApplicationRow = styled.div`
    margin-bottom: 60px;

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 20px;
    }

    @media (max-width: calc(${settings.bp.small.view} - 1px)) {
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const StEducatorApplicationInner = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
    }
`;

const StEducatorApplicationRowTitle = styled.h3`
    margin: 0 0 20px;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.07em;
    text-transform: uppercase;
`;

const StEducatorApplicationItem = styled.div`
    font-size: 16px;
    line-height: 1.63;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.small.view}) {
        margin-right: 40px;
        margin-bottom: 0;
        width: 450px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 70px;
    }

    h4 {
        margin: 0 0 6px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        width: 400px;
    }

    p {
        margin: 0 0 14px;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

const StEducatorApplicationItemText = styled.div``;

function EducatorApplicationRowItem(props) {
    const { title, text } = props;
    return (
        <StEducatorApplicationItem>
            {title && <h4>{title}</h4>}
            <StEducatorApplicationItemText
                dangerouslySetInnerHTML={{ __html: text }}
            />
        </StEducatorApplicationItem>
    );
}

function EducatorApplicationRow(props) {
    const { title, items } = props;
    return (
        <StEducatorApplicationRow>
            {title && (
                <StEducatorApplicationRowTitle>
                    {title}
                </StEducatorApplicationRowTitle>
            )}
            <StEducatorApplicationInner>
                {items.map((item, index) => (
                    <EducatorApplicationRowItem
                        key={item.id || index}
                        {...item}
                    />
                ))}
            </StEducatorApplicationInner>
        </StEducatorApplicationRow>
    );
}

export default function ContentCreatorApplication(props) {
    const { colorWhite, logo, items, bgImg } = props;
    return (
        <StEducatorApplicationRoot>
        <StEducatorApplication colorWhite={colorWhite}>
            {logo && (
                <StEducatorApplicationLogo>
                    <img src={logo} alt="loyalty logo" />
                </StEducatorApplicationLogo>
            )}
            <StEducatorApplicationWrap>
                {items.map((item, index) => (
                    <EducatorApplicationRow key={item.id || index} {...item} />
                ))}
            </StEducatorApplicationWrap>
        </StEducatorApplication>
        <div>
            <img src={bgImg} />
        </div>
        </StEducatorApplicationRoot>
    );
}
