import styled, { css } from 'styled-components';
import settings from '../../global/settings';

export const StSliderFavorite2 = styled.div`
    width: 346px !important;
    padding: 0 0 0 20px;
    overflow: hidden;

    @media (min-width: ${settings.bp.medium.view}) {
        min-width: 480px !important;
        width: 33.333vw !important;
        padding-left: 0;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export const StSliderFavorite2Desc = styled.div`
    width: 100%;
    padding: 20px 0 0;
    display: flex;

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 30px;
        text-align: center;
        display: block;
    }

    h2 {
        font-weight: 500;
        margin: 0;
        letter-spacing: 0.08em;
        font-size: 16px;
        line-height: 1.25;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 22px;
            line-height: 0.91;
            margin: 0 0 26px;
        }
    }

    h3 {
        font-size: 16px;
        line-height: 1;
        margin: 0 0 40px;
        letter-spacing: 0.05em;
        font-weight: normal;
    }

    .StLink,
    h3 {
        display: none;

        @media (min-width: ${settings.bp.medium.view}) {
            display: block;
        }
    }
`;

export const StSliderFavorite2Img = styled.div`
    position: relative;
    z-index: 1;
    margin: 0 0 40px;

    img {
        width: auto;
        height: auto;
    }

    @media (max-width: calc(${settings.bp.medium.view} - 1px)) {
        margin-bottom: 0;
        width: 110px;
        min-width: 110px;
        min-height: 60px;

        img {
            width: auto;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
            max-width: 80%;
        }
    }
`;
