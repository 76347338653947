import React from 'react';

import styled from 'styled-components';

import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore, StLink } = SharedStyles;
import CustomLink from './CustomLink';

const StBanner = styled.div.attrs((props) => ({
    className: 'StBanner',
}))`
  display: block;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width: ${settings.bp.small.view}) {
    flex: 1;
    flex-direction row;
    text-transform: uppercase;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .StButtonLink a {
    color: ${(props) => props.textColor};
    border-color: ${(props) => props.textColor} !important;
  }

  .StButtonLinkMobile a {
    color: ${(props) => props.textColor};
    border-color: ${(props) => props.textColor} !important;
  }

  .StBannerText {
    color: ${(props) => props.textColor}; 
  }
`;

const StButtonLink = styled.div.attrs((props) => ({
    className: 'StButtonLink',
}))`
    display: none;
    @media (min-width: ${settings.bp.small.view}) {
        display: inline-block;
    }
    padding-left: 12px;
    a {
        font-size: 10px;
        font-weight: 500;

        &:before {
            display: none;
        }
    }
`;

const StButtonLinkMobile = styled.div.attrs((props) => ({
    className: 'StButtonLinkMobile',
}))`
    display: block;
    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }
    a {
        font-size: 10px;
        font-weight: 500;

        &:before {
            display: none;
        }
    }
`;

const StBannerText = styled.div.attrs((props) => ({
    className: 'StBannerText',
}))`
    font-size: 10px;
    text-transform: uppercase;
    @media (min-width: ${settings.bp.small.view}) {
        display: inline-block;
    }
`;

export default function Nav(props) {
    const {
        bannerText,
        bannerButtonText,
        bannerButtonLink,
        bannerColor,
        bannerTextColor,
    } = props;

    return (
        <StBanner
            style={{ backgroundColor: bannerColor }}
            textColor={bannerTextColor}
        >
            {bannerText && <StBannerText>{bannerText}</StBannerText>}
            {bannerButtonLink && bannerButtonText && (
                <>
                    <StButtonLink>
                        <StLinkMore inversionColor styleSmall>
                            <CustomLink href={bannerButtonLink}>
                                {bannerButtonText}
                            </CustomLink>
                        </StLinkMore>
                    </StButtonLink>
                    <StButtonLinkMobile>
                        <StLink>
                            <CustomLink href={bannerButtonLink}>
                                {bannerButtonText}
                            </CustomLink>
                        </StLink>
                    </StButtonLinkMobile>
                </>
            )}
        </StBanner>
    );
}
