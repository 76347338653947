import React from 'react'
import styled, { css } from 'styled-components'
import settings from '../../kenra-storybook/global/settings'
import {
    SliderBestSeller,
    SliderBestSellerItem,
    SliderBestSellerItemWrapper,
    Container,
    Subtitle,
} from '../../kenra-storybook/index'
import { useIntl, Link } from 'gatsby-plugin-react-intl'

import { getProductUrl } from 'helpers/url'
import get from 'lodash.get'

const swiperUseEffect = function (swiper) {
    const slides = swiper.slides
    Object.values(slides).forEach(elem => {
        if (
            elem.querySelector &&
            elem.classList.contains('swiper-slide-duplicate')
        ) {
            if (elem.querySelector('picture > img')) {
                elem.querySelector('picture > img').style.opacity = 1
            }
        }
    })
}

const Heading = styled.div`
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.676px;
    text-transform: uppercase;
    text-align: left;
    padding-left: 23px;
    @media (min-width: ${settings.bp.medium.view}) {
        padding-left: 50px;
        font-size: 22px;
        letter-spacing: 1px;
    }
    ${props =>
        props.alignCenter &&
        css` 
        text-align: center;
        @media (min-width: ${settings.bp.medium.view}) {
            margin-bottom: -20px;
        }
    `};
`

const StStarsContainer = styled.div`
    flex: 1; 
    display: flex;
    text-align: center;
    margin-bottom: -16px;
    max-width: 100px;
    align-self: center;
    @media (min-width: ${settings.bp.medium.view}) {
        padding-top: 10px;
        max-width: 150px;
    }
`

const StarsImage = (url) => {
    return <StStarsContainer>
        <img style={{ maxWidth: '100%' }} src={url} />
    </StStarsContainer>
}


const BestSellerSlide = ({
    title,
    subtitle,
    products,
    shopifyProducts,
    allGoogleSheetProductsRow,
    magicMousse,
    hideDots,
    removeWidth,
    alignCenter,
    externalShopNow,
    increasePadding,
    isPro,
}) => {
    let intl = useIntl()
    const disableGifs = magicMousse
    return (
        <>
            {subtitle && <Container>
                <Subtitle>{subtitle}</Subtitle>
            </Container>}
            {
                title && magicMousse && (
                    <Heading alignCenter={alignCenter}>{title}</Heading>
                )
            }
            <SliderBestSeller
                heading={magicMousse ? null : title}
                swiperUseEffect={swiper => swiperUseEffect(swiper)}
                showDots={!hideDots && magicMousse}
                newArrowStyle={magicMousse}
                numberOfItems={products.length}
            >
                {products.map((product, index) => {
                    let handle = product?.shopifyProduct?.handle
                    let shopifyProduct = shopifyProducts?.find(
                        p => p.handle === handle
                    )
                    if (!product || !shopifyProduct) return null

                    let title = product.title
                    let description = product.description
                    let url = getProductUrl(handle)
                    let homepageImage = get(
                        product,
                        'homepageImage.localFile.publicURL'
                    )
                    let gifOverlayImage = get(
                        product,
                        'gifOverlayImage.localFile.publicURL'
                    )

                    let productImage = get(
                        product,
                        'productItem.images[0].localFile.publicURL'
                    )

                    let storedRegion = ''
                    let skus = []
                    let productItem = get(product, 'productItem')

                    if (typeof window !== 'undefined') {
                        storedRegion = localStorage.getItem('preferred-region')
                    }



                    for (
                        let shopifyLoop = 0;
                        shopifyLoop < shopifyProduct.variants.length;
                        shopifyLoop++
                    ) {
                        if (
                            typeof productItem.variants[shopifyLoop] ===
                            'undefined'
                        ) {
                            console.log(
                                'Product Item does not have matching variant for: ',
                                shopifyProduct.variants[shopifyLoop]
                            )
                            skus.push(shopifyProduct.variants[shopifyLoop].sku)
                            continue
                        }

                        // Does the product exist for the caAssortment?
                        if (storedRegion !== 'CA') {
                            skus.push(shopifyProduct.variants[shopifyLoop].sku)
                        } else {
                            allGoogleSheetProductsRow.nodes.forEach(
                                productInfo => {
                                    let shopifySku = parseInt(
                                        shopifyProduct.variants[shopifyLoop].sku
                                    )
                                    if (
                                        shopifySku === productInfo.sku &&
                                        productInfo.caAssortment === 'Y'
                                    ) {
                                        let productItemIDH = productInfo.canIdh
                                        if (
                                            productItemIDH !== null &&
                                            typeof productItemIDH !==
                                            'undefined' &&
                                            !skus.includes(
                                                productItemIDH.toString()
                                            )
                                        ) {
                                            skus.push(
                                                productItemIDH.toString()
                                            )
                                        } else if (
                                            !skus.includes(
                                                shopifyProduct.variants[
                                                    shopifyLoop
                                                ].sku
                                            )
                                        ) {
                                            skus.push(
                                                shopifyProduct.variants[
                                                    shopifyLoop
                                                ].sku
                                            )
                                        }
                                    }
                                }
                            )
                        }
                    }
                    return (
                        <SliderBestSellerItemWrapper
                            key={`${product.id}_${index}`}
                        >
                            <SliderBestSellerItem
                                title={title}
                                description={magicMousse ? null : description}
                                url={url}
                                productImage={productImage}
                                gifOverlayImage={disableGifs ? null : gifOverlayImage}
                                homepageImage={homepageImage}
                                productId={skus[0]}
                                shopButtonStyle={magicMousse ? '3' : null}
                                starsImage={product.reviewStarsImage && StarsImage(product.reviewStarsImage)}
                                removeWidth={removeWidth}
                                externalShopNow={externalShopNow}
                                increasePadding={increasePadding}
                                isPro={isPro}
                            />
                        </SliderBestSellerItemWrapper>
                    )
                })}
            </SliderBestSeller>
        </>
    )
}

export default BestSellerSlide
