import React from 'react';
import styled, { css } from 'styled-components';

import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore } = SharedStyles;
import CustomLink from './CustomLink';

const StTextureProductList = styled.div.attrs((props) => ({
    className: 'StTextureProductList',
}))`
    ${(props) =>
        props.center &&
        css`
            align-items: center;
            justify-content: center;
        `};

    display: flex;
    flex-wrap: wrap;
`;

const StTextureProductListItem = styled.div.attrs((props) => ({
    className: 'StTextureProductListItem',
}))`
    margin-bottom: 60px;
    padding: 0 20px;
    width: 50%;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 100px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        width: 33.333%;
    }

    @media (min-width: ${settings.bp.large.view}) {
        padding: 0 30px;
        margin-bottom: 140px;
    }

    .StTabProductListItemImg {
        padding-bottom: 140%;
        position: relative;

        img {
            position: absolute;
            left: 50%;
            top: 100%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -100%);
        }
    }
`;

const StTextureProductItem = styled.div.attrs((props) => ({
    className: 'StTextureProductItem',
}))`
    text-align: center;
    color: ${settings.color.gray};
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.05em;

    h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000;
        margin: 0 0 5px;

        a {
            color: currentColor;
            text-decoration: none;
        }
    }

    p {
        margin: 0;
    }

    .StLinkMore {
        margin-top: 18px;

        a {
            min-width: 118px;
        }
    }
`;

const StTextureProductItemImg = styled.div.attrs((props) => ({
    className: 'StTabProductListItemImg',
}))`
    margin-bottom: 44px;
    position: relative;
`;

const StTextureProductCategoryItem = styled.div.attrs((props) => ({
    className: 'StTextureProductCategoryItem',
}))`
    min-width: 83px;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    text-align: center;
    margin: 10px auto;
    text-transform: uppercase;
    padding: 8px;

    ${(props) =>
        props.categoryColor &&
        css`
            background-color: ${props.categoryColor};
        `};
`;

const StTextureProductCategoryHover = styled.div.attrs((props) => ({
    className: 'StTextureProductCategoryHover',
}))`
    width: 200px;
    font-size: 12px;
    text-align: left;
    margin: 10px auto;
    padding: 8px;
    border: 1px solid #000;
    display: none;
    position: absolute;
    transform: translate(-30%, 0px);
    z-index: 999;
    background: #fff;
`;

const StTextureProductCategory = styled.div.attrs((props) => ({
    className: 'StTextureProductCategory',
}))`
    @media (min-width: ${settings.bp.medium.view}) {
        &:hover {
            cursor: pointer;
            & > .StTextureProductCategoryHover {
                display: block;
            }
        }
    }

    &:not(:last-child) {
        margin-right: 10px;
        @media (max-width: ${settings.bp.medium.view}) {
            margin-right: 0;
        }
    }
`;

const StTextureProductCategoryList = styled.div.attrs((props) => ({
    className: 'StTextureProductCategoryList',
}))`
    display: flex;
    justify-content: center;
    @media (max-width: ${settings.bp.medium.view}) {
        display: block;
    }
`;

function TextureProductItem(props) {
    const {
        link,
        btnLinkText,
        btnLinkHref,
        img,
        title,
        text,
        category,
        variants,
    } = props;
    return (
        <StTextureProductItem>
            <StTextureProductItemImg>
                <CustomLink href={link}>
                    <img src={img} alt={title} />
                </CustomLink>
            </StTextureProductItemImg>
            {title && (
                <h2>
                    <CustomLink href={link}>{title}</CustomLink>
                </h2>
            )}
            <p>{text}</p>
            <StTextureProductCategoryList>
                {category &&
                    category.map((categoryItem, index) => (
                        <StTextureProductCategory key={index}>
                            <StTextureProductCategoryItem
                                categoryColor={categoryItem.color}
                            >
                                {categoryItem.text}
                            </StTextureProductCategoryItem>
                            <StTextureProductCategoryHover>
                                {categoryItem.description}
                            </StTextureProductCategoryHover>
                        </StTextureProductCategory>
                    ))}
            </StTextureProductCategoryList>
            {btnLinkHref && (
                <StLinkMore>
                    <CustomLink href={btnLinkHref}>
                        {btnLinkText || 'Shop'}
                    </CustomLink>
                </StLinkMore>
            )}
        </StTextureProductItem>
    );
}

export default function TextureProductList(props) {
    const { items, center } = props;

    return (
        <StTextureProductList center={center}>
            {items.map((item, index) => (
                <StTextureProductListItem key={item.id || index}>
                    <TextureProductItem {...item} />
                </StTextureProductListItem>
            ))}
        </StTextureProductList>
    );
}
