import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

const StZoomImgWrap = styled.div.attrs((props) => ({
    className: 'StZoomImgWrap',
}))`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
        display: block;
        height: auto;
        width: auto;
        pointer-events: none;
    }

    ${(props) =>
        props.useSquare &&
        css`
            padding-bottom: 100%;
            width: 100%;
            height: 0;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        `};

    &:hover {
        img {
            opacity: 0;
        }

        .StZoomImg {
            opacity: 1;
        }
    }
`;

const StZoomImg = styled.div.attrs((props) => ({
    className: 'StZoomImg',
}))`
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
`;

export default function ZoomImg(props) {
    const [position, setPosition] = useState('50% 50%');
    const { useSquare, bgColor, img, alt, ...restProps } = props;
    const elmImg = useRef();

    function handleMouseMove(e) {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        if (
            !elmImg ||
            !elmImg.current ||
            (elmImg.current.naturalWidth <= width &&
                elmImg.current.naturalHeight <= height)
        )
            return;
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - top) / height) * 100;
        setPosition(`${x}% ${y}%`);
    }

    return (
        <StZoomImgWrap
            style={{ backgroundColor: bgColor || 'transparent' }}
            useSquare={useSquare}
            onMouseMove={handleMouseMove}
        >
            <StZoomImg
                style={{
                    backgroundImage: `url(${img})`,
                    backgroundPosition: position,
                }}
            />
            <img ref={elmImg} src={img} alt={alt || ''} {...restProps} />
        </StZoomImgWrap>
    );
}
