import React from 'react';

import styled from 'styled-components';

import settings from '../global/settings';
import Container from './Container';
import VideoLightBox from './VideoLightBox';
import * as SharedStyles from '../global/SharedStyles';
const { StLink } = SharedStyles;

const StVideoWithDesc = styled.div`
    .container {
        display: flex;
        flex-direction: column-reverse;

        @media (min-width: ${settings.bp.small.view}) {
            min-height: 466px;
            flex-direction: row;
            background-color: #fff;
        }
    }
`;

const StVideoWithDescText = styled.div`
    padding: 30px;
    background-image: -webkit-repeating-radial-gradient(
        center center,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4) 0,
        transparent 1px,
        transparent 100%
    );
    background-image: -moz-repeating-radial-gradient(
        center center,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4) 0,
        transparent 1px,
        transparent 100%
    );
    background-image: -ms-repeating-radial-gradient(
        center center,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4) 0,
        transparent 1px,
        transparent 100%
    );
    background-image: repeating-radial-gradient(
        center center,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4) 0,
        transparent 1px,
        transparent 100%
    );
    background-size: 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: ${settings.bp.small.view}) {
        width: 50%;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        padding: 59px 80px;
    }

    h5 {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.1em;
        margin: 0 0 10px;

        @media (min-width: ${settings.bp.small.view}) {
            margin-bottom: 5px;
        }
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.1em;
        margin: 0 0 10px;
        color: #4a4a4a;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 30px;
            margin-bottom: 20px;
        }
    }

    h3 {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        letter-spacing: 0.1em;
        margin: 0 0 40px;
        display: none;

        @media (min-width: ${settings.bp.small.view}) {
            display: block;
        }
    }
`;

const StVideoWithDescBgView = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    height: 278px;

    @media (min-width: ${settings.bp.small.view}) {
        height: auto;
        width: 50%;
    }
`;

export default function VideoWithDesc(props) {
    const { text, linkMoreText, linkMoreHref, ...restProps } = props;
    return (
        <StVideoWithDesc>
            <Container>
                <StVideoWithDescText>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                    {linkMoreText && linkMoreHref && (
                        <StLink>
                            <a href={linkMoreHref}>{linkMoreText}</a>
                        </StLink>
                    )}
                </StVideoWithDescText>
                <StVideoWithDescBgView>
                    <VideoLightBox {...restProps} />
                </StVideoWithDescBgView>
            </Container>
        </StVideoWithDesc>
    );
}
