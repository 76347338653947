import styled, { css } from 'styled-components';
import settings from '../../global/settings';

export const StSliderVideoWrap = styled.div`
    .swiper-container {
        .swiper-button-next,
        .swiper-button-prev {
            @media (min-width: ${settings.bp.medium.view}) {
                width: 16vw !important;
            }
        }
    }
`;

export const StSliderVideo = styled.div`
    width: 100vw !important;
    position: relative;
    cursor: pointer;

    @media (min-width: ${settings.bp.medium.view}) {
        width: 68vw !important;

        &:first-child:last-child {
            width: 100vw !important;
        }
    }

    .StButtonPlayContainer {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(8, 8, 8, 0.64);
        transition: background-color 0.3s;

        &:hover {
            background-color: rgba(8, 8, 8, 0.44);
        }
    }
`;

export const StSliderVideoModal = styled.div`
    width: 0;
    height: 0;
    position: relative;
    overflow: hidden;
`;

export const StSliderVideoElm = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    height: 260px !important;

    @media (min-width: ${settings.bp.medium.view}) {
        height: 540px !important;
    }
`;

export const StSliderVideoDesc = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.08em;
    padding: 20px 10%;

    @media (min-width: ${settings.bp.medium.view}) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 8%;
        color: #fff;
        z-index: 5;
        pointer-events: none;

        * {
            color: #fff;
        }
    }

    p {
        margin: 0 0 10px;
    }

    h5 {
        text-transform: uppercase;
        margin: 0;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.09em;
    }
`;
