import React, { useState } from 'react';
import styled from 'styled-components';

import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
import CustomLink from './CustomLink';
import { StProductDetailShopBtns } from './ProductDetail/ProductDetail';
import QuantityButtons from './QuantityButtons';
import DDButton from './DDButton';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
const { StLinkMore } = SharedStyles;

const StProductListItem = styled.div`
    text-align: center;
    color: ${settings.color.gray};
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.05em;

    h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000;
        margin: 0 0 5px;

        a {
            color: currentColor;
            text-decoration: none;
        }
    }

    p {
        margin: 0;
    }

    .StDDButton {
        margin-top: 15px;
    }

    .StLinkMore {
        margin-top: 18px;

        a {
            min-width: 118px;
        }
    }
`;

const StProductListItemImg = styled.div.attrs((props) => ({
    className: 'StProductListItemImg',
}))`
    margin-bottom: 44px;
    position: relative;
`;

export default function ProductItem (props) {
    const {isQuiz} = props
    const [productQuantity, setProductQuantity] = useState(1);

    const handleChange = (e, updateQuantity) => {
        updateQuantity(e.target.value);
        setProductQuantity(e.target.value);
    };
    
    const handleQuantityMinus = updateQuantity => {
        if (productQuantity > 0) {
            updateQuantity(productQuantity - 1);
            setProductQuantity(productQuantity - 1);
        }
    };
    
    const handleQuantityPlus = updateQuantity => {
        updateQuantity(productQuantity + 1);
        setProductQuantity(productQuantity + 1);
    };

    const { link, btnLinkText, btnLinkHref, img, title, text, sku, isLoggedIn, addToCart } =
        props;

    let buttonTitle = (
        <FormattedMessage id="product-shop-addtocart-button" />
    );
    let data = {
        quantity: productQuantity,
        code: sku,
    };

    return (
        <StProductListItem>
            <StProductListItemImg>
                <CustomLink href={link}>
                    <img src={img} alt={title} />
                </CustomLink>
            </StProductListItemImg>
            {title && (
                <h2 style={isQuiz ? {minHeight: '60px', marginBottom: '0px'}: {}}>
                    <CustomLink href={link}>{title}</CustomLink>
                </h2>
            )}
            {isQuiz && <p style={{minHeight: '20px'}}>{title.toUpperCase() === 'CLARIFY SHAMPOO' ? `Use once a week`:` `}</p>}
            <p>{text}</p>
            {btnLinkHref && (
                <StLinkMore>
                    <CustomLink href={btnLinkHref}>
                        {btnLinkText || 'Shop'}
                    </CustomLink>
                </StLinkMore>
            )}
            {isLoggedIn && (
                <StProductDetailShopBtns stack>
                    <QuantityButtons
                        center
                        handleChange={(e, updateQuantity) =>
                            handleChange(e, updateQuantity)
                        }
                        handleQuantityMinus={updateQuantity =>
                            handleQuantityMinus(updateQuantity)
                        }
                        handleQuantityPlus={updateQuantity =>
                            handleQuantityPlus(updateQuantity)
                        }
                        quantity={productQuantity}
                    />
                    <DDButton
                        onClick={() => addToCart(data)}
                        defaultTitle={buttonTitle}
                    />
                </StProductDetailShopBtns>
            )}
        </StProductListItem>
    );
}
