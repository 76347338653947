import React from 'react';
import styled from 'styled-components';

import settings from '../global/settings';
import CustomLink from './CustomLink';

const StImg = styled.div`
    width: 116px;
    margin: 0 auto;

    @media (min-width: ${settings.bp.medium.view}) {
        margin: 0;
    }
`;

export default function Logo(props) {
    const { alt, src, href, ...restProps } = props;
    return (
        <StImg>
            <CustomLink href={href} {...restProps}>
                <img src={src} alt={alt} />
            </CustomLink>
        </StImg>
    );
}
