import React from 'react';

import styled, { css } from 'styled-components';
import settings from '../global/settings';

const StListInfo = styled.div`
    background-color: #f5f5f5;
    padding: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        padding: 0;
        background-color: transparent;
    }
`;

const StListWrap = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin: 0 -33px;
    }
`;

const StListItem = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.08em;
    font-weight: normal;
    border-top: 2px solid #979797;
    padding-top: 20px;
    margin-top: 20px;

    &:first-child {
        padding-top: 0;
        margin-top: 0;
        border-top: none;
    }

    @media (min-width: ${settings.bp.small.view}) {
        padding-top: 0;
        border-top: none;
        margin: 0 33px 40px;
        width: calc(50% - 66px);
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin: 0 33px 80px;
        width: calc(33.333% - 66px);
    }

    h4 {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
        margin: 0 0 16px;
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.07em;
        margin: 0 0 10px;
    }

    p {
        margin: 0 0 24px;
    }

    *:last-child {
        margin-bottom: 0;
    }

    ${props =>
        props.showMobilePin &&
        css`
            padding-left: 32px;
            position: relative;

            &:first-child:before {
                top: 0;
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 20px;
                width: 20px;
                height: 20px;
                background-size: 20px;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAwMNJStYg5z+AAADEklEQVRo3uWZS0hUURzGfzNjpj3U0iApibBoKqmF0UJ6kJSbCIqgokAoN0VY0SaIsFW0CUs3LYqKFhYIIhRFi8oExUUggr2wNCoyxqaJFF/NzG3hAybu/9x77j2jC7+zu/d8/993DjP3nHsuzHUFPHjClFNGmFUsIUScGH28pY3n9KU7bg7n6cISWwcnyU4XPJsaYgr4VPvBWTLM48v55AI+1booNQkPcJm4Bt7CYpTTpvAhbmvCp1odQf/4IA894i0s6v0HqPOBt7C44A9/yCfeIsFu7/hCfvsOYPGVxV4DNBjAW1hc84bfTNKh8CDdtPKGYce/5AovAR4oiz5iD5mTPbPZR4uy93V9fD5jYrkoFTaOwwwpHPN1A5wSi0VYK3hK+SO69usGaBYKxdmucB0QA9zUwwf4JRS66+B8Ivje6QVYKY4k7ODcKc6c1q+gXCjz3tEZIip4N9gb7NerfKF8l2OABN3CHaGmfYBFQpGoi9kbEK7n6AQY1iuSojzh+pBOgJ9CkWIXAaQ+Ay680yoRf8sFDs6w4EyKM2OrDPGxetHBWS/4PujgAV4JhWIUKlzrGBV89ySLtG1sF67n0USWcC+XZvFx06o7A2WKxbWd5TaOIl6Ljr8s0w0Q5JsiQoRzLEgZ+yXl9u2ZDJJfTuupVkYc4QU9RClgIzumNyf2quKO7gzAJsWI9FqMhfp4gDZDATxsyCZ0zAg+6biEi8rki4EATV7xAGcMBNjiJ0AW333iHzshQsq7cbDdgruVxVH6/cwAZPHZx/gb/MEnVOkZP84aEwGCdHoMUGsCD7CVhAd8P7mmAsAtDwGOmMPDUiKa+BZPZ7AKHdfCj7HeLB4CvNQIcMU0HqCEcZf43pTNioNCrntGgF0u+iU5SE86ZgAy6HAx/hvpgU+omEEH/EfxvdKQqpX4BNvSi4cATxUBrqYbD1AkHkF06p+FedNe2yPMIfMPH1m1NgFOzBwe5tH+H75xJvEAq1M+YPWaXHrdqmL6O9KIv52vd9VMBqiaHTwEaMTi/mzhAXJoSN+X0rmif+S00edRAdbNAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAzLTAzVDEzOjM3OjQzKzAwOjAwaFuwDgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMy0wM1QxMzozNzo0MyswMDowMBkGCLIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC');
            }

            @media (min-width: ${settings.bp.small.view}) {
                padding-left: 0;

                &:before {
                    display: none;
                }
            }
        `};
`;

const StTextAction = styled.div`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;
    overflow-wrap: break-word;

    ${props =>
        props.onClick &&
        css`
            cursor: pointer;
        `};
`;

const StTitle = styled.div`
    font-size: 16px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: 2px solid #979797;
    margin-bottom: 20px;
    padding-bottom: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }
`;

function ListInfoItem(props) {
    const {
        showMobilePin,
        subtitle,
        title,
        text,
        textAction,
        onClickTextAction,
        phone,
        website,
        color,
    } = props;

    return (
        <StListItem showMobilePin={showMobilePin}>
            {subtitle && <h4>{subtitle}</h4>}
            {title && <h3>{title}</h3>}
            {color && (
                <img
                    src="/images/color-icon.jpg"
                    alt=""
                    style={{ maxHeight: 48, padding: '2px' }}
                />
            )}
            {text && <p>{text}</p>}
            {phone && <p>{phone}</p>}
            {website && (
                <p>
                    <StTextAction
                        onClick={() => {
                            const url =
                                website.substring(0, 4) === 'http'
                                    ? website
                                    : 'https://' + website;

                            window.open(url);
                            return false;
                        }}
                    >
                        {website}
                    </StTextAction>
                </p>
            )}
            {textAction && (
                <StTextAction onClick={onClickTextAction}>
                    {textAction}
                </StTextAction>
            )}
        </StListItem>
    );
}

export default function ListInfo(props) {
    const { items, showMobileTitleResults, showMobilePin } = props;
    return (
        <StListInfo>
            {showMobileTitleResults && (
                <StTitle>
                    {items.length} Result{items.length !== 1 ? 's' : ''}
                </StTitle>
            )}
            <StListWrap>
                {items.map((item, index) => (
                    <ListInfoItem
                        showMobilePin={showMobilePin}
                        {...item}
                        key={item.id || index}
                    />
                ))}
            </StListWrap>
        </StListInfo>
    );
}
