import React from 'react';

import styled, { css } from 'styled-components';

import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StLinkMore } = SharedStyles;
const { CssButton } = SharedStyles;

import { SignInUp } from '../index';
import { useIntl } from 'gatsby-plugin-react-intl';
import { CountryOptions, StateOptions, ProfessionOptions } from '../global/Data';
import CustomLink from './CustomLink';

import {
    Spacing,
    Container,
    Fieldset,
    FieldsGroup,
    FieldSelect,
    FieldInput,
    FieldSubmit,
    FieldTextarea,
    FieldCheckbox,
    LinkImage,
    FieldRadios,
    FieldLink,
} from '../index';

const StModal = styled.div`
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
`;

const StModalWrapper = styled.div`
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 550px;
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
`;

const StModalContent = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
`;

const StCloseButton = styled.div`
    color: #aaa;
    float: right;
    margin-right: 14px;
    font-size: 28px;
    &:hover {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const StLogo = styled.div`
    width: 30%;
    margin: auto;
    padding-bottom: 20px;
`;

const StLogoWrapper = styled.div`
    display: flex;
`;

const StLogoDivider = styled.div`
    border-left: 1px solid black;
    height: 50px;
`;

const StSignInDivider = styled.div`
    border-top: 1px solid black;
    width: 80%;
    margin: 20px auto 0px;
`;

const StModalTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 600;
    letter-spacing: 0.07em;
`;

const StModalSubTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    opacity: 0.5;
`;

export const StForm = styled.div`
    margin-bottom: 20px;
    padding-bottom: 50px;
    align-items: center;
    width: 100%;
    position: relative;
    display: flex;

    form {
        width: 100%;

        .msg-alert {
            position: absolute;
            left: 25%;
            top: calc(100% - 30px);
            color: #fff;
            border-radius: 0px;
            font-weight: 500;
            max-width: 50%;
            width: 50%;
            text-align: center;

            p {
                padding: 5px 10px;
                margin: 0;
            }
        }

        .mailchimp-wrapper {
            width: 100%;
        }

        .field-radio {
            margin: 10px 10px 0;
            display: inline-block;

            @media (min-width: ${settings.bp.small.view}) {
                margin: 20px 25px 0 45px;
            }

            input {
                position: absolute;
                left: -9999px;
                visibility: hidden;
                opacity: 0;

                &:checked + label {
                    &:after {
                        width: 10.5px;
                        height: 10.5px;
                        left: 1.5px;
                        top: 2.5px;
                        background-color: #000;
                    }
                }
            }

            label {
                cursor: pointer;
                position: relative;
                padding-left: 20px;
                user-select: none;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                }

                &:before {
                    left: 0;
                    top: 1px;
                    width: 14px;
                    height: 14px;
                    border: 2px solid #000;
                }
            }
        }
    }

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 20px;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin-right: 10%;
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.large.view}) {
        margin-right: 15%;
    }

    .StField {
        margin-bottom: 0;
    }

    .StFieldCheckbox {
        margin: 12px 20px;
        label {
            font-size: 13px;
            font-weight: 100;

            a {
                text-decoration: underline;
            }
        }
    }

    StFieldSubmit {
        input {
            width: 200px;
            min-width: 200px;
            text-align: center;
        }
    }

    .StLinkMore {
        display: flex;
        justify-content: center;

        a {
            min-width: 180px;
        }
    }

    input {
        padding: 13px 17px !important;
        height: auto !important;
        text-align: left;
        border: 1px solid #00000020;
        margin: 12px 20px;
        letter-spacing: 0.01em;
        max-width: calc(100% - 40px);
        width: calc(100% - 40px);
    }

    button {
        cursor: pointer;
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 20px;
        height: 60px;
        padding: 0;
        border-radius: 0;
        background-color: #000;
        color: #fff;
        font-size: 10pt;
        text-transform: uppercase;
    }
`;

const StFieldButtonSubmit = styled.div`
    text-align: center;

    & > * {
        ${CssButton};
        min-width: 200px !important;
        width: 200px !important;
        text-align: center !important;
    }

    ${(props) =>
        props.disabled &&
        css`
            opacity: 50%;
            & > * {
                cursor: default !important;
                ${CssButton};
                min-width: 200px !important;
                width: 200px !important;
                text-align: center !important;
            }
        `};
`;

const StFieldWrapper = styled.div`
    margin: 12px 20px;
`;

const StFieldSelectWrapper = styled.div`
    margin: 20px 0px;
`;

const StTitleText = styled.div`
    font-size: 12px;
    @media (min-width: ${settings.bp.small.view}) {
        font-size: 10px;
    }

    margin: 12px 20px;
    color: #8B8B8B;
`;

const StTermsTitle = styled.div`
    font-size: 14px;
    @media (min-width: ${settings.bp.small.view}) {
        font-size: 12px;
    }

    margin: 12px 20px;
    color: #8B8B8B;
`;

const changeToCreate = function (update, mode) {
    update(mode);
};

export default function SignInUpModal(props) {
    const {
        title,
        onClick,
        logos,
        handleChange,
        currentProfession,
        handleSubmit,
        handleCheckboxChange,
        mode,
        signIn,
        create,
        update,
        action,
        forgotPasswordLink,
        submitDisabled
    } = props;
    if (!action) {
        console.log('WARNING: No action provided!');
        return null;
    }

    let intl = useIntl();

    let logo1 = logos[0];
    let logo2 = null;
    if (logos.length > 1) {
        logo2 = logos[1];
    }

    let isSignIn = mode === 1;
    return (
        <StModal
            onClick={(event) => {
                if (event.target === event.currentTarget && onClick) {
                    onClick();
                }
            }}
        >
            <StModalWrapper>
                <StCloseButton onClick={onClick}>&times;</StCloseButton>
                <StModalContent>
                    <StLogoWrapper>
                        {logo1 && logo1.src && (
                            <StLogo>
                                <img src={logo1.src} alt={logo1.alt} />
                            </StLogo>
                        )}
                        {logo2 && logo2.src && (
                            <>
                                <StLogoDivider />
                                <StLogo>
                                    <img src={logo2.src} alt={logo2.alt} />
                                </StLogo>
                            </>
                        )}
                    </StLogoWrapper>
                    <StForm>
                        {!isSignIn && (
                            <form onSubmit={handleSubmit} autoComplete={'off'}>
                                <StTitleText
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                                <Fieldset>
                                    <FieldsGroup>
                                        <FieldInput
                                            type="text"
                                            id="text-id-1"
                                            placeholder={intl.formatMessage({
                                                id: 'form-first-name',
                                            })}
                                            name="firstName"
                                            onChange={handleChange}
                                            required
                                        />
                                        <FieldInput
                                            type="text"
                                            id="text-id-2"
                                            placeholder={intl.formatMessage({
                                                id: 'form-last-name',
                                            })}
                                            name="lastName"
                                            onChange={handleChange}
                                        />
                                    </FieldsGroup>
                                </Fieldset>
                                <StFieldWrapper>
                                    <StFieldSelectWrapper>
                                        <FieldSelect
                                            type="text"
                                            id="profession-id-1"
                                            placeholder={intl.formatMessage({
                                                id: 'signinup-profession',
                                            })}
                                            name="profession"
                                            onChange={handleChange}
                                            options={ProfessionOptions()}
                                            required
                                        />
                                    </StFieldSelectWrapper>
                                </StFieldWrapper>
                                <Fieldset>
                                    <FieldInput
                                        type="text"
                                        id="salon-id-1"
                                        placeholder={intl.formatMessage({
                                            id: 'form-salon-name-title',
                                        })}
                                        name="salonName"
                                        onChange={handleChange}
                                        required
                                    />
                                </Fieldset>
                                {currentProfession === 'salon_owner' && 
                                    <Fieldset>
                                        <FieldInput
                                            type="text"
                                            id="stylist-id-1"
                                            placeholder={intl.formatMessage({
                                                id: 'signinup-num-stylists',
                                            })}
                                            name="numStylists"
                                            onChange={handleChange}
                                            required
                                        />
                                    </Fieldset>
                                }
                                <Fieldset>
                                    <FieldInput
                                        type="text"
                                        id="license-id-1"
                                        placeholder={intl.formatMessage({
                                            id: 'signinup-licence-number',
                                        })}
                                        name="licenseNumber"
                                        onChange={handleChange}
                                        required
                                    />
                                </Fieldset>
                                <Fieldset>
                                    <FieldInput
                                        type="text"
                                        id="address-id-1"
                                        placeholder={intl.formatMessage({
                                            id: 'form-street',
                                        })}
                                        name="addressLine1"
                                        onChange={handleChange}
                                        required
                                    />
                                    <FieldInput
                                        type="text"
                                        id="address-id-2"
                                        placeholder={intl.formatMessage({
                                            id: 'form-apartment',
                                        })}
                                        name="addressLine2"
                                        onChange={handleChange}
                                        required
                                    />
                                    <FieldsGroup>
                                        <FieldInput
                                            type="text"
                                            id="address-id-3"
                                            placeholder={intl.formatMessage({
                                                id: 'form-city',
                                            })}
                                            name="addressCity"
                                            onChange={handleChange}
                                        />
                                        <FieldInput
                                            type="text"
                                            id="address-id-4"
                                            placeholder={intl.formatMessage({
                                                id: 'form-zip-code',
                                            })}
                                            name="addressZip"
                                            onChange={handleChange}
                                            required
                                        />
                                    </FieldsGroup>
                                    <StFieldWrapper>
                                        <StFieldSelectWrapper>
                                            <FieldSelect
                                                type="text"
                                                id="address-id-5"
                                                placeholder={intl.formatMessage(
                                                    { id: 'signinup-state' }
                                                )}
                                                name="addressState"
                                                onChange={handleChange}
                                                options={StateOptions()}
                                                required
                                            />
                                        </StFieldSelectWrapper>
                                        <StFieldSelectWrapper>
                                            <FieldSelect
                                                type="text"
                                                id="address-id-6"
                                                placeholder={intl.formatMessage(
                                                    { id: 'signinup-country' }
                                                )}
                                                name="addressCountry"
                                                onChange={handleChange}
                                                options={CountryOptions()}
                                                required
                                            />
                                        </StFieldSelectWrapper>
                                    </StFieldWrapper>
                                </Fieldset>
                                <Fieldset>
                                    <FieldInput
                                        type="tel"
                                        id="num-id-1"
                                        placeholder={intl.formatMessage({
                                            id: 'form-phone-title',
                                        })}
                                        size={12}
                                        name="phone"
                                        onChange={handleChange}
                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        required
                                    />
                                </Fieldset>
                                <Fieldset>
                                    <FieldInput
                                        type="email"
                                        id="email-id-1"
                                        placeholder={intl.formatMessage({
                                            id: 'form-email-address-text',
                                        })}
                                        name="email"
                                        onChange={handleChange}
                                        required
                                    />
                                </Fieldset>
                                <Fieldset>
                                    <FieldInput
                                        type="password"
                                        id="password"
                                        placeholder={intl.formatMessage({
                                            id: 'signinup-password',
                                        })}
                                        name="password"
                                        onChange={handleChange}
                                        title="Must contain at least one number, one uppercase and lowercase letter, one symbol and at least 8 or more characters"
                                        required
                                    />
                                    <FieldInput
                                        type="password"
                                        id="confirm-password"
                                        placeholder={intl.formatMessage({
                                            id: 'signinup-confirm-password',
                                        })}
                                        name="confirmPassword"
                                        onChange={handleChange}
                                        title="Must contain at least one number, one uppercase and lowercase letter, one symbol and at least 8 or more characters"
                                        required
                                    />
                                </Fieldset>
                                <FieldCheckbox
                                    label={intl.formatMessage({
                                        id: 'signinup-kenra-email-checkbox-text',
                                    })}
                                    id="kenra-consent"
                                    name="kenra-consent"
                                    onChange={handleCheckboxChange}
                                />
                                <FieldCheckbox
                                    label={intl.formatMessage({
                                        id: 'signinup-salonory-email-checkbox-text',
                                    })}
                                    id="salonory-consent"
                                    name="salonory-consent"
                                    onChange={handleCheckboxChange}
                                />
                                <FieldCheckbox
                                    label={intl.formatMessage({
                                        id: 'signinup-rewards-checkbox-text',
                                    })}
                                    id="rewards-consent"
                                    name="rewards-consent"
                                    onChange={handleCheckboxChange}
                                />
                                <StTermsTitle>
                                    {intl.formatMessage({
                                        id: 'signinup-terms-title',
                                    })}
                                </StTermsTitle>
                                <FieldCheckbox
                                    label={intl.formatMessage({
                                        id: 'signinup-terms-checkbox-text',
                                    })}
                                    id="terms"
                                    name="terms"
                                    onChange={handleCheckboxChange}
                                />
                                <StFieldButtonSubmit disabled={submitDisabled}>
                                    <input value={create} type="submit" disabled={submitDisabled}/>
                                </StFieldButtonSubmit>
                            </form>
                        )}
                        {isSignIn && (
                            <form onSubmit={handleSubmit}>
                                <Fieldset>
                                    <FieldInput
                                        type="email"
                                        id="email-id-1"
                                        placeholder={intl.formatMessage({
                                            id: 'form-email-address-text',
                                        })}
                                        name="email"
                                        onChange={handleChange}
                                        required
                                    />
                                </Fieldset>
                                <Fieldset>
                                    <FieldInput
                                        type="password"
                                        id="password"
                                        placeholder={intl.formatMessage({
                                            id: 'signinup-password',
                                        })}
                                        name="password"
                                        onChange={handleChange}
                                        title="Must contain at least one number, one uppercase and lowercase letter, one symbol and at least 8 or more characters"
                                        required
                                    />
                                </Fieldset>
                                <Fieldset>
                                    <FieldLink
                                        url={forgotPasswordLink}
                                        text={intl.formatMessage({
                                            id: 'form-forgot-password-text',
                                        })}
                                    />
                                </Fieldset>
                                <FieldCheckbox
                                    defaultChecked
                                    label={intl.formatMessage({
                                        id: 'signinup-keep-signed-in-text',
                                    })}
                                    id="keep-signed-in"
                                    name="keep-signed-in"
                                />
                                <StFieldButtonSubmit>
                                    <input value={signIn} type="submit" />
                                </StFieldButtonSubmit>
                                <StSignInDivider />
                                <StModalSubTitle>
                                    {intl.formatMessage({
                                        id: 'signinup-new-to-salonory-text',
                                    })}
                                </StModalSubTitle>
                                <StFieldButtonSubmit>
                                    <input
                                        onClick={() => {
                                            changeToCreate(update, 2);
                                        }}
                                        value={create}
                                        type="button"
                                    />
                                </StFieldButtonSubmit>
                            </form>
                        )}
                    </StForm>
                </StModalContent>
            </StModalWrapper>
        </StModal>
    );
}

/*
                <FieldInput
                  type="text"
                  id="address-id-3"
                  placeholder={intl.formatMessage({ id: 'signinup-state' })}
                  name="addressLine3"
                  onChange={handleChange}
                />


                  <FieldInput
                    type="text"
                    id="address-id-4"
                    placeholder={intl.formatMessage({ id: 'signinup-country' })}
                    name="addressCountry"
                    onChange={handleChange}
                  />

                */
