import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import styled, { css } from 'styled-components';

import settings from '../../global/settings';

const StSlider = styled.div.attrs(props => ({
    className: 'StSlider',
}))`
    ${props =>
        props.hideArrows &&
        css`
            .swiper-button-next,
            .swiper-button-prev {
                display: none !important;
            }
        `};

    ${props =>
        props.hideProgress &&
        css`
            .swiper-pagination-progressbar {
                display: none !important;
            }
        `};

    ${props =>
        props.removeProgressTopSpacing &&
        css`
            .swiper-pagination-progressbar {
                margin-top: 0 !important;
            }
        `};

    ${props =>
        props.embeddedSpace &&
        css`
        margin 0 auto;
        max-width: 75%;
      `};

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        @media (min-width: ${settings.bp.small.view}) {
            height: 67px;
        }
    }

    .swiper-container-horizontal
        > .swiper-pagination-bullets
        .swiper-pagination-bullet {
        margin: 0 15px;
    }
    .swiper-container {
        margin-top: 15px;
        @media (min-width: ${settings.bp.small.view}) {
            padding-bottom: 50px;
            margin-top:0;
        }
        .swiper-pagination-bullet {
            width: 100px;
            height: 3px;
            left: 475px;
            top: 742px;
            border-radius: 0px;
            background: #cccccc;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: #000000;
            }
        }
        .swiper-slide {
            height: auto;
        }
        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 35px;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;
            z-index: 2;
            margin-top: 0;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: calc(50% - 30px);
                left: 0;

                ${props =>
                    props.hideProgress &&
                    css`
                        top: 50%;
                    `};
            }

            &:before {
                transform: translate(0, -50%) rotate(45deg);
                width: 8px;
                height: 8px;
                border-left: 2px solid #000;
                border-bottom: 2px solid #000;
                z-index: 2;
                left: 14px;
            }

            &:after {
                transform: translate(0, -50%);
                width: 35px;
                height: 35px;
                border: 1px solid #000;
                background-image: none;
                background-color: #fff;
                z-index: 1;
            }

            &.swiper-button-disabled {
                opacity: 0 !important;
                visibility: hidden !important;
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;

            &:after {
                right: 0;
                left: auto;
            }

            &:before {
                transform: translate(0, -50%) rotate(-135deg);
                right: 14px;
                left: auto;
            }
        }

        &.swiper-container-horizontal > .swiper-pagination-progressbar {
            position: relative;
            height: 2px;
            background-color: rgba(0, 0, 0, 0.4);
            overflow: hidden;
            max-width: 636px;
            width: calc(100% - 40px);
            margin: 60px auto 0;

            .swiper-pagination-progressbar-fill {
                background-color: #000;
            }
        }

        &:hover {
            .swiper-button-next,
            .swiper-button-prev {
                opacity: 1;
                visibility: visible;
            }
        }
    }
`;

let config = {
    speed: 600,
    slidesPerView: 'auto',
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};

export default function NewSlider(props) {
    const [isSwiperEnabled, setSwiper] = useState(true);
    const [swiper, updateSwiper] = useState(null);

    let {
        removeProgressTopSpacing,
        spacingSmall,
        hideProgress,
        hideArrows,
        onlyMobile,
        settings,
        autoPlay,
        swiperUseEffect,
        embeddedSpace,
    } = props;
    config = { ...config, ...settings };
    let timer;

    let autoPlayLoop = false;
    if (autoPlay) {
        autoPlayLoop = true;
    }

    const autoPlayConfig = {
        // autoplay: {
        //     delay: 5000,
        //     disableOnInteraction: false,
        // },
        autoplay: false,
        loop: autoPlayLoop,
        speed: 600,
        slidesPerView: 'auto',
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        getSwiper: updateSwiper,
    };

    useEffect(() => {
        if (!onlyMobile) return;
        checkWidth();
        window.addEventListener('resize', handlerResize);
        return () => {
            window.removeEventListener('resize', handlerResize);
        };
    }, [handlerResize, onlyMobile]);

    useEffect(() => {
        if (swiper && swiperUseEffect) {
            swiperUseEffect(swiper);
        } else if (swiper) {
            const slides = swiper.slides;
            Object.values(slides).forEach(elem => {
                if (
                    elem.querySelector &&
                    elem.classList.contains('swiper-slide-duplicate')
                ) {
                    if (elem.querySelector('img')) {
                        elem.querySelector('img').style.opacity = 1;
                    }
                }
            });
        }
    }, [swiper, swiperUseEffect]);

    function handlerResize() {
        clearTimeout(timer);
        timer = setTimeout(() => {
            checkWidth();
        }, 100);
    }

    function checkWidth() {
        if (window.innerWidth < 768) {
            setSwiper(true);
        } else {
            setSwiper(false);
        }
    }

    return (
        <StSlider
            hideArrows={hideArrows}
            hideProgress={hideProgress}
            removeProgressTopSpacing={removeProgressTopSpacing}
            spacingSmall={spacingSmall}
            embeddedSpace={embeddedSpace}
        >
            {isSwiperEnabled && props.children.length > 1 && autoPlay ? (
                <Swiper {...autoPlayConfig}>{props.children}</Swiper>
            ) : isSwiperEnabled && props.children.length > 1 ? (
                <Swiper {...config}>{props.children}</Swiper>
            ) : (
                props.children
            )}
        </StSlider>
    );
}
