import React from 'react';

import styled, { css } from 'styled-components';
import settings from '../global/settings';

import ScrollableBlock from './ScrollableBlock';

const StCategoryNav = styled.div`
    white-space: nowrap;
    padding: 24px 0 6px;
`;

const StCategoryNavItem = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 160px;
    padding: 0 10px;

    @media (min-width: ${settings.bp.small.view}) {
        width: 280px;
        padding: 0 20px;
    }

    img {
        margin: 0 auto 10px;
        display: block;
        border-radius: 50%;
        width: 65px;
        height: 65px;

        @media (min-width: ${settings.bp.small.view}) {
            min-width: 86px;
            width: 86px;
            height: 86px;
            margin: 0 18px 0 0;
        }
    }

    a {
        display: block;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.04em;
        color: #000;

        @media (min-width: ${settings.bp.small.view}) {
            font-size: 14px;
            display: flex;
            align-items: center;
        }
    }
`;

function CategoryNavItem(props) {
    const { img, title, ...restProps } = props;
    return (
        <StCategoryNavItem>
            <a {...restProps}>
                <img src={img} alt={title} />
                <span>{title}</span>
            </a>
        </StCategoryNavItem>
    );
}

export default function CategoryNav(props) {
    const { items } = props;
    return (
        <StCategoryNav>
            <ScrollableBlock>
                {items.map((item, index) => (
                    <CategoryNavItem key={item.id || index} {...item} />
                ))}
            </ScrollableBlock>
        </StCategoryNav>
    );
}
