import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import VideoLightBox from './VideoLightBox';
import StContainer from './Container';
import * as SharedStyles from '../global/SharedStyles';

import settings from '../global/settings';
const { StLinkMore } = SharedStyles;
import CustomLink from './CustomLink';

const StVideoWithInfo = styled.div`
    @media (min-width: ${settings.bp.medium.view}) {
        .container {
            max-width: 1320px;
            padding: 0 30px 0 30px;

            ${(props) =>
                props.isInfo &&
                css`
                    padding-right: 250px;
                `};
        }
    }

    .container {
        width: 100%;
    }
`;

const StVideo = styled.div`
    padding-bottom: 56.25%;
    position: relative;
`;

const StInfo = styled.div`
    background-color: #e1e6eb;
    padding: 50px 44px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.06em;
    line-height: 1.88;

    @media (min-width: ${settings.bp.medium.view}) {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 2;
        width: 416px;
        backface-visibility: hidden;
    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 14px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 18px;
        }
    }

    p {
        margin: 0 0 16px;
    }

    .StLinkMore {
        margin-top: 40px;
        text-align: center;

        @media (min-width: ${settings.bp.medium.view}) {
            margin-top: 20px;
            text-align: left;
        }

        a {
            width: 100%;
            max-width: 276px;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
`;

export default function VideoWithInfo(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const {
        img,
        videoOptions,
        text,
        textStyle,
        buttonText,
        href,
        ...restProps
    } = props;

    return (
        <StVideoWithInfo isInfo={text}>
            <StContainer>
                <StVideo onClick={() => setModalOpen(!isModalOpen)}>
                    <VideoLightBox
                        bgImg={img}
                        videoOptions={videoOptions}
                        isOpen={isModalOpen}
                        {...restProps}
                    />
                </StVideo>
                {text && (
                    <StInfo style={textStyle}>
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                        {href && (
                            <StLinkMore styleBig>
                                <CustomLink href={href}>
                                    {buttonText}
                                </CustomLink>
                            </StLinkMore>
                        )}
                    </StInfo>
                )}
            </StContainer>
        </StVideoWithInfo>
    );
}
