import React from 'react';
import PropTypes from 'prop-types';
import {
    SharedStyles,
} from '../../kenra-storybook/index';
import styled, { css } from 'styled-components';
const {
    CssButton
} = SharedStyles;

const StFieldButtonSubmit = styled.div`
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    text-align: center;
    max-width: 333px;
    min-width: 200px !important;
    & > * {
        ${CssButton};
        text-align: center !important;
    }

    ${(props) =>
        props.disabled &&
        css`
            opacity: 50%;
            & > * {
                cursor: default !important;
                ${CssButton};
                min-width: 200px !important;
                text-align: center !important;
            }
        `};
        input {
            background-color: black;
        }
`;


const KLAVIYO_ATTRIBUTES = [
    'first_name',
    'last_name',
    'email',
]

class Subscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async handleSubmit(evt) {
        try {
            this.setState({ status: null })
            evt.preventDefault();
            const { fields, action, onSignUp } = this.props;
            var jsonProperties = {};
            var jsonAttributes = {};
            fields.forEach((field) => {
                if (field.name === 'Full Name') {
                    const splitName = this.state[field.name]?.trim()?.split(' ')
                    jsonAttributes.first_name = splitName?.shift()?.trim()
                    jsonAttributes.last_name = splitName?.join(' ')?.trim() || " "
                } else if (KLAVIYO_ATTRIBUTES.includes(field.name)) {
                    jsonAttributes[field.name] = this.state[field.name];
                }
                
            });
            fields.forEach((field) => {
                jsonProperties[field.name] = this.state[field.name];
            });
            const contact = this.state['CustomerContact']
            const location = this.state['CustomerLocation']
            const email = this.state['email'];
            const phone = this.state['phone'];
            const source = "Kenra Professional website footer"
            const jsonData = {
                subscribeMethod: contact,
                attentiveData: {
                    "user": {
                        phone,
                        email,
                    },
                    "subscriptionType": "MARKETING",
                    "signUpSourceId": null,
                    "singleOptIn": false
                    },
                data: {
                type: 'subscription',
                attributes: {
                  custom_source: source,
                  profile: {
                    data: {
                      type: 'profile',
                      attributes: {
                        ...jsonAttributes,
                        properties: {
                            ...jsonProperties
                        }
                      },
                    }
                  }
                }
              }
            }
    
            var postBody = JSON.stringify(jsonData);
            const emailRegex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
            const cellRegex = /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            const emailEntered = emailRegex.test(email)
            const phoneEntered = cellRegex.test(phone)
            if (contact === 'both' && (!emailEntered || !phoneEntered)) {
                if (!emailEntered && phoneEntered) {
                    this.setState({ status: 'emptyEmail' })
                } else if (!phoneEntered && emailEntered) {
                    this.setState({ status: 'emptyPhone' })
                } else if (!emailEntered && !phoneEntered) {
                    this.setState({ status: 'emptyBoth' })
                }
            } else if (contact === 'email' && !emailEntered){
                this.setState({ status: 'emptyEmail' })
            } else if (contact === 'sms' && !phoneEntered) {
                this.setState({ status: 'emptyPhone' })
            } else {
                await this.sendData(action, postBody, onSignUp)
            }
        } catch (e) {
            console.log (e)
        } 
    }

    async sendData(url, body, onSignUp) {
        this.setState({ status: 'sending' });

        await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: body,
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 406) {
                        throw new Error(
                            'Looks like you might be a robot, please try filling the form out manually'
                        );
                    }
                    throw new Error(
                        "Something went wrong with submitting this form. Please try again and if you still can't get in touch online please call us"
                    );
                }
            })
            .then(() => {
                this.setState({ status: 'success' });
                if (onSignUp) {
                    onSignUp();
                }
            })
            .catch((error) => {
                console.log('Subscribe Error', error);
                this.setState({ status: 'error' });
            });
    }

    render() {
        const { fields, email, styles, className, buttonClassName, action, title } =
            this.props;
        const messages = {
            ...Subscribe.defaultProps.messages,
            ...this.props.messages,
        };
        const { status } = this.state;
        return (
            <form onSubmit={this.handleSubmit.bind(this)} className={className}>
                <p hidden>
                    <label>
                        Don’t fill this out:
                        <input
                            name="bot-field"
                            onChange={({ target }) => {
                                this.setState({
                                    'bot-field': target.id,
                                });
                            }}
                        />
                    </label>
                </p>
                <div className="subscribe-wrapper">
                    {title && <h4>{title}</h4>}
                    {fields.map((input, index) => (
                        <div
                            key={input.id || index}
                            className={`field-${input.type}`}
                        >
                            {input.label &&
                                input.type !== 'radio' &&
                                input.type !== 'checkbox' && (
                                    <label htmlFor={input.id}>
                                        {input.label}
                                    </label>
                                )}
                            {input.type !== 'label' && (
                                <input
                                    {...input}
                                    onChange={({ target }) => {
                                        if (
                                            input.type !== 'radio' &&
                                            input.type !== 'checkbox'
                                        ) {
                                            this.setState({
                                                [input.name]: target.value,
                                            });
                                        } else {
                                            this.setState({
                                                [input.name]: target.id,
                                            });
                                        }
                                    }}
                                    defaultValue={this.state[input.name]}
                                />
                            )}
                            {input.label &&
                                (input.type === 'radio' ||
                                    input.type === 'checkbox') && (
                                    <label htmlFor={input.id}>
                                        {input.label}
                                    </label>
                                )}
                        </div>
                    ))}
                    <div>
                        <p>Yes, I’d like to sign up to receive the latest news, special offers, and promotions from Kenra Professional and other Henkel Brands. I can unsubscribe at any time. I accept the <a style={{textDecoration: 'underline'}} href="https://www.henkel-northamerica.com/terms-of-use-na?view=content-blank">Terms of Use</a>. For information on purposes for which we use your information, see our <a style={{textDecoration: 'underline'}} href="https://www.henkel-northamerica.com/privacy-statement-na?view=content-blank">Privacy Policy</a> and <a style={{textDecoration: 'underline'}} href="https://www.henkel-northamerica.com/ca-privacy-rights?pageID=564690">CA Privacy Notice</a>.</p>
                    </div>

                        <StFieldButtonSubmit disabled={status === 'sending' || status === 'success'}>
                                    <input value={'Sign up'} type="submit" disabled={status === 'sending' || status === 'success'}/>
                        </StFieldButtonSubmit>

                </div>
                <div style={styles.sendingMsg} className="msg-alert">
                    {status === 'sending' && <p>{messages.sending}</p>}
                    {status === 'success' && <p>{messages.success}</p>}
                    {status === 'duplicate' && <p>{messages.duplicate}</p>}
                    {status === 'emptyEmail' && <p>{messages.emptyEmail}</p>}
                    {status === 'emptyPhone' && <p>{messages.emptyPhone}</p>}
                    {status === 'emptyBoth' && <p>{messages.emptyBoth}</p>}
                    {status === 'error' && <p>{messages.error}</p>}
                </div>
            </form>
        );
    }
}

Subscribe.defaultProps = {
    messages: {
        sending: 'Sending...',
        success: 'Thank you for subscribing!',
        error: 'An unexpected internal error has occurred.',
        emptyEmail: 'Please enter a valid e-mail.',
        emptyPhone: 'Please enter a valid cell number.',
        emptyBoth: 'Please enter a valid e-mail and cell number.',
        duplicate: 'Looks like you are already subscribed',
        button: 'Submit',
    },
    buttonClassName: '',
    styles: {
        sendingMsg: {
            backgroundColor: '#0652DD',
        },
        successMsg: {
            backgroundColor: '#009432',
        },
        duplicateMsg: {
            backgroundColor: '#EE5A24',
        },
        errorMsg: {
            backgroundColor: '#ED4C67',
        },
    },
};

Subscribe.propTypes = {
    action: PropTypes.string,
    messages: PropTypes.object,
    email: PropTypes.string,
    fields: PropTypes.array,
    styles: PropTypes.object,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
};

export default Subscribe;
