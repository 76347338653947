import React from 'react';
import styled, { css } from 'styled-components';
import settings from '../global/settings';
import Field from './Field';

const StFieldInput = styled.div.attrs(props => ({
    className: 'StFieldInput',
}))`
    max-width: 100%;
    width: 100%;
    ${props =>
        props.quarterWidth &&
        css`
            width: 33%;
            max-width: 33%;
            padding: 6px;
        `};

    @media (min-width: ${settings.bp.small.view}) {
        ${props =>
            props.halfWidth &&
            css`
                width: 50%;
                max-width: 50%;
                padding: 6px;
            `};
    }
    @media (min-width: ${settings.bp.small.view}) {
        ${props =>
            props.quarterWidth &&
            css`
                width: 25%;
                max-width: 25%;
                padding: 6px;
            `};
    }
`;

export default function FieldInput(props) {
    const {
        type,
        label,
        desc,
        id,
        name,
        error,
        halfWidth,
        quarterWidth,
        ...restProps
    } = props;
    return (
        <StFieldInput halfWidth={halfWidth} quarterWidth={quarterWidth}>
            <Field error={error}>
                <input
                    type={type || 'text'}
                    name={name}
                    id={id}
                    {...restProps}
                />
                {label && <label htmlFor={id}>{label}</label>}
                {desc && <div>{desc}</div>}
            </Field>
        </StFieldInput>
    );
}
