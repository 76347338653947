import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import ButtonPlay from './ButtonPlay';
import settings from '../global/settings';
import * as SharedStyles from '../global/SharedStyles';
const { StLink } = SharedStyles;

const StBannerVideoWrap = styled.div`
    text-align: center;

    .StLink {
        text-align: center;
        margin-top: 40px;
    }
`;

const StBannerVideo = styled.div`
    ${(props) =>
        props.maxWidthMedium &&
        css`
            margin-left: auto;
            margin-right: auto;
            max-width: 1022px;
        `};
`;

const StBannerVideoInner = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    background-color: #000;

    iframe,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const StBannerVideoBg = styled.div`
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    background-color: #000;
`;

function GetTypeVideo(props) {
    const { videoType, ...restProps } = props;
    return videoType === 'html' ? (
        <GetTypeHtml {...restProps} />
    ) : (
        <GetTypeIframe {...restProps} />
    );
}

function GetTypeIframe(props) {
    const { videoUrl, ...restProps } = props;
    let srcString = videoUrl;
    if (restProps.autoPlay === true) {
        srcString += '?autoPlay=1&muted=1';
    }

    if (restProps.showControls === false) {
        if (srcString.indexOf('?') > 0) {
            srcString += '&';
        } else {
            srcString += '?';
        }

        srcString +=
            'playbar=false&settingsControl=false&smallPlayButton=false&volumeControl=false';
    }

    if (restProps.autoPlay) {
        return (
            <iframe
                title={'videoframe'}
                allow="autoplay"
                frameBorder={0}
                src={srcString}
            />
        );
    }
    return <iframe title={'videoframe'} frameBorder={0} src={srcString} />;
}

export default function BannerVideo(props) {
    const { videoUrl, videoType, maxWidthMedium, ...restProps } = props;

    return (
        <StBannerVideoWrap>
            <StBannerVideo maxWidthMedium={maxWidthMedium}>
                <StBannerVideoInner>
                    <GetTypeVideo videoUrl={videoUrl} {...restProps} />
                </StBannerVideoInner>
            </StBannerVideo>
        </StBannerVideoWrap>
    );
}
