import React from 'react';

import styled from 'styled-components';

import { FormattedMessage } from 'gatsby-plugin-react-intl';

const StModal = styled.div`
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
`;

const StModalWrapper = styled.div`
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 550px;
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.4);
`;

const StModalContent = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
`;

const StModalCompanyText = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
    margin-bottom: 20px;

    text-transform: uppercase;
    font-size: 15px;
    font-weight: 200;

`;

const StCloseButton = styled.div`
    color: #aaa;
    float: right;
    margin-right: 14px;
    font-size: 28px;
    &:hover {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const StModalTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 600;
    letter-spacing: 0.07em;
`;

const StModalSubTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    font-size: 15px;
    line-height: 1.4;
    color: #818181;
`;

export default function IngredientsModal(props) {
    const { onClick, title, subtitle, isError } = props;

    return (
        <StModal
            onClick={(event) => {
                if (event.target === event.currentTarget && onClick) {
                    onClick();
                }
            }}
        >
            <StModalWrapper>
                <StCloseButton onClick={onClick}>&times;</StCloseButton>
                <StModalContent>
                    {title && <StModalTitle>{title}</StModalTitle>}
                    {isError && <FormattedMessage id="product-ingredient-definition-error" />}
                    {subtitle && !isError && <StModalSubTitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
                </StModalContent>
                <StModalCompanyText>
                    <FormattedMessage id="product-ingredient-powered-text" />
                </StModalCompanyText>
            </StModalWrapper>
        </StModal>
    );
}
