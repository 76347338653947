import React from 'react';
import styled from 'styled-components';

const MdBreakPoint = '768px';

const TitleWrapper = styled.h2`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 21px !important;
    text-align: center;
    color: black !important;

    @media (min-width: ${MdBreakPoint}) {
        font-size: 26px !important;
        line-height: 30px !important;
    }
`;

const Title = ({ children }) => {
    return <TitleWrapper>{children}</TitleWrapper>;
};

export default Title;
