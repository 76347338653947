import React, { useState } from 'react';

import styled, { css, keyframes } from 'styled-components';

import settings from '../global/settings';

import * as SharedStyles from '../global/SharedStyles';
import CustomLink from './CustomLink';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
library.add(faCheck);
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const { StLinkMore } = SharedStyles;
import MobileFilterFixedContent from './MobileFilterFixedContent';

const showItem = keyframes`
  0% {
    opacity: 0;
    transform: translate(-10px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const StFilterHeaderNav = styled.div.attrs((props) => ({
    className: 'StFilterHeaderNav',
}))`
    @media (max-width: ${settings.bp.medium.view}) {
        width: 100%;
    }

    @media (min-width: ${settings.bp.small.view}) {
        width: calc(100% - 50px);
    }

    @media (min-width: ${settings.bp.medium.view}) {
        margin: 0 auto;
        position: relative;
        z-index: 99;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.3s, opacity 0.3s;
        overflow: hidden;
        display: none;
        width: calc(100% - 120px);

        & > div {
            transition: transform 0.3s;
            transform: translate(0, -20px);
            background-color: #fff;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            & > .StFilterNav {
                height: calc(100vh - 170px);
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
            }
        }

        ${(props) =>
            props.filterMenuOpen &&
            css`
                opacity: 1;
                visibility: visible;
                display: block;

                & > div {
                    transform: translate(0, 0);
                }
                & > .StFilterNavItem > .StFilterNavElm {
                    display: block;
                }
            `};

        ${(props) =>
            props.isMobile &&
            css`
                display: none;
            `};

        ${(props) =>
            props.isMobile &&
            props.filterMenuOpen &&
            css`
                display: block;
            `};

        @media (min-width: ${settings.bp.medium.view}) {
            transition: none;
            opacity: 1;
            visibility: visible;
            position: relative;
            overflow: visible;
            display: block;

            & > div {
                transition: none;
                transform: none;
                background-color: transparent;
                position: static;
                padding-top: 0;

                & > .StFilterNav {
                    height: auto;
                    overflow-y: visible;
                }
            }
        }
    }
`;

const StFilterNav = styled.nav.attrs((props) => ({
    className: 'StFilterNav',
}))``;

const StFilterNavFirst = styled.div.attrs((props) => ({
    className: 'StFilterNavFirst',
}))`
  text-transform: uppercase;
  letter-spacing: 0.065em;
  font: normal 22px/1.4 ${settings.font.roboto};
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transform: translateZ(0) scale(1, 1);
  padding: 20px 40px;

  & > .StFilterNavItem > a,
  & > .StFilterNavItem > .StFilterNavElm > a {
      padding: 0px 50px 0px 20px;
      font-weight: 500;
      color: #000;
      @media (max-width: ${settings.bp.medium.view}) {
        padding: 0;
        font-size: 16px;
      }
    }
  }

  @media (max-width: ${settings.bp.medium.view}) {
      padding: 0px
  }


  .sub-nav-hidden {
    & > .StCollapsibleBtn:after,
    & > .StFilterNavSubElmTitle > .StCollapsibleBtn:after {
      display: block;
    }

    & > .StFilterNavSub,
    & > .StFilterNavSubElmInner {
      display: none;
    }
  }

  @media (min-width: ${settings.bp.medium.view}) {
    padding: 0;
    display: flex;
    font-size: 12px;
    font-weight: 500;

    & > .StFilterNavItem {
      padding: 0 10px;
      border: 1px solid #000;

      display:flex;

      & > a {
        font-weight: 500;
        padding: 20px 0;
        height: 60px;

        &:before {
          border-bottom-width: 3px !important;
        }
      }
    }

    .sub-nav-hidden > .StFilterNavSubElmInner {
      display: block;
    }
  }
`;

const StFilterNavItem = styled.div.attrs((props) => ({
    className: 'StFilterNavItem',
}))`
    position: relative;

    @media (min-width: ${settings.bp.medium.view}) {
        position: static;

        .StFilterNavItem {
            opacity: 0;
            animation: ${showItem} 600ms ease-in-out forwards;
        }
    }

    ${(props) =>
        props.filterMenuOpen &&
        css`
            & > .StFilterNavSub {
                display: block;
            }
        `};

    a {
        display: flex;
        color: #8b8b8b;
        text-decoration: none;
        padding: 10px 0;
        position: relative;
        letter-spacing: 0.05em;

        &.active,
        &:hover {
            color: #000;
        }

        ${(props) =>
            props.active &&
            css`
                color: #000;
            `};

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 8px;
            border-bottom: 1px solid #000;
            transition: width 0.3s;
            width: 0;
        }

        @media (min-width: ${settings.bp.medium.view}) {
            padding: 0;
            width: 160px;

            &:before {
                bottom: 0;
            }
        }

        &.with-btn {
            margin: 0;
            @media (min-width: ${settings.bp.medium.view}) {
                margin: auto;
            }
        }
    }
`;

const StFilterNavSub = styled.div.attrs((props) => ({
    className: 'StFilterNavSub',
}))`
    background-color: #fff;
    font-size: 18px;

    &.sub-nav-extended {
        .StFilterNavSubElm {
            text-transform: none;
        }

        .StFilterNavSubElmInner {
            padding: 0 23px;
            font-size: 16px;
            @media (max-width: ${settings.bp.medium.view}) {
                padding: 0px;
                font-size: 14px;
            }
        }
    }

    @media (min-width: ${settings.bp.medium.view}) {
        box-shadow: 0px 5px 8px -2px rgba(0, 0, 0, 0.3);
        font-size: inherit;
        position: absolute;
        top: 100%;
        background-color: #fff;
        margin: 0;
        display: none;
        padding: 20px;

        &.sub-nav-extended {
            transform: translate(-50%, 0);
            left: 50%;
            display: flex;
            width: 100%;
            justify-content: center;

            .StFilterNavSubElm {
                width: 25%;
                margin-right: 20px;
                font-weight: normal;
            }

            .StFilterNavSubElmInner {
                padding: 0;
                font-size: inherit;
            }

            .StFilterNavItem:not(:last-child) {
                margin-bottom: 6px;
            }
        }

        &.sub-nav-default {
            width: 190px;
            margin-left: -20px;

            .StFilterNavItem:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    @media (min-width: ${settings.bp.large.view}) {
        &.sub-nav-extended {
            padding: 30px 45px;

            .StFilterNavSubElm {
            }
        }

        &.sub-nav-default {
            padding: 30px 34px;
            margin-left: -34px;

            .StFilterNavItem:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    @media (min-width: ${settings.bp.large.view}) {
        &.sub-nav-extended {
            padding: 30px 95px;
            margin: 1px;
        }
    }
`;

const StFilterNavElm = styled.div.attrs((props) => ({
    className: 'StFilterNavElm',
}))`
    text-decoration: none;
    img {
        width: 40px;
        display: none;
        @media (min-width: ${settings.bp.medium.view}) {
            display: block;
        }
    }
    &:hover {
        cursor: pointer;
    }
    cursor: default;
    display: flex;

    @media (max-width: ${settings.bp.medium.view}) {
        a {
            padding: 5px 0px;
            width: 270px;
        }
    }
`;

const StFilterNavSubElm = styled.div.attrs((props) => ({
    className: 'StFilterNavSubElm',
}))`
    position: relative;
    @media (max-width: ${settings.bp.medium.view}) {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
`;

const StFilterNavSubElmInner = styled.div.attrs((props) => ({
    className: 'StFilterNavSubElmInner',
}))`
    @media (max-width: ${settings.bp.medium.view}) {
        font-weight: 500;
        padding: 5px 0px;
        font-size: 14px;
    }
`;

const StFilterNavSubElmTitle = styled.div.attrs((props) => ({
    className: 'StFilterNavSubElmTitle',
}))`
    cursor: pointer;
    text-transform: uppercase;
    font-weight: normal;
    padding: 10px 50px 10px 0;

    @media (min-width: ${settings.bp.medium.view}) {
        margin-bottom: 10px;
        font-weight: 500;
        padding: 0;
        cursor: default;
    }
    @media (max-width: ${settings.bp.medium.view}) {
        font-weight: 500;
        padding: 5px 0px;
        font-size: 14px;
    }
`;

const StCheck = styled.div.attrs((props) => ({
    className: 'StCheck',
}))`
    content: '';
    width: 10px;
    height: 6px;
    border-left: 2px solid rgba(0, 0, 0, 0.5);
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 2px;
    top: 9px;
    transform: rotate(-45deg);
    @media (min-width: ${settings.bp.medium.view}) {
        top: 4px;
    }
`;

const StFixedMenuMobileBtn = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 49;
    background-color: #fff;
    padding: 20px;
    text-align: center;

    ${(props) =>
        props.filterMenuOpen &&
        css`
            display: none;
        `};

    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }

    a {
        min-width: 182px;
    }
`;

const StMobileView = styled.div`
    display: flex;
    @media (min-width: ${settings.bp.medium.view}) {
        display: none;
    }
`;

const StDesktopView = styled.div`
    display: none;
    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
    }
`;

function NavListItemSub(props) {
    let [isOpen, toggleOpen] = useState(false);
    const {
        item,
        menuCallback,
        filterMenuOpen,
        setFilter,
        upperIndex,
        categoryFilter,
        setMenuOpen,
        isMobile,
    } = props;
    const { itemsTitle, items } = item;

    return (
        <StFilterNavSubElm
            className={
                !filterMenuOpen && itemsTitle ? 'sub-nav-hidden' : undefined
            }
        >
            {itemsTitle && (
                <StFilterNavSubElmTitle>{itemsTitle}</StFilterNavSubElmTitle>
            )}
            <StFilterNavSubElmInner>
                {items.map((item, index) => (
                    <FilterNavListItem
                        key={item.id || index}
                        item={item}
                        index={upperIndex}
                        menuCallback={menuCallback}
                        filterMenuOpen={filterMenuOpen}
                        setFilter={setFilter}
                        categoryFilter={categoryFilter}
                        setMenuOpen={setMenuOpen}
                        isMobile={isMobile}
                    />
                ))}
            </StFilterNavSubElmInner>
        </StFilterNavSubElm>
    );
}

function FilterNavListItem(props) {
    let [isOpen, toggleOpen] = useState(false);
    const {
        item,
        index,
        menuCallback,
        filterMenuOpen,
        setFilter,
        categoryFilter,
        setMenuOpen,
        isMobile,
    } = props;
    const { title, href, image, isActive, items, itemIndex } = item;

    let active = false;
    if (
        typeof categoryFilter !== 'undefined' &&
        categoryFilter.length >= index &&
        categoryFilter[index].length > 0 &&
        categoryFilter[index].includes(itemIndex)
    ) {
        active = true;
    }

    return (
        <StFilterNavItem
            style={{ animationDelay: `${index * 50}ms` }}
            className={[
                !filterMenuOpen && items ? 'sub-nav-hidden' : undefined,
                isActive ? 'active' : undefined,
            ]}
            filterMenuOpen={filterMenuOpen}
            active={active}
        >
            {title && (
                <StFilterNavElm>
                    {items && !isMobile && (
                        <CustomLink className={'with-btn'} href={href}>
                            {title}
                        </CustomLink>
                    )}
                    {items && isMobile && (
                        <CustomLink className={undefined} href={href}>
                            {title}
                        </CustomLink>
                    )}
                    {!items && (
                        <>
                            <StMobileView>
                                <CustomLink
                                    className={undefined}
                                    href={href}
                                    onClick={() =>
                                        setFilter([{ index, itemIndex }])
                                    }
                                >
                                    {title}
                                    {active && <StCheck></StCheck>}
                                </CustomLink>
                            </StMobileView>
                            <StDesktopView>
                                <CustomLink
                                    className={undefined}
                                    href={href}
                                    onClick={() => {
                                        setFilter([{ index, itemIndex }]);
                                        setMenuOpen(!filterMenuOpen);
                                    }}
                                >
                                    {title}
                                    {active && <StCheck></StCheck>}
                                </CustomLink>
                            </StDesktopView>
                        </>
                    )}
                    {image && !isMobile && (
                        <img
                            src={image}
                            onClick={() => setMenuOpen(!filterMenuOpen)}
                        />
                    )}
                </StFilterNavElm>
            )}
            {items && (
                <>
                    <StFilterNavSub
                        className={
                            items.length > 1
                                ? 'sub-nav-extended'
                                : 'sub-nav-default'
                        }
                    >
                        {items.map((item, index) => (
                            <NavListItemSub
                                key={item.id || index}
                                item={item}
                                menuCallback={menuCallback}
                                filterMenuOpen={filterMenuOpen}
                                setFilter={setFilter}
                                upperIndex={index}
                                categoryFilter={categoryFilter}
                                setMenuOpen={setMenuOpen}
                                isMobile={isMobile}
                            />
                        ))}
                    </StFilterNavSub>
                </>
            )}
        </StFilterNavItem>
    );
}

export default function FilterNav(props) {
    const [filterMenuOpen, setFilterMenuOpen] = useState(false);
    const [isMobileActive, toggleMobileActive] = useState(false);

    const {
        items,
        menuCallback,
        setFilter,
        clearFilter,
        categoryFilter,
        isMobile,
        mobileText,
        clearText,
        applyText,
    } = props;

    let btnItems = [
        {
            text: clearText,
            method: clearFilter,
        },
        {
            text: applyText,
            method: setFilterMenuOpen,
        },
    ];
    return (
        <>
            <MobileFilterFixedContent
                show={filterMenuOpen}
                toggleShow={setFilterMenuOpen}
                btnItems={btnItems}
            >
                <StFilterHeaderNav
                    filterMenuOpen={filterMenuOpen}
                    isMobile={isMobile}
                >
                    <div>
                        <StFilterNav>
                            <StFilterNavFirst isMobile={isMobile}>
                                {items.map((item, index) => (
                                    <FilterNavListItem
                                        key={item.id || index}
                                        item={item}
                                        menuCallback={menuCallback}
                                        filterMenuOpen={filterMenuOpen}
                                        setFilter={setFilter}
                                        categoryFilter={categoryFilter}
                                        setMenuOpen={setFilterMenuOpen}
                                        isMobile={isMobile}
                                    />
                                ))}
                            </StFilterNavFirst>
                        </StFilterNav>
                    </div>
                </StFilterHeaderNav>
            </MobileFilterFixedContent>
            <StFixedMenuMobileBtn filterMenuOpen={filterMenuOpen}>
                <StLinkMore>
                    <CustomLink
                        href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            setFilterMenuOpen(!filterMenuOpen);
                        }}
                    >
                        {mobileText}
                    </CustomLink>
                </StLinkMore>
            </StFixedMenuMobileBtn>
        </>
    );
}
