/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
// You can delete this file if you're not using it
import './src/components/Layout/global.css';

import { AuthProvider } from './src/provider/AuthContext';
export const wrapRootElement = ({ element }) => (
    <AuthProvider>{element}</AuthProvider>
);

export const onInitialClientRender = () => {
    const isProfessional = JSON.parse(localStorage.getItem('isProfessional'));
    if (isProfessional !== null && isProfessional === 1) {
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = 'https://cdn.attn.tv/kenra/dtag.js';
        document.body.appendChild(scriptElement);
    }
};
